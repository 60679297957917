/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgSoftware = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.843} viewBox="0 0 28.843 20.787" {...props}>
    <g transform="translate(0 -71.5)">
      <path
        data-name="Path 159"
        d="M28.422 75.978h-28v-2.366a1.69 1.69 0 011.69-1.69H26.73a1.69 1.69 0 011.69 1.69z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 160"
        d="M26.73 71.922v4.056h1.69v-2.366a1.69 1.69 0 00-1.69-1.69z"
        fill="#e8edf2"
      />
      <path
        data-name="Path 161"
        d="M26.73 91.864H2.112a1.69 1.69 0 01-1.69-1.686v-14.2h28v14.2a1.69 1.69 0 01-1.692 1.686z"
        fill="#12b05e"
      />
      <path
        data-name="Path 162"
        d="M26.73 75.978v15.886a1.69 1.69 0 001.69-1.69V75.978z"
        fill="#03984b"
      />
      <circle
        data-name="Ellipse 9"
        cx={0.845}
        cy={0.845}
        r={0.845}
        transform="translate(2.282 73.126)"
        fill="#29cef6"
      />
      <circle
        data-name="Ellipse 10"
        cx={0.845}
        cy={0.845}
        r={0.845}
        transform="translate(5.558 73.126)"
        fill="#f3f3f3"
      />
      <circle
        data-name="Ellipse 11"
        cx={0.845}
        cy={0.845}
        r={0.845}
        transform="translate(8.834 73.126)"
        fill="#f78e36"
      />
      <g data-name="Group 38">
        <path
          data-name="Path 166"
          d="M26.73 71.5h-1.479a.422.422 0 100 .845h1.479A1.269 1.269 0 0128 73.612v1.943H.845v-1.943a1.269 1.269 0 011.267-1.267H23.4a.422.422 0 100-.845H2.112A2.115 2.115 0 000 73.612v4.658a.422.422 0 10.845 0V76.4H28v10.894a.422.422 0 10.845 0V73.612A2.115 2.115 0 0026.73 71.5z"
        />
        <path
          data-name="Path 167"
          d="M28.42 88.653a.422.422 0 00-.423.422v1.1a1.269 1.269 0 01-1.267 1.267H2.112a1.269 1.269 0 01-1.267-1.267V80.083a.422.422 0 00-.845 0v10.091a2.115 2.115 0 002.112 2.112H26.73a2.115 2.115 0 002.112-2.112v-1.1a.422.422 0 00-.422-.421z"
        />
        <g data-name="Group 39">
          <path
            data-name="Path 168"
            d="M11.336 80.68a.423.423 0 00-.6 0l-2.539 2.539a.423.423 0 000 .6l2.505 2.505a.424.424 0 10.6-.6l-2.207-2.207 2.24-2.24a.422.422 0 00.001-.597z"
          />
          <path
            data-name="Path 169"
            d="M17.018 86.322a.422.422 0 00.6 0l2.505-2.505a.423.423 0 000-.6l-2.539-2.539a.424.424 0 00-.6.6l2.24 2.24-2.207 2.207a.423.423 0 00.001.597z"
          />
          <path
            data-name="Path 170"
            d="M12.66 87.879a.423.423 0 00.4-.273l3-7.909a.423.423 0 10-.79-.3l-3 7.909a.423.423 0 00.395.573z"
          />
        </g>
        <path
          data-name="Path 171"
          d="M1.859 73.972a1.267 1.267 0 101.267-1.268 1.269 1.269 0 00-1.267 1.268zm1.69 0a.422.422 0 11-.422-.423.423.423 0 01.426.423z"
        />
        <path
          data-name="Path 172"
          d="M5.136 73.972a1.267 1.267 0 101.267-1.268 1.269 1.269 0 00-1.267 1.268zm1.69 0a.423.423 0 11-.423-.423.423.423 0 01.423.423z"
        />
        <path
          data-name="Path 173"
          d="M8.412 73.972a1.267 1.267 0 101.267-1.268 1.269 1.269 0 00-1.267 1.268zm1.69 0a.422.422 0 11-.423-.423.423.423 0 01.423.423z"
        />
      </g>
    </g>
  </svg>
);

SvgSoftware.defaultProps = {};
export default SvgSoftware;
/* eslint-enable */
