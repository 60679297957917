/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgResumeViewedToday = (props: SVGProps<SVGSVGElement>) => (
  <svg width={54.179} viewBox="0 0 54.179 54.161" {...props}>
    <g data-name="Resume Viewed Today">
      <g data-name="Group 98">
        <g data-name="Group 96">
          <g data-name="Group 92">
            <g data-name="Group 89">
              <path
                data-name="Path 310"
                d="M50.264 42.05V13.474a3.511 3.511 0 00-1.028-2.482l-9.166-9.17A3.509 3.509 0 0037.588.794H7.426a3.51 3.51 0 00-3.51 3.511V42.05z"
                fill="#f4fbff"
              />
              <g data-name="Group 88">
                <path
                  data-name="Path 311"
                  d="M49.236 10.992l-5.674-5.676V42.05h6.7V13.474a3.512 3.512 0 00-1.026-2.482z"
                  fill="#e4f6ff"
                />
              </g>
              <path
                data-name="Path 312"
                d="M49.236 10.991L40.07 1.822a3.508 3.508 0 00-1.028-.713v8.737a2.176 2.176 0 002.176 2.177h8.731a3.508 3.508 0 00-.713-1.032z"
                fill="#12b05e"
              />
            </g>
            <g data-name="Group 91">
              <g data-name="Group 90" fill="#fff">
                <path
                  data-name="Path 313"
                  d="M21.109 21.883H11.65a1.854 1.854 0 01-1.854-1.854V8.296a1.854 1.854 0 011.854-1.854h9.458a1.854 1.854 0 011.854 1.854v11.733a1.854 1.854 0 01-1.853 1.854z"
                />
                <path
                  data-name="Path 314"
                  d="M16.379 15.959a6.922 6.922 0 00-6.511 4.577 1.854 1.854 0 001.782 1.347h9.459a1.854 1.854 0 001.782-1.347 6.922 6.922 0 00-6.512-4.577z"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 94">
            <g data-name="Group 93">
              <path
                data-name="Path 315"
                d="M16.794 28.489l-1.288-1.461a3.329 3.329 0 00-2.5-1.127H3.235a2.441 2.441 0 00-2.441 2.441v22.5a2.531 2.531 0 002.531 2.531h47.53a2.531 2.531 0 002.531-2.531v-18.78a2.441 2.441 0 00-2.441-2.441H19.294a3.329 3.329 0 01-2.5-1.132z"
                fill="#12b05e"
              />
            </g>
          </g>
          <g data-name="Group 95" fill="#03984b">
            <path
              data-name="Path 316"
              d="M8.803 27.028l1.288 1.461a3.329 3.329 0 002.5 1.127h6.7a3.329 3.329 0 01-2.5-1.127l-1.288-1.461a3.329 3.329 0 00-2.5-1.127h-6.7a3.33 3.33 0 012.5 1.127z"
            />
            <path
              data-name="Path 317"
              d="M50.945 29.617h-6.7a2.441 2.441 0 012.441 2.441v18.78a2.531 2.531 0 01-2.531 2.53h6.7a2.53 2.53 0 002.531-2.53V32.055a2.441 2.441 0 00-2.441-2.438z"
            />
          </g>
        </g>
        <g data-name="Group 97">
          <ellipse
            data-name="Ellipse 35"
            cx={2.953}
            cy={2.954}
            rx={2.953}
            ry={2.954}
            fill="#ffcd27"
            transform="translate(13.426 10.746)"
          />
        </g>
      </g>
      <g data-name="Group 99">
        <path
          data-name="Path 318"
          d="M28.959 21.488h15.424a.794.794 0 100-1.587H28.959a.794.794 0 100 1.587z"
        />
        <path
          data-name="Path 319"
          d="M28.959 16.933h15.424a.794.794 0 100-1.587H28.959a.794.794 0 100 1.587z"
        />
        <path
          data-name="Path 320"
          d="M21.108 22.676a2.651 2.651 0 002.648-2.648V8.295a2.651 2.651 0 00-2.648-2.648H11.65a2.651 2.651 0 00-2.648 2.648v11.733a2.651 2.651 0 002.648 2.648zM11.65 7.234h9.458a1.062 1.062 0 011.061 1.061v9.483a7.676 7.676 0 00-2.7-1.967 3.747 3.747 0 10-6.188 0 7.677 7.677 0 00-2.7 1.967V8.295a1.062 1.062 0 011.061-1.061zm2.57 6.466a2.159 2.159 0 112.159 2.16 2.162 2.162 0 01-2.159-2.16zm-3.5 6.837a6.1 6.1 0 013.9-3.528 3.736 3.736 0 003.513 0 6.1 6.1 0 013.9 3.528 1.061 1.061 0 01-.93.552H11.65a1.062 1.062 0 01-.93-.552z"
        />
        <path
          data-name="Path 321"
          d="M53.385 40.988a.794.794 0 00.794-.794v-8.137a3.238 3.238 0 00-3.122-3.231V13.474a4.276 4.276 0 00-1.26-3.044l-9.166-9.169A4.275 4.275 0 0037.587 0H7.425a4.309 4.309 0 00-4.3 4.3v17.891a.794.794 0 001.587 0V4.305a2.72 2.72 0 012.713-2.718h30.162a2.736 2.736 0 01.66.08v8.176a2.973 2.973 0 002.969 2.97h8.172a2.738 2.738 0 01.08.661v15.344H19.289a2.537 2.537 0 01-1.9-.858L16.1 26.503a4.125 4.125 0 00-3.092-1.4H3.234A3.238 3.238 0 000 28.342v22.5a3.328 3.328 0 003.324 3.324h47.53a3.328 3.328 0 003.324-3.324v-6.937a.794.794 0 00-1.587 0v6.932a1.739 1.739 0 01-1.737 1.737H3.324a1.739 1.739 0 01-1.737-1.737v-22.5a1.649 1.649 0 011.647-1.647h9.772a2.537 2.537 0 011.9.858l1.294 1.47a4.125 4.125 0 003.092 1.4h31.652a1.649 1.649 0 011.647 1.647v8.138a.794.794 0 00.794.794zM39.835 9.843V2.71l8.513 8.516h-7.131a1.384 1.384 0 01-1.382-1.383z"
        />
      </g>
    </g>
  </svg>
);

SvgResumeViewedToday.defaultProps = {};
export default SvgResumeViewedToday;
/* eslint-enable */
