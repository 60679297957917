import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Collapse,
    IconButton, Typography,
} from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp } from '@mui/icons-material';

import UnderlineBow from '../../foundation/UnderlineBow';
import Anchor from '../../foundation/Anchor';
import { CenteredFlex } from '../../foundation/Flex';

export type CollapseableProps = {
    title: string
    children: JSX.Element | JSX.Element[]
    height?: number
    showAll?: boolean
}

const CollapseableStyled = styled.div`
    background-color: #f9f9fb;
    border-radius: 10px;
    padding: 1rem 2rem;
    padding-inline-end: 1rem;
`;
const CollapseableTitleStyled = styled.div`
    display: flex;
    align-items: center;
    /* padding: 0.6rem; */
    div:last-child {
        margin-inline-start: auto;
    }
`;

const CollapseableArea = (props: CollapseableProps): JSX.Element => {
    const [open, setOpen] = useState<boolean>(true);
    const [secondStep, setSecondStep] = useState<boolean>(false);

    return <CollapseableStyled>
        <div>
            <CollapseableTitleStyled>
                <div>
                    <UnderlineBow>
                        <Typography color="secondary" variant="h5" fontWeight="bold">{props.title}</Typography>
                    </UnderlineBow>
                </div>
                <div>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </div>
            </CollapseableTitleStyled>
            <Collapse in={open}>
                <div style={props.showAll || secondStep ? {} : { height: `${props.height || 300}px`, overflowY: 'hidden' }}>
                    {props.children}
                </div>
                {!props.showAll && !secondStep && <CenteredFlex>
                    <Anchor href="#" onClick={ev => {
                        ev.preventDefault();
                        setSecondStep(true);
                    }} text="הצג הכל ▼" />
                </CenteredFlex>}
            </Collapse>
        </div>
    </CollapseableStyled>;
};

export default CollapseableArea;
