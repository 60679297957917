import { Container, Grid, Typography } from '@mui/material';
import { Facebook as FacebookIcon } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import Anchor from '../../foundation/Anchor';
import CustomBR from '../../foundation/CustomBR';
import { centeredFlex } from '../../foundation/Flex';

export type FooterProps = {
    children: JSX.Element | JSX.Element[]
}

const FooterTopStyled = styled.div`
    background-color: ${props => props.theme.palette.secondary.main};
    h3 {
        font-weight: bold;
    }
    p {
        opacity: 0.6;
    }
    a, a:hover {
        opacity: 1;
        text-decoration: none;
    }

`;
const FooterBottomStyled = styled.div`
    padding: 2rem 0;
    background-color: ${props => props.theme.palette.secondary.dark};
    p {
        opacity: 0.6;
    }
`;

const FacebookButton = styled.span`
    ${centeredFlex}
    justify-content: space-evenly;
    width: 160px;
    width: 180px;
    height: 60px;
    background-color: #eef8ff;
    border-radius: 0.6rem;
    > span {
        font-size: 18px;
        font-weight: bold;
        color: #092436;
    }
`;
const Footer = (): JSX.Element => <div>
    <FooterTopStyled>
        <Container>
            <CustomBR times={2} />
            <Grid container spacing={5}>
                <Grid item sm={12} md={6}>
                    <Typography variant="h3" color="white">קצת עלינו</Typography>
                    <CustomBR />
                    <Typography variant="body1" color="white">
                        Mploy הוא לוח דרושים שנועד לסייע לכל אחד למצוא עבודה או לפרסם משרה בקלות, מטרתנו היא לייצר פלטפורמה איכותית, חדשנית וחברתית לשוק העבודה בישראל.
                        <CustomBR times={2} />
                        אנו חוסכים זמן יקר למועמדים על ידי איסוף וריכוז משרות חמות ועדכניות ממאות מקומות ברשת.
                        <CustomBR times={2} />
                        בנוסף לכך, יצרנו אפליקציה איכותית ראשונה מסוגה המאפשרת בקלות לחפש עבודה ולשלוח קורות חיים מכל מקום ובכל זמן.
                    </Typography>
                    <CustomBR />
                    <Anchor blank href="https://www.facebook.com/mploy100/">
                        <FacebookButton>
                            <span>Facebook</span>
                            <FacebookIcon fontSize="large" fill="#1877f2" htmlColor="#1877f2" />
                        </FacebookButton>
                    </Anchor>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Typography variant="h3" color="primary">Mploy אצלכם בנייד</Typography>
                    <Typography variant="body1" color="white">
                        <CustomBR />
                        השקנו עבורכם אפליקציה מגניבה וקלה לשימוש לחיפוש עבודה במהירות מכל מקום ובכל זמן.
                        <CustomBR times={2} />
                    </Typography>
                    <Grid container>
                        <Grid item sm={12} lg={6}>
                            <Anchor title="הורדת אפליקציית Mploy - דרושים לאנדרואיד"
                                blank href="https://play.google.com/store/apps/details?id=com.chikichak.phone">
                                <img alt="הורדת אפליקציית Mploy - דרושים לאנדרואיד"
                                    width="201.5" height="60"
                                    src="/images/icon-google-play.png" />
                            </Anchor>
                        </Grid>
                        {/* <Grid item sm={12} lg={6}>
                            <Anchor title="הורדת אפליקציית Mploy - דרושים לאייפון"
                                blank
                                width="201.5" height="60"
                                // eslint-disable-next-line max-len
                                href="https://itunes.apple.com/us/app/chikichak-%D7%97%D7%99%D7%A4%D7%95%D7%A9-%D7%A2%D7%91%D7%95%D7%93%D7%94-%D7%93%D7%A8%D7%95%D7%A9%D7%99%D7%9D/id1201748100?ls=1&mt=8">
                                <img alt="הורדת אפליקציית Mploy - דרושים לאייפון" style={{ height: '60px' }}
                                    src="/images/icon-app-store.svg" />
                            </Anchor>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
            <CustomBR times={3} />
        </Container>
    </FooterTopStyled>
    <FooterBottomStyled>
        <Container>
            <Grid container alignItems="center" alignContent="center" justifyContent="space-between">
                <Grid item sm={12} md={6}><Typography color="white">© Mploy - כל הזכויות שמורות.</Typography></Grid>
                <Grid item sm={12} md={6}>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Anchor href="/" text="דף הבית" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/home/privacy" text="מדיניות הגנת הפרטיות" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/home/regulations" text="תנאי שימוש" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/home/contactus" text="צור קשר" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/about/show" text="אודות" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/allTags" text="תגיות" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/qsa" text="הרשמה לסוכן חכם" title="הרשמה מהירה לסוכן החכם שלנו" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/blogs" text="הבלוג שלנו" title="הבלוג שלנו" />
                        </Grid>
                        <Grid item>
                            <Anchor href="/user/buyJobs" text="גיוס עובדים" title="הצטרפות כמעסיק וגיוס עובדים" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>

    </FooterBottomStyled>
</div>;

export default Footer;
