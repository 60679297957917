/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgJobsOnSite = (props: SVGProps<SVGSVGElement>) => (
  <svg width={53.924} viewBox="0 0 53.924 53.778" {...props}>
    <g data-name="Jobs on site">
      <g data-name="Group 124">
        <g data-name="Group 116">
          <g data-name="Group 115">
            <path
              data-name="Path 364"
              d="M50.365 41.958H3.558A2.768 2.768 0 01.79 39.189V3.558A2.768 2.768 0 013.558.79h46.808a2.768 2.768 0 012.768 2.768v35.631a2.768 2.768 0 01-2.769 2.769z"
              fill="#f4fbff"
            />
            <path
              data-name="Path 365"
              d="M50.365.79h-6.712a2.768 2.768 0 012.768 2.768v35.631a2.768 2.768 0 01-2.768 2.768h6.712a2.768 2.768 0 002.768-2.768V3.558A2.768 2.768 0 0050.365.79z"
              fill="#e4f6ff"
            />
            <path
              data-name="Path 366"
              d="M43.631 33.059H10.292a2.79 2.79 0 01-2.79-2.79v-11.57a2.79 2.79 0 012.79-2.79H43.63a2.79 2.79 0 012.79 2.79v11.565a2.79 2.79 0 01-2.789 2.795z"
              fill="#ffcd27"
            />
            <path
              data-name="Path 367"
              d="M50.365.79H3.558A2.768 2.768 0 00.79 3.558v4.787h52.344V3.558A2.768 2.768 0 0050.365.79z"
              fill="#12b05e"
            />
            <path
              data-name="Path 368"
              d="M46.421 3.558v4.787h6.712V3.558A2.768 2.768 0 0050.365.79h-6.712a2.769 2.769 0 012.768 2.768z"
              fill="#03984b"
            />
          </g>
        </g>
        <g data-name="Group 123">
          <g data-name="Group 122">
            <g data-name="Group 121">
              <g data-name="Group 120">
                <g data-name="Group 119">
                  <path
                    data-name="Path 369"
                    d="M25.918 33.428a1.944 1.944 0 00-1.359.445V22.045a1.981 1.981 0 00-1.879-2A1.95 1.95 0 0020.659 22v9.449a1.95 1.95 0 00-2.021-1.949 1.981 1.981 0 00-1.879 2v1.879a1.95 1.95 0 00-2.021-1.949 1.981 1.981 0 00-1.879 2v1.08a1.95 1.95 0 00-2.021-1.949 1.981 1.981 0 00-1.879 2v6.321a7.817 7.817 0 00.633 3.081l1.66 3.924a6.262 6.262 0 01.495 2.44v1.733h12.032a11.159 11.159 0 011.542-5.087l.868-1.159a7.816 7.816 0 001.562-4.688v-5.687a1.983 1.983 0 00-1.834-2z"
                    fill="#ffddce"
                  />
                  <path
                    data-name="Path 370"
                    d="M18.658 29.497a1.9 1.9 0 00-.918.264 1.949 1.949 0 01.969 1.686v1.8a1.95 1.95 0 001.95 1.95v-3.748a1.95 1.95 0 00-2.001-1.952z"
                    fill="#ffcbbe"
                  />
                  <path
                    data-name="Path 371"
                    d="M24.559 33.872V22.044a1.982 1.982 0 00-1.684-1.979 1.942 1.942 0 00-1.086.165 1.919 1.919 0 01.872 1.767v9.977a1.9 1.9 0 001.898 1.898z"
                    fill="#ffcbbe"
                  />
                  <g data-name="Group 117">
                    <path
                      data-name="Path 372"
                      d="M16.759 33.375a1.95 1.95 0 00-2-1.949 1.9 1.9 0 00-.918.264 1.949 1.949 0 01.968 1.685v.712a1.95 1.95 0 001.95 1.95z"
                      fill="#ffcbbe"
                    />
                  </g>
                  <g data-name="Group 118">
                    <path
                      data-name="Path 373"
                      d="M10.859 32.549a1.9 1.9 0 00-.918.264 1.949 1.949 0 01.969 1.685v.426a1.95 1.95 0 001.95 1.95v-2.372a1.95 1.95 0 00-2.001-1.953z"
                      fill="#ffcbbe"
                    />
                  </g>
                </g>
                <path
                  data-name="Path 374"
                  d="M11.488 52.988H24.22a1.22 1.22 0 001.22-1.22v-2.2a1.22 1.22 0 00-1.22-1.22H11.488a1.22 1.22 0 00-1.22 1.22v2.2a1.22 1.22 0 001.22 1.22z"
                  fill="#12b05e"
                />
                <path
                  data-name="Path 375"
                  d="M24.219 48.344h-6.7a1.22 1.22 0 011.22 1.22v2.2a1.22 1.22 0 01-1.22 1.22h6.7a1.22 1.22 0 001.22-1.22v-2.2a1.22 1.22 0 00-1.22-1.22z"
                  fill="#03984b"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 125">
        <path
          data-name="Path 376"
          d="M15.6 20.074v7.046c0 .833-.923 1.387-1.76.583a.79.79 0 00-1.116 1.118 2.544 2.544 0 004.456-1.7v-7.046a.79.79 0 00-1.58 0z"
        />
        <path
          data-name="Path 377"
          d="M37.82 19.284h-2.561a.79.79 0 00-.79.79v8.815a.79.79 0 00.79.79s2.355-.011 3-.011a3.162 3.162 0 001.746-5.8 2.817 2.817 0 00-2.193-4.587zm0 1.58a1.239 1.239 0 010 2.478h-1.771v-2.48zm.447 7.226c-.4 0-1.47 0-2.218.007v-3.17c1.555-.006.794 0 2.218 0a1.583 1.583 0 010 3.166z"
        />
        <circle
          data-name="Ellipse 37"
          cx={0.79}
          cy={0.79}
          r={0.79}
          transform="translate(38.193 3.633)"
        />
        <circle
          data-name="Ellipse 38"
          cx={0.79}
          cy={0.79}
          r={0.79}
          transform="translate(41.458 3.633)"
        />
        <circle
          data-name="Ellipse 39"
          cx={0.79}
          cy={0.79}
          r={0.79}
          transform="translate(44.723 3.633)"
        />
        <path
          data-name="Path 378"
          d="M50.365 0H3.558A3.562 3.562 0 000 3.558v35.631a3.562 3.562 0 003.558 3.558h1.708a.79.79 0 100-1.58H3.558a1.981 1.981 0 01-1.978-1.978V9.135h50.764v30.054a1.981 1.981 0 01-1.978 1.978H31.444a.79.79 0 100 1.58h18.921a3.562 3.562 0 003.558-3.558V3.558A3.562 3.562 0 0050.365 0zM1.58 7.555v-4A1.981 1.981 0 013.558 1.58h46.807a1.981 1.981 0 011.978 1.979v4z"
        />
        <path
          data-name="Path 379"
          d="M43.631 33.845a3.584 3.584 0 003.58-3.58v-3.938a.79.79 0 00-1.58 0v3.942a2 2 0 01-2 2H25.349v-2.668a5.2 5.2 0 10-1.2-9.862 2.7 2.7 0 00-1.442-.476 2.738 2.738 0 00-2.839 2.738v6.965a2.739 2.739 0 00-3.842 1.954 2.731 2.731 0 00-3.672 1.255 2.736 2.736 0 00-2.807-.048 1.99 1.99 0 01-1.256-1.858v-11.57a2 2 0 012-2h33.338a2 2 0 012 2v3.936a.79.79 0 101.58 0v-3.936a3.584 3.584 0 00-3.58-3.58H10.292a3.584 3.584 0 00-3.58 3.58v11.57a3.562 3.562 0 001.728 3.066 2.835 2.835 0 00-.271 1.215v6.319c0 2.633 1.065 3.963 2.228 7.011a2.008 2.008 0 00-.919 1.685v2.2a2.012 2.012 0 002.009 2.009h12.732a2.012 2.012 0 002.009-2.009v-2.2a2 2 0 00-.66-1.484c.661-1.352 2.973-3.116 2.973-6.968v-5.687a2.816 2.816 0 00-.482-1.579H43.63zm-13.746-9.363a3.617 3.617 0 01-4.536 3.5v-5.937a2.846 2.846 0 00-.183-1.007 3.612 3.612 0 014.719 3.445zm-5.236 27.287a.43.43 0 01-.43.43H11.487a.43.43 0 01-.43-.43v-2.2a.43.43 0 01.429-.43h12.733a.43.43 0 01.43.43zm2.313-10.657c0 3.407-1.962 4.42-2.891 6.443H11.978c-1.309-3.368-2.226-4.464-2.226-6.687v-6.321a1.166 1.166 0 011.966-.882 1.15 1.15 0 01.355.835.79.79 0 001.58 0v-1.08a1.165 1.165 0 011.965-.882 1.151 1.151 0 01.355.835.79.79 0 101.58 0v-1.879a1.166 1.166 0 011.965-.882 1.151 1.151 0 01.355.835.79.79 0 001.58 0v-9.449a1.159 1.159 0 011.2-1.159 1.194 1.194 0 011.117 1.206v11.824a.79.79 0 001.294.608 1.162 1.162 0 01.809-.264 1.187 1.187 0 011.09 1.208z"
        />
      </g>
    </g>
  </svg>
);

SvgJobsOnSite.defaultProps = {};
export default SvgJobsOnSite;
/* eslint-enable */
