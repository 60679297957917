import {
    Alert,
    Container, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Lock } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { LoggedInUser } from '../../../types/auth.types';
import Page from '../layout/base.page';
import TextField from '../foundation/TextField';
import Button from '../foundation/Button';
import { resetUserPassword } from '../../api/auth.api';
import { updateQueryStringParameter } from '../../utils/urlUtils';
import CustomBR from '../foundation/CustomBR';

export type ResetPasswordPageProps = {
    userid: string,
    safeid: string,
    url: string
    user: LoggedInUser
}

interface IFormResetPassword {
    password: string;
    passwordConfirm: string;
}

const ResetPasswordPage = (props: ResetPasswordPageProps): JSX.Element => {
    const [successMsg, setSuccessMsg] = useState<string>(null);
    const {
        register, handleSubmit, errors,
    } = useForm<IFormResetPassword>();

    const onSubmit = async (data: IFormResetPassword) => {
        await resetUserPassword({ userid: props.userid, safeid: props.safeid }, data);
        window.history.pushState({}, '', updateQueryStringParameter('redirect', '/'));
        setSuccessMsg('הסיסמא אופסה בהצלחה! כעת יש לבצע התחברות עם סיסמתכם החדשה.');
    };

    return <Page
        showBreadCrumbs={false}
        url={props.url} user={props.user}>
        <Container>
            <CustomBR times={2} />
            {successMsg ? <Alert severity="success">{successMsg}</Alert> : <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="body1">
                על מנת לאפס את הסיסמא של המשתמש שלך, יש להזין פעמיים את הסיסמא הרצויה, ולאחר מכן לבצע התחברות.
                </Typography>
                <CustomBR />
                <TextField
                    inputRef={register({ required: true, minLength: 6 })}
                    margin="dense"
                    name="password'"
                    id="password"
                    label="בחר סיסמא"
                    type="password"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password && 'הסיסמא אינה תקינה, לפחות 6 תווים'}
                    InputProps={{
                        endAdornment: <Lock />,
                    }}
                />
                <TextField
                    inputRef={register({ required: true, minLength: 6 })}
                    margin="dense"
                    name="passwordConfirm'"
                    id="passwordConfirm"
                    label="בחר סיסמא"
                    type="password"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password && 'הסיסמא אינה תקינה, לפחות 6 תווים'}
                    InputProps={{
                        endAdornment: <Lock />,
                    }}
                />
                <CustomBR times={2} />
                <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>איפוס סיסמא</Button>
            </form>}
            <CustomBR times={2} />
        </Container>
    </Page>;
};

export default ResetPasswordPage;
