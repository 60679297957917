import {
    Container, Typography,
} from '@mui/material';
import { Phone, PersonOutline, Mail } from '@mui/icons-material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoggedInUser, UserProfileViewModel } from '../../../types/auth.types';
import { postUserProfile } from '../../api/user.api';
import { sendUserEmailConfirmation } from '../../api/auth.api';
import Button from '../foundation/Button';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';
import Alert from '../foundation/Alert';

export type UserProfilePageProps = {
    url: string
    user?: LoggedInUser
    userProfile: UserProfileViewModel
    isUserRegistered?: boolean
}

interface IFormUserProfile {
    firstname: string
    lastname: string
    phone: string
    email: string
}

type NotificationProps = { severity: 'error' | 'success' | 'info' | 'warning', message: string }

const UserProfilePage = (props: UserProfilePageProps) => {
    const [notification, setNotification] = useState<NotificationProps>(null);
    const [userProfile, setUserProfile] = useState<UserProfileViewModel>(props.userProfile);
    const {
        register, handleSubmit, errors,
    } = useForm<IFormUserProfile>();

    const onSubmit = async (data: IFormUserProfile) => {
        try {
            const res = await postUserProfile({ userid: props.user.userid }, data, true);
            setUserProfile(res.data);
            setNotification({ message: 'הפרטים נשמרו בהצלחה!', severity: 'success' });
        } catch (error) {
            setNotification({ message: error.response?.data || error.message, severity: 'error' });
        }
    };

    const sendEmailConfirmation = async () => {
        await sendUserEmailConfirmation({ userid: props.user.userid });
        setNotification({ message: 'נשלח אליך כעת אימייל בהצלחה, על מנת לאמת את כתובת האימייל שלך יש ללחוץ על הקישור במייל שנשלח אליך.', severity: 'success' });
    };

    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - הפרופיל שלי">
        <Container>
            <CustomBR />
            {notification && <><Alert severity={notification.severity}>{notification.message}</Alert><CustomBR /></>}
            {!props.isUserRegistered && <>
                <Alert severity="warning"><>
                    <b>שים לב:</b> נראה כי כתובת המייל שלך איננה מאומתת. ללא אימות כתובת המייל ייתכן ותיהיה מוגבל לבצע פעולות מסוימות.
                    <br />
                    על מנת לאמת את כתובת המייל, יש ללחוץ על הקישור לאימות שנשלח אליך במייל לכתובת: <b>{userProfile.email}</b>.
                    <CustomBR />
                    <Button size="small" onClick={sendEmailConfirmation}>שלח מייל נוסף לאימות</Button>
                </></Alert>
                <CustomBR />
            </>}

            <BGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול פרטים אישיים</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    <TextField
                        defaultValue={props.userProfile.firstname}
                        required
                        inputRef={register({ required: true })}
                        name="firstname"
                        id="firstname"
                        label="שם פרטי"
                        type="text"
                        fullWidth
                        error={!!errors.firstname}
                        helperText={errors.firstname && 'יש להזין שם פרטי'}
                        InputProps={{ endAdornment: <PersonOutline /> }}
                    />
                    <CustomBR />
                    <TextField
                        defaultValue={props.userProfile.lastname}
                        required
                        inputRef={register({ required: true })}
                        name="lastname"
                        id="lastname"
                        label="שם משפחה"
                        type="text"
                        fullWidth
                        error={!!errors.lastname}
                        helperText={errors.lastname && 'יש להזין שם משפחה'}
                        InputProps={{ endAdornment: <PersonOutline /> }}
                    />
                    <CustomBR />
                    <TextField
                        defaultValue={props.userProfile.phone}
                        required
                        inputRef={register({ required: true, minLength: 8 })}
                        name="phone"
                        id="phone"
                        label="מספר פלאפון"
                        type="number"
                        fullWidth
                        error={!!errors.phone}
                        helperText={errors.phone && 'יש להזין שם מספר טלפון'}
                        InputProps={{ endAdornment: <Phone /> }}
                    />
                    <CustomBR />
                    <TextField
                        defaultValue={props.userProfile.email}
                        required
                        inputRef={register({ required: true })}
                        name="email"
                        id="email"
                        label="כתובת מייל"
                        type="email"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email && 'יש להזין כתובת מייל'}
                        InputProps={{ endAdornment: <Mail /> }}
                    />
                    <CustomBR />
                    <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>שמירת פרטים אישיים</Button>
                    <CustomBR />
                </WhiteBGContainer>
                <CustomBR />
            </BGContainer>
        </Container>
        <CustomBR />
    </Page>;
};

export default UserProfilePage;
