/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgCampaignCall = (props: SVGProps<SVGSVGElement>) => (
  <svg width={67.718} viewBox="0 0 67.718 65.068" {...props}>
    <g data-name="Group 820">
      <path
        data-name="Path 2637"
        d="M55.814 16.13v39.68h-5.29V16.13z"
        fill="#12b05e"
      />
      <path
        data-name="Path 2638"
        d="M50.524 18.775v34.388s-13.463-10.58-25.394-10.58V29.357c11.931 0 25.394-10.582 25.394-10.582z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 2639"
        d="M25.13 45.229H6.613V26.711H25.13z"
        fill="#12b05e"
      />
      <path
        data-name="Path 2640"
        d="M24.167 60.374a2.646 2.646 0 01-2.543 3.371H19.19a2.645 2.645 0 01-2.543-1.919l-4.743-16.598h7.936z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 2641"
        d="M6.613 29.356v13.226H5.291a3.968 3.968 0 01-3.968-3.967v-5.29a3.968 3.968 0 013.968-3.969z"
        fill="#f9f9fb"
      />
    </g>
    <g data-name="Group 821" transform="translate(0 14.808)">
      <path
        data-name="Path 2642"
        d="M5.29 29.099v1.323a1.323 1.323 0 001.323 1.323h4.293l4.469 15.638a3.984 3.984 0 003.815 2.879h2.434a3.967 3.967 0 003.815-5.057l-3.846-13.462h3.537a1.323 1.323 0 001.323-1.323v-1.276c9.729.645 20.339 8.09 22.749 9.871v1.984a1.323 1.323 0 001.323 1.323h5.29a1.323 1.323 0 001.322-1.323V27.114a1.323 1.323 0 10-2.645 0v12.565h-2.645V2.645h2.645V15.21a1.323 1.323 0 102.645 0V1.323a1.323 1.323 0 00-1.323-1.324h-5.29A1.323 1.323 0 0049.2 1.323v1.986c-2.411 1.782-13.021 9.226-22.749 9.871v-1.281a1.323 1.323 0 00-1.323-1.323H6.613a1.323 1.323 0 00-1.323 1.323v1.323A5.3 5.3 0 000 18.517v5.29a5.3 5.3 0 005.29 5.292zm21.162-13.268c9.124-.535 18.6-6.387 22.749-9.262v29.187c-4.146-2.875-13.625-8.728-22.749-9.262zM22.9 45.929a1.322 1.322 0 01-1.272 1.685H19.19a1.327 1.327 0 01-1.272-.96l-4.261-14.911h5.185zM7.936 13.226h15.871v15.873H7.936zm-5.29 5.29a2.648 2.648 0 012.644-2.645v10.581a2.648 2.648 0 01-2.645-2.645z"
      />
      <path
        data-name="Path 2643"
        d="M66.395 18.516h-5.29a1.323 1.323 0 100 2.645h5.29a1.323 1.323 0 000-2.645z"
      />
      <path
        data-name="Path 2644"
        d="M66.984 29.238l-5.29-2.645a1.323 1.323 0 10-1.183 2.366l5.29 2.645a1.323 1.323 0 101.183-2.366z"
      />
      <path
        data-name="Path 2645"
        d="M59.922 12.495a1.323 1.323 0 001.774.591l5.29-2.645a1.323 1.323 0 10-1.183-2.366l-5.29 2.645a1.323 1.323 0 00-.591 1.775z"
      />
      <path
        data-name="Path 2646"
        d="M11.904 15.87a1.323 1.323 0 00-1.323 1.324v5.29a1.323 1.323 0 102.645 0v-5.29a1.323 1.323 0 00-1.322-1.324z"
      />
      <path
        data-name="Path 2647"
        d="M17.194 15.87a1.323 1.323 0 00-1.323 1.324v5.29a1.323 1.323 0 002.645 0v-5.29a1.323 1.323 0 00-1.322-1.324z"
      />
      <circle
        data-name="Ellipse 211"
        cx={1.204}
        cy={1.204}
        r={1.204}
        transform="translate(54.561 20.059)"
      />
      <g transform="translate(21.742 -13.855)">
        <circle cx={9.926} cy={9.926} r={9.926} fill="#f9f9fb" />
        <circle
          cx={1.341}
          cy={1.341}
          r={1.341}
          transform="translate(8.586 8.586)"
          fill="#ffc400"
        />
      </g>
      <path
        d="M23.569-9.695a.549.549 0 00-.757.17 10.472 10.472 0 103.278-3.271.548.548 0 10.585.928 9.379 9.379 0 11-1.637 14.571 9.438 9.438 0 01-1.3-11.641.549.549 0 00-.169-.757z"
        stroke="#000"
        strokeWidth={0.8}
      />
      <path d="M38.798-3.37h.676a.55.55 0 100-1.1h-.676a.55.55 0 100 1.1zM23.315-3.937a.548.548 0 00.548.548h.676a.55.55 0 100-1.1h-.676a.548.548 0 00-.548.552zM32.227-11.057v-.676a.55.55 0 10-1.1 0v.676a.55.55 0 101.1 0zM31.112 3.201v.676a.55.55 0 101.1 0v-.676a.55.55 0 10-1.1 0zM28.921-7.452a.549.549 0 00-.776.776l1.859 1.859a1.889 1.889 0 103.332 0l3.75-3.75a.549.549 0 00-.776-.776l-3.75 3.75a1.885 1.885 0 00-1.781 0zm3.541 3.525a.792.792 0 11-.792-.792.793.793 0 01.792.792zM25.038-10.559a.548.548 0 10-.388.161.551.551 0 00.388-.161z" />
    </g>
  </svg>
);

SvgCampaignCall.defaultProps = {};
export default SvgCampaignCall;
/* eslint-enable */
