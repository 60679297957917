import { Drawer, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import React, { useContext, useState } from 'react';
import { Close } from '@mui/icons-material';
import SvgMenu from '../../svg/generated/components/Menu';
import { LogoHand } from '../../foundation/Logo';
import UserContext from '../../context/user.context';
import { centeredFlex } from '../../foundation/Flex';
import CustomBR from '../../foundation/CustomBR';
import Ellipsis from '../../foundation/Ellipsis';

export type NavbarMobileProps = {
    children: JSX.Element | JSX.Element[]
}

/**
 * until https://github.com/mui-org/material-ui/issues/25336 is fixed
 */
const RTLDrawer = styled(Drawer)`
    .MuiDrawer-paperAnchorRight {
        left: 0;
        right: auto;
        width: 70vw;
        max-width: 20rem;
    }
`;

const NavbarMobileStyled = styled.div`
    .MuiIconButton-root {
        border: 2px solid ${props => props.theme.palette.secondary.main};
        border-radius: 10px;
        padding: 12px;
        svg {
            width: 20px;
            margin: 0.3rem;
        }
    }
`;

const NavbarMobileContent = styled.div`
    padding: 1rem;
`;

const NavbarMobileLogoWrapper = styled.div`
    ${centeredFlex}
    justify-content: start;
    background-color: #f9f9fb;
    padding-top: 3rem;
    padding-bottom: 1rem;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1rem;
    > div:first-child {
        margin-inline-end: 10px;
    }
`;

const CloseButtonStyled = styled.div`
    position: absolute;
    inset-inline-end: 0;
    top: 0;
`;

const NavbarMobile = (props: NavbarMobileProps): JSX.Element => {
    const [drawer, setDrawer] = useState<boolean>(false);
    const user = useContext(UserContext);

    return <NavbarMobileStyled>
        <IconButton onClick={() => setDrawer(true)}>
            <SvgMenu />
        </IconButton>
        <RTLDrawer anchor="left" open={drawer} onClose={() => setDrawer(false)}>
            <div role="presentation">
                <CloseButtonStyled>
                    <IconButton onClick={() => setDrawer(false)}>
                        <Close />
                    </IconButton>
                </CloseButtonStyled>
                <NavbarMobileLogoWrapper>
                    <div><LogoHand /></div>
                    <div>
                        <div><Typography fontWeight="bold" variant="h3" color="secondary">ברוך הבא</Typography></div>
                        <CustomBR h={10} />
                        <div>{user ? <Typography color="secondary">
                            <Ellipsis maxWidth="2323" title={user.email}>
                                {user.email}
                            </Ellipsis>
                        </Typography> : <Typography>אורח</Typography> }</div>
                    </div>
                </NavbarMobileLogoWrapper>
                <NavbarMobileContent>
                    {props.children}
                </NavbarMobileContent>
            </div>
        </RTLDrawer>
    </NavbarMobileStyled>;
};

export default NavbarMobile;
