import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
import TextField from '../../foundation/TextField';
// import 'moment/locale/he';

const FORMAT = 'LL';

export type DatePickerProps = {
    datePlaceholder?: any,
    dayPickerProps: any,
    inputRef?: React.Ref<HTMLInputElement>,
    onDayChange: (date: any) => void,
    value: string | Date,
    placeholder?: string,
};

export const DatePickerWrapper = (props: DatePickerProps) => {
    const [value, setValue] = useState(props.value);

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            label={props.placeholder || 'בחר תאריך'}
            value={value}
            onChange={newValue => {
                setValue(newValue);
                if (props.onDayChange) props.onDayChange((newValue as any).$d as Date);
            }}
            inputRef={props.inputRef}
            renderInput={params => <TextField {...params} />}
        />
    </LocalizationProvider>;
};

export default DatePickerWrapper;
