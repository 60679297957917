import {
    Container, Typography,
} from '@mui/material';
import { LoggedInUser } from '../../../types/auth.types';
import { deleteAllCache, deleteWhatsappGroups } from '../../api/cache.api';
import Button from '../foundation/Button';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type AdminCacheProps = {
    url: string
    user?: LoggedInUser
}

const AdminCache = (props: AdminCacheProps): JSX.Element => {
    const clearCacheClick = () => {
        deleteAllCache();
    };
    const clearWhatsappQueueClick = () => {
        deleteWhatsappGroups();
    };
    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - דרושים - ניהול מטמון" >
        <Container>
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול מטמון</Typography>
            <CustomBR />
            <Button onClick={clearCacheClick}>נקה מטמון</Button>
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול משרות וואטסאפ</Typography>
            <CustomBR />
            <Button onClick={clearWhatsappQueueClick}>נקה משרות בתור לוואטסאפ</Button>
        </Container>
        <CustomBR />
    </Page>;
};

export default AdminCache;
