import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { EmailOutlined, AttachFile } from '@mui/icons-material';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import CustomBR from '../../foundation/CustomBR';
import { UserProfileViewModel } from '../../../../types/auth.types';
import { JobViewModel } from '../../../../types/jobs.types';
import Anchor from '../../foundation/Anchor';
import Select from '../../foundation/Select';
import TextArea from '../../foundation/TextArea';
import { TextButton } from '../../foundation/Button';

export type ApplyToJobModalProps = {
    open?: boolean
    onClose: () => void
    job?: JobViewModel
    userProfile: UserProfileViewModel
    onApplyClick: (userProfile: UserProfileViewModel, job: JobViewModel, data: { message: string, cvid: number }) => void
};

const ApplyToJobModalStyle = styled.div`
`;

const ApplyToJobModal = (props: ApplyToJobModalProps) => {
    const defaultCV = props.userProfile.cvs.find(c => c.default)?.cvid;
    const [showLastMessage, setShowLastMessage] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>();
    const [cv, setCV] = useState<number | null>(defaultCV);
    const loadLastMessage = () => {
        setMessage(localStorage.getItem('user-message'));
        setShowLastMessage(false);
    };
    useEffect(() => {
        if (localStorage.getItem('user-message')) setShowLastMessage(true);
    }, []);

    const onMessageChange = useCallback(ev => setMessage(ev.target.value), []);

    return (props.job ? <DialogWithHeader
        open={props.open}
        onFirstActionClick={props.onClose}
        firstActionText="ביטול"
        secondActionText="סיום והגשת מועמדות"
        onSecondActionClick={() => {
            localStorage.setItem('user-message', message);
            props.onApplyClick(props.userProfile, props.job, { message, cvid: cv });
        }}
        onClose={props.onClose} title="הגשת מועמדות למשרה">
        <ApplyToJobModalStyle>
            <CustomBR />
            <Typography display="inline">הינך עומד להגיש מועמדות למשרה: </Typography>
            <Anchor href={`/job/details/${props.job.jobid}`} fontWeight="bold" text={props.job.title} blank />
            <CustomBR />
            <Box display="flex" alignContent="center">
                <AttachFile color="primary" />
                <Typography display="inline">קורות חיים להגשה&nbsp;</Typography>(<Anchor text="ניהול קורות החיים שלי" blank href="/user/cvs" />)
            </Box>
            <CustomBR h={10} />
            <Select
                fullWidth
                onChange={ev => { setCV(parseInt(ev.target.value as string, 10)); }}
                defaultValue={defaultCV}
                size="small" options={props.userProfile.cvs?.map(cvItem => (
                    { text: cvItem.originalName, value: cvItem.cvid }),
                )} />
            <CustomBR />
            <Box display="flex" alignContent="center">
                <EmailOutlined color="primary" />
                <Typography display="inline">&nbsp;לבחירתך, ניתן להוסיף מספר מילים שיישלחו אל המעסיק :</Typography>
            </Box>
            <CustomBR h={10} />
            <TextArea value={message} onChange={onMessageChange} fullWidth rows={3} />
            {showLastMessage && <TextButton onClick={loadLastMessage}>טען הודעה אחרונה</TextButton>}
            <CustomBR />
        </ApplyToJobModalStyle>
    </DialogWithHeader> : null);
};
export default ApplyToJobModal;
