import { useState } from 'react';
import styled from 'styled-components';
import { Mail, Lock } from '@mui/icons-material';

import {
    Divider, Grid, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import TextField from '../../foundation/TextField';
import Button from '../../foundation/Button/Button';
import Anchor from '../../foundation/Anchor';
import { tryNavigateToRedirectUrl } from '../../../utils/urlUtils';
import { loginApi } from '../../../api/auth.api';
import Alert from '../../foundation/Alert';
import { emailPattern } from '../../../utils/forms.utils';
import FacebookLogin from '../../components/FacebookLogin';
import GoogleLogin from '../../components/GoogleLogin';
import LinkedinLogin from '../../components/LinkedinLogin';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import CustomBR from '../../foundation/CustomBR';

export type LoginModalProps = {
    open: boolean
    onClose: () => void
    onResetPasswordClick: () => void
    gotoRegister: () => void
};

const LoginModalStyle = styled.div`
`;

interface IFormLogin {
    username: string;
    password: string;
}

const LoginModal = (props: LoginModalProps) => {
    const [formErr, setFormErr] = useState<string>(null);
    const {
        register, handleSubmit, errors,
    } = useForm<IFormLogin>();

    const onSubmit = async (data: IFormLogin) => {
        try {
            await loginApi(data);
            tryNavigateToRedirectUrl();
        } catch (error) {
            setFormErr(error.message);
        }
    };

    const renderFooter = () => <Typography variant="body2">
    עוד לא רשום? <Anchor text="הירשם עכשיו" href="#" onClick={ev => {
            ev.preventDefault();
            props.gotoRegister();
        }} /> — זה כיף וקל!
    </Typography>;

    return <DialogWithHeader
        renderFooter={renderFooter}
        open={props.open} onClose={props.onClose} title="התחברות ל-Mploy">
        <LoginModalStyle>
            {formErr && <Alert severity="error">{formErr}</Alert>}
            <CustomBR />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true, pattern: emailPattern })}
                            name="username"
                            id="email"
                            label="כתובת אימייל"
                            type="email"
                            fullWidth
                            autoComplete="username"
                            error={!!errors.username}
                            helperText={errors.username && 'כתובת המייל אינה תקינה'}
                            InputProps={{
                                endAdornment: <Mail />,
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true, minLength: 6 })}
                            margin="dense"
                            name="password'"
                            id="password"
                            label="סיסמא"
                            type="password"
                            autoComplete="current-password"
                            fullWidth
                            error={!!errors.password}
                            helperText={errors.password && 'הסיסמא אינה תקינה, לפחות 6 תווים'}
                            InputProps={{
                                endAdornment: <Lock />,
                            }}
                        />
                    </div>
                    <Grid textAlign="right">
                        <div>
                            <Anchor onClick={ev => {
                                ev.preventDefault();
                                props.onResetPasswordClick();
                            }} href="#" text="שכחתי סיסמא" />
                        </div>
                    </Grid>
                    <CustomBR />
                    <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>התחברות</Button>
                </div>
            </form>
            <CustomBR />
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}><Divider /></Grid>
                <Grid item><Typography variant="body1">או</Typography></Grid>
                <Grid item xs={5}><Divider /></Grid>
            </Grid>
            <CustomBR />
            <Grid container spacing={3}>
                <Grid sm={4} xs={12} item>
                    <GoogleLogin />
                </Grid>
                <Grid sm={4} xs={12} item>
                    <LinkedinLogin />
                </Grid>
                <Grid sm={4} xs={12} item>
                    <FacebookLogin />
                </Grid>
            </Grid>
            <CustomBR />
        </LoginModalStyle>
        {/* <DialogBottomStyle>

        </DialogBottomStyle> */}
    </DialogWithHeader>;
};

export default LoginModal;
