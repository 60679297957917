import React, { useState } from 'react';
import styled from 'styled-components';
import {
    FormGroup, Box, FormControlLabel, FormHelperText, Typography, Grid, Divider,
} from '@mui/material';
import {
    EmailOutlined, AttachFile, Lock, Mail, Phone,
} from '@mui/icons-material';

import { useForm } from 'react-hook-form';

import DialogWithHeader from '../../foundation/DialogWithHeader';
import CustomBR from '../../foundation/CustomBR';
import { LoggedInUser, UserProfileViewModel } from '../../../../types/auth.types';
import { JobSearchResultViewModel, JobViewModel } from '../../../../types/jobs.types';
import Anchor from '../../foundation/Anchor';
import TextArea from '../../foundation/TextArea';
import Checkbox from '../../foundation/Checkbox';
import { registerUser } from '../../../api/auth.api';
import TextField from '../../foundation/TextField';
import { emailPattern } from '../../../utils/forms.utils';
import { postUserProfile, uploadUserCV } from '../../../api/user.api';
import Alert from '../../foundation/Alert';

export type ApplyToJobMissingDataModalProps = {
    open?: boolean
    onClose: () => void
    job?: JobViewModel
    user?: LoggedInUser
    userProfile?: UserProfileViewModel
    onRegistrationComplete: (user: LoggedInUser) => void
    onUserProfileUpdate: (user: UserProfileViewModel) => void
    onApplyClick: (userProfile: UserProfileViewModel, job: JobViewModel, data: { message: string, cvid: number }) => void
};

const ApplyToJobMissingDataModalStyle = styled.div`
`;

interface IFormRegister {
    email: string;
    password: string;
    smartAgentByActivity: boolean
    firstname: string
    lastname: string
    phone: string
    cv: FileList
    user?: LoggedInUser
    job?: JobSearchResultViewModel
    message?: string
}

const ApplyToJobMissingDataModal = (props: ApplyToJobMissingDataModalProps) => {
    const [formErr, setFormErr] = useState<string>(null);
    const {
        register, handleSubmit, errors,
    } = useForm<IFormRegister>();
    const onSubmit = async (data: IFormRegister) => {
        try {
            window.showBackdrop();
            let { user } = props;
            if (!user) {
                const userRes = await registerUser({
                    smartAgentByActivity: data.smartAgentByActivity,
                    email: data.email,
                    password: data.password,
                    lastname: data.lastname,
                    firstname: data.firstname,
                });
                user = userRes.data;
                props.onRegistrationComplete(user);
            }
            const userProfile = (await postUserProfile(
                { userid: user.userid },
                { firstname: data.firstname, lastname: data.lastname, phone: data.phone },
            )).data;

            let cv;
            if (data.cv?.length) {
                const formData = new FormData();
                formData.append('file', data.cv[0]);
                cv = (await uploadUserCV({ userid: user.userid }, formData)).data;
                userProfile.cvs.push(cv);
            }

            props.onUserProfileUpdate(userProfile);
            props.onApplyClick(userProfile, props.job, { message: data.message, cvid: cv?.cvid });
            props.onClose();
        } catch (err) {
            setFormErr(err.response?.data || err.message);
        } finally {
            window.hideBackdrop();
        }
    };

    const showLoginModal = React.useCallback(() => { props.onClose(); window.modals.toggleLoginModal(); }, []);

    return (props.job ? <DialogWithHeader
        open={props.open}
        onFirstActionClick={props.user ? props.onClose : showLoginModal}
        firstActionText={props.user ? 'ביטול' : 'התחברות'}
        secondActionText={props.user ? 'הגשת מועמדות' : 'הרשמה לאתר והגשת מועמדות'}
        onSecondActionClick={handleSubmit(onSubmit)}
        onClose={props.onClose} title="הגשת מועמדות למשרה">
        <ApplyToJobMissingDataModalStyle>
            <CustomBR />
            {formErr && <><Alert severity="error">{formErr}</Alert><CustomBR /></>}
            <Typography display="inline">הינך עומד להגיש מועמדות למשרה: </Typography>
            <Anchor href={`/job/details/${props.job.jobid}`} fontWeight="bold" text={props.job.title} blank />
            <CustomBR />
            <Typography>על מנת להגיש מועמדות יש למלא את הפרטים הבאים</Typography>
            <CustomBR />
            <form onSubmit={handleSubmit(onSubmit)}>
                {!props.user && <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                inputRef={register({ required: true, pattern: emailPattern })}
                                name="email"
                                id="email"
                                label="כתובת אימייל"
                                type="email"
                                fullWidth
                                autoComplete="username"
                                error={!!errors.email}
                                helperText={errors.email && 'כתובת המייל אינה תקינה'}
                                InputProps={{
                                    endAdornment: <Mail />,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                inputRef={register({ required: true, minLength: 6 })}
                                name="password"
                                id="password"
                                label="בחר סיסמא"
                                type="password"
                                autoComplete="new-password"
                                fullWidth
                                error={!!errors.password}
                                helperText={errors.password && 'הסיסמא אינה תקינה, לפחות 6 תווים'}
                                InputProps={{
                                    endAdornment: <Lock />,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <CustomBR />
                    <Divider />
                    <CustomBR />
                </>}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={register()}
                            name="firstname"
                            id="firstname"
                            label="שם פרטי"
                            defaultValue={props.userProfile?.firstname}
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={register()}
                            name="lastname"
                            id="lastname"
                            label="שם משפחה"
                            defaultValue={props.userProfile?.lastname}
                            type="text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <CustomBR />
                <TextField
                    required
                    inputRef={register({ required: true })}
                    name="phone"
                    id="phone"
                    label="מספר טלפון"
                    type="number"
                    helperText={errors.phone && 'חובה להזין טלפון'}
                    defaultValue={props.userProfile?.phone}
                    fullWidth
                    InputProps={{
                        endAdornment: <Phone />,
                    }}
                />
                <CustomBR />
                <Box>
                    <Typography>קורות חיים להגשה&nbsp;</Typography>
                    <TextField
                        required
                        inputRef={register({ required: !!props.job.cvMandatory })}
                        name="cv"
                        id="cv"
                        type="file"
                        fullWidth
                        error={!!errors.cv}
                        helperText={errors.cv && 'חובה לצרף קורות חיים'}
                        InputProps={{
                            endAdornment: <AttachFile />,
                        }}
                    />
                </Box>
                <CustomBR h={10} />
                <CustomBR />
                <Box display="flex" alignContent="center">
                    <EmailOutlined color="primary" />
                    <Typography display="inline">&nbsp;לבחירתך, ניתן להוסיף מספר מילים שיישלחו אל המעסיק :</Typography>
                </Box>
                <CustomBR h={10} />
                <TextArea
                    inputRef={register()}
                    name="message"
                    id="message"
                    label="הודעה קצרה למעסיק"
                    fullWidth rows={2}
                />
                <CustomBR />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            defaultChecked
                            inputRef={register()}
                            name="smartAgentByActivity"
                        />}
                        label="הפעל סוכן חכם מבוסס פעילות"
                    />
                    <FormHelperText>קבלת התראות במייל על משרות חדשות מותאמות לפעילות שלכם</FormHelperText>
                </FormGroup>
            </form>
        </ApplyToJobMissingDataModalStyle>
    </DialogWithHeader> : null);
};
export default ApplyToJobMissingDataModal;
