/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgHumanResources = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.965} viewBox="0 0 28.965 32.536" {...props}>
    <g data-name="Human Resources">
      <g data-name="Group 373">
        <g data-name="Group 371">
          <g data-name="Group 361">
            <path
              data-name="Path 1102"
              d="M19.89 8.311H6.702v-1.4a6.434 6.434 0 016.434-6.43h.32a6.434 6.434 0 016.434 6.434v1.4z"
              fill="#3e5564"
            />
          </g>
          <g data-name="Group 362">
            <path
              data-name="Path 1103"
              d="M13.455.476h-.32a6.482 6.482 0 00-.918.066 6.435 6.435 0 015.516 6.368v1.4h2.156v-1.4A6.434 6.434 0 0013.455.481z"
              fill="#2e4757"
            />
          </g>
          <g data-name="Group 363">
            <path
              data-name="Path 1104"
              d="M26.114 27.15v4.909H.48v-4.846a4.613 4.613 0 012.824-4.252l9.995-4.345 10.071 4.317a4.612 4.612 0 012.744 4.217z"
              fill="#12b05e"
            />
          </g>
          <g data-name="Group 364">
            <path
              data-name="Path 1105"
              d="M13.295 23.243c-1.953 0-3.536-.383-3.536-2.336v-4.092h7.073v4.092c0 1.953-1.584 2.336-3.537 2.336z"
              fill="#e2a876"
            />
          </g>
          <g data-name="Group 365">
            <path
              data-name="Path 1106"
              d="M20.015 11.691h-1.1v-3.38h1.1a.85.85 0 01.85.85v1.68a.85.85 0 01-.85.85z"
              fill="#e2a876"
            />
          </g>
          <g data-name="Group 366">
            <path
              data-name="Path 1107"
              d="M6.576 11.691h1.1v-3.38h-1.1a.85.85 0 00-.85.85v1.68a.85.85 0 00.85.85z"
              fill="#e2a876"
            />
          </g>
          <g data-name="Group 367">
            <path
              data-name="Path 1108"
              d="M18.912 12.618a5.618 5.618 0 01-11.236 0V8.311a2.748 2.748 0 01.617-1.687 2.708 2.708 0 011.346-.845c1.608-.464 2.206.864 4.032.774 1.664-.082 2.1-1.232 3.4-.846a2.584 2.584 0 011.843 2.6l-.002 4.311z"
              fill="#ecbd83"
            />
          </g>
          <g data-name="Group 368">
            <path
              data-name="Path 1109"
              d="M14.999 32.058h-3.411l1.5-8.628.019-.109a.188.188 0 01.117-.144l.074.065.074-.065a.187.187 0 01.118.144l.019.109z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 369">
            <path
              data-name="Path 1110"
              d="M13.295 23.243l-.21.188-1.938 1.737a.767.767 0 01-1.173-.184l-2.3-3.926 2.083-.906 3.462 3.025z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 370">
            <path
              data-name="Path 1111"
              d="M18.931 21.034l-2.315 3.954a.766.766 0 01-1.172.184l-1.938-1.737-.211-.188.074-.065 3.463-3.047z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Group 372">
          <path
            data-name="Path 1112"
            d="M27.601 32.059h-7.782a.888.888 0 01-.888-.888v-4.887a.888.888 0 01.888-.888H27.6a.888.888 0 01.888.888v4.887a.888.888 0 01-.888.888z"
            fill="#ffcd27"
          />
        </g>
      </g>
      <g data-name="Group 374">
        <path
          data-name="Path 1113"
          d="M5.59 28.303a.477.477 0 00-.477.477v3.28a.477.477 0 00.953 0v-3.28a.477.477 0 00-.476-.477z"
        />
        <path
          data-name="Path 1114"
          d="M27.602 24.92h-1.528a5.068 5.068 0 00-2.517-2.42l-4.417-1.9-.022-.01-.664-.284-1.112-.477-.034-.014V17.2a6.082 6.082 0 002.081-4.582v-.45h.626a1.328 1.328 0 001.327-1.327v-1.68a1.328 1.328 0 00-.976-1.278V6.91A6.918 6.918 0 0013.455 0h-.32a6.918 6.918 0 00-6.91 6.91v.973a1.328 1.328 0 00-.976 1.278v1.68a1.328 1.328 0 001.327 1.327h.626v.45a6.082 6.082 0 002.08 4.582v2.637l-6.169 2.682a5.079 5.079 0 00-3.111 4.694v4.846a.477.477 0 00.953 0v-4.846A4.13 4.13 0 013.49 23.4l3.988-1.734.5.86a.477.477 0 00.823-.482l-.445-.761 1.029-.447.2.151 2.962 2.282-1.982 1.781-.91-1.555a.477.477 0 10-.823.482l1.205 2.059a.477.477 0 00.729.114l1.792-1.606-1.435 7.425a.477.477 0 10.936.181l1.236-6.394 1.236 6.394a.477.477 0 10.936-.181l-1.435-7.426 1.792 1.606a.477.477 0 00.729-.114l2.576-4.4 4.047 1.734a4.115 4.115 0 011.807 1.551h-5.164a1.366 1.366 0 00-1.365 1.365v4.887a1.366 1.366 0 001.365 1.365h7.783a1.366 1.366 0 001.365-1.365v-4.888a1.366 1.366 0 00-1.365-1.364zm-7.214-14.079a.374.374 0 01-.373.373h-.625V8.788h.625a.374.374 0 01.373.373zm-13.812.373a.374.374 0 01-.373-.373v-1.68a.374.374 0 01.373-.373h.626v2.427zm1.577 1.4V8.302a2.266 2.266 0 01.5-1.371 2.227 2.227 0 011.114-.694 2.189 2.189 0 011.717.274 4.369 4.369 0 002.207.518 4.061 4.061 0 001.711-.481.477.477 0 00-.431-.85 3.075 3.075 0 01-1.322.379 3.482 3.482 0 01-1.763-.433 3.121 3.121 0 00-2.384-.323A3.063 3.063 0 007.24 7.834h-.066V6.91A5.964 5.964 0 0113.135.953h.32a5.964 5.964 0 015.958 5.957v.924h-.043a3.036 3.036 0 00-.36-1.133 3.137 3.137 0 00-1.808-1.451.477.477 0 00-.273.913 2.175 2.175 0 011.243 1 2.152 2.152 0 01.26 1.128V12.617a5.142 5.142 0 01-10.283 0zm2.149 7.721a3.513 3.513 0 01-.043-.253 3.008 3.008 0 01-.023-.374v-1.82a6.084 6.084 0 006.12 0v1.821a3.08 3.08 0 01-.021.359c-.01.09-.025.179-.043.267l-3 2.308zm5.724 4.715l-1.986-1.779 3.184-2.453.8.341.226.1zm11.986 6.121a.412.412 0 01-.411.411h-7.782a.412.412 0 01-.411-.411v-4.887a.412.412 0 01.411-.411h7.783a.412.412 0 01.411.411z"
        />
        <path
          data-name="Path 1115"
          d="M27.117 27.995a1.323 1.323 0 00-1.344-1.3h-.84a.477.477 0 00-.477.477v3.158a.477.477 0 10.953 0v-.677l.873.992a.477.477 0 00.716-.63l-.722-.82a1.3 1.3 0 00.841-1.2zm-1.344-.344a.347.347 0 110 .688h-.359v-.689h.361z"
        />
        <path
          data-name="Path 1116"
          d="M23.012 26.698a.477.477 0 00-.477.477v1.048h-1.23v-1.048a.477.477 0 10-.953 0v3.158a.477.477 0 10.953 0v-1.157h1.233v1.157a.477.477 0 10.953 0v-3.158a.477.477 0 00-.479-.477z"
        />
      </g>
    </g>
  </svg>
);

SvgHumanResources.defaultProps = {};
export default SvgHumanResources;
/* eslint-enable */
