/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgStatusAtAnyMoment = (props: SVGProps<SVGSVGElement>) => (
  <svg width={37.471} viewBox="0 0 37.471 52.275" {...props}>
    <g data-name="Status At Any Moment">
      <path
        data-name="Path 277"
        d="M18.735 42.321v9.189c6.118 0 11.078-2.057 11.078-4.594s-4.96-4.595-11.078-4.595z"
        fill="#e9b300"
      />
      <path
        data-name="Path 278"
        d="M26.751 46.916c0-2.538-3.588-4.595-8.015-4.595-6.118 0-11.078 2.057-11.078 4.595s4.96 4.594 11.078 4.594c4.422 0 8.015-2.057 8.015-4.594z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 279"
        d="M18.735.766v47.681a1.528 1.528 0 001.243-.639l13.361-18.6A17.97 17.97 0 0018.735.766z"
        fill="#03984b"
      />
      <path
        data-name="Path 280"
        d="M19.766 47.808l11.083-18.6a20.474 20.474 0 002.792-10.472c0-9.924-6.674-17.97-14.907-17.97a17.97 17.97 0 00-14.6 28.441l13.361 18.6a1.528 1.528 0 001.243.639 1.218 1.218 0 001.028-.638z"
        fill="#12b05e"
      />
      <path
        data-name="Path 281"
        d="M18.735 7.658v22.156a11.078 11.078 0 000-22.156z"
        fill="#efeff2"
      />
      <path
        data-name="Path 282"
        d="M26.751 18.736c0-6.118-3.593-11.078-8.015-11.078a11.078 11.078 0 000 22.156c4.422 0 8.015-4.956 8.015-11.078z"
        fill="#fff"
      />
      <path
        data-name="Path 283"
        d="M18.736 6.892A11.844 11.844 0 1030.58 18.736 11.857 11.857 0 0018.736 6.892zm0 22.156a10.312 10.312 0 1110.312-10.312 10.324 10.324 0 01-10.312 10.312z"
      />
      <path
        data-name="Path 284"
        d="M37.471 18.735A18.735 18.735 0 103.51 29.654l13.361 18.6a2.294 2.294 0 003.729 0l3.315-4.616c3.045.7 5.132 2.02 5.132 3.276 0 1.811-4.235 3.829-10.312 3.829S8.423 48.725 8.423 46.914c0-.862.97-1.771 2.661-2.494a.766.766 0 00-.6-1.408c-2.315.99-3.59 2.376-3.59 3.9 0 1.557 1.32 2.962 3.717 3.956a24.22 24.22 0 0016.253 0c2.4-.994 3.717-2.4 3.717-3.956 0-1.988-2.107-3.675-5.7-4.618l9.082-12.643a18.6 18.6 0 003.508-10.916zM19.357 47.36a.762.762 0 01-1.242 0L4.753 28.76a17.2 17.2 0 1127.964 0z"
      />
    </g>
  </svg>
);

SvgStatusAtAnyMoment.defaultProps = {};
export default SvgStatusAtAnyMoment;
/* eslint-enable */
