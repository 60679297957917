/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgAdvertisingMarketingAndPublish = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.419} viewBox="0 0 29.419 23.56" {...props}>
    <g data-name="Advertising Marketing and Publi...">
      <g data-name="Group 245">
        <path
          data-name="Path 763"
          d="M28.1.454H1.32a.86.86 0 00-.86.86v3.437h28.5V1.314a.86.86 0 00-.86-.86z"
          fill="#384656"
        />
        <path
          data-name="Path 764"
          d="M.46 3.716h28.5V4.75H.46z"
          fill="#2f3b48"
        />
        <path
          data-name="Path 765"
          d="M28.1 23.106H1.32a.86.86 0 01-.86-.86v-17.5h28.5v17.5a.86.86 0 01-.86.86z"
          fill="#fed2a4"
        />
        <path
          data-name="Path 766"
          d="M28.1 22.072H1.32a.86.86 0 01-.86-.86v1.034a.86.86 0 00.86.86H28.1a.86.86 0 00.86-.86v-1.034a.86.86 0 01-.86.86z"
          fill="#f1b678"
        />
        <path
          data-name="Path 767"
          d="M22.899 17.839H6.522a.86.86 0 01-.86-.86v-6.884a.86.86 0 01.86-.86H22.9a.86.86 0 01.86.86v6.884a.86.86 0 01-.861.86z"
          fill="#ffcd27"
        />
        <path
          data-name="Path 768"
          d="M22.899 16.805H6.522a.86.86 0 01-.86-.86v1.034a.86.86 0 00.86.86H22.9a.86.86 0 00.86-.86v-1.034a.86.86 0 01-.861.86z"
          fill="#ebb400"
        />
      </g>
      <g data-name="Group 246">
        <path
          data-name="Path 769"
          d="M28.126 0H1.293A1.3 1.3 0 000 1.293v20.974a1.3 1.3 0 001.293 1.293h15.786a.432.432 0 000-.864H1.293a.43.43 0 01-.43-.43v-17.1h21.186a.432.432 0 000-.864H.864V1.293a.43.43 0 01.43-.43h26.832a.43.43 0 01.43.43v3.012h-4.78a.432.432 0 100 .864h4.78v17.1a.43.43 0 01-.43.43h-9.32a.432.432 0 100 .864h9.32a1.3 1.3 0 001.293-1.293V1.293A1.3 1.3 0 0028.126 0z"
        />
        <path
          data-name="Path 770"
          d="M2.325 2.542a.723.723 0 10.723-.723.724.724 0 00-.723.723z"
          fill="#fff"
        />
        <path
          data-name="Path 771"
          d="M4.485 2.542a.723.723 0 10.723-.723.724.724 0 00-.723.723z"
          fill="#fff"
        />
        <path
          data-name="Path 772"
          d="M6.645 2.542a.723.723 0 10.723-.723.724.724 0 00-.723.723z"
          fill="#fff"
        />
        <path
          data-name="Path 773"
          d="M11.532 15.945a.432.432 0 00.808-.3l-1.676-4.441v-.012a.532.532 0 00-.494-.331.532.532 0 00-.494.332v.01l-1.691 4.441a.432.432 0 00.807.307l.315-.828h2.115zm-2.1-1.69l.737-1.935.73 1.935z"
        />
        <path
          data-name="Path 774"
          d="M13.242 15.722a.46.46 0 00.442.5c.037 0 .919 0 1.271-.01a2.358 2.358 0 002.153-2.608c0-1.566-.883-2.618-2.2-2.618H13.67a.434.434 0 00-.432.436zm1.67-3.869c.983 0 1.334.906 1.334 1.754 0 .829-.408 1.729-1.3 1.744-.188 0-.543.006-.827.007V11.852z"
        />
        <path
          data-name="Path 775"
          d="M19.685 15.361a1.7 1.7 0 01-1.25-.526.432.432 0 10-.64.58 2.578 2.578 0 001.89.81 1.749 1.749 0 001.766-1.332 1.573 1.573 0 00-1.226-1.722c-.575-.212-1.108-.443-1.248-.5a.44.44 0 01-.173-.426.594.594 0 01.45-.468 1.634 1.634 0 011.361.311.432.432 0 00.554-.663 2.487 2.487 0 00-2.164-.475 1.455 1.455 0 00-1.056 1.172 1.3 1.3 0 00.58 1.289.438.438 0 00.06.033c.026.012.642.286 1.337.542.483.178.729.455.674.76a.891.891 0 01-.915.615z"
        />
        <path
          data-name="Path 776"
          d="M10.634 18.286h12.281a1.3 1.3 0 001.294-1.3v-6.9a1.3 1.3 0 00-1.293-1.293H6.509a1.3 1.3 0 00-1.293 1.293v6.9a1.3 1.3 0 001.293 1.3h2.4a.432.432 0 100-.864h-2.4a.43.43 0 01-.43-.43v-6.9a.43.43 0 01.43-.43h16.41a.43.43 0 01.43.43v6.9a.43.43 0 01-.43.43H10.634a.432.432 0 100 .864z"
        />
      </g>
    </g>
  </svg>
);

SvgAdvertisingMarketingAndPublish.defaultProps = {};
export default SvgAdvertisingMarketingAndPublish;
/* eslint-enable */
