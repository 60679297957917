/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgCandidates = (props: SVGProps<SVGSVGElement>) => (
  <svg width={42.47} viewBox="0 0 42.47 57.47" {...props}>
    <path
      d="M35.353 7.118h-7.5a6.618 6.618 0 00-13.235 0h-7.5A6.625 6.625 0 00.5 13.735v36.617a6.625 6.625 0 006.618 6.618h28.235a6.625 6.625 0 006.618-6.618V13.735a6.625 6.625 0 00-6.618-6.617zm-16.323 0a2.206 2.206 0 114.412 0v4.412h-4.413zm18.529 43.235a2.209 2.209 0 01-2.206 2.206H7.118a2.209 2.209 0 01-2.206-2.206V13.735a2.209 2.209 0 012.206-2.206h7.5v4.412h13.235v-4.412h7.5a2.209 2.209 0 012.206 2.206zM26.8 35.321a6.948 6.948 0 10-11.126 0 10.409 10.409 0 00-4.421 5.946 2.206 2.206 0 002.125 2.8h0a2.187 2.187 0 002.116-1.584 5.957 5.957 0 0111.484 0 2.187 2.187 0 002.116 1.584h0a2.206 2.206 0 002.125-2.8 10.409 10.409 0 00-4.419-5.946zm-8.1-4.159a2.537 2.537 0 112.535 2.538 2.539 2.539 0 01-2.535-2.539z"
      fill="#12b05e"
      stroke="#12b05e"
      strokeWidth={1}
    />
  </svg>
);

SvgCandidates.defaultProps = {};
export default SvgCandidates;
/* eslint-enable */
