import React, { useEffect } from 'react';

export type TawkToProps = {
}

const TawkTo = (props: TawkToProps): JSX.Element => {
    useEffect(() => {
        const Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        const s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/6238eeb9a34c2456412c2a6e/1fun691fh';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    }, []);
    return <div id="tawk-to" />;
};

export default TawkTo;
