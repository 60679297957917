import React from 'react';
import styled from 'styled-components';
import {
    Dialog, DialogProps, DialogContent, DialogTitle,
    DialogActions,
    Divider, IconButton, Typography,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import Button from './Button';

export type DialogWithHeaderProps = {
    children: JSX.Element
    renderFooter?: () => JSX.Element
    title: string,
    open: boolean
    onClose: () => void
    firstActionText?: string
    onFirstActionClick?: () => void
    secondActionText?: string
    onSecondActionClick?: () => void
    gray?: boolean
} & DialogProps;

const DialogStyle = styled.div`
    background: #f9f9fb;
    .MuiTypography-h4 {
        text-align: center;
        margin: -1.5rem 5rem 0;
    }    
    ${props => props.theme.breakpoints.down('sm')} {
        .MuiTypography-h4 {
            margin: -1.5rem 3rem 0;
        }
        .MuiDialogTitle-root {
            padding: 16px 12px;
        }
    }
`;
const DialogActionsStyle = styled.div`
    padding: 0.5rem;
`;

const DialogBottomStyle = styled.div`
    padding: 10px 0;
    border-top: 1px solid #ddd;
    text-align: center;
`;

const DialogContentStyle = styled(DialogContent)<{ gray?: boolean }>`
    ${({ gray }) => (gray ? 'background-color: #f4f4f4;' : '')}
`;

const DialogWithHeader = (props: DialogWithHeaderProps) => <Dialog open={props.open}
    fullWidth={props.fullWidth}
    maxWidth={props.maxWidth}
    onClose={props.onClose} aria-labelledby="form-dialog-title">
    <DialogStyle>
        <DialogTitle id="form-dialog-title">
            <IconButton onClick={props.onClose}>
                <Close />
            </IconButton>
            <Typography fontWeight="bold" variant="h4" color="secondary">{props.title}</Typography>
        </DialogTitle>
        <Divider />
        <DialogContentStyle gray={props.gray}>
            {props.children}
        </DialogContentStyle>
        {(props.onFirstActionClick || props.onSecondActionClick) ? <>
            <Divider />
            <DialogActionsStyle>
                <DialogActions>
                    <Button variant="outlined" onClick={props.onFirstActionClick} color="primary">
                        {props.firstActionText}
                    </Button>
                    {props.secondActionText && <Button onClick={props.onSecondActionClick} color="primary">
                        {props.secondActionText}
                    </Button>}
                </DialogActions>
            </DialogActionsStyle></> : null}
        {props.renderFooter && <DialogBottomStyle>
            {props.renderFooter()}
        </DialogBottomStyle>}
    </DialogStyle>
</Dialog>;

export default DialogWithHeader;
