import { useState } from 'react';
import {
    Box, Container, Divider, FormControlLabel, FormGroup, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoggedInUser } from '../../../types/auth.types';
import { TagSynonymViewModel, TagViewModel } from '../../../types/tags.types';
import {
    createTag, createTagSynonym, deleteTagSynonym, getTagSynonym, mergeTags,
} from '../../api/tags.api';
import Button from '../foundation/Button';
import CustomBR from '../foundation/CustomBR';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';
import Checkbox from '../foundation/Checkbox';
import { TagsSearchAutocomplete } from '../components/AutocompleteRemote';

export type AdminTagsProps = {
    url: string
    user?: LoggedInUser
}

export type AdminTagsState = {
    tagSynonyms?: TagSynonymViewModel[]
    currentTag?: {
        tagid: number
        tag: string
    }
}

interface IFormAdminTags {
    newTag: string
    newTagGroupId: number
    parentNewTagName: string
    newTagLevel: string
    isTagVertical: boolean
}

const AdminTags = (props: AdminTagsProps) => {
    const { register, getValues, setValue } = useForm<IFormAdminTags>();

    const [tagSynonyms, setTagSynonyms] = useState<TagSynonymViewModel[]>(null);
    const [currentTag, setCurrentTag] = useState<Partial<TagViewModel>>(null);

    const removeTagSynonym = async synonymid => {
        console.log('synonymid', synonymid);
        await deleteTagSynonym(synonymid);
        const currentSynonymTags = tagSynonyms;
        setTagSynonyms(currentSynonymTags.filter(tag => tag.synonymid !== synonymid));
    };

    const onCreateTagSynonym = async () => {
        const respose = await createTagSynonym({
            synonym: getValues('newSynonym'),
            tagid: currentTag.tagid,
        });
        setTagSynonyms([respose.data, ...tagSynonyms]);
    };

    const createNewTag = async () => {
        await createTag({
            tag: getValues('newTag'),
            groupid: getValues('newTagGroupId'),
            parentName: getValues('parentNewTagName'),
            level: getValues('newTagLevel') && parseInt(getValues('newTagLevel'), 10),
            vertical: getValues('isTagVertical'),
        });

        setValue('newTag', '');
    };

    const renderTagSynonym = () => {
        if (!tagSynonyms?.length) return '';

        return (
            <div>
                <div><Typography variant="h3">תגיות קיימות</Typography></div>
                <br />
                {tagSynonyms.map((tagSynonymItem, index) => (
                    <Box display="flex" key={index} style={{ margin: '10px' }}>
                        <Button onClick={() => removeTagSynonym(tagSynonymItem.synonymid)}>הסר</Button>
                        <Typography style={{ marginRight: '10px' }}>{tagSynonymItem.synonym}</Typography>
                    </Box>
                ))}
            </div>
        );
    };

    const getTagSynonyms = async (selectedTag: TagViewModel) => {
        setCurrentTag(selectedTag);
        const tagSynonymsData = (await getTagSynonym({ origin: selectedTag.tag })).data;
        setTagSynonyms(tagSynonymsData);
    };

    const addSynonymInput = () => (
        <div>
            <Typography>הוספת וריאציות: במידה והמילים מופרדות באמצעות פסיק, שתיהן יהיו חייבות להמצא בטקסט (ללא חשיבות לסדר)</Typography>
            <CustomBR />
            <TextField
                inputRef={register()}
                name="newSynonym"
                id="newSynonym"
                label="אפשרות חדשה לזיהוי תגית" onEnter={onCreateTagSynonym} />
            <CustomBR />
            <Button onClick={onCreateTagSynonym}>הוסף</Button>
        </div>
    );

    const onMergeTags = async () => {
        await mergeTags({
            oldTagId: getValues('oldTag'),
            newTagId: getValues('newExistTag'),
        });

        setValue('oldTag', '');
        setValue('newExistTag', '');
    };

    const renderMergeTags = () => (
        <div>
            <Typography variant="h1">מיזוג תגיות</Typography>
            <div>
                <Typography>מזג תגית מספר : </Typography>
                <TextField
                    inputRef={register()}
                    name="oldTag"
                    id="oldTag"
                    type="number" placeholder="מספר תגית למחיקה" />
                <Typography>לתוך תגית מספר : </Typography>
                <TextField
                    inputRef={register()}
                    name="newExistTag"
                    id="newExistTag"
                    type="number" placeholder="מספר תגית אליה ייתבצע המיזוג" />
                <CustomBR />
                <Typography>לאחר מיזוג התגיות, ייתרחשו הדברים הבאים: (לפי הסדר)</Typography>
                <ul>
                    <li>משתמשים אשר רשומים לתגית הישנה יירשמו לתגית החדשה</li>
                    <li>כל המשרות עם התגית הישנה יעודכנו לתגית החדשה</li>
                    <li>כל הלידים עם התגית הישנה יעודכנו לתגית החדשה</li>
                    <li>כל הוריאציות של התגית הישנה ישמשו גם לתגית החדשה</li>
                    <li>התגית בתיבת הטקסט הראשונה תמחק לחלוטין.</li>
                    <li>יש להוסיף ידנית 301.</li>
                </ul>
                <CustomBR />
                <Button onClick={onMergeTags}>מזג תגיות</Button>
            </div>
        </div>
    );

    const renderSubmitNewTagForm = () => (
        <div>
            <TextField
                inputRef={register()}
                name="newTag"
                id="newTag"
                label="שם התגית החדשה" onEnter={createNewTag} />
            <CustomBR />
            <TextField
                inputRef={register()}
                name="newTagLevel"
                id="newTagLevel"
                type="number" label="רמת התגית בעץ (1 הכי גבוה)" />
            <CustomBR />
            <TextField
                inputRef={register()}
                name="parentNewTagName"
                id="parentNewTagName"
                label="שם תגית האבא (אופציונלי)"
                onEnter={createNewTag} />
            <CustomBR />
            <TextField
                inputRef={register()}
                name="newTagGroupId"
                id="newTagGroupId"
                type="number" label="מספר קבוצה (אופציונלי)" />
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox
                        inputRef={register()}
                        name="isTagVertical"
                    />}
                    label="האם התגית ורטיקלית? (האם התגית מאפיינת את סוג המשרה ולא תפקידים במשרה)"
                />
            </FormGroup>
            <CustomBR />
            <Button onClick={createNewTag}>הוסף תגית חדשה</Button>
        </div>
    );

    return (
        <Page
            url={props.url}
            user={props.user}
            pageHeaderTitle="Mploy - ניהול תגיות">
            <Container>
                <div className="container container-box">
                    <h1>הוספת וריאציות לתגיות</h1>
                    {currentTag && <div>
                        <Typography fontWeight="bold">תגית בשימוש: {currentTag.tag}</Typography>
                        <CustomBR />
                        <Divider />
                        <CustomBR />
                    </div>}
                    <div>
                        <Typography>טען תגית לפי שם</Typography>
                        <CustomBR />
                        <TagsSearchAutocomplete
                            clearOnChange
                            onChange={(ev, val: TagViewModel) => { getTagSynonyms(val); }} />
                    </div>
                    <CustomBR />
                    <Divider />
                    <CustomBR />
                    {addSynonymInput()}
                    <CustomBR />
                    <Divider />
                    <CustomBR />
                    {renderTagSynonym()}
                </div>
                <div className="container">
                    <h1>הוספת תגיות</h1>
                    <div>
                        <Typography>הוספת תגיות חדשות למערכת</Typography>
                        {renderSubmitNewTagForm()}
                    </div>
                </div>
                <CustomBR />
                <Divider />
                <CustomBR />
                <div className="container">
                    {renderMergeTags()}
                </div>
                <CustomBR />
                <CustomBR />
            </Container>
        </Page>
    );
};

export default AdminTags;
