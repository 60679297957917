import React from 'react';
import { TooltipProps as TooltipPropsMUI, Tooltip as TooltipMUI } from '@mui/material';

export type TooltipProps = {
    children?: JSX.Element | string
} & TooltipPropsMUI

const Tooltip = (props: TooltipProps): JSX.Element => <TooltipMUI
    {...props}>
    {props.children}
</TooltipMUI>;

export default Tooltip;
