/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width={26} viewBox="0 0 26 26" {...props}>
    <path
      d="M24.556 0H1.444A1.444 1.444 0 000 1.444v23.112A1.444 1.444 0 001.444 26h12.444V15.947h-3.377v-3.936h3.377V9.122c0-3.358 2.051-5.188 5.056-5.188a28.25 28.25 0 013.026.155v3.5H19.9c-1.629 0-1.947.777-1.947 1.914V12h3.9l-.506 3.936h-3.398V26h6.607A1.444 1.444 0 0026 24.556V1.444A1.444 1.444 0 0024.556 0z"
      fill="#1877f2"
    />
  </svg>
);

SvgFacebook.defaultProps = {};
export default SvgFacebook;
/* eslint-enable */
