export const ACTION_LIFECYCLE = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    BAD_REQUEST: 'BAD_REQUEST',
    UNAUTHORIZED: 'UNAUTHORIZED',
    FORBIDDEN: 'FORBIDDEN',
    NOT_FOUND: 'NOT_FOUND',
    CONFLICT: 'CONFLICT',
    SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
    CONNECTIVITY_ERROR: 'CONNECTIVITY_ERROR',
    CLIENT_TOO_MANY_REQUESTS: 'CLIENT_TOO_MANY_REQUESTS',
    SERVER_ERROR: 'SERVER_ERROR',
    FAILURE: 'FAILURE',
    COMPLETE: 'COMPLETE',
} as const;

export const API_METHODS = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    HEAD: 'HEAD',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
} as const;

export const API_FAILURES_CODES = {
    400: ACTION_LIFECYCLE.BAD_REQUEST,
    401: ACTION_LIFECYCLE.UNAUTHORIZED,
    403: ACTION_LIFECYCLE.FORBIDDEN,
    404: ACTION_LIFECYCLE.NOT_FOUND,
    409: ACTION_LIFECYCLE.CONFLICT,
    503: ACTION_LIFECYCLE.SERVICE_UNAVAILABLE,
    [-1]: ACTION_LIFECYCLE.CONNECTIVITY_ERROR,
    [-429]: ACTION_LIFECYCLE.CLIENT_TOO_MANY_REQUESTS,
    429: ACTION_LIFECYCLE.CLIENT_TOO_MANY_REQUESTS,
};

export const RESPONSE_TEXT = {
    BAD_REQUEST: 'Bad Request',
} as const;
