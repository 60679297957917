/* eslint-disable max-len */
import {
    Container, Typography,
} from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type RegulationsProps = {
    url: string
    user?: LoggedInUser
}

const Regulations = (props: RegulationsProps): JSX.Element => <Page
    showBreadCrumbs={false}
    url={props.url}
    user={props.user}
    pageHeaderTitle="Mploy - תנאי שימוש" >
    <Container>
        <CustomBR />
        <Typography color="secondary" fontWeight="bold" variant="h3">תקנון האתר</Typography>
        <CustomBR />
        <BGContainer>
            <Typography color="secondary" fontWeight="bold" variant="h4">מבוא</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
הפלטפורמה Mploy, הכוללת אתר ואפליקציה משמשת כלוח פרסומי. השימוש באתר ובתכנים המוצגים בו כפוף לתנאי השימוש המפורטים להלן. אנא קרא תנאים אלה בעיון ובקפידה, שכן תחילת השימוש באתר ותכניו מעידה על הסכמתך ואישורך לאמור בתנאי שימוש אלו.
האמור בתקנון זה מנוסח בלשון זכר מטעמי נוחות בלבד אולם הוא מיועד לנשים וגברים כאחד.
                `}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">הגדרות</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
Mploy, המפעילה את אתר www.mploy.co.il תיקרא להלן: "Mploy" ו/או "המפעילה", והאתר ייקרא להלן: "האתר"
אדם אשר נרשם לאתר באמצעות עמוד ההצטרפות על מנת לחפש עבודה ייקרא להלן: "מחפש עבודה" או "המשתמש"
אדם אשר נרשם לאתר באמצעות עמוד ההצטרפות על מנת לחפש עובדים ייקרא להלן: "מחפש עובדים" או "המעסיק"
המידע המוצג באתר, לרבות המידע במודעות הדרושים המוצגות באתר וכן המידע אשר מוזן על ידי משתמש באתר ייקרא להלן: "המידע"
הפרופיל שיוצר משתמש אשר נרשם לאתר באמצעות מערכת מחפשי העבודה באתר ייקרא להלן: "כרטיס המשתמש"
העלאת תוכן לאתר, לרבות לכרטיס המשתמש באמצעות הזנת נתונים ו/או העלאת קבצים ייקרא להלן: "פרסום"
תכנים מכל מין ו/או סוג שהוא, אשר ניתן להעלות ו/או לכלול באתר בכל דרך שהיא, לרבות באמצעות מלל ו/או מספור ו/או תמונות ו/או כל אמצעי חזותי ו/או קולי אחר (כגון: הודעות, תמונות, קבצים מסוגים שונים, קישורים, טקסטים וכל מידע אחר) יקראו להלן: "תכנים"
                `}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">השימוש באתר</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
1. האתר, ממשק ניהול קורות החיים באתר וכרטיס המשתמש למחפשי עבודה מיועדים לשימושם של מחפשי עבודה ומחפשי עובדים ולמטרה זו בלבד.
2. השימוש באתר בכלל ובמערכת מחפשי העבודה וכרטיס המשתמש בפרט נועדו אך ורק למטרות משלוח קורות חיים של מחפשי עבודה במענה למודעות ספציפיות. השימוש באתר אינו נועד להעלאת תוכן פרסומי ו/או העברת מסרים כלשהם אל מפרסמי המודעות באתר במסווה של מידע אישי של מחפש עבודה.
3. המפעילה מכבדת את פרטיות המשתמשים באתר ואת פרטיות המידע האישי שמועלה לאתר על ידי מחפשי העבודה והמעסיקים. מידע אודות מדיניות הפרטיות של האתר ניתן למצוא בעמוד מדיניות פרטיות. יובהר, כי מדיניות הפרטיות מהווה חלק בלתי נפרד מתקנון זה ויכולה להשתנות מעת לעת.
4. מבלי לגרוע מהאמור לעיל ו/או במדיניות הפרטיות באתר, המפעילה תמנע ככל האפשר ממסירת פרטיך האישיים לצדדים שלישיים, למעט במקרים בהם ייפתח הליך משפטי (פלילי או אזרחי) כנגדה הנוגע למסירת פרטיך האישיים ו/או במקרים בהם תהיה מחויבת לעשות כן על פי צו שיפוטי שיינתן כנגדה.
5. מחפש העבודה או מעסיק מתחייב שלא להשתמש באתר למטרות אחרות מלבד חיפוש עבודה או חיפוש עובדים ומתחייב שלא לפרסם באתר את התכנים הבאים:

1. כל תוכן הפוגע ו/או מפר זכויות קנייניות של אחרים – לרבות קניין רוחני (זכויות יוצרים, סימני מסחר וכיוצ"ב), לרבות העלאת תוכן אשר אינו שייך למפרסם.
2. תכנים בעלי אופי פורנוגרפי ו/או מיני בוטה, מאיימים, גזעניים, מוציאי לשון הרע, מעודדים לביצוע עבירות, פליליות או אזרחיות, פוגעים בפרטיות, מזיקים, פוגעים ברגשות הציבור ותכנים אשר מהווים הפרה כלשהי של החוק.
3. תכנים אשר מכילים מידע שגוי ו/או שקרי ו/או מידע השייך לכל אדם או גוף אחר.
4. כל תוכן המהווה לשון הרע על אדם או הפוגע בפרטיות .
5. כל תוכן ומידע הנוגע לקטינים ומזהה אותם, את פרטיהם האישיים או את מענם ודרכי ההתקשרות עימם.
6. כל תוכנת מחשב, קוד מחשב או יישום הכוללים נגיף-מחשב (וירוס) ו/או רוגלות, ואנדלים (Vandals), לרבות תוכנות עוינות הידועות כסוס טרויאני, תולעים, יישומים מזיקים ((Malicious Applications וכיו"ב.
7. סיסמאות, שמות משתמש ופרטים אחרים, המאפשרים שימוש בתוכנות מחשב, קבצים דיגיטליים, אתרי אינטרנט או שירותים, הטעונים הרשמה או תשלום, בלא תשלום או הרשמה כאמור.
8. כל מידע ו/או תוכן שפרסומו נאסר לפי הוראות כל דין, לרבות על-פי צווי איסור פרסום של בתי-משפט, ולרבות פרטים העשויים לזהות נפגעי עבירות מין, עבריינים או קורבנות מעשי עבירה קטינים וכיוצ"ב.
9. כל מידע בעל אופי או תוכן טורדני, מעליב, עוין, מאיים, גס או גזעני.
10. כל מידע העלול להטעות צרכן.
11. כל מידע המנוגד לכללי השימוש המקובלים באינטרנט או העלול לגרום נזק או לפגוע במשתמשי אינטרנט בכלל ובמשתמשי האתר בפרט.
12. מידע שהגישה אליו חסומה באמצעות סיסמה וכיו"ב ואינה מתאפשרת באופן חופשי לכל משתמשי האינטרנט.
13. בעלי אופי מסחרי ו/או המכילים מידע פרסומי כלשהו.
14. אינם רלוונטיים להצעת העבודה המפורסמת ו/או מכילים מידע שגוי ו/או שקרי ו/או מוטעה ו/או מטעה ו/או מידע השייך לכל אדם או גוף אחר אשר אינו מחפש העבודה, לרבות קורות חיים, תמונות, ומידע אישי.
15. תכנים אשר אינם רלוונטיים לעמוד בו הם מופיעים.
16. תכנים אשר מכילים מידע שגוי ו/או שקרי ו/או מוטעה ו/או מטעה ו/או מידע השייך לכל אדם או גוף אחר אשר אינו המשתמש, לרבות קורות חיים, תמונות, ומידע אישי.

6. המפעילה רשאית לסרב לפרסם תכנים באתר, אשר לדעתה מפרים את תקנון האתר ו/או עלולים לפגוע באתר ו/או בצד שלישי כלשהו, והיא רשאית למחוק תכנים כאלו בכל עת ובמידת הצורך אף למחוק את התוכן שהועלה על ידי המשתמש או את כרטיס המשתמש כולו או חלקו, באופן זמני או לצמיתות.
7. במידה ויתקבלו אצל המפעילה תלונות אודות שימוש בכרטיס משתמש בניגוד לתנאי התקנון, המפעילה שומרת לעצמה את הזכות למחוק באופן מיידי את כרטיס המשתמש ללא התראה מוקדמת וללא מתן הנמקה למחפש העבודה.
8. מחיקת תכנים נתונה לשיקול דעתה הבלעדי של Mploy, והמשתמש באתר מאשר כי אין ולא תהיה לו כל טענה ו/או דרישה ו/או תביעה כלפי Mploy בקשר למחיקת תכנים כאמור.
9. הפסקת פעילות המשתמש לא תגרע מהתחייבויותיו של המשתמש כמפורט במסגרת תקנון זה, אף אם יותר למשתמש לשוב ולהשתמש בשירותי האתר.
10. מבלי לגרוע מן האמור לעיל, מחפש העבודה מתחייב להשתמש במערכת לשליחת קורות החיים המהווה חלק מהאתר, וזאת אך ורק לצורך שליחת קורות חיים, ולא לכל מטרה אחרת, לרבות אך לא רק לצורך משלוח תוכן שיווקי ו/או פרסומי ו/או אחר מכל מין ו/או סוג שהוא. במידה ומחפש העבודה השתמש באתר לשם משלוח תוכן שיווקי כאמור בסעיף זה, עליו לפצות את המפעילה, מיד עם דרישתה הראשונה לכך, בגין נזקיה הישירים והעקיפים הנובעים כתוצאה מהפרה כאמור, מבלי שתוטל על המפעילה כל חובה להוכיח את נזקיה והפסדיה כאמור, בסכום קבוע ומוערך מראש בסך של 2,000 ₪ עבור כל הפרה כאמור, וזאת בנוסף ומבלי לגרוע מכל סעד אחר שיעמוד לחברה כנגדי, לרבות זכותה של המפעילה לתבוע בנוסף סעד של שיפוי ו/או פיצויים ו/או כל סעד מצטבר אחר לפי שיקול דעתה הבלעדי של המפעילה, עקב הפרת ההתחייבות כאמור.
11. מבלי לגרוע מהאמור לעיל, לחברה תעמוד זכות לנקוט בכל הליך משפטי, לרבות דרישה לסעדים זמניים, כנגד מחפש עבודה אשר הפר מי מסעיפי תקנון זה.
12. המשתמש מתחייב לשפות את Mploy, עובדיה, מנהליה, בעלי מניותיה או מי מטעמם בגין כל נזק ו/או הפסד ו/או אובדן רווח ו/או תשלום ו/או הוצאה שייגרמו לה בגין הפרת תנאי תקנון זה.
13. המפעילה לא תישא בכל אחריות ביחס למידע הנמצא באתר ובכל מכשיר קצה בהם הוא מופיע, תוכנו, מהימנותו, דיוקו, אמינותו והשפעתו על המחשבים של הגולשים באתר וכן לכל נזק, אי נוחות, אבדן, עגמת נפש וכיוצא באלו תוצאות, ישירות או עקיפות שייגרמו למשתמש, לרכושו או לכל צד שלישי בשל שימוש במידע זה.
14. התכנים שנמסרים לפרסום באתר יהיו חשופים לכל משתמשי האינטרנט, לרבות מנועי חיפוש שסורקים את האתר על בסיס יומי/שבועי/חודשי באופן אוטומטי והמפעילה אינה יכולה לדעת אילו תגובות עלולות לקבל בעקבות מסירת התכנים. המפעילה לא תישא כלפי המשתמש באחריות לתגובות אלו.
15. מלוא זכויות היוצרים והקניין הרוחני בעיצוב האתר, תוכנו (לרבות המידע והתכנים המופיעים בו), אופן הצגתו ובכל תוכנה, יישום, קוד מחשב, קובץ גרפי, טקסט וכל חומר אחר הכלול בו - הינן של המפעילה בלבד ויש לה אינטרס וזכות להגן עליו. אין להעתיק, להפיץ, לשנות, להציג בפומבי ו/או למסור לצד שלישי כל חלק מן הנ"ל בלא קבלת הסכמתה של המפעילה, בכתב ומראש. סימני המסחר באתר הינם קניינה של המפעילה בלבד - או, במידה שפורסמו מטעמם של מפרסמים, קניינם של מפרסמים אלה בלבד. אין לעשות בהם שימוש בלא הסכמתם, בכתב ומראש.
16. השימוש באתר, במידע ובתכנים המופיעים בו ניתן לשימוש כמות שהוא (as is) בהתאם להחלטת המפעילה, ולא תהיה למחפש העבודה כל טענה, תביעה או דרישה כלפי המפעילה בגין תכונות השימוש, המגבלות או התגובות שיעורר הפרסום באתר ובמכשירי הקצה. השימוש, בתכנים המוצגים באתר ובמכשירי הקצה ובשירותי האתר, יעשה על אחריותו הבלעדית והמלאה של מחפש העבודה.
17. המפעילה רשאית לסגור את האתר ולשנות מעת לעת את מבנהו, מראהו וזמינותם של השירותים והתכנים הניתנים בו וזאת ללא צורך להודיע על כך מראש. לא תהיה למחפש העבודה כל טענה, תביעה או דרישה כלפי המפעילה בקשר לכך.
18. המפעילה אינה מתחייבת שהשירות הניתן באתר לא יופרע ו/או יינתן כסדרו ללא הפסקות והפרעות ו/או יהיה חסין מפני גישה לא חוקית למחשבי המפעילה, נזקים, קלקולים, תקלות, כשלים בחומרה, תוכנה או בקווי התקשורת אצל המפעילה או מי מספקיה או ייפגע מכל סיבה אחרת, והמפעילה לא תהא אחראית לכל נזק, ישיר או עקיף, עגמת נפש וכיו"ב שייגרמו למחפש העבודה או לרכושו עקב כך.
19. אין המפעילה מתחייבת כי כל הקישורים שימצאו באתר יהיו תקינים ויובילו לאתר אינטרנט פעיל, עקב הימצאותו של קישור לאתר מסוים באתר המפעילה אינה מלמדת כי תוכן האתר המקושר הינו מהימן, מלא או עדכני, והמפעילה לא תישא בכל אחריות בקשר לכך. מבלי לגרוע באמור לעיל המפעילה אינה אחראית לכל נזק, עקיף או ישיר שייגרם למשתמש ו/או לרכושו כתוצאה משימוש ו/או הסתמכות על המידע והתכנים המופיעים באתרים אליהם תגיע באמצעות או דרך שימוש או קישור הקיימים באתר המפעילה ו/או בגין שימוש או הסתמכות על מידע ותכנים המתפרסמים באתר ע"י צדדים שלישיים.
20. מחפש העבודה מתחייב שלא לעשות כל שימוש מסחרי במידע, כולו או מקצתו, לרבות פרסום המידע כולו או מקצתו, בכל דרך שהיא וכן מתחייב שלא להעביר את המידע, כולו או מקצתו, לצד שלישי כלשהו.
21. מחפש העבודה מצהיר כי ידוע לו כי השימוש באתר נועד לצרכים פרטיים ולחיפוש עבודה בלבד.
22. מחפש העבודה מתחייב שלא יעדה כל שימוש במידע, כולו או חלקו, לצרכם של אחרים ו/או לבקשתם של אחרים.
23. הוראות תקנון זה מתווספות לכל הוראות תקנון אחר המצוי באתר ובמקרה של אי התאמה, גוברות עליהן.
24. המפעילה רשאית לשנות את תנאי שימוש אלה מעת לעת בלי למסור על כך הודעה מראש. המפעילה תפרסם את התנאים החדשים ותחילת תוקפם יהיה ממועד פרסומם.
25. על הסכם זה יחולו אך ורק דיני מדינת ישראל. מקום השיפוט הבלעדי לכל עניין הנוגע להסכם זה והשימוש באתר הינו בבתי המשפט המוסמכים באזור תל-אביב יפו או בבתי המשפט בפתח תקווה.
26. המפעילה רשאית להפסיק את השימוש באתר במידה והמשתמש לא יעמוד במי תנאי תקנו זה.
27. כל המשרות באתר מיועדות לגברים ולנשים בכל גיל, וללא כל קשר למעמדם האישי, נטייתם המינית, היותן בהריון, טיפולי פוריות, טיפולי הפריה חוץ גופית, היותם הורים, גזעם, דתם, לאומיותם, ארץ מוצאם, השקפתם, מפלגתם או שירותם במילואים, קריאתם לשירות מילואים או שירותם הצפוי בשירות מילואים, כהגדרתו בחוק שירות ביטחון [נוסח משולב], התשמ"ו-1986, לרבות מחמת תדירותו או משכו. בהתאם, מעסיקים המפרסמים מודעות דרושים באתר מתחייבים לפרסם משרות בנוסח שוויוני ולא מפלה, כמתחייב בחוק שוויון ההזדמנויות בעבודה, התשמ"ח-1988. כל המשרות באתר מכוונות לכל.
28. בעצם שימוש בפלטפורמת Mploy אני מצהיר כי קראתי את תקנון האתר ואני מסכים לתנאים המפורטים בו ומתחייב לכבדם.
                `}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">מתן חיווי למועמד</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
כחלק משירותי האתר מאפשר האתר למועמד לקבל חיווי אודות סטטוס פנייתו למעסיק. במסגרת זאת, יישלח למועמד דיווח אוטומטי כאשר מעסיק צפה בנתונים ששלח. מעסיק אשר אינו מעוניין כי יישלחו דיווחים כאמור בקשר למשרות המפורסמות על ידו מוזמן לפנות למוקד שירות הלקוחות של המפעילה באמצעות משלוח דואר אלקטרוני לכתובת info@mploy.co.il מובהר, מעסיק אשר לא פנה כאמור – מועמדים ששלחו מועמדות למשרות שפרסם יקבלו דיווחים כאמור.
פרסום באתר
1. מודעות הדרושים שיפרסם מעסיק באתר תשמשנה למציאת מועמדים מתאימים לתפקיד שפורסם בלבד ולא תכלולנה משרות פיקטיביות. למען הסר ספק, יובהר כי חל איסור על פרסום מודעות דרושים מתוך מטרה ליצור מאגר קורות חיים של מועמדים ו/או לסחור בקורות חיים של מועמדים ו/או כל פעילות אחרת שאיננה מציאת מועמדים מתאימים לתפקיד שפורסם.
2. מעסיק לא יפרסם בעת ובעונה אחת מספר מודעות דרושים בגין משרות ותפקידים זהים.
3. מודעות דרושים יפורסמו אך ורק בשם המעסיק שהתקשר עם המפעילה בהסכם.
                `}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">פרסום משרות</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
מותר לפרסום
• הצעות עבודה בישראל ע"פ חוק
• הצעות עבודה בחו"ל אך ורק באם מדובר על רילוקיישן (מעבר לחו"ל)
• מס' טלפון ו/או פקס

אסור לפרסום
• הצעות מסחריות (מכל סוג)
• הצעות ללימודים ו/או קורסים
• הצעות עבודה לצעירים בחו"ל (לא רילוקיישן)
• הצעות עבודה בשיטת פרמידה (MLM) וכיו"ב (אלא רק בתחום שיווק רשתי)
                `}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">פרסום משרות באתר</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
• ניתן לרכוש באתר מודעות דרושים
• כל מודעת דרושים היא בעצם פרסום משרה באתר לחודש
• מודעות המפורסמות ישירות באתר מקודמות ומקבלות עדיפות על פני משרות שהמערכת אוספת ממקורות חיצוניים
• ניתן לפרסם משרה אחת ליותר מחודש על ידי שימוש בכמה מודעות דרושים שבחשבונך
• מרגע פרסום מודעת הדרושים לא ניתן לקבל החזר כספי עבור אותו קמפיין
• אין לפרסם משרות שאינן עומדות בתקנון באתר, משרות מסוג זה יימחקו מהאתר ללא החזר כספי
• כל מודעה שנרכשה בתוקף לשנה אחת מרגע רכישתה
`}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">קידום ממומן של משרות</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1" whiteSpace="pre-line">{`
• קמפיין קידום אחד הינו למעשה קידום משרה לחודש בדיוק
• משרה עליה הופעל קמפיין קידום, תקודם בתוצאות החיפוש תחת התחומים והתפקידים הרלוונטים ותופיע בצורה מודגשת ובולטת באתר
• הקפאת משרה דרך פאנל ניהול המשרות אינו משפיע על זמני הקמפין הקידום
• מרגע הפעלת קמפין הקידום של מודעה לא ניתן לקבל החזר כספי עבור אותו קמפין הקידום
• אין לקדם משרות שאינן עומדות בתקנון באתר, משרות מסוג זה יימחקו מהאתר ללא החזר כספי
• כל קמפין הקידום בתוקף לשנה אחת מרגע רכישתו
`}</Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography>
לכל שאלה ניתן להעזר בדף <Anchor href="/home/contactus" text="צור קשר" />.
            </Typography>
        </BGContainer>
    </Container>
    <CustomBR />
</Page>;

export default Regulations;
