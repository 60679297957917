import { JobViewModel } from '../../types/jobs.types';
import { API_METHODS } from './api.enums';
import newAPIService from './newAPIService';
import { apiAction } from './utils.actions';

export const reportJob = (job: JobViewModel, text) => newAPIService.post('/report', {
    subject: 'דיווח על משרה',
    message: `
    title: ${job.title} 
    jobid: ${job.jobid} 
    joburl: https://www.mploy.co.il/job/details/${job.jobid} 
    current - url : ${window.location.href}
    user message: ${text}`,
});

export type ContactUsPayload = {
    fullname: string
    email: string
    subject: string
    message: string
}
export type UserSettingsViewModel = {
    userid: number
}
export const postContactUs = (
    payload: ContactUsPayload,
) => apiAction({
    url: '/contactus',
    method: API_METHODS.POST,
    payload,
});
