import {
    Divider, FormControl, FormHelperText, Grid, Typography,
} from '@mui/material';
import { Mail, Lock } from '@mui/icons-material';

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoggedInUser } from '../../../../types/auth.types';
import { TagViewModel } from '../../../../types/tags.types';
import { registerUser } from '../../../api/auth.api';
import { reverseGeocode } from '../../../api/geo.api';
import { postUserSettings } from '../../../api/user.api';
import { emailPattern } from '../../../utils/forms.utils';
import { GeoAutocomplete, LocationOption, TagsSearchAutocomplete } from '../../components/AutocompleteRemote';
import Alert from '../../foundation/Alert';
import Anchor from '../../foundation/Anchor';
import Button from '../../foundation/Button';
import CustomBR from '../../foundation/CustomBR';
import RemovableTag from '../../foundation/RemovableTag';
import Select from '../../foundation/Select';
import TextField from '../../foundation/TextField';

export type RegisterSmartAgentProps = {
    defaultTags?: TagViewModel[]
    defaultLocation?: LocationOption
    /**
     * default true
     */
    tagsMandatory?: boolean
    onRegistrationComplete?: (user: LoggedInUser) => void
    onSmartAgentRegistrationComplete?: (userSettings, tags: TagViewModel[], distance: number) => void
    showDefaultText?: boolean
    user?: LoggedInUser
}

const defaultCity = {
    formatted_address: 'תל אביב',
    latitude: 32.0852999,
    longitude: 34.78176759,
} as LocationOption;

interface IFormRegister {
    email: string;
    distance: number
    password: string;
    smartAgentNotificationType: number
}

const agentOptions = [
    { value: 1, text: 'מיידי - מספר פעמים ביום' },
    { value: 2, text: 'יומי - כל הבוקר' },
    { value: 3, text: 'שבועי - ראשון בבוקר' },
    { value: 4, text: 'ללא' },
];

const RegisterSmartAgent = (props: RegisterSmartAgentProps): JSX.Element => {
    const [location, setLocation] = useState<LocationOption>(props.defaultLocation || defaultCity);
    const [tags, setTags] = useState<TagViewModel[]>(props.defaultTags || []);
    const [formErr, setFormErr] = useState<string>(null);
    const {
        register, handleSubmit, errors,
    } = useForm<IFormRegister>();

    const onSubmit = async (data: IFormRegister) => {
        if (props.tagsMandatory !== false && !tags.length) {
            setFormErr('חובה לבחור תפקידים או תחומים לסוכן החכם');
            return;
        }

        try {
            window.showBackdrop();
            let { user } = props;
            if (!user) {
                const userRes = await registerUser({
                    email: data.email,
                    password: data.password,
                    smartAgentByActivity: false,
                });
                user = userRes.data;
                if (props.onRegistrationComplete) props.onRegistrationComplete(user);
            }
            const userSettingsData = {
                latitude: location.latitude,
                longitude: location.longitude,
                formatted_address: location.formatted_address,
                job_distance: data.distance,
                tags: tags.map(t => t.tagid),
                smartAgentNotificationType: data.smartAgentNotificationType,
            };
            if (tags.length) {
                userSettingsData.job_distance = data.distance;
                userSettingsData.tags = tags.map(t => t.tagid);
                userSettingsData.smartAgentNotificationType = data.smartAgentNotificationType;
            }

            const userSettingsRes = await postUserSettings({ userid: user.userid }, userSettingsData);
            if (props.onSmartAgentRegistrationComplete) props.onSmartAgentRegistrationComplete(userSettingsRes.data, tags, data.distance);
        } catch (error) {
            setFormErr(error.message);
        }
        window.hideBackdrop();
    };

    const updateInitialLocation = () => {
        if (props.defaultLocation) return;
        window.navigator.geolocation.getCurrentPosition(async position => {
            const addressRes = await reverseGeocode({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
            setLocation(addressRes.data as LocationOption);
        });
    };
    useEffect(updateInitialLocation, []);
    return <div>
        {formErr && <><Alert severity="error">{formErr}</Alert><CustomBR /></>}
        {props.showDefaultText && <>
            <Typography>הירשמו לסוכן חכם וקבלו למייל בכל בוקר משרות שרלוונטיות אליכם.</Typography>
            <Typography>השירות ניתן ללא תשלום.</Typography><CustomBR />
        </>}
        <form onSubmit={handleSubmit(onSubmit)}>
            {!props.user && <><Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size="small"
                        required
                        inputRef={register({ required: true, pattern: emailPattern })}
                        name="email"
                        id="email"
                        label="כתובת אימייל"
                        type="email"
                        fullWidth
                        autoComplete="email"
                        error={!!errors.email}
                        helperText={errors.email && 'כתובת המייל אינה תקינה'}
                        InputProps={{
                            endAdornment: <Mail />,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        size="small"
                        inputRef={register({ required: true, minLength: 6 })}
                        name="password"
                        id="password"
                        label="בחר סיסמא"
                        type="password"
                        autoComplete="new-password"
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password && 'הסיסמא אינה תקינה, לפחות 6 תווים'}
                        InputProps={{
                            endAdornment: <Lock />,
                        }}
                    />
                </Grid>
            </Grid>
            <CustomBR />
            <Divider />
            <CustomBR /></>}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <GeoAutocomplete
                        size="small"
                        onChange={(ev, val: LocationOption) => setLocation(val)}
                        helperText="המיקום ממנו נחפש לך משרות"
                        defaultValue={location?.formatted_address} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size="small"
                        defaultValue={20}
                        required
                        inputRef={register({ required: true })}
                        name="distance"
                        id="distance"
                        label="מרחק"
                        type="number"
                        fullWidth
                        error={!!errors.distance}
                        helperText={errors.distance ? 'יש להזין מרחק' : 'מרחק בק"מ'}
                    />
                </Grid>
            </Grid>
            <CustomBR />
            <FormControl fullWidth>
                <Select
                    size="small"
                    name="smartAgentNotificationType"
                    inputRef={register({ required: true })}
                    required fullWidth options={agentOptions} />
                <FormHelperText>בחר את סוג ההתראות למייל</FormHelperText>
            </FormControl>
            <CustomBR />
            <Typography>בחר תחומים ותפקידים מתאימים לסוכן החכם:</Typography>
            <CustomBR />
            {tags.length > 0 && <>
                {tags.map(tag => <RemovableTag
                    key={tag.tagid}
                    onRemoveClick={() => setTags(tags.filter(t => t.tagid !== tag.tagid))}
                    text={tag.tag}
                />)}
                <CustomBR /></>}
            <TagsSearchAutocomplete
                filterItems={tags.map(t => t.tag)}
                clearOnChange
                onChange={(ev, val: TagViewModel) => { setTags([...tags, val]); }} />
            <CustomBR />
            <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>סיום והרשמה</Button>
            <CustomBR />
            {!props.user && <Typography>
                בהרשמה לאתר אני מסכים ל<Anchor text="תנאי השימוש" href="/home/regulations" blank />
            </Typography>}
        </form>
    </div>;
};

export default RegisterSmartAgent;
