import {
    Container, Typography,
} from '@mui/material';
import { LoggedInUser } from '../../../types/auth.types';
import BuyPromotedJobs from '../containers/BuyPromotedJobs';
import CustomBR from '../foundation/CustomBR';
import { CenteredFlex } from '../foundation/Flex';
import Page from '../layout/base.page';

export type BuyPromotedJobsWrapperProps = {
    url: string
    user?: LoggedInUser
}

const BuyPromotedJobsWrapper = (props: BuyPromotedJobsWrapperProps) => <Page
    url={props.url}
    user={props.user}
    bg="main"
    pageHeaderTitle="Mploy - קידום משרות ורכישת קמפיינים" >
    <Container>
        <CustomBR />
        <CenteredFlex>
            <Typography color="secondary" fontWeight="bold" variant="h3">Mploy - רכישת קמפיינים</Typography>
        </CenteredFlex>
        <BuyPromotedJobs />
    </Container>
    <CustomBR />
</Page>;

export default BuyPromotedJobsWrapper;
