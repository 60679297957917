import styled from 'styled-components';

type EllipsisProps = { title: string, maxWidth: number };

export default styled.span.attrs<EllipsisProps>(props => ({
    title: props.title,
}))<EllipsisProps>`
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
`;
