import { AxiosError, AxiosResponse } from 'axios';
import { buildQueryString } from '../utils/urlUtils';
import { API_METHODS } from './api.enums';
import newAPIService from './newAPIService';

type APIActionOptions = {
    url: string
    query?: any
    payload?: any
    method: keyof typeof API_METHODS
    backdrop?: boolean
    globalError?: boolean
}

export const apiAction = async <Res>(opt: APIActionOptions): Promise<AxiosResponse<Res>> => {
    try {
        let res;
        let { url } = opt;
        if (opt.query) url = `${url}?${buildQueryString(opt.query)}`;
        if (opt.backdrop) window.showBackdrop();
        switch (opt.method) {
        case API_METHODS.POST:
            res = await newAPIService.post<Res>(url, opt.payload);
            break;
        case API_METHODS.GET:
            res = await newAPIService.get<Res>(url);
            break;
        case API_METHODS.PUT:
            res = await newAPIService.put<Res>(url, opt.payload);
            break;
        case API_METHODS.PATCH:
            res = await newAPIService.patch<Res>(url, opt.payload);
            break;
        case API_METHODS.DELETE:
            res = await newAPIService.delete<Res>(url);
            break;
        default:
            break;
        }
        return res;
    } catch (error) {
        const err = error as AxiosError;
        if (opt?.globalError) window.showGlobalModal('אירעה שגיאה', error.message);
        throw err;
    } finally {
        if (opt.backdrop) window.hideBackdrop();
    }
};

// type AnyFunction = (...args: any[]) => any

// type ArgumentTypes<F extends AnyFunction> = F extends (...args: infer A) => any ? A : never;

// export type APIActionOptions = {
//     backdrop?: boolean
//     globalError?: boolean
// }

// type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

// export const createAPIAction = async <T extends AnyFunction>(
//     fn: T, opt: APIActionOptions = {},
// ): Promise<ThenArg<ReturnType<T>>> => {
//     try {
//         if (opt.backdrop) window.showBackdrop();
//         const res = await fn();
//         return res;
//     } catch (error) {
//         if (opt?.globalError) window.showGlobalModal('אירעה שגיאה', error.message);
//         return error;
//     } finally {
//         if (opt.backdrop) window.showBackdrop();
//     }
// };
// export const createAPIAction = <T extends SomeAsyncFunction>(fn: T, opt: APIActionOptions = {}) => async (
//     ...args: ArgumentTypes<T>
// ): Promise<ThenArg<ReturnType<T>>> => {
//     try {
//         if (opt.backdrop) window.showBackdrop();
//         const res = await fn(...args);
//         return res;
//     } catch (error) {
//         if (opt?.globalError) window.showGlobalModal('אירעה שגיאה', error.message);
//         return error;
//     } finally {
//         if (opt.backdrop) window.showBackdrop();
//     }
// };
