import React from 'react';
import { Pagination as PaginationMUI } from '@mui/material';
import { updateQueryStringParameter, removeURLParameter } from '../../../utils/urlUtils';
import { Flexbox } from '../../foundation/Flex';
import Button from '../../foundation/Button';
import TextField from '../../foundation/TextField';

export type PaginationProps = {
    showTopPagination?: boolean,
    currentPage: number,
    pages: number,
    gotoPage?: (page: number) => void,
    url?: string,
}

type State = {
    page?: string
}

export default class Pagination extends React.PureComponent<PaginationProps, State> {
    constructor(props) {
        super(props);
        this.state = {};
        this.selectRef = React.createRef();
    }

    selectRef: React.RefObject<HTMLSelectElement>

    renderGotoSpecificPage() {
        if (this.props.pages < 15) return '';
        return <>
            <TextField
                size="small"
                placeholder="מספר דף"
                type="number"
                inputProps={{
                    max: this.props.pages,
                }}
                style={{ width: '100px', marginInlineEnd: '10px' }}
                onChange={ev => this.setState({ page: ev.target.value })}
            />
            <Button
                disabled={!this.state.page}
                size="small" onClick={() => {
                    const page = parseInt(this.state.page, 10);
                    if (page > this.props.pages) return;
                    this.onGoToPage(page);
                }}>עבור</Button>
        </>;
    }

    onGoToPage = (page: number) => {
        if (this.props.gotoPage) {
            this.props.gotoPage(page);
        } else {
            window.location.assign(page === 1 ? removeURLParameter('page', this.props.url)
                : updateQueryStringParameter('page', page.toString(), this.props.url));
        }
    }

    getPagination() {
        return <Flexbox>
            <PaginationMUI
                page={this.props.currentPage}
                onChange={(ev, page) => this.onGoToPage(page)}
                count={this.props.pages}
                shape="rounded"
                variant="outlined"
                color="primary"
                showLastButton
                showFirstButton
                getItemAriaLabel={(type, page) => `עבור לעמוד ${page}`}
                size="large"
                siblingCount={2}
            />
            {this.renderGotoSpecificPage()}
        </Flexbox>;
    }

    render() {
        return this.props.pages > 0 ? <div>
            {this.props.showTopPagination && this.getPagination()}
            {this.props.children}
            {this.getPagination()}
        </div> : (this.props.children || '');
    }
}
