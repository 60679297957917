/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgEngineering = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.215} viewBox="0 0 28.215 28.215" {...props}>
    <g data-name="Group 268">
      <path
        data-name="Path 837"
        d="M19.128 6.392V3.608a2.282 2.282 0 00-2.282-2.282h-5.478a2.282 2.282 0 00-2.282 2.282v2.784z"
        fill="#12b05e"
      />
      <path
        data-name="Path 838"
        d="M15.248 6.392h-2.282V.854a.441.441 0 01.441-.441h1.4a.441.441 0 01.441.441z"
        fill="#efd27c"
      />
      <path
        data-name="Path 839"
        d="M16.846 1.326h-1.267a2.282 2.282 0 012.282 2.282v2.784h1.267V3.608a2.282 2.282 0 00-2.282-2.282z"
        fill="#009b4b"
      />
      <path
        data-name="Path 840"
        d="M9.543 11.369H8.63a.913.913 0 01-.913-.913V8.63a.913.913 0 01.913-.913h10.954a.913.913 0 01.913.913v1.826a.913.913 0 01-.913.913H9.543z"
        fill="#ffb37a"
      />
      <path
        data-name="Path 841"
        d="M11.369 14.107v6.391h5.478v-6.391z"
        fill="#ffb37a"
      />
      <path
        data-name="Path 842"
        d="M9.543 7.303v4.067a4.565 4.565 0 109.129 0V7.305z"
        fill="#ffc89f"
      />
      <path
        data-name="Path 843"
        d="M20.498 6.804a.913.913 0 00-.913-.913H8.63a.913.913 0 00-.913.913v.456a.456.456 0 00.456.456h11.868a.456.456 0 00.456-.456v-.456z"
        fill="#efd27c"
      />
      <path
        data-name="Path 844"
        d="M19.584 5.891h-1.267a.913.913 0 01.913.913v.456a.456.456 0 01-.456.456h1.267a.456.456 0 00.456-.456v-.456a.913.913 0 00-.913-.913z"
        fill="#eabc73"
      />
      <path
        data-name="Path 845"
        d="M3.152 17.759a1.826 1.826 0 011.826-1.826H7.26a.456.456 0 01.456.456v1.645H3.152z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 846"
        d="M20.498 18.034v-1.645a.456.456 0 01.456-.456h2.278a1.826 1.826 0 011.826 1.826v.276z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 847"
        d="M23.236 15.933h-1.267a1.826 1.826 0 011.826 1.826v.276h1.267v-.276a1.826 1.826 0 00-1.826-1.826z"
        fill="#ebb400"
      />
      <path
        data-name="Path 848"
        d="M3.152 17.759h21.911v10.042H3.152z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 849"
        d="M23.795 17.759h1.267v10.042h-1.267z"
        fill="#ebb400"
      />
      <g data-name="Group 266">
        <path
          data-name="Path 850"
          d="M26.038 22.324h-1.007a.882.882 0 00-.882.882v4.155a.441.441 0 00.441.441h2.327a.882.882 0 00.882-.882v-2.832a1.763 1.763 0 00-1.761-1.764z"
          fill="#f4dabd"
        />
        <path
          data-name="Path 851"
          d="M26.039 22.324h-1.008a.882.882 0 00-.107.007 1.763 1.763 0 011.609 1.756v2.837a.882.882 0 01-.882.882h1.267a.882.882 0 00.882-.882v-2.832a1.763 1.763 0 00-1.761-1.768z"
          fill="#ebb39a"
        />
      </g>
      <g data-name="Group 267">
        <path
          data-name="Path 852"
          d="M2.176 22.324h1.007a.882.882 0 01.882.882v4.155a.441.441 0 01-.441.441H1.295a.882.882 0 01-.882-.878v-2.832a1.763 1.763 0 011.763-1.768z"
          fill="#f4dabd"
        />
      </g>
    </g>
    <g data-name="Group 269" fill="#131213">
      <path
        data-name="Path 853"
        d="M14.108 12.695a1.331 1.331 0 001.148-.662.413.413 0 00-.715-.415.5.5 0 01-.866 0 .413.413 0 00-.715.415 1.331 1.331 0 001.148.662z"
      />
      <path
        data-name="Path 854"
        d="M12.281 10.413a.413.413 0 00.413-.413v-.456a.414.414 0 10-.827 0V10a.413.413 0 00.414.413z"
      />
      <path
        data-name="Path 855"
        d="M15.933 10.413a.413.413 0 00.413-.413v-.456a.414.414 0 10-.827 0V10a.413.413 0 00.414.413z"
      />
      <path
        data-name="Path 856"
        d="M26.038 21.911h-.562V17.76a2.242 2.242 0 00-2.239-2.239h-2.282a.871.871 0 00-.87.87v.956h-.59a.414.414 0 100 .827h5.15v3.8a1.3 1.3 0 00-.913 1.237v4.183H8.543a.414.414 0 100 .827h18.376a1.3 1.3 0 001.3-1.3v-2.834a2.179 2.179 0 00-2.181-2.176zm-5.127-5.521a.043.043 0 01.043-.043h2.282a1.415 1.415 0 011.351 1h-3.676zm6.477 10.53a.469.469 0 01-.468.468h-2.325a.028.028 0 01-.028-.028v-.973h1.413a.414.414 0 000-.827h-1.413v-1h1.413a.414.414 0 000-.827h-1.413v-.531a.469.469 0 01.468-.468h1.007a1.352 1.352 0 011.35 1.35z"
      />
      <path
        data-name="Path 857"
        d="M17.846 18.173a.414.414 0 100-.827h-.586v-2.127a4.973 4.973 0 001.809-3.437h.517a1.328 1.328 0 001.326-1.326V8.63a1.318 1.318 0 00-.245-.766.866.866 0 00.245-.6V6.8a1.328 1.328 0 00-1.326-1.326h-.043V3.609a2.7 2.7 0 00-2.7-2.7h-1.181V.854A.855.855 0 0014.808 0h-1.4a.855.855 0 00-.854.854v.059h-1.185a2.7 2.7 0 00-2.7 2.7v1.865H8.63A1.328 1.328 0 007.3 6.8v.46a.866.866 0 00.245.6 1.319 1.319 0 00-.245.77v1.826a1.328 1.328 0 001.33 1.326h.517a4.973 4.973 0 001.808 3.437v2.127H8.13v-.956a.871.871 0 00-.87-.87H4.978a2.242 2.242 0 00-2.239 2.239v4.152h-.562A2.179 2.179 0 000 24.087v2.833a1.3 1.3 0 001.3 1.3h5.6a.414.414 0 100-.827H4.477V23.206a1.3 1.3 0 00-.913-1.237v-3.8zm2.239-7.717a.5.5 0 01-.5.5h-.5V8.13h.5a.5.5 0 01.5.5v1.826zm-3.239-8.717a1.871 1.871 0 011.869 1.869v1.87h-1v-.5a.414.414 0 00-.827 0v.5h-1.226V1.739zM13.38.854a.028.028 0 01.028-.028h1.4a.028.028 0 01.028.028v4.624H13.38zM9.5 3.609a1.871 1.871 0 011.869-1.869h1.184v3.738h-1.227v-.5a.414.414 0 00-.827 0v.5h-1zM8.13 6.8a.5.5 0 01.5-.5h10.955a.5.5 0 01.5.5v.46a.043.043 0 01-.043.043H8.173a.043.043 0 01-.043-.043zm0 3.652V8.63a.5.5 0 01.5-.5h.5v2.825h-.5a.5.5 0 01-.5-.5zm1.826.913V8.13h8.3v3.239a4.151 4.151 0 01-8.3 0zm1.826 4.4a4.969 4.969 0 004.651 0v1.577h-4.651v-1.573zm-6.8.578h2.279a.043.043 0 01.043.043v.956H3.627a1.415 1.415 0 011.351-1zm-1.326 7.39H2.239a.414.414 0 100 .827h1.413v1H2.239a.414.414 0 100 .827h1.413v.972a.028.028 0 01-.028.028H1.3a.469.469 0 01-.468-.468v-2.832a1.352 1.352 0 011.35-1.35h1.001a.469.469 0 01.468.468z"
      />
    </g>
  </svg>
);

SvgEngineering.defaultProps = {};
export default SvgEngineering;
/* eslint-enable */
