import {
    Box,
    Container, Divider, IconButton, Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Delete, FileCopy } from '@mui/icons-material';
import { LoggedInUser, UserProfileViewModel, UserSettingsViewModel } from '../../../types/auth.types';
import Button from '../foundation/Button';
import { ShadowBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import DateFormat from '../foundation/DateFormat';
import { CenteredFlex, Flexbox } from '../foundation/Flex';
import Page from '../layout/base.page';
import { deleteCV, setDefaultCV, uploadUserCV } from '../../api/user.api';
import UserNotification, { UserNotificationProps } from '../components/UserNotification';
import Tooltip from '../foundation/Tooltip';
import Anchor from '../foundation/Anchor';
import Alert from '../foundation/Alert';

export type UserProfilePageProps = {
    url: string
    user: LoggedInUser
    userProfile: UserProfileViewModel
    userSettings: UserSettingsViewModel
}

const ChooseCVArea = styled(CenteredFlex)`
    button {
        margin: 0 1rem;
    }
    justify-content: start;
    flex-wrap: wrap;
    ${props => props.theme.breakpoints.down('sm')} {
        > div {
            padding: 1rem 0;
        }
    }
`;
const CVItem = styled(Flexbox)`
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem 0;
    border-radius: 10px;
    > div {
        margin: 0 0.5rem;
    }
    > div:last-child {
        margin-inline-start: auto;
    }
`;

const UserProfilePage = (props: UserProfilePageProps) => {
    const [file, setFile] = useState<File>(null);
    const [notification, setNotification] = useState<UserNotificationProps>(null);
    const [userProfile, setUserProfile] = useState<UserProfileViewModel>(props.userProfile);
    const fileEl = useRef<HTMLInputElement>(null);
    const uploadFile = async () => {
        try {
            const formData = new FormData();
            window.showBackdrop();
            formData.append('file', file);
            const newCV = await uploadUserCV({ userid: props.user.userid }, formData);
            const newUserProfile = {
                ...userProfile,
                cvs: [newCV.data, ...userProfile.cvs].map(cv => {
                    cv.default = cv.cvid === newCV.data.cvid ? true : null;
                    return cv;
                }),
            };
            setUserProfile(newUserProfile);
            setNotification({ id: Math.random(), message: 'קורות החיים הועלו למערכת בהצלחה', severity: 'success' });
        } catch (error) {
            window.showGlobalModal('אירעה שגיאה', error.message);
            setNotification({ id: Math.random(), message: `אירעה שגיאה : ${error.message}`, severity: 'error' });
        } finally {
            window.hideBackdrop();
            setFile(null);
            fileEl.current.value = '';
        }
    };

    useEffect(() => {
        if (file) uploadFile();
    }, [file]);

    const setCVAsDefault = async cvid => {
        window.showBackdrop();
        const data = { default: true };
        await setDefaultCV({ userid: props.user.userid, cvid }, data);
        const newUserProfile = {
            ...userProfile,
            cvs: userProfile.cvs?.map(cv => {
                cv.default = cv.cvid === cvid ? true : null;
                return cv;
            }),
        };
        setUserProfile(newUserProfile);
        setNotification({
            id: Math.random() * 1000,
            severity: 'success',
            message: 'קורות החיים הוגדרו כברירת מחדל בהצלחה',
        });
        window.hideBackdrop();
    };

    const onCVDelete = async cvid => {
        window.showBackdrop();
        await deleteCV({ userid: props.user.userid, cvid });
        const newUserProfile = { ...userProfile, cvs: userProfile.cvs?.filter(cv => cv.cvid !== cvid) };
        setUserProfile(newUserProfile);
        setNotification({
            id: Math.random() * 1000,
            severity: 'success',
            message: 'קורות החיים נמחקו בהצלחה',
        });
        window.hideBackdrop();
    };

    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle={'Mploy - ניהול קו"ח'}>
        <Container>
            <CustomBR />
            {notification && <><UserNotification blink {...notification} /><CustomBR /></>}
            <ShadowBGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול קורות החיים שלי</Typography>
                <CustomBR />
                <Typography color="secondary" variant="body1">העלאת קובץ קורות החיים ( עד 3 קבצים )</Typography>
                <Typography color="secondary" variant="caption">שים לב: סוגי קבצים אפשריים : rtf, doc, docx, pdf, txt או תמונה. גודל מקסימלי : 4MB</Typography>
                <CustomBR />
                <ChooseCVArea>
                    <input
                        onChange={ev => { setFile(ev.target.files?.[0]); }}
                        hidden ref={fileEl} type="file" id="cv-file" aria-describedby="העלאת קובץ" />
                    <div><Box display="flex"><Button
                        disabled={userProfile.cvs?.length > 2 || !!file}
                        color="secondary" onClick={() => { fileEl.current.click(); }}>הוספת קובץ קורות חיים</Button>
                    </Box>
                    <CustomBR />
                    {file && <div><Typography color="secondary" variant="body1">הקובץ {file.name} נשמר במערכת ברגעים אלו...</Typography></div>}
                    <CustomBR />
                    </div>
                </ChooseCVArea>
                <Typography color="secondary" fontWeight="bold" variant="h5">קורות החיים במערכת</Typography>
                <div>
                    {userProfile.cvs?.sort(cv => (cv.default ? -1 : 1)).map((cv, index) => <div key={cv.cvid}>{index > 0 && <Divider />}<CVItem>
                        <div><FileCopy color="secondary" /></div>
                        <div><Anchor blank href={`/user/downloadCV/${cv.cvid}`} color="secondary" fontWeight="bold">{cv.originalName}</Anchor></div>
                        <Tooltip title="תאריך העלאת קובץ למערכת">
                            <div>
                                <DateFormat showTime date={cv.createdAt} />
                            </div>
                        </Tooltip>
                        <div>
                            {cv.default ? <Box display="inline-block"><Typography color="secondary" fontWeight="bold">ברירת מחדל</Typography></Box>
                                : <Button size="small" color="secondary" onClick={() => setCVAsDefault(cv.cvid)}>הגדר כברירת מחדל</Button>}
                            {cv.default && userProfile.cvs?.length !== 1
                                ? <IconButton disabled onClick={() => onCVDelete(cv.cvid)}>
                                    <Delete color={cv.default ? 'inherit' : 'error'} />
                                </IconButton>
                                : <Tooltip title="מחיקת קורות חיים">
                                    <IconButton onClick={() => onCVDelete(cv.cvid)}>
                                        <Delete color={cv.default ? 'inherit' : 'error'} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </CVItem></div>)}
                    {props.userSettings.showToEmployers && <><CustomBR /><Alert severity="info"><>
                        הינך מוצג בפני מעסיקים פוטנציאלים.&nbsp;
                        <Anchor href="/user/settings">הגדרות משתמש</Anchor>
                    </></Alert></>}
                </div>
                <CustomBR />
            </ShadowBGContainer>
        </Container>
        <CustomBR />
    </Page>;
};

export default UserProfilePage;
