import {
    Container, Divider, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoggedInUser } from '../../../types/auth.types';
import { JobViewModel } from '../../../types/jobs.types';
import { clearUsersToJobEmail } from '../../api/admin.actions';
import { getJob } from '../../api/job.api';
import JobItem from '../components/JobItem';
import Button from '../foundation/Button';
import CustomBR from '../foundation/CustomBR';
import { Flexbox } from '../foundation/Flex';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';

export type AdminJobsProps = {
    url: string
    user?: LoggedInUser
}

interface IFormAdminJobs {
    jobId: string
}

const AdminCompanies = (props: AdminJobsProps): JSX.Element => {
    const [job, setJob] = useState<JobViewModel>(null);

    const { register, getValues } = useForm<IFormAdminJobs>();

    const loadJob = async () => {
        const jobId = getValues('jobId');
        const jobRes = await getJob({ jobId: parseInt(jobId, 10) });
        setJob(jobRes.data);
    };
    const onClearUsersToJobEmail = async () => {
        const jobId = getValues('jobId');
        await clearUsersToJobEmail({ jobId: parseInt(jobId, 10) });
    };

    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - דרושים - ניהול משרות" >
        <Container>
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול משרות</Typography>
            <CustomBR />
            <div><Typography color="secondary" variant="h4">טעינת משרה</Typography></div>
            <CustomBR />
            <Flexbox>
                <TextField
                    inputRef={register()}
                    name="jobId"
                    id="jobId"
                    style={{ width: '300px' }}
                    type="text" label="מספר משרה" />
                &nbsp;&nbsp;&nbsp;
                <Button size="large" onClick={loadJob}>חיפוש משרה</Button>
            </Flexbox>
            <CustomBR />
            <div><Typography color="secondary" variant="h4">תוצאות</Typography></div>
            <CustomBR />
            <Divider />
            <CustomBR />

            {job && <>
                <JobItem job={job} lightMode
                    showRemoveJob={false}
                    showFullDetails />

                <CustomBR />
                <Button color="error" size="small" onClick={onClearUsersToJobEmail}>איפוס דיוור למשתמשים</Button>
                <CustomBR />
            </>}
        </Container>

    </Page>;
};

export default AdminCompanies;
