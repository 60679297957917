import React from 'react';
import { Close, Add as AddIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { Typography } from '@mui/material';

import Button from './Button/Button';

export type RemovableTagProps = {
    text: string
    onRemoveClick: (ev) => void
}

const TagStyle = styled.div`
    display: inline-flex;
    align-items: center;
    padding-inline-end: 0.7rem;
    padding-bottom: 0.7rem;
    .MuiTypography-root {
        border-end-end-radius: 5px;
        border-start-end-radius: 5px;
        padding-inline-end: 0.5rem;
    }
    .MuiButtonBase-root {
        border-radius: 0;
        border-start-start-radius: 5px;
        border-end-start-radius: 5px;
        border-inline-end: 1px solid white;
        padding-left: 0;
        padding-right: 0;
        min-width: 30px;
        box-shadow: none;
    }
`;
const RemovableTagStyle = styled(TagStyle)`
    display: inline-flex;
    align-items: center;
    padding-inline-end: 0.7rem;
    padding-bottom: 0.7rem;
    .MuiTypography-root {
        background: ${props => props.theme.palette.primary.main};
        padding: 4px;
    }
    .MuiButtonBase-root {
        border-radius: 0;
        border-start-start-radius: 5px;
        border-end-start-radius: 5px;
        border-inline-end: 1px solid white;
        padding-left: 0;
        padding-right: 0;
        min-width: 30px;
        box-shadow: none;
    }
`;

const RemovableTag = (props: RemovableTagProps): JSX.Element => <RemovableTagStyle>
    <Button size="small" onClick={props.onRemoveClick}><Close fontSize="small" /></Button>
    <Typography variant="body2" color="white" display="inline-block">{props.text}</Typography>
</RemovableTagStyle>;

export type AddableTagProps = {
    text: string
    onAddClick: (ev) => void
}

const AddableTagStyle = styled(TagStyle)`
    .MuiTypography-root {
        background: initial;
        border: 1px solid ${props => props.theme.palette.primary.main}9c;
        padding: 3px;
        color: ${props => props.theme.palette.primary.main};
    }
`;

export const AddableTag = (props: AddableTagProps): JSX.Element => <AddableTagStyle>
    <Button variant="outlined" size="small" onClick={props.onAddClick}><AddIcon fontSize="small" /></Button>
    <Typography variant="body2" color="white" display="inline-block">{props.text}</Typography>
</AddableTagStyle>;

export default RemovableTag;
