/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={59.332} viewBox="0 0 59.332 59.332" {...props}>
    <path
      data-name="Path 2677"
      d="M49.598 11.969l8.575 8.422V50.12a8.054 8.054 0 01-8.054 8.054H9.213a8.054 8.054 0 01-8.054-8.054V20.391l8.515-8.515z"
      fill="#ffc400"
    />
    <path
      data-name="Path 2678"
      d="M39.282 39.282l10.316-10.316V4.635a3.476 3.476 0 00-3.477-3.476H13.21a3.476 3.476 0 00-3.476 3.476v24.33L20.05 39.282l9.616-5.229z"
      fill="#fff"
    />
    <g data-name="Group 830" fill="#12b05e">
      <path data-name="Path 2679" d="M49.598 11.969l8.575 8.422-8.575 8.575z" />
      <path data-name="Path 2680" d="M9.734 11.969l-8.575 8.417 8.575 8.575z" />
    </g>
    <path
      data-name="Path 2681"
      d="M43.413 57.014h-.015a1.159 1.159 0 000 2.318h.015a1.159 1.159 0 100-2.318z"
    />
    <path
      data-name="Path 2682"
      d="M58.989 19.568l-8.233-8.175V4.635A4.641 4.641 0 0046.121 0h-32.91a4.641 4.641 0 00-4.636 4.635v6.7L.339 19.571A1.16 1.16 0 000 20.39v29.729a9.223 9.223 0 009.213 9.213h29.02a1.159 1.159 0 100-2.318H9.213a6.851 6.851 0 01-3.69-1.075L7.7 53.767h17.8a1.159 1.159 0 100-2.318H10.013l13.823-13.823a8.254 8.254 0 0111.659 0l18.314 18.313a6.851 6.851 0 01-3.689 1.075h-1.565a1.159 1.159 0 100 2.318h1.564a9.175 9.175 0 006.147-2.358 1.167 1.167 0 00.116-.1c.015-.015.029-.032.043-.048a9.186 9.186 0 002.907-6.707V20.39a1.159 1.159 0 00-.343-.822zm-2.458.825l-5.775 5.775V14.659zM8.575 26.168L2.8 20.39l5.778-5.778zM2.318 50.119V23.188l16.339 16.34L3.8 54.384a6.859 6.859 0 01-1.482-4.265zM22.2 35.987l-1.9 1.9-9.4-9.4V4.635a2.32 2.32 0 012.318-2.318h32.903a2.32 2.32 0 012.318 2.318v23.85l-9.4 9.4-1.9-1.9a10.575 10.575 0 00-14.939.002zm33.333 18.4L40.675 39.528l16.339-16.34v26.931a6.859 6.859 0 01-1.483 4.265z"
    />
    <path
      data-name="Path 2683"
      d="M18.719 12.979h11.643a1.159 1.159 0 100-2.318H18.719a1.159 1.159 0 100 2.318z"
    />
    <path
      data-name="Path 2684"
      d="M35.73 12.979a1.159 1.159 0 10-.819-.34 1.172 1.172 0 00.819.34z"
    />
    <path
      data-name="Path 2685"
      d="M18.719 21.323h21.895a1.159 1.159 0 100-2.318H18.719a1.159 1.159 0 100 2.318z"
    />
    <path
      data-name="Path 2686"
      d="M41.773 28.507a1.159 1.159 0 00-1.159-1.159h-21.9a1.159 1.159 0 100 2.318h21.895a1.159 1.159 0 001.164-1.159z"
    />
  </svg>
);

SvgMail.defaultProps = {};
export default SvgMail;
/* eslint-enable */
