import {
    Box, Container, Dialog, FormControlLabel, FormGroup, Grid, Hidden, IconButton, Slide, Typography,
} from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import Mark, { MarkOptions } from 'mark.js';
import { LoggedInUser, UserProfileViewModel } from '../../../types/auth.types';
import { JobSearchResultViewModel } from '../../../types/jobs.types';
import { ExtendedTagViewModel } from '../../../types/tags.types';
import JobItemList from '../containers/JobItemList/JobItemList.container';
import Checkbox from '../foundation/Checkbox';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import theme from '../layout/theme';
import { GeoAutocomplete, LocationOption, TagsSearchAutocomplete } from '../components/AutocompleteRemote';
import Select from '../foundation/Select';
import Anchor from '../foundation/Anchor';

import SmartAgentModal from '../modals/SmartAgentModal';
import { updateQueryStringParameter } from '../../utils/urlUtils';
import Pagination from '../components/Pagination/Pagination';
import Button from '../foundation/Button';
import { Breadcrumb } from '../layout/Breadcrumbs/Breadcrumbs';
import { setCookie } from '../infra/storageUtils';
import TagsFilters from '../components/TagsFilters/TagsFilters';
// import Mark from 'mark.js';

type JobsPageCritera = {
    showAppliedJobs: boolean,
    showJobsSentByEmail: boolean,
    lat?: string,
    lng?: string,
    pageSize: number,
    page: number,
    start: number,
    direction: 'DESC' | 'ASC',
    distance?: number,
    address?: string,
    orderby: 'updatedAt' | 'createdAt' | 'distanceSquared',
    loc?: string,
    originalText?: string
    text?: string
};

export type JobsPageProps = {
    url: string,
    user?: LoggedInUser,
    criteria: JobsPageCritera,
    jobs: JobSearchResultViewModel[],
    totalJobs: number,
    rootTags: ExtendedTagViewModel[],
    userProfile?: UserProfileViewModel,
    tags: ExtendedTagViewModel[],
    relatedTags: ExtendedTagViewModel[],
    verticalTags: ExtendedTagViewModel[],
    title: string,
    description: string,
    h1: string,
}

const ROOT_TAG_LEVEL = 1;
const criteriaDefaults = {
    loc: 'תל אביב',
    distance: 20,
    showAppliedJobs: false,
    showJobsSentByEmail: true,
};

type JobsPageState = {
    showSmartAgentModal: boolean
    currentPage: number
    showFiltersDialog?: boolean
    pages: number
} & JobsPageProps;

const SearchFieldsWrapperStyled = styled.div`
    padding: 2rem;
    border-radius: 10px;
    background: ${props => props.theme.palette.primary.extraLight};
    a.MuiLink-root {
        text-decoration: underline;
    }
`;

const styleWidthCauseOfAds = { width: '100%' };

const TransitionMobileDialog = React.forwardRef((
    props: TransitionProps & {
        children?: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) => <Slide direction="down" ref={ref} {...props} />);

class JobsPage extends React.Component<JobsPageProps, JobsPageState> {
    searchTagsText: string

    refJobList: React.RefObject<HTMLDivElement> = React.createRef()

    Mark: typeof Mark

    breadCrumbs: Breadcrumb[]

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showFiltersDialog: false,
            showSmartAgentModal: false,
            currentPage: props.criteria.page || 1,
            pages: Math.ceil(props.totalJobs / props.criteria.pageSize),
        };
        this.breadCrumbs = this.getBreadCrumbs();
    }

    componentDidMount() {
        this.Mark = require('mark.js');
        this.markSearchWords();
    }

    instance: Mark

    markSearchWords() {
        // const context = document.querySelectorAll('.job-list .preMock');
        this.instance = new this.Mark(this.refJobList.current.querySelectorAll('p'));
        let keywords = this.state.criteria.originalText;
        if (!keywords?.trim()) return;

        const options: MarkOptions = {
            wildcards: 'enabled',
            ignorePunctuation: ':;.,-–—‒_(){}[]!\'"+='.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&').split(''),
        };

        if (keywords[0] === '"' && keywords[keywords.length - 1] === '"') {
            keywords = keywords.slice(1, keywords.length - 1);
            options.accuracy = {
                value: 'exactly',
                limiters: ['.', ','],
            };
            options.separateWordSearch = false;
        }

        this.instance.unmark({ done: () => { this.instance.mark(keywords, options); } });
    }

    getTagsForUrl() {
        const { tags } = this.state;
        if (!tags?.length) return '';
        if (tags.length === 1) return `[${tags[0].display}]`;
        return `[${tags.map(tagItem => tagItem.display).join('][')}]`;
    }

    getBreadCrumbs(): Breadcrumb[] {
        let url = '/';
        const crumbs = [];
        const selectedRootTags = this.state.tags?.filter(tag => tag.level === ROOT_TAG_LEVEL);
        const { criteria } = this.state;
        if (selectedRootTags?.length > 0) {
            url = `/job/tagged/${selectedRootTags[0].display}`;
            crumbs.push({
                text: selectedRootTags[0].tag,
                url,
            });
        }

        const noneRootLeveLTags = this.state.tags?.filter(t => t.level !== ROOT_TAG_LEVEL);
        if (noneRootLeveLTags && noneRootLeveLTags.length > 0) {
            url += url === '/' ? 'job/tagged/' : '+';
            url += noneRootLeveLTags.map(t => t.display).join('+');
            crumbs.push({
                text: noneRootLeveLTags.map(t => t.tag).join(' | '),
                url,
            });
        }

        if (criteria.originalText) {
            url = (url === '/') ? `/job/search/${criteria.originalText}` : `/${this.getTagsForUrl()}${criteria.originalText}`;
            crumbs.push({
                url,
                name: criteria.originalText,
                text: `'${criteria.originalText}'`,
            });
        }

        if (crumbs.length === 0) {
            crumbs.push({
                text: 'כל התחומים',
                url: '/search',
            });
        }

        return crumbs;
    }

    renderResults = () => <div>
        <Typography display="inline" color="secondary">
            <Hidden smDown>לחיפוש זה נמצאו </Hidden>
        </Typography>
        <Typography color={theme.palette.common.yellowText} display="inline">{this.state.totalJobs} משרות</Typography>
    </div>

    updateUserProfile = (userProfile: UserProfileViewModel) => { this.setState({ userProfile }); }

    updateUser = (user: LoggedInUser) => { this.setState({ user }); }

    updateJobs = (jobs: JobSearchResultViewModel[]) => { this.setState({ jobs }); }

    onReadMoreClick = (jobs: JobSearchResultViewModel[]) => { this.markSearchWords(); }

    getQuery() {
        let query = '';
        const { criteria } = this.state;
        const { distance } = criteria;

        if (distance !== criteriaDefaults.distance) {
            query += `&distance=${distance}`;
        }

        if (criteria.loc && criteria.loc !== criteriaDefaults.loc) {
            query += `&loc=${encodeURIComponent(criteria.loc.replace(/\s/g, '-'))}`;
        } else if (criteria.address && criteria.address !== criteriaDefaults.loc) {
            query += `&lat=${criteria.lat}&lng=${criteria.lng}&address=${encodeURIComponent(criteria.address)}`;
        }

        if (this.state.user && criteria.showAppliedJobs !== undefined
            && criteria.showAppliedJobs !== criteriaDefaults.showAppliedJobs) {
            query += `&showAppliedJobs=${criteria.showAppliedJobs}`;
        }

        if (criteria.orderby) {
            query += `&orderby=${criteria.orderby}&direction=${criteria.direction}`;
        }

        return query !== '' ? query.slice(1, query.length) : undefined;
    }

    searchJobs = (ev?: any) => {
        window.showBackdrop();
        const query = this.getQuery();
        const queryString = query ? `?${query}` : '';

        setCookie('jobsCriteria', JSON.stringify(this.state.criteria), 100);

        if (ev) ev.preventDefault();

        let searchTxt = (this.searchTagsText || this.searchValue)?.trim();

        if (searchTxt && !this.state.tags?.find(t => t.tag === searchTxt)) {
            searchTxt = this.getTagsForUrl() + searchTxt.replace(/ /g, '-');
            window.location.assign(`/job/search/${encodeURIComponent(searchTxt)}${queryString}`);
        } else if (this.state.tags && this.state.tags.length > 0) {
            window.location.assign(`/job/tagged/${this.state.tags.map(t => t.display).join('+')}${queryString}`);
        } else {
            window.location.assign(`/job/search/${queryString}`);
        }
    }

    changeShowAppliedJobs = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ criteria: { ...this.state.criteria, showAppliedJobs: ev.target.checked } }, this.searchJobs);
    }

    distanceChange = ev => {
        this.setState(
            { criteria: { ...this.state.criteria, distance: parseInt(ev.target.value, 10) } },
            this.searchJobs,
        );
    }

    getNoneRootTag() {
        return this.state.tags?.filter(tag => tag.level > ROOT_TAG_LEVEL) || [];
    }

    onCategorySelect = (categoryTag?: ExtendedTagViewModel) => {
        if (categoryTag?.tagid > 0) {
            this.setState({ tags: [categoryTag] }, this.searchJobs);
        } else { // select all
            this.setState({ tags: this.getNoneRootTag() }, this.searchJobs);
        }
    }

    onTagSelect = (tag: ExtendedTagViewModel) => {
        const currentTags = this.state.tags || [];
        currentTags.push(tag);
        this.setState({ tags: currentTags }, this.searchJobs);
    }

    onTagItemSelect = (ev, val: ExtendedTagViewModel | string) => {
        if (typeof val === 'string') {
            this.searchTagsText = val;
            this.searchJobs(ev);
            return;
        }

        if (!val.tagid) return;
        if (val.level === ROOT_TAG_LEVEL) {
            this.onCategorySelect(val);
        } else {
            this.onTagSelect(val);
        }
    }

    locationChange = (ev, val: LocationOption) => {
        const { criteria } = this.state;

        if ((val.areaid || val.cityid) && criteria.loc !== val.formatted_addres) {
            criteria.loc = val.formatted_address;
            criteria.lat = null;
            criteria.lng = null;
            criteria.address = null;
        } else if (criteria.address !== val.formatted_address && val.latitude) {
            criteria.lat = val.latitude?.toString();
            criteria.lng = val.longitude?.toString();
            criteria.address = val.formatted_address;
            criteria.loc = null;
        }

        this.setState({ criteria: { ...criteria } }, this.searchJobs);
    }

    orderChange = ev => {
        const optionVal = parseInt(ev.target.value, 10);
        const option = this.getOrderOptionByValue(optionVal);
        this.setState({
            criteria: {
                ...this.state.criteria,
                orderby: option.orderby,
                direction: option.direction as 'DESC' | 'ASC',
            },
        },
        this.searchJobs);
    }

    getOrderOptionByValue = val => this.getOrderbyOptions().find(opt => opt.value === val)

    getOrderOptionByCriteria = () => {
        const opts = this.getOrderbyOptions();
        const val = opts.find(
            opt => opt.orderby === this.state.criteria.orderby && opt.direction === this.state.criteria.direction,
        );
        return val ?? opts[0];
    }

    getDistanceOptions() {
        return [
            { id: 1, value: 2, text: '2 ק"מ' },
            { id: 2, value: 5, text: '5 ק"מ' },
            { id: 3, value: 10, text: '10 ק"מ' },
            { id: 4, value: 20, text: '20 ק"מ' },
            { id: 5, value: 50, text: '50 ק"מ' },
            { id: 6, value: 100, text: '100 ק"מ' },
            { id: 7, value: 1000, text: 'כל הארץ' },
        ];
    }

    getOrderbyOptions() {
        return [
            { value: 1, orderby: null, text: 'מיון חכם' },
            {
                value: 2, direction: 'DESC', orderby: 'updatedAt', text: 'תאריך: חדשים ראשונים',
            },
            {
                value: 3, direction: 'ASC', orderby: 'updatedAt', text: 'תאריך: חדשים אחרונים',
            },
            {
                value: 4, direction: 'ASC', orderby: 'distanceSquared', text: 'מרחק: קרובים ראשונים',
            },
        ];
    }

    removeTag = tagItem => {
        const verticalTags = [...(this.state.verticalTags || [])];
        const relatedTags = [...(this.state.relatedTags || [])];
        const tags = this.state.tags ? this.state.tags.filter(t => t.tagid !== tagItem.tagid) : this.state.tags;
        relatedTags.forEach(t => { if (t.tagid === tagItem.tagid) t.selected = false; });
        verticalTags.forEach(t => { if (t.tagid === tagItem.tagid) t.selected = false; });
        this.setState({ tags, relatedTags, verticalTags }, this.searchJobs);
    }

    openSmartAgent = ev => {
        ev.preventDefault();
        this.setState({ showSmartAgentModal: true });
    }

    closeSmartAgent = () => { this.setState({ showSmartAgentModal: false }); }

    gotoLoginAndRedirectSettings = () => {
        window.history.pushState({}, '', updateQueryStringParameter('redirect', '/user/settings'));
        window.modals.toggleLoginModal();
    }

    renderJobResults() {
        const { totalJobs, currentPage, criteria } = this.state;
        return <Typography variant="body1">
            <span>{totalJobs > 0 ? 'לוח' : ''} {this.props.h1 || this.props.title}, לחיפוש זה נמצאו <b>{totalJobs} משרות</b>.</span>
            {this.props.totalJobs > criteria.pageSize
            && <span> מציג תוצאות {((currentPage - 1) * criteria.pageSize) + 1} - {Math.min(currentPage * criteria.pageSize, totalJobs)}</span>}
        </Typography>;
    }

    closeFiltersDialog = () => this.setState({ showFiltersDialog: false })

    openFiltersDialog = () => this.setState({ showFiltersDialog: true })

    searchValue?: string

    onSearchInputChange = (ev, val, reason) => this.searchValue = val;

    render() {
        const defaultSmartAgentLocation = this.state.criteria.lat ? {
            latitude: parseFloat(this.state.criteria.lat),
            longitude: parseFloat(this.state.criteria.lng),
            formatted_address: this.state.criteria.address,
        } : undefined;

        return <Page
            breadCrumbs={this.breadCrumbs}
            crumbsSide={this.renderResults()}
            url={this.state.url}
            user={this.state.user}
            pageHeaderTitle={this.state.h1 || this.state.title}>
            <SmartAgentModal
                defaultLocation={defaultSmartAgentLocation}
                gotoLogin={this.gotoLoginAndRedirectSettings}
                user={this.props.user}
                defaultTags={this.state.tags}
                onClose={this.closeSmartAgent} open={this.state.showSmartAgentModal} />
            <Container>
                <Hidden mdUp>
                    <Dialog fullScreen
                        TransitionComponent={TransitionMobileDialog}
                        open={this.state.showFiltersDialog}
                        onClose={this.closeFiltersDialog}>
                        <Box display="flex" flexDirection="row-reverse">
                            <IconButton edge="start" color="inherit" onClick={this.closeFiltersDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box paddingX={2}>
                            <TagsFilters
                                tags={this.props.tags}
                                rootTags={this.props.rootTags}
                                verticalTags={this.props.verticalTags}
                                relatedTags={this.props.relatedTags}
                                removeTag={this.removeTag}
                                onTagSelect={this.onTagSelect}
                                onCategorySelect={this.onCategorySelect} />
                        </Box>
                    </Dialog>
                </Hidden>
                <Grid container>
                    <Hidden mdDown>
                        <Grid item md={3}>
                            <Box paddingRight={5}>
                                <TagsFilters
                                    tags={this.props.tags}
                                    rootTags={this.props.rootTags}
                                    verticalTags={this.props.verticalTags}
                                    relatedTags={this.props.relatedTags}
                                    removeTag={this.removeTag}
                                    onTagSelect={this.onTagSelect}
                                    onCategorySelect={this.onCategorySelect} />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item md={9} sm={12} style={styleWidthCauseOfAds}>
                        <CustomBR />
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={this.changeShowAppliedJobs}
                                    defaultChecked={this.props.criteria.showAppliedJobs}
                                    name="showAppliedJobs"
                                />}
                                label="הצג משרות שפניתי אליהן\לא התעניינתי"
                            />
                        </FormGroup>
                        <SearchFieldsWrapperStyled>
                            <Hidden mdUp>
                                <Box display="flex" flexDirection="row-reverse">
                                    <Button disableRipple size="large" onClick={this.openFiltersDialog} variant="text">הצג סינונים נוספים</Button>
                                </Box>
                            </Hidden>
                            <TagsSearchAutocomplete
                                defaultValue={this.props.criteria.originalText}
                                onEnter={this.searchJobs}
                                onInputChange={this.onSearchInputChange}
                                onChange={this.onTagItemSelect}
                            />
                            <CustomBR />
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <GeoAutocomplete
                                        defaultValue={this.props.criteria.loc || this.props.criteria.address}
                                        onChange={this.locationChange} />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Select
                                        fullWidth
                                        defaultValue={this.state.criteria.distance}
                                        onChange={this.distanceChange}
                                        options={this.getDistanceOptions()}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Select
                                        fullWidth
                                        onChange={this.orderChange}
                                        defaultValue={this.getOrderOptionByCriteria().value}
                                        options={this.getOrderbyOptions()}
                                    />
                                </Grid>
                            </Grid>
                            <>
                                <CustomBR />
                                <Box display="flex" justifyContent="center">
                                    <Anchor href="#" onClick={this.openSmartAgent} color="primary">הזמן שלך חשוב? הגדר סוכן חכם</Anchor>
                                </Box>
                            </>
                        </SearchFieldsWrapperStyled>
                        <CustomBR />
                        {this.renderJobResults()}
                        <JobItemList
                            innerRef={this.refJobList}
                            showRemoveJob
                            showDetailsLink
                            onJobsChange={this.updateJobs}
                            onReadMoreClick={this.onReadMoreClick}
                            showTags={false}
                            user={this.state.user}
                            userProfile={this.state.userProfile}
                            onUserChange={this.updateUser}
                            onUserProfileChange={this.updateUserProfile}
                            jobs={this.state.jobs} />
                        <Pagination
                            url={this.state.url}
                            pages={this.state.pages}
                            currentPage={this.state.currentPage}
                        />
                    </Grid>
                </Grid>
                <CustomBR />
            </Container>
        </Page>;
    }
}

export default JobsPage;
