import React, { CSSProperties } from 'react';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';

export type BackToTopProps = {
}

const backToTopStyle = {
    cursor: 'pointer',
    position: 'fixed',
    bottom: '5px',
    right: '5px',
} as CSSProperties;
function ScrollTop(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector('header');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" style={backToTopStyle}>
                {children}
            </div>
        </Zoom>
    );
}

const BackToTop = (props: BackToTopProps): JSX.Element => <ScrollTop {...props}>
    <Fab color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
    </Fab>
</ScrollTop>;

export default BackToTop;
