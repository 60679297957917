import React from 'react';

export default class InListAd extends React.Component {
    componentDidMount() {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.log('adsbygoogle err ', e);
        }
    }

    render() {
        return (
            <div>
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-7369058349181167"
                    data-ad-slot="4805015484"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                />
            </div>
        );
    }
}
