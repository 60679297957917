/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgMedicineAndPharma = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23.574} viewBox="0 0 23.574 30.486" {...props}>
    <g data-name="Medicine and Pharma" transform="translate(-58.042)">
      <path
        data-name="Path 260"
        d="M78.41 9.475a4.37 4.37 0 00-.213-.115 5.316 5.316 0 01-2.614-5.7H64.076a5.316 5.316 0 01-2.614 5.7 4.37 4.37 0 00-.213.115 5.3 5.3 0 00-2.76 4.784v13.079a2.7 2.7 0 002.7 2.7h17.279a2.7 2.7 0 002.7-2.7V14.259a5.3 5.3 0 00-2.758-4.784z"
        fill="#12b05e"
      />
      <g data-name="Group 74" fill="#03984b">
        <path
          data-name="Path 261"
          d="M78.41 9.475a4.37 4.37 0 00-.213-.115 5.316 5.316 0 01-2.614-5.7h-3.067a6.617 6.617 0 002.614 6.359c.072.037.143.074.213.115a5.3 5.3 0 012.76 4.784v12.42a2.7 2.7 0 01-2.7 2.7h3.067a2.7 2.7 0 002.7-2.7V14.259a5.3 5.3 0 00-2.76-4.784z"
        />
        <path
          data-name="Path 262"
          d="M78.41 9.475a4.37 4.37 0 00-.213-.115 5.316 5.316 0 01-2.614-5.7h-3.067a6.617 6.617 0 002.614 6.359c.072.037.143.074.213.115a5.3 5.3 0 012.76 4.784v12.42a2.7 2.7 0 01-2.7 2.7h3.067a2.7 2.7 0 002.7-2.7V14.259a5.3 5.3 0 00-2.76-4.784z"
        />
      </g>
      <path
        data-name="Path 263"
        d="M77.089 5.145H62.571a.881.881 0 01-.881-.881V1.328a.881.881 0 01.881-.881h14.518a.881.881 0 01.881.881v2.935a.881.881 0 01-.881.882z"
        fill="#fb6672"
      />
      <path
        data-name="Path 264"
        d="M77.084.445h-2.172v4.7h2.172a.881.881 0 00.881-.881V1.328a.881.881 0 00-.881-.883z"
        fill="#fb4a59"
      />
      <path
        data-name="Path 265"
        d="M58.489 14.261v9.7H81.17v-9.87H58.491a9.305 9.305 0 00-.002.17z"
        fill="#faf9f3"
      />
      <path
        data-name="Path 266"
        d="M78.048 14.09a6.243 6.243 0 01.054.825v9.046h3.067v-9.87z"
        fill="#ededed"
      />
      <circle
        data-name="Ellipse 30"
        cx={7.238}
        cy={7.238}
        r={7.238}
        transform="translate(62.592 11.72)"
        fill="#ffcd27"
      />
      <path
        data-name="Path 267"
        d="M69.829 11.72a7.264 7.264 0 00-1.534.163 7.239 7.239 0 010 14.149 7.238 7.238 0 101.534-14.312z"
        fill="#ebb400"
      />
      <path
        data-name="Path 268"
        d="M73.883 17.85h-2.658a.289.289 0 01-.289-.289v-2.658a.289.289 0 00-.289-.289H69.01a.289.289 0 00-.289.289v2.658a.289.289 0 01-.289.289h-2.657a.289.289 0 00-.289.289v1.634a.289.289 0 00.289.289h2.658a.289.289 0 01.289.289v2.658a.289.289 0 00.289.289h1.637a.289.289 0 00.289-.289v-2.656a.289.289 0 01.289-.289h2.658a.289.289 0 00.289-.289v-1.637a.289.289 0 00-.29-.288z"
        fill="#faf9f3"
      />
      <path
        data-name="Path 269"
        d="M64.134 2.073a.447.447 0 10-.893 0v1.453a.447.447 0 10.893 0z"
      />
      <path
        data-name="Path 270"
        d="M75.97 3.972a.447.447 0 00.447-.447V2.073a.447.447 0 10-.893 0v1.453a.447.447 0 00.446.446z"
      />
      <path
        data-name="Path 271"
        d="M72.9 3.972a.447.447 0 00.447-.447V2.073a.447.447 0 10-.893 0v1.453a.447.447 0 00.446.446z"
      />
      <path
        data-name="Path 272"
        d="M69.829 3.972a.447.447 0 00.447-.447V2.073a.447.447 0 00-.893 0v1.453a.447.447 0 00.446.446z"
      />
      <path
        data-name="Path 273"
        d="M66.759 3.972a.447.447 0 00.447-.447V2.073a.447.447 0 10-.893 0v1.453a.447.447 0 00.446.446z"
      />
      <path
        data-name="Path 274"
        d="M69.829 11.273a7.684 7.684 0 107.684 7.684 7.693 7.693 0 00-7.684-7.684zm0 14.476a6.791 6.791 0 116.791-6.791 6.8 6.8 0 01-6.791 6.791z"
      />
      <path
        data-name="Path 275"
        d="M73.88 17.404h-2.5v-2.5a.736.736 0 00-.736-.736H69.01a.736.736 0 00-.736.736v2.5h-2.5a.736.736 0 00-.736.736v1.637a.736.736 0 00.736.736h2.5v2.5a.736.736 0 00.736.736h1.637a.736.736 0 00.736-.736v-2.5h2.5a.736.736 0 00.736-.736v-1.638a.736.736 0 00-.739-.735zm-.158 2.215h-2.5a.736.736 0 00-.736.736v2.5h-1.321v-2.5a.736.736 0 00-.736-.736h-2.5v-1.321h2.5a.736.736 0 00.736-.736v-2.5h1.321v2.5a.736.736 0 00.736.736h2.5z"
      />
      <path
        data-name="Path 276"
        d="M81.614 14.078a5.766 5.766 0 00-2.979-4.988 4.082 4.082 0 00-.234-.126 4.656 4.656 0 01-2.358-3.374h1.045a1.329 1.329 0 001.327-1.327V1.327A1.329 1.329 0 0077.088 0H62.57a1.329 1.329 0 00-1.327 1.327v2.935a1.329 1.329 0 001.327 1.327h1.045a4.656 4.656 0 01-2.357 3.373c-.069.036-.152.078-.234.126a5.766 5.766 0 00-2.979 4.987v13.263a3.152 3.152 0 003.148 3.148h17.275a3.152 3.152 0 003.148-3.148V14.26l-.002-.182zM62.57 4.7a.435.435 0 01-.434-.434V1.327a.435.435 0 01.434-.434h14.518a.434.434 0 01.434.434v2.935a.434.434 0 01-.434.434H62.57zm18.153 18.814h-3.449a.447.447 0 000 .893h3.449v2.931a2.258 2.258 0 01-2.255 2.255H61.19a2.258 2.258 0 01-2.255-2.255v-2.931h3.389a.447.447 0 000-.893h-3.389v-8.978h3.389a.447.447 0 000-.893h-3.356a4.819 4.819 0 012.505-3.782c.064-.037.133-.073.193-.1A5.575 5.575 0 0064.52 5.59h10.619a5.575 5.575 0 002.854 4.168l.193.1a4.819 4.819 0 012.5 3.782h-3.412a.447.447 0 100 .893h3.449v8.977z"
      />
    </g>
  </svg>
);

SvgMedicineAndPharma.defaultProps = {};
export default SvgMedicineAndPharma;
/* eslint-enable */
