/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgHardware = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.771} viewBox="0 0 29.771 29.771" {...props}>
    <path
      data-name="Path 436"
      d="M27.009 6.222a1.163 1.163 0 001.163 1.163h1.164v15h-1.164a1.163 1.163 0 000 2.326h1.164v4.625H.436v-4.624h1.163a1.163 1.163 0 100-2.326H.436v-15h1.163a1.163 1.163 0 000-2.326H.436V.436h28.9v4.623h-1.164a1.163 1.163 0 00-1.163 1.163z"
      fill="#12b05e"
    />
    <g data-name="Group 145" transform="translate(1.948 1.803)" fill="#f9f9fb">
      <circle data-name="Ellipse 42" cx={0.872} cy={0.872} r={0.872} />
      <circle
        data-name="Ellipse 43"
        cx={0.872}
        cy={0.872}
        r={0.872}
        transform="translate(24.131)"
      />
      <circle
        data-name="Ellipse 44"
        cx={0.872}
        cy={0.872}
        r={0.872}
        transform="translate(0 24.48)"
      />
      <circle
        data-name="Ellipse 45"
        cx={0.872}
        cy={0.872}
        r={0.872}
        transform="translate(24.131 24.48)"
      />
    </g>
    <path data-name="Rectangle 159" fill="#3e5564" d="M5.771 6h18v18h-18z" />
    <path data-name="Rectangle 160" fill="#3e5564" d="M22.771 6h1v18h-1z" />
    <path data-name="Rectangle 161" fill="#f9f9fb" d="M6.771 7h16v16h-16z" />
    <path data-name="Rectangle 162" fill="#e8edf2" d="M20.771 7h2v16h-2z" />
    <path data-name="Path 437" d="M9.174 18.275v2.395h2.616z" fill="#f9f9fb" />
    <path
      data-name="Rectangle 163"
      fill="#fad87c"
      d="M7.181.436H22.59V2.18H7.181z"
    />
    <path
      data-name="Rectangle 164"
      fill="#febe40"
      d="M20.846.436h1.744V2.18h-1.744z"
    />
    <path
      data-name="Rectangle 165"
      fill="#fad87c"
      d="M7.181 27.591H22.59v1.744H7.181z"
    />
    <path
      data-name="Rectangle 166"
      fill="#febe40"
      d="M20.846 27.591h1.744v1.744h-1.744z"
    />
    <path
      data-name="Path 438"
      d="M28.172 5.495h1.163a.436.436 0 00.436-.436V.436A.436.436 0 0029.335 0H6.29a.436.436 0 000 .872h.455V2.18a.436.436 0 00.436.436H22.59a.436.436 0 00.436-.436V.872H28.9v3.75h-.727a1.6 1.6 0 000 3.2h.727V21.95h-.727a1.6 1.6 0 000 3.2h.727v3.75h-5.874v-1.309a.436.436 0 00-.436-.436H20.5a.436.436 0 000 .872h1.657v.873H7.617v-.872h10.99a.436.436 0 000-.872H7.181a.436.436 0 00-.436.436V28.9H.872v-3.75H1.6a1.6 1.6 0 000-3.2H.872V7.821H1.6a1.6 1.6 0 000-3.2H.872V.872h3.547a.436.436 0 000-.872H.436A.436.436 0 000 .436v4.623a.436.436 0 00.436.436H1.6a.727.727 0 010 1.454H.436A.436.436 0 000 7.385v15a.436.436 0 00.436.436H1.6a.727.727 0 010 1.454H.436a.436.436 0 00-.436.437v4.623a.436.436 0 00.436.436h28.9a.436.436 0 00.436-.436v-4.623a.436.436 0 00-.436-.436h-1.164a.727.727 0 110-1.454h1.163a.436.436 0 00.436-.436v-15a.436.436 0 00-.436-.436h-1.163a.727.727 0 110-1.454zm-6.018-3.75H7.617V.872h14.537z"
    />
    <path
      data-name="Path 439"
      d="M2.82 1.366a1.308 1.308 0 101.308 1.308A1.31 1.31 0 002.82 1.366zm0 1.744a.436.436 0 11.436-.436.437.437 0 01-.436.436z"
    />
    <path
      data-name="Path 440"
      d="M28.26 2.674a1.308 1.308 0 10-1.308 1.308 1.31 1.31 0 001.308-1.308zm-1.744 0a.436.436 0 11.436.436.437.437 0 01-.437-.436z"
    />
    <path
      data-name="Path 441"
      d="M1.512 27.154a1.308 1.308 0 101.308-1.308 1.31 1.31 0 00-1.308 1.308zm1.744 0a.436.436 0 11-.436-.436.437.437 0 01.436.436z"
    />
    <path
      data-name="Path 442"
      d="M26.951 28.463a1.308 1.308 0 10-1.308-1.309 1.31 1.31 0 001.308 1.309zm0-1.744a.436.436 0 11-.436.436.437.437 0 01.436-.437z"
    />
    <path
      data-name="Path 443"
      d="M7.326 22.88h15.118a.436.436 0 00.436-.436V7.326a.436.436 0 00-.436-.436h-11.3a.436.436 0 000 .872h10.864v14.246H7.762V7.762h1.541a.436.436 0 100-.872H7.326a.436.436 0 00-.436.436v15.118a.436.436 0 00.436.436z"
    />
    <path
      data-name="Path 444"
      d="M4.419 6.251a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.95h-.727a.436.436 0 000 .872h.727v.669a.436.436 0 00.436.436h.669v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.95v.727a.436.436 0 00.872 0v-.727h.669a.436.436 0 00.436-.436v-.669h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.95h.727a.436.436 0 000-.872h-.727v-.668a.436.436 0 00-.436-.436h-.669V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.95V4.42a.436.436 0 00-.872 0v.727h-.668a.436.436 0 00-.436.436v.669zm1.6-.233h17.734v17.735H6.018z"
    />
    <path
      data-name="Path 445"
      d="M14.503 9.411a.436.436 0 100-.872h-1.512a.436.436 0 00-.436.436v2.675a.436.436 0 00.436.436h1.512a.436.436 0 000-.872h-1.076v-1.8z"
    />
    <path
      data-name="Path 446"
      d="M16.031 11.65v-.883h1.076a.436.436 0 00.436-.436V8.975a.436.436 0 00-.436-.436h-1.512a.436.436 0 00-.436.436v2.675a.436.436 0 10.872 0zm0-2.239h.64v.484h-.64z"
    />
    <path
      data-name="Path 447"
      d="M18.2 8.539a.436.436 0 00-.436.436v2.675a.436.436 0 00.436.436h1.512a.436.436 0 00.436-.436V8.975a.436.436 0 00-.872 0v2.239h-.64V8.975a.436.436 0 00-.436-.436z"
    />
    <path
      data-name="Path 448"
      d="M8.998 17.876a.436.436 0 00-.26.4v2.395a.436.436 0 00.436.436h2.615a.436.436 0 00.295-.758l-2.617-2.398a.436.436 0 00-.469-.075zm.612 1.39l1.057.968H9.61z"
    />
  </svg>
);

SvgHardware.defaultProps = {};
export default SvgHardware;
/* eslint-enable */
