/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgFreeJobPosting = (props: SVGProps<SVGSVGElement>) => (
  <svg width={43.358} viewBox="0 0 43.358 52.063" {...props}>
    <g data-name="Free Job Posting">
      <g data-name="Group 80">
        <path
          data-name="Path 300"
          d="M40.088 36.257a3.448 3.448 0 01-.372 1.323 197.439 197.439 0 01-29.713-.131 3.456 3.456 0 01-.309-1.192A211.46 211.46 0 019.567 6.43q.06-.9.127-1.8a3.479 3.479 0 013.084-3.194 109.518 109.518 0 0124.227 0 3.478 3.478 0 013.084 3.194q.283 3.77.429 7.54a211.436 211.436 0 01-.43 24.087z"
          fill="#e7ecf6"
        />
        <path
          data-name="Path 301"
          d="M35.811 40.139a3.466 3.466 0 01-.372 1.324 197.384 197.384 0 01-29.714-.132 3.457 3.457 0 01-.308-1.192 211.289 211.289 0 01-.385-25.146q.1-2.339.258-4.68.06-.9.127-1.8A3.48 3.48 0 018.5 5.318a109.556 109.556 0 0124.227 0 3.479 3.479 0 013.084 3.195q.282 3.77.429 7.54.1 2.566.139 5.13.141 9.476-.568 18.956z"
          fill="#f9f6f6"
        />
        <path
          data-name="Path 302"
          d="M35.811 40.139a3.466 3.466 0 01-.372 1.324 197.384 197.384 0 01-29.714-.132 3.456 3.456 0 01-.308-1.192 211.289 211.289 0 01-.385-25.147 46.876 46.876 0 0114.483-.036 3.087 3.087 0 012.569 2.7l.059.533a3.09 3.09 0 003.087 2.755q5.575-.026 11.149.237.141 9.478-.568 18.958z"
          fill="#e7ecf6"
        />
        <path
          data-name="Path 303"
          d="M42.242 43.247q-.172 2.314-.43 4.628a3.089 3.089 0 01-2.828 2.731 215.357 215.357 0 01-34.609 0 3.1 3.1 0 01-2.83-2.749 126.938 126.938 0 01.024-28.378 3.092 3.092 0 012.625-2.706 46.968 46.968 0 0114.617-.055 3.087 3.087 0 012.569 2.7l.059.533a3.091 3.091 0 003.088 2.756q7.474-.035 14.948.449a3.1 3.1 0 012.889 2.9 126.656 126.656 0 01-.119 17.189z"
          fill="#12b05e"
        />
        <path
          data-name="Path 304"
          d="M6.612 50.777q-1.118-.078-2.237-.169a3.1 3.1 0 01-2.83-2.75 126.914 126.914 0 01.023-28.378 3.092 3.092 0 012.624-2.703q1.358-.2 2.671-.333a3.209 3.209 0 00-1.676 2.467 132.009 132.009 0 00-.024 29.517 3.224 3.224 0 001.449 2.349z"
          fill="#03984b"
        />
      </g>
      <g data-name="Group 81">
        <path
          data-name="Path 305"
          d="M43.123 26.011a3.868 3.868 0 00-1.7-2.966c.044-3.623 0-7.29-.146-10.908-.1-2.511-.243-5.057-.43-7.568A4.239 4.239 0 0037.089.674a110.7 110.7 0 00-24.395 0A4.239 4.239 0 008.94 4.5l-.524.054a4.241 4.241 0 00-3.76 3.9 216.883 216.883 0 00-.426 7.547l-.146.02a3.853 3.853 0 00-3.272 3.375 128.122 128.122 0 00-.471 23.7.763.763 0 101.521-.111 126.608 126.608 0 01.465-23.419 2.327 2.327 0 011.977-2.037 46.347 46.347 0 0114.383-.057 2.33 2.33 0 011.934 2.028l.058.529a3.846 3.846 0 003.832 3.436h.018c4.942-.023 9.954.127 14.895.447a2.339 2.339 0 012.18 2.188 126.577 126.577 0 01-.117 17.05v.038c-.111 1.5-.255 3.047-.428 4.6a2.327 2.327 0 01-2.13 2.054 215.616 215.616 0 01-34.488 0 2.339 2.339 0 01-2.137-2.068c-.068-.608-.134-1.25-.2-1.909a.763.763 0 10-1.518.147c.065.666.132 1.316.2 1.931a3.867 3.867 0 003.527 3.425c5.763.464 11.564.7 17.367.7s11.6-.232 17.365-.7a3.854 3.854 0 003.524-3.406c.174-1.559.318-3.113.43-4.62v-.037a128.159 128.159 0 00.121-17.293zm-5.969-3.757a212.125 212.125 0 00-.475-12.275.763.763 0 10-1.522.1 209.425 209.425 0 01.469 12.1c-3.7-.172-7.424-.251-11.106-.234h-.011a2.324 2.324 0 01-2.315-2.075l-.059-.537a3.814 3.814 0 00-.2-.861h9.235a.763.763 0 000-1.525H20.924a3.835 3.835 0 00-1.993-.977 46.647 46.647 0 00-13.168-.17c.073-1.811.169-3.634.29-5.435.043-.649.084-1.236.125-1.8a2.715 2.715 0 012.407-2.493 109.26 109.26 0 0124.058 0 2.725 2.725 0 012.061 1.355.763.763 0 001.325-.756 4.255 4.255 0 00-3.218-2.115 110.809 110.809 0 00-22.307-.208 2.712 2.712 0 012.358-2.158 109.178 109.178 0 0124.059 0 2.713 2.713 0 012.407 2.492 212.232 212.232 0 01.579 17.755 3.857 3.857 0 00-.385-.045q-1.181-.076-2.368-.138z"
        />
        <path
          data-name="Path 306"
          d="M16.732 47.705a2.343 2.343 0 001.148-.3 2.573 2.573 0 001.312-2.256v-7.146a.763.763 0 00-1.525 0v7.146a1.047 1.047 0 01-.527.922c-.631.35-1.286-.268-1.322-.3a.763.763 0 00-1.078 1.079 3.287 3.287 0 00.9.613 2.618 2.618 0 001.092.242z"
          fill="#fbfbfc"
        />
        <path
          data-name="Path 307"
          d="M35.943 37.236h-2.6a.763.763 0 00-.763.763v8.941a.763.763 0 00.763.763s2.389-.011 3.047-.011a3.168 3.168 0 001.714-5.834 2.819 2.819 0 00-2.167-4.623zm0 1.525a1.295 1.295 0 010 2.589h-1.834v-2.592h1.835zm.454 7.405c-.415 0-1.527 0-2.288.007v-3.292h2.288a1.644 1.644 0 010 3.288z"
          fill="#fbfbfc"
        />
        <path
          data-name="Path 308"
          d="M26.072 47.703a5.233 5.233 0 10-5.233-5.233 5.239 5.239 0 005.233 5.233zm0-8.941a3.708 3.708 0 11-3.708 3.708 3.712 3.712 0 013.708-3.708z"
          fill="#fbfbfc"
        />
        <path
          data-name="Path 309"
          d="M31.17 12.563H11.345a.763.763 0 000 1.525H31.17a.763.763 0 000-1.525z"
        />
      </g>
    </g>
  </svg>
);

SvgFreeJobPosting.defaultProps = {};
export default SvgFreeJobPosting;
/* eslint-enable */
