import React, { useRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import CustomBR from '../../foundation/CustomBR';
import TextArea from '../../foundation/TextArea';
import Button from '../../foundation/Button';
import { reportJob } from '../../../api/contactus.api';
import { JobViewModel } from '../../../../types/jobs.types';

export type ReportJobModalProps = {
    open?: boolean
    onClose: () => void
    job?: JobViewModel
};

const ReportJobModalStyle = styled.div`
`;

const ReportJobModal = (props: ReportJobModalProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>();
    if (!props.job) return null;

    const onReportClick = async () => {
        await reportJob(props.job, textareaRef.current.value);
        props.onClose();
        window.showGlobalModal('דיווח על משרה', 'תודה רבה. הדיווח התקבל בהצלחה ונשתדל לטפל בו במהרה.');
    };

    return <DialogWithHeader
        open={props.open}
        onClose={props.onClose} title="דווח על בעיה במשרה">
        <ReportJobModalStyle>
            <CustomBR />
            <Typography>אנו לוקחים ברצינות כל דיווח המתקבל מהמשתמשים על משרות במערכת.
השאירו פרטים ליצירת קשר ותיאור של הדיווח</Typography>
            <CustomBR />
            <TextArea
                inputRef={textareaRef}
                fullWidth
                variant="outlined"
                label="תיאור הדיווח על המשרה"
                rows={5} />
            <CustomBR />
            <Button color="error" fullWidth onClick={onReportClick}>שלח דיווח</Button>
            <CustomBR />
        </ReportJobModalStyle>
    </DialogWithHeader>;
};

export default ReportJobModal;
