import dayjs from 'dayjs';

export const getDates = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
        dateArray.push(currentDate);
        currentDate = new Date(dayjs(currentDate).add(1, 'days').toDate());
    }
    return dateArray;
};
