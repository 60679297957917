import newAPIService from './newAPIService';

type SummaryCounters = {
    numberOfJobs: number
    numberOfTodayJobs: number
    numberOfUsers: number
    numberOfTodayWatchedCV: number
};
export const summaryCount = async () => newAPIService.get<SummaryCounters>('/home/summaryCount');

export const getUserSearchKeys = async (
    params: {userId},
) => newAPIService.get<string[]>(`/userSearchKeys/${params.userId}`);
