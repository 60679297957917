import {
    Container, Step, StepConnector, StepLabel, Stepper, Typography,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from 'styled-components';
import employer_to_job_stateEnum from '../../../common/employer_to_job_state.enum';
import { LoggedInUser } from '../../../types/auth.types';
import { AppliedJobViewModel } from '../../../types/jobs.types';
import { getUserAppliedJobs } from '../../api/userToJob.api';
import Pagination from '../components/Pagination/Pagination';
import Anchor from '../foundation/Anchor';
import { BGContainer, ShadowBGContainer, YellowBox } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import DateFormat from '../foundation/DateFormat';
import { CenteredFlex, Flexbox } from '../foundation/Flex';
import Page from '../layout/base.page';

export type UserAppliedJobsProps = {
    url: string
    user?: LoggedInUser
    appliedJobs: AppliedJobViewModel[],
    totalAppliedJobs: number
}

const AppliedJobItemStyled = styled.div`
`;

const steps = ['פנית למשרה זו', 'הפנייה שלך יחד עם קורות החיים (במידה וצורפו) הגיעו אל המעסיק', 'המעסיק צפה בפנייה שלך!'];

const pageSize = 10;

const renderEmployerResponse = appliedJob => {
    let response;
    const { employerState } = appliedJob;

    switch (employerState) {
    case employer_to_job_stateEnum.CANDIDATE_DOES_NOT_MATCH:
        response = 'לצערנו, המעסיק מצא כי אינך מתאים למשרה.';
        break;
    case employer_to_job_stateEnum.EMPLOYER_WILL_CONTACT:
        response = 'ברכות! המעסיק צפה בפנייה שלך, וציין כי ייצור עמך קשר בקרוב.';
        break;
    case employer_to_job_stateEnum.JOB_OCCUPIED_ALREADY:
        response = 'לצערנו, המעסיק ציין כי המשרה אויישה על ידי מועמד אחר..';
        break;
    default:
        break;
    }

    return response;
};

const direction = 'DESC';
const orderby = 'created_at';

const UserAppliedJobs = (props: UserAppliedJobsProps) => {
    const [appliedJobs, setAppliedJobs] = useState<AppliedJobViewModel[]>(props.appliedJobs);
    const [totalAppliedJobs, setTotalAppliedJobs] = useState<number>(props.totalAppliedJobs);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const onGoToPage = async page => {
        const res = await getUserAppliedJobs(
            { userid: props.user.userid },
            {
                start: (page - 1) * pageSize,
                orderby,
                direction,
            },
            true,
        );

        window.scroll({ top: 0, left: 0 });

        setAppliedJobs(res.data.appliedJobs);
        setTotalAppliedJobs(res.data.totalAppliedJobs);
        setCurrentPage(page);
    };
    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - המשרות אליהן פניתי" >
        <Container>
            <CustomBR />
            <CenteredFlex>
                <Typography color="secondary" fontWeight="bold" variant="h4">סה"כ משרות אליהן הגשת מועמדות</Typography>
            </CenteredFlex>
            <CustomBR />
            <CenteredFlex>
                <YellowBox>
                    <Typography variant="h6" fontWeight="bold">
                        {totalAppliedJobs}
                    </Typography>
                </YellowBox>
            </CenteredFlex>
            <CustomBR />
            {totalAppliedJobs === 0 && <Typography>לא נמצאו במערכת משרות שהגשת אליהן מועמדות</Typography>}
            {totalAppliedJobs > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                gotoPage={onGoToPage}
                url={props.url}
                pages={Math.ceil(totalAppliedJobs / pageSize)}
                currentPage={currentPage}
            /></Flexbox><CustomBR /></>}
            {appliedJobs?.map(appliedJob => {
                if (!appliedJob.job) return '';
                let status = 'פנית למשרה זו, ואנו מעבירים את הפנייה למעסיק',
                    activeStep = 0;
                if (appliedJob.employerState === employer_to_job_stateEnum.SENT) {
                    status = 'הפנייה הועברה למעסיק';
                    activeStep = 1;
                }
                if (appliedJob.employerState === employer_to_job_stateEnum.WATCHED) {
                    status = 'ברכות! המעסיק צפה בפנייה שלך';
                    activeStep = 2;
                }
                return <div key={appliedJob.job.jobid}><AppliedJobItemStyled>
                    <ShadowBGContainer>
                        <div><Anchor fontWeight="bold" color="secondary" blank href={`/job/details/${appliedJob.job.jobid}`} text={appliedJob.job.title} /></div>
                        <CustomBR h={10} />
                        <div><Typography style={{ opacity: 0.8 }} color="secondary" variant="body2">
                            {`${appliedJob.job.description}\n${appliedJob.job.requirement || ''}`}
                        </Typography></div>
                        <CustomBR />
                        <BGContainer>
                            {appliedJob.employerState > employer_to_job_stateEnum.WATCHED ? <CenteredFlex><Typography color="secondary" variant="body1">
                                <b>תגובת המעסיק:</b> {renderEmployerResponse(appliedJob)}
                            </Typography></CenteredFlex> : <CenteredFlex>
                                <Typography color="secondary" variant="body1"><b>סטטוס:</b> {status}</Typography>
                            </CenteredFlex>}
                            <CustomBR />
                            <div>
                                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
                                    {steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel
                                                StepIconComponent={({ active, completed }) => (
                                                    (active || completed) ? <CheckCircle color="primary" /> : <CheckCircle color="disabled" />
                                                )}>
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            <CustomBR />
                            <div>
                                <Typography color="secondary" fontWeight="bold" display="inline"> פנית למשרה זו בתאריך:&nbsp;</Typography>
                                <DateFormat date={appliedJob.createdAt} showTime />
                            </div>
                            <CustomBR h={12} />
                            <div>
                                {appliedJob.hasCV && <>
                                    <Typography color="secondary" fontWeight="bold" display="inline">קורות החיים שהוגשו:&nbsp;</Typography>
                                    <Anchor
                                        blank
                                        href={`/user/cv/${appliedJob.job.jobid}`}
                                        text={`${appliedJob.userCV.originalName}`} /></>}
                            </div>
                        </BGContainer>
                    </ShadowBGContainer>
                </AppliedJobItemStyled><CustomBR /></div>;
            })}
            {totalAppliedJobs > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                gotoPage={onGoToPage}
                url={props.url}
                pages={Math.ceil(totalAppliedJobs / pageSize)}
                currentPage={currentPage}
            /></Flexbox><CustomBR /></>}
        </Container>
        <CustomBR />
    </Page>;
};

export default UserAppliedJobs;
