import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

export const deleteAllCache = () => apiAction({
    method: API_METHODS.DELETE,
    url: '/cache',
    backdrop: true,
});

export const deleteWhatsappGroups = () => apiAction({
    method: API_METHODS.DELETE,
    url: '/admin/clear-whatsapp-queue',
    backdrop: true,
});
