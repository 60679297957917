/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgAppStore = (props: SVGProps<SVGSVGElement>) => (
  <svg width={202.5} viewBox="0 0 202.5 60" {...props}>
    <path
      data-name="Path 383"
      d="M195.3 60H7.094A7.11 7.11 0 010 52.9V7.089A7.1 7.1 0 017.094 0H195.3a7.2 7.2 0 017.2 7.089V52.9a7.207 7.207 0 01-7.2 7.1z"
      fill="#a6a6a6"
    />
    <path
      data-name="Path 384"
      d="M201.045 52.902a5.745 5.745 0 01-5.751 5.745H7.093a5.752 5.752 0 01-5.758-5.745V7.087a5.76 5.76 0 015.758-5.752h188.2a5.751 5.751 0 015.752 5.752v45.815z"
    />
    <g data-name="Group 136">
      <g data-name="Group 135" fill="#fff">
        <path
          data-name="Path 385"
          d="M45.196 29.676a8.7 8.7 0 014.141-7.3 8.906 8.906 0 00-7.017-3.791c-2.951-.311-5.812 1.765-7.316 1.765-1.533 0-3.847-1.736-6.342-1.684a9.338 9.338 0 00-7.86 4.794c-3.4 5.884-.864 14.532 2.392 19.288 1.629 2.329 3.533 4.931 6.024 4.839 2.438-.1 3.348-1.554 6.289-1.554 2.915 0 3.77 1.554 6.311 1.5 2.616-.042 4.263-2.34 5.835-4.69a19.249 19.249 0 002.668-5.435 8.4 8.4 0 01-5.125-7.732z"
        />
        <path
          data-name="Path 386"
          d="M40.392 15.459a8.559 8.559 0 001.961-6.134 8.718 8.718 0 00-5.637 2.916 8.145 8.145 0 00-2.01 5.907 7.2 7.2 0 005.686-2.689z"
        />
      </g>
    </g>
    <g data-name="Group 137" fill="#fff">
      <path
        data-name="Path 387"
        d="M80.467 47.256h-3.406l-1.866-5.864h-6.49l-1.778 5.864h-3.312l6.426-19.958h3.969zm-5.835-8.324l-1.687-5.212q-.268-.8-1.007-3.761h-.06q-.295 1.273-.948 3.761l-1.658 5.212z"
      />
      <path
        data-name="Path 388"
        d="M96.994 39.882a8.18 8.18 0 01-1.984 5.8 5.8 5.8 0 01-4.413 1.9 4.413 4.413 0 01-4.086-2.044h-.06v7.582h-3.2V37.6q0-2.308-.118-4.738h2.813l.178 2.281h.06a5.69 5.69 0 019.106-.564 7.93 7.93 0 011.704 5.303zm-3.258.117a5.926 5.926 0 00-.948-3.465 3.274 3.274 0 00-2.784-1.422 3.326 3.326 0 00-2.146.785 3.639 3.639 0 00-1.259 2.058 4.187 4.187 0 00-.148.975v2.4a3.846 3.846 0 00.963 2.652 3.192 3.192 0 002.5 1.082 3.274 3.274 0 002.813-1.392 6.126 6.126 0 001.008-3.673z"
      />
      <path
        data-name="Path 389"
        d="M113.548 39.882a8.174 8.174 0 01-1.986 5.8 5.8 5.8 0 01-4.411 1.9 4.409 4.409 0 01-4.086-2.043h-.06v7.582h-3.2V37.6q0-2.308-.118-4.738h2.813l.178 2.281h.06a5.692 5.692 0 019.108-.564 7.953 7.953 0 011.702 5.303zm-3.258.117a5.926 5.926 0 00-.95-3.465 3.27 3.27 0 00-2.783-1.422 3.33 3.33 0 00-2.148.785 3.637 3.637 0 00-1.256 2.058 4.269 4.269 0 00-.148.975v2.4a3.857 3.857 0 00.96 2.652 3.2 3.2 0 002.5 1.082 3.274 3.274 0 002.813-1.392 6.126 6.126 0 001.012-3.673z"
      />
      <path
        data-name="Path 390"
        d="M132.059 41.657a5.32 5.32 0 01-1.773 4.143 7.858 7.858 0 01-5.437 1.747 9.518 9.518 0 01-5.174-1.243l.741-2.666a8.766 8.766 0 004.623 1.275 4.371 4.371 0 002.815-.816 2.665 2.665 0 001-2.181 2.78 2.78 0 00-.829-2.046 7.711 7.711 0 00-2.754-1.543q-5.243-1.955-5.241-5.724a5.065 5.065 0 011.838-4.033A7.244 7.244 0 01126.76 27a9.689 9.689 0 014.53.948l-.8 2.607a7.86 7.86 0 00-3.82-.918 3.867 3.867 0 00-2.634.83 2.37 2.37 0 00-.8 1.808 2.44 2.44 0 00.917 1.954 10.358 10.358 0 002.9 1.541 9.852 9.852 0 013.79 2.427 5.07 5.07 0 011.216 3.46z"
      />
      <path
        data-name="Path 391"
        d="M142.632 35.258h-3.525v6.988q0 2.666 1.866 2.666a5.687 5.687 0 001.42-.149l.089 2.429a7.246 7.246 0 01-2.487.354 3.837 3.837 0 01-2.962-1.155 5.674 5.674 0 01-1.066-3.881v-7.252h-2.1v-2.4h2.1v-2.635l3.141-.948v3.583h3.524v2.4z"
      />
      <path
        data-name="Path 392"
        d="M158.536 39.94a7.9 7.9 0 01-1.9 5.449 6.769 6.769 0 01-5.274 2.192 6.437 6.437 0 01-5.048-2.1 7.654 7.654 0 01-1.881-5.3 7.818 7.818 0 011.939-5.478 6.712 6.712 0 015.226-2.133 6.573 6.573 0 015.094 2.1 7.567 7.567 0 011.844 5.27zm-3.318.1a6.474 6.474 0 00-.858-3.416 3.159 3.159 0 00-2.871-1.722 3.207 3.207 0 00-2.933 1.722 6.6 6.6 0 00-.858 3.475 6.465 6.465 0 00.858 3.414 3.269 3.269 0 005.775-.03 6.5 6.5 0 00.887-3.44z"
      />
      <path
        data-name="Path 393"
        d="M168.931 35.674a5.553 5.553 0 00-1.008-.088 3.022 3.022 0 00-2.607 1.275 4.792 4.792 0 00-.8 2.843v7.551h-3.2l.03-9.861q0-2.489-.12-4.532h2.786l.117 2.754h.088a4.923 4.923 0 011.6-2.28 3.867 3.867 0 012.311-.771 5.134 5.134 0 01.8.058v3.049z"
      />
      <path
        data-name="Path 394"
        d="M183.234 39.378a7.5 7.5 0 01-.117 1.451h-9.594a4.176 4.176 0 001.392 3.26 4.752 4.752 0 003.138 1.006 10.611 10.611 0 003.882-.681l.5 2.22a12 12 0 01-4.826.89 6.99 6.99 0 01-5.259-1.97 7.274 7.274 0 01-1.91-5.286 8.246 8.246 0 011.781-5.422 6.129 6.129 0 015.033-2.308 5.364 5.364 0 014.711 2.308 7.785 7.785 0 011.269 4.532zm-3.049-.83a4.345 4.345 0 00-.621-2.458 2.794 2.794 0 00-2.55-1.334 3.034 3.034 0 00-2.545 1.3 4.772 4.772 0 00-.947 2.487h6.661z"
      />
    </g>
    <g data-name="Group 138" fill="#fff">
      <path data-name="Path 395" d="M135.189 14.193v1.38h-4.263v-1.38z" />
      <path
        data-name="Path 396"
        d="M137.948 14.368c.242-1.725-.449-2.4-1.535-2.656l.311-1.605a2.636 2.636 0 012.364 2.431 3.553 3.553 0 013.346-2.417c2.693 0 3.346 2.468 3.346 5.8v2.21c0 .156-.189 1.294-.24 1.536h-3.4c-.688 0-1.587.018-1.949.052l.292-1.536a16.358 16.358 0 012.123-.069h1.812c.018-.725.018-1.433.018-2.122 0-2.175-.277-4.3-2.3-4.3-1.914 0-2.587 1.9-2.846 3.072-.277 1.156-.639 3.245-.949 4.9h-1.342c.344-1.778.758-4.021.949-5.296z"
      />
      <path
        data-name="Path 397"
        d="M151.199 11.743l.292-1.536a19.329 19.329 0 002.4.069h5.073l-.311 1.571h-.654c-.519 0-.709.363-.709 1.6 0 2.07.088 4.521.138 6.213h-1.344c-.018-1.122-.12-4.021-.12-5.9a3.114 3.114 0 01.36-1.915h-3.208a10.21 10.21 0 01-1.917-.102z"
      />
      <path
        data-name="Path 398"
        d="M165.79 14.315c0-1.708-.19-2.469-1.813-2.469h-1.709a10.107 10.107 0 01-1.915-.1l.298-1.544a19.313 19.313 0 002.4.069h1.071c2.52 0 3 .6 3 3.555v5.833h-1.329z"
      />
      <path
        data-name="Path 399"
        d="M171.343 14.262c0-1.484-.173-2.019-1.518-2.348a10.252 10.252 0 01-1.174-.327l.294-1.519a6.39 6.39 0 001.191.276c1.934.414 2.555 1.053 2.555 3.676v5.643h-1.347z"
      />
      <path
        data-name="Path 400"
        d="M181.868 14.521c0-1.916-.173-2.674-1.794-2.674h-2.934a11.037 11.037 0 01-1.984-.1l.293-1.536a19.01 19.01 0 002.382.069h2.364c2.451 0 3 .708 3 3.537v5.85h-1.329v-5.146zm-6.368.207h1.344v4.937H175.5z"
      />
    </g>
  </svg>
);

SvgAppStore.defaultProps = {};
export default SvgAppStore;
/* eslint-enable */
