import 'regenerator-runtime/runtime';
import 'core-js';

/**
 * window.performance
 */
if (!window.perfomance || !window.perfomance.now) {
    let offset;
    if (!Date.now) Date.now = () => new this().getTime();

    (window.perfomance
      || (window.perfomance = {})).now = () => Date.now() - offset;

    offset = (window.perfomance.timing
      || (window.perfomance.timing = {})).navigatorStart
        || (window.perfomance.timing.navigatorStart = Date.now());
}

/**
 * TODO : Remove someday
 * fix for samsung internet 7
 * useragent : Mozilla/5.0 (Linux; Android 8.0.0; SAMSUNG SM-G965F Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko)
 * SamsungBrowser/7.0 Chrome/59.0.3071.125 Mobile Safari/537.36
 */
document.getElementsByClassName.ToString = document.getElementsByClassName && document.getElementsByClassName.toString;
