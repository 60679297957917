import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

export type DateFormatProps = {
    /**
     * e.g 2021-03-22T18:09:18.000Z
     */
    date: string
    showTime?: boolean
    color?: string
    fontWeight?: string
}

const DateFormat = ({
    date, showTime, fontWeight, color,
}: DateFormatProps): JSX.Element => <Typography color={color}
    display="inline" fontWeight={fontWeight || 'normal'}>
    {showTime
        ? dayjs(date).format('HH:mm DD/MM/YYYY')
        : dayjs(date).format('DD/MM/YYYY')}
</Typography>;

export default DateFormat;
