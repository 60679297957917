import GET_JOBS_TYPE_ENUM from '../../common/get_job_type.enum';
import { JobViewModel } from '../../types/jobs.types';
import { TagViewModel } from '../../types/tags.types';
import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

export type CreateNewJobPayload = {
    longitude?: number
    latitude?: number
    formatted_address: string
    description: string
    requirement?: string
    phone?: string
    email?: string
    employerJobid?: string
    cvMandatory?: boolean
    companyName?: string
    tags: number[]
    /**
     * in days
     */
    jobPeriod: number
    promotion?: number
}
export type CreateNewJobResponse = JobViewModel
export const createNewJob = (
    payload: CreateNewJobPayload, backdrop?: boolean,
) => apiAction<CreateNewJobResponse>({
    url: '/jobs',
    method: API_METHODS.POST,
    payload,
    backdrop,
});

export type EditJobPayload = {
    jobid: number
    longitude?: number
    latitude?: number
    formatted_address?: string
    description: string
    requirement?: string
    phone?: string
    email?: string
    employerJobid?: string
    cvMandatory?: boolean | number
    companyName?: string
    tags?: number[]
}
export type EditJobParams = {
    jobid: number
}
export type EditJobQuery = {
    safeid?: string
}
export type EditJobResponse = JobViewModel

export const editJob = (
    params: EditJobParams, query: EditJobQuery = {}, payload: EditJobPayload, backdrop?: boolean,
) => apiAction<CreateNewJobResponse>({
    url: `/jobs/${params.jobid}${query.safeid ? `?safeid=${query.safeid}` : ''}`,
    method: API_METHODS.PUT,
    payload,
    backdrop,
});

/**
 * @param {Object} param0
 * @param {string} param0.orderBy one of : title, created_at, updated_at, numOfApplies
 * @param {string} param0.direction DESC or ASC
 * @param {string} param0.anyFilter filter employer jobs by text
 * @param {number} [param0.start] for pagination
 * @param {Object} options
 * @param {boolean} [options.global]
 * @param {boolean} [options.showGlobalError]
 */

type GetEmployerJobsQuery = {
    orderby: string
    direction: string
    any: string
    start: number
    type?: string
}
type GetEmployerJobsResponse = {
    rows: (JobViewModel & { appliedUsers: number; start?: string; end?: string; })[],
    count: number
}
export const getEmployerJobs = async (
    query: GetEmployerJobsQuery, backdrop?: boolean,
) => apiAction<GetEmployerJobsResponse>({
    url: '/jobs',
    query: {
        type: GET_JOBS_TYPE_ENUM.USER_POSTED,
        ...query,
    },
    backdrop,
    method: API_METHODS.GET,
});

export type DeleteJobParams = { jobid: number }
export const deleteJob = (params: DeleteJobParams, backdrop?: boolean) => apiAction<boolean>({
    url: `/jobs/${params.jobid}`,
    backdrop,
    method: API_METHODS.DELETE,
});

export type GetTagsQuery = {
    text: string
}
export const getTags = (query: GetTagsQuery) => apiAction<TagViewModel[]>({
    url: '/tags',
    query,
    method: API_METHODS.GET,
    backdrop: false,
});

/**
 * @param {number} jobId
 */
export const getJob = (params: { jobId: number }) => apiAction<JobViewModel>({
    url: `/jobs/${params.jobId}`,
    backdrop: true,
    method: API_METHODS.GET,
});

/**
 * @param {number} jobid
 * @param {Object} param1
 * @param {Object} param1.data
 * @param {string} param1.data.startDate format: yyyymmdd
 * @param {string} param1.data.endDate format: yyyymmdd
 */
export const getJobStats = (params: { jobid: number }, query: { startDate: string, endDate: string }) => apiAction<boolean>({
    url: `/jobs-stats/${params.jobid}`,
    query,
    backdrop: false,
    method: API_METHODS.GET,
});

// async (jobid, { global = false, showGlobalError = true, data } = {}) => ApiService.get(
//     `/jobs-stats/${jobid}`,
//     { global, showGlobalError, data },
// );
