import {
    Container, Typography,
} from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import AppRoot from '../layout/AppRoot';

export type ThankYouProps = {
    url: string
    user?: LoggedInUser
}

const ThankYou = (props: ThankYouProps) => {
    React.useEffect(() => {
        window.gtag('event', 'conversion', {
            send_to: 'AW-863359041/zJk-CMz22skYEMGg15sD',
            transaction_id: '',
        });
    }, []);
    return <AppRoot user={props.user}>
        <Container>
            <CustomBR />
            <BGContainer>
                <CustomBR />
                <CustomBR />
                <WhiteBGContainer>
                    <Typography color="secondary" fontWeight="bold" variant="h4">תודה! הרכישה הושלמה בהצלחה.</Typography>
                    <CustomBR />
                    <Typography>אנו שמחים שבחרת ב Mploy כחלק מתהליך הגיוס הבא שלך, הצלחה שלך היא הצלחה שלנו.</Typography>
                    <Typography>ניתן כעת לגשת לפאנל ניהול המעסיקים ולפרסם משרות חדשות.</Typography>
                    <CustomBR />
                    <Anchor href="/user/postedJobs" text="לחץ כאן למעבר לפרסום משרות" />
                    <CustomBR />
                    <Anchor href="/home/contactus" text="אנחנו לשירותך - צור קשר" />
                </WhiteBGContainer>
                <CustomBR />
                <CustomBR />
            </BGContainer>
            <CustomBR />
        </Container>
    </AppRoot>;
};
export default ThankYou;
