/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgAviationAndNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.83} viewBox="0 0 29.83 29.83" {...props}>
    <g data-name="Aviation and Navigation">
      <g data-name="Group 187">
        <path
          data-name="Path 596"
          d="M7.21 12.659v-.9a.683.683 0 00-.682-.684h-.9a.683.683 0 00-.682.684v1.9z"
          fill="#fff"
        />
        <path
          data-name="Path 597"
          d="M22.619 12.659v-.9a.683.683 0 01.682-.684h.9a.683.683 0 01.682.684v1.9z"
          fill="#fff"
        />
        <path
          data-name="Path 598"
          d="M20.906 27.575l-4.264-2.252.805-19.12A7.805 7.805 0 0015.538.751a.828.828 0 00-1.25 0 7.806 7.806 0 00-1.908 5.451l.805 19.121-4.264 2.252a1.269 1.269 0 00-.676 1.122v.317a.35.35 0 00.42.343l6.247-1.286 6.247 1.286a.35.35 0 00.42-.343v-.317a1.269 1.269 0 00-.673-1.122z"
          fill="#f2eff2"
        />
        <g data-name="Group 186">
          <path
            data-name="Path 599"
            d="M21.161 28.308l-6.188-1.273a.291.291 0 00-.117 0l-6.188 1.273a.348.348 0 01-.322-.1 1.269 1.269 0 00-.1.489v.317a.35.35 0 00.42.343l6.247-1.285 6.247 1.285a.35.35 0 00.42-.343v-.317a1.269 1.269 0 00-.1-.489.348.348 0 01-.319.1z"
            fill="#dee7ed"
          />
        </g>
        <path
          data-name="Path 600"
          d="M28.326 15.199l-11.05-4.916-.223 5.294 11.864 1.614a.394.394 0 00.447-.391 1.75 1.75 0 00-1.038-1.601z"
          fill="#12b05e"
        />
        <path
          data-name="Path 601"
          d="M24.885 12.619l-2.249-1a.685.685 0 00-.016.146v.9l2.265 1.008z"
          fill="#dee7ed"
        />
        <path
          data-name="Path 602"
          d="M28.917 16.142l-11.82-1.608-.046 1.042 11.864 1.614a.394.394 0 00.447-.391 1.752 1.752 0 00-.16-.731.391.391 0 01-.285.074z"
          fill="#009e4c"
        />
        <path
          data-name="Path 603"
          d="M12.553 10.283l-11.05 4.916a1.75 1.75 0 00-1.037 1.6.394.394 0 00.447.391l11.864-1.614z"
          fill="#12b05e"
        />
        <path
          data-name="Path 604"
          d="M7.21 11.764a.689.689 0 00-.016-.146l-2.249 1v1.049l2.265-1.008z"
          fill="#dee7ed"
        />
        <path
          data-name="Path 605"
          d="M.913 16.142a.39.39 0 01-.287-.074 1.752 1.752 0 00-.16.732.394.394 0 00.447.391l11.863-1.615-.044-1.043z"
          fill="#009e4c"
        />
      </g>
      <g data-name="Group 188">
        <path
          data-name="Path 606"
          d="M28.53 14.801l-3.189-1.42v-1.623a1.123 1.123 0 00-1.121-1.123h-.9a1.123 1.123 0 00-1.12 1.123v.223l-4.469-1.986.16-3.79a8.225 8.225 0 00-2.02-5.769 1.267 1.267 0 00-1.91 0 8.225 8.225 0 00-2.022 5.765l.16 3.791-4.466 1.989v-.223a1.123 1.123 0 00-1.121-1.123h-.9a1.123 1.123 0 00-1.121 1.123v1.623L1.3 14.801a2.193 2.193 0 00-1.3 2 .832.832 0 00.944.826l3.685-.5a.438.438 0 10-.118-.868l-3.634.494a1.317 1.317 0 01.778-1.152l10.483-4.664.18 4.262-6.07.826a.438.438 0 00.058.872.449.449 0 00.06 0l5.989-.815.379 9.009-4.027 2.121a1.7 1.7 0 00-.91 1.512v.317a.788.788 0 00.947.772l6.171-1.27 1.378.283a.438.438 0 00.176-.858l-1.469-.3a.437.437 0 00-.176 0l-6.152 1.266v-.21a.832.832 0 01.444-.737l4.272-2.256a.438.438 0 00.233-.406l-.807-19.159a7.353 7.353 0 011.8-5.153.391.391 0 01.592 0 7.353 7.353 0 011.805 5.157l-.807 19.157a.438.438 0 00.233.406l4.272 2.256a.831.831 0 01.444.737v.21l-2.972-.612a.438.438 0 10-.176.858l3.077.633a.788.788 0 00.947-.772v-.317a1.7 1.7 0 00-.91-1.512L17.1 25.085l.379-9.009 2.344.319a.438.438 0 10.118-.868l-2.425-.33.18-4.262 10.479 4.666a1.317 1.317 0 01.779 1.154l-7.28-.99a.438.438 0 00-.118.868l7.331 1a.833.833 0 00.944-.826 2.194 2.194 0 00-1.3-2zM5.364 11.758a.247.247 0 01.245-.247h.9a.247.247 0 01.245.247v.613l-1.394.62v-1.233zm17.708 0a.247.247 0 01.245-.247h.9a.247.247 0 01.245.247v1.233l-1.394-.62v-.613z"
        />
      </g>
    </g>
  </svg>
);

SvgAviationAndNavigation.defaultProps = {};
export default SvgAviationAndNavigation;
/* eslint-enable */
