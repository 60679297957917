import { Container, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Anchor from '../../foundation/Anchor';
import { safeStringify } from '../../utils/json.utils';

const baseURl = 'https://www.mploy.co.il';

export type Breadcrumb = {
    url: string,
    name?: string,
    text: string
}
export type BreadcrumbsProps = {
    crumbs: Breadcrumb[]
    sideChildrens?: JSX.Element
}

const BreadcrumbsStyled = styled.div`
    background: #f9f9fB;
    padding: 1rem;
    > div {
        display: flex;
        > div:last-child {
            margin-inline-start: auto;
        }
    }
    ${props => props.theme.breakpoints.down('sm')} {  
        padding: 1rem 0;
    }
    > .MuiContainer-root {
        flex-wrap: wrap;
    }
`;
export default class Breadcrumbs extends React.Component<BreadcrumbsProps> {
    getBreadCrumbsSchema(crumbs) {
        const scheme = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: crumbs.map((c, i) => ({
                '@type': 'ListItem',
                position: i + 1,
                name: c.name || c.text,
                item: c.url !== undefined ? baseURl + c.url : undefined,
            })),
        };

        return safeStringify(scheme);
    }

    render() {
        const crumbs = [...this.props.crumbs];
        crumbs.unshift({
            name: 'דרושים',
            url: '',
        });
        return crumbs ? <BreadcrumbsStyled>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: this.getBreadCrumbsSchema(crumbs) }} />
            <Container>
                <div>
                    <Anchor href={baseURl} text="דרושים" />
                </div>
                {crumbs.map((crumb, i) => (
                    <div key={i}>
                        {crumb.url && (i !== crumbs.length - 1) ? <Anchor href={crumb.url} text={crumb.text} />
                            : <Typography color="secondary">{crumb.text}</Typography>}
                        {i !== crumbs.length - 1 ? <span>&nbsp;\&nbsp;</span> : ''}
                    </div>
                ))}
                <div>
                    {this.props.sideChildrens}
                </div>
            </Container>
        </BreadcrumbsStyled> : '';
    }
}
