/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgTrials = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.6} viewBox="0 0 27.6 27.842" {...props}>
    <path
      data-name="Path 919"
      d="M26.489 26.732a2.376 2.376 0 01-3.451-.1l-9.375-10.473-.1-2.512 2.361.254 10.473 9.374a2.377 2.377 0 01.1 3.452zm0 0"
      fill="#ba7e56"
    />
    <path
      data-name="Path 920"
      d="M26.49 26.732a2.377 2.377 0 01-3.451-.1L13.664 16.16l-.1-2.354.838 1.613 9.735 10.113c.945.982 2.018 1.529 2.349 1.2zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 921"
      d="M13.565 13.732v.078l-.056-.108zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 922"
      d="M2.419 15.299L15.298 2.42l6.055 6.055L8.474 21.353zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 923"
      d="M5.049 12.67l7.621-7.621 6.055 6.055-7.621 7.621zm0 0"
      fill="#12b05e"
    />
    <path
      data-name="Path 924"
      d="M10.095 15.914l5.818-5.818 1.91 1.91-5.818 5.818zm0 0"
      fill="#03984b"
    />
    <path
      data-name="Path 925"
      d="M12.005 19.626a1.274 1.274 0 01-1.8 0l-6.19-6.19a1.275 1.275 0 011.8-1.8l6.19 6.19a1.275 1.275 0 010 1.8zm0 0"
      fill="#ba7e56"
    />
    <path
      data-name="Path 926"
      d="M8.095 17.519l2.107 2.107a1.274 1.274 0 101.8-1.8l-2.107-2.107a1.274 1.274 0 11-1.8 1.8zm0 0"
      fill="#996441"
    />
    <g data-name="Group 303" fill="#ba7e56">
      <path
        data-name="Path 927"
        d="M19.625 12.007a1.275 1.275 0 000-1.8l-6.19-6.19a1.275 1.275 0 00-1.8 1.8l6.19 6.19a1.275 1.275 0 001.8 0zm0 0"
      />
      <path
        data-name="Path 928"
        d="M9.145 22.485l-.311.311a1.466 1.466 0 01-2.073 0l-5.92-5.92a1.466 1.466 0 010-2.073l.311-.311a1.466 1.466 0 012.073 0l5.92 5.92a1.466 1.466 0 010 2.073zm0 0"
      />
      <path
        data-name="Path 929"
        d="M22.485 9.145l.311-.311a1.466 1.466 0 000-2.073L16.877.841a1.466 1.466 0 00-2.073 0l-.311.311a1.466 1.466 0 000 2.073l5.92 5.92a1.466 1.466 0 002.072 0zm0 0"
      />
    </g>
    <path
      data-name="Path 930"
      d="M17.518 8.096l2.107 2.107a1.274 1.274 0 11-1.8 1.8l-2.107-2.107a1.274 1.274 0 101.8-1.8zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 931"
      d="M20.691 4.655l2.107 2.107a1.466 1.466 0 010 2.073l-.311.311a1.466 1.466 0 01-2.073 0l-2.107-2.107a1.466 1.466 0 002.073 0l.311-.311a1.466 1.466 0 000-2.073zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 932"
      d="M4.655 20.689l2.107 2.107a1.466 1.466 0 002.073 0l.311-.311a1.466 1.466 0 000-2.073l-2.107-2.107a1.466 1.466 0 010 2.073l-.311.311a1.466 1.466 0 01-2.073 0zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 933"
      d="M14.769 19.411a1.274 1.274 0 010-1.8l2.6-2.6a1.275 1.275 0 111.8 1.8l-2.6 2.6a1.274 1.274 0 01-1.8 0zm0 0"
      fill="#ba7e56"
    />
    <path
      data-name="Path 934"
      d="M17.844 18.14l-1.272 1.272a1.274 1.274 0 01-1.8-1.8l1.272-1.272a1.274 1.274 0 101.8 1.8zm0 0"
      fill="#996441"
    />
    <path
      data-name="Path 935"
      d="M27.602 24.975a2.766 2.766 0 00-.928-2l-1.207-1.08a.413.413 0 10-.551.616l1.207 1.08a1.963 1.963 0 11-2.772 2.772l-6.206-6.934 2.045-2.045 4.5 4.032a.413.413 0 00.551-.616l-4.512-4.039a1.688 1.688 0 00-2.463-2.2l-.743-.665 1.336-1.336a1.688 1.688 0 002.314-2.315l.444-.444a1.882 1.882 0 002.159-.356l.311-.311a1.881 1.881 0 000-2.658L17.168.55a1.879 1.879 0 00-2.658 0l-.308.311a1.882 1.882 0 00-.259 2.333l-.38.38a1.688 1.688 0 00-2.369 2.366l-5.255 5.255a1.688 1.688 0 00-2.366 2.366l-.38.38a1.88 1.88 0 00-2.333.26l-.311.311a1.882 1.882 0 000 2.658l.446.446a.414.414 0 00.585-.585l-.446-.446a1.054 1.054 0 010-1.488l.311-.311a1.053 1.053 0 011.488 0l5.92 5.92a1.053 1.053 0 010 1.487l-.311.307a1.052 1.052 0 01-1.488 0l-4.3-4.3a.414.414 0 00-.585.585l4.3 4.3a1.879 1.879 0 002.658 0l.311-.311a1.882 1.882 0 00.356-2.159l.444-.444a1.689 1.689 0 002.315-2.315l1.095-1.095.665.743a1.689 1.689 0 002.205 2.463l6.213 6.941a2.763 2.763 0 002 .928h.08a2.791 2.791 0 002.791-2.861zM6.535 11.769l5.233-5.233 5.47 5.47-5.236 5.233zm7.621-7.621l.337-.337 5.47 5.47-.337.337zm.629-2.7l.311-.311a1.053 1.053 0 011.488 0l5.918 5.917a1.054 1.054 0 010 1.488l-.311.311a1.054 1.054 0 01-1.488 0l-5.92-5.92a1.053 1.053 0 010-1.488zm-2.86 2.857a.863.863 0 011.218 0l.132.132 6.048 6.048a.861.861 0 01-1.219 1.217L12.062 5.66l-.134-.133a.863.863 0 010-1.218zM3.811 14.493l.337-.337 5.47 5.47-.337.337zm7.9 4.841a.862.862 0 01-1.218 0l-6.191-6.19a.862.862 0 011.22-1.218l6.19 6.19a.863.863 0 010 1.218zm4.225-4.859l.729.652-1.78 1.781-.652-.728zm-.876 4.644a.861.861 0 010-1.218l2.6-2.6a.861.861 0 111.218 1.218l-2.6 2.6a.861.861 0 01-1.218 0zm0 0"
    />
  </svg>
);

SvgTrials.defaultProps = {};
export default SvgTrials;
/* eslint-enable */
