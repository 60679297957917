/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={37.125} viewBox="0 0 37.125 37.295" {...props}>
    <path
      d="M15.253 18.735L1.187 4.667A2.342 2.342 0 014.5 1.357l14.063 14.067L32.629 1.357a2.34 2.34 0 013.309 3.309L21.872 18.735 35.938 32.8a2.34 2.34 0 11-3.309 3.309L18.563 22.043 4.5 36.109A2.341 2.341 0 011.187 32.8zm0 0"
      fill="#333"
      stroke="#fff"
      strokeWidth={1}
      opacity={0.3}
    />
  </svg>
);

SvgClose.defaultProps = {};
export default SvgClose;
/* eslint-enable */
