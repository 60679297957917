// import PlotDates from '../infra/plotDates';
// import Anchor from '../infra/Anchor';
// import DateRangePicker from '../infra/dateRangePicker';
// import Page from '../layout/page';
import dayjs from 'dayjs';
import { Component } from 'react';
import { Container } from '@mui/material';
import { getJobStats } from '../../api/job.api';
import { JobViewModel } from '../../../types/jobs.types';
import Page from '../layout/base.page';
import { LoggedInUser } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import DateRangePicker from '../components/DateRangePicker';
import PlotDates from '../components/PlotDates';
import CustomBR from '../foundation/CustomBR';

type JobStatsProps = {
    job: JobViewModel
    url: string
    user?: LoggedInUser
}

type JobStatsState = {
    views: {
        x: any[],
        y: any[],
        startDate: Date,
        endDate: Date
    }
    applications: any
    showPhoneClicks: any
    showPlots?: boolean
}

export default class JobStats extends Component<JobStatsProps, JobStatsState> {
    constructor(props) {
        super(props);
        const endDate = new Date();
        const startDate = dayjs(endDate).add(-30, 'day').toDate();

        this.state = {
            views: {
                x: [],
                y: [],
                startDate,
                endDate,
            },
            applications: {
                x: [],
                y: [],
                startDate,
                endDate,
            },
            showPhoneClicks: {
                x: [],
                y: [],
                startDate,
                endDate,
            },
        };
    }

    async fetchJobStats(startDate, endDate) {
        const { job } = this.props;

        const query = {
            startDate: dayjs(startDate).format('YYYYMMDD'),
            endDate: dayjs(endDate).format('YYYYMMDD'),
        };
        return getJobStats({ jobid: job.jobid }, query);
    }

    setStatsData(data, callback) {
        const { views, applications, showPhoneClicks } = this.state;
        if (!data || !data.hits || data.hits.length === 0) {
            callback();
            return;
        }

        for (let i = 0; i < data.hits.length; i += 1) {
            const hit = data.hits[i]._source;
            const {
                date,
                applications: applicationsCount = 0,
                showPhoneClicks: showPhoneClicksCount = 0,
                views: viewsCount = 0,
            } = hit;
            const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
            views.x.push(formattedDate);
            views.y.push(viewsCount);

            applications.x.push(formattedDate);
            applications.y.push(applicationsCount);

            showPhoneClicks.x.push(formattedDate);
            showPhoneClicks.y.push(showPhoneClicksCount);
        }

        this.setState({ views, applications, showPhoneClicks }, callback);
    }

    async componentDidMount() {
        const { startDate, endDate } = this.state.views;
        this.fetchAndRefreshStats(startDate, endDate);
    }

    async fetchAndRefreshStats(startDate, endDate) {
        this.setState({ showPlots: false });
        const statsRes = await this.fetchJobStats(startDate, endDate);
        this.setStatsData(statsRes.data, () => { this.setState({ showPlots: true }); });
    }

    handleStartDateChange = async start => {
        await this.fetchAndRefreshStats(start, this.state.views.endDate);
        this.setState({
            views: {
                ...this.state.views,
                startDate: start,
            },
            applications: {
                ...this.state.applications,
                startDate: start,
            },
            showPhoneClicks: {
                ...this.state.showPhoneClicks,
                startDate: start,
            },
        });
    }

    handleEndDateChange = async end => {
        await this.fetchAndRefreshStats(this.state.views.startDate, end);
        this.setState({
            views: {
                ...this.state.views,
                endDate: end,
            },
            applications: {
                ...this.state.applications,
                endDate: end,
            },
            showPhoneClicks: {
                ...this.state.showPhoneClicks,
                endDate: end,
            },
        });
    }

    getBreadCrumbs = () => {
        const crumbs = [];

        crumbs.push({
            text: 'ניהול המשרות שלי',
            url: '/user/postedJobs',
        });
        crumbs.push({
            text: `סטטיסטיקות למשרה: ${this.props.job.title}`,
            url: '',
        });

        return crumbs;
    }

    render() {
        const { job } = this.props;
        const {
            showPlots, views, applications, showPhoneClicks,
        } = this.state;
        return (
            <Page
                url={this.props.url}
                user={this.props.user}
                breadCrumbs={this.getBreadCrumbs()}
                pageHeaderTitle={`Mploy - סטטיסטיקות למשרה: ${job.title}`}>
                <Container>
                    <div className="row">
                        <div className="col-md-3"><h3>סטטיסטיקות לתקופה</h3></div>
                        <div className="col-md-6">
                            <DateRangePicker
                                onFromChange={this.handleStartDateChange}
                                onToChange={this.handleEndDateChange}
                                from={views.startDate}
                                to={views.endDate}
                            />
                        </div>
                    </div>
                    <CustomBR />
                    <hr />
                    <CustomBR />
                    {showPlots
                        ? <div>
                            <div>
                                <h3>מספר צפיות במשרה</h3>
                                <PlotDates
                                    startDate={views.startDate}
                                    endDate={views.endDate}
                                    scatter={{ x: views.x, y: views.y }}
                                />
                            </div>
                            <div>
                                <h3>מספר מועמדויות שהוגשו</h3>
                                <PlotDates
                                    startDate={applications.startDate}
                                    endDate={applications.endDate}
                                    scatter={{ x: applications.x, y: applications.y }}
                                />
                            </div>
                            <div>
                                <h3>מספר הצגות מספר טלפון</h3>
                                <PlotDates
                                    startDate={showPhoneClicks.startDate}
                                    endDate={showPhoneClicks.endDate}
                                    scatter={{ x: showPhoneClicks.x, y: showPhoneClicks.y }}
                                />
                            </div>
                        </div>
                        : <div className="spinner">
                            <br />
                            <i className="fa fa-refresh fa-spin" />
                            <span className="big-text">טוען דוחות...</span>
                        </div>
                    }
                    <CustomBR />
                </Container>
            </Page>
        );
    }
}
