/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgSeniours = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23.198} viewBox="0 0 23.198 37.391" {...props}>
    <g data-name="Group 378">
      <path
        data-name="Path 1144"
        d="M20.716 13.87C22.308 2.195 16.04.584 11.598.584S.888 2.195 2.48 13.87z"
        fill="#dfe5ec"
      />
      <path
        data-name="Path 1145"
        d="M4.458 11.433a2.3 2.3 0 00-2.1 1.368q.052.52.127 1.069H4.7v-2.425a2.323 2.323 0 00-.242-.012z"
        fill="#a0aab7"
      />
      <path
        data-name="Path 1146"
        d="M20.716 13.87q.075-.549.127-1.069a2.3 2.3 0 00-2.1-1.368 2.327 2.327 0 00-.238.012v2.425z"
        fill="#a0aab7"
      />
      <path
        data-name="Path 1147"
        d="M22.627 36.809H.57v-5.873a8.5 8.5 0 018.5-8.5h5.047a8.5 8.5 0 018.5 8.5z"
        fill="#12b05e"
      />
      <path
        data-name="Path 1148"
        d="M.57 35.492h22.057v1.315H.57z"
        fill="#088243"
      />
      <path
        data-name="Path 1149"
        d="M11.598 22.428a6.9 6.9 0 006.9-6.9v-4.051a3.869 3.869 0 00-1.865-3.289 3.612 3.612 0 01-1.244-1.6 4.261 4.261 0 00-7.588 0 3.611 3.611 0 01-1.244 1.6 3.87 3.87 0 00-1.865 3.289v4.049a6.9 6.9 0 006.9 6.9z"
        fill="#fed2a4"
      />
      <g data-name="Group 377" fill="#e6ba94">
        <path
          data-name="Path 1150"
          d="M11.599 21.111a6.9 6.9 0 01-6.9-6.9v1.315a6.902 6.902 0 1013.805 0v-1.315a6.9 6.9 0 01-6.905 6.9z"
        />
        <path data-name="Path 1151" d="M11.598 22.428z" />
      </g>
      <path
        data-name="Path 1152"
        d="M4.696 15.526v-2.767a2.327 2.327 0 00-.238-.012 2.3 2.3 0 10.469 4.555 6.912 6.912 0 01-.231-1.776z"
        fill="#ffbd86"
      />
      <path
        data-name="Path 1153"
        d="M18.739 12.747a2.33 2.33 0 00-.238.012v2.766a6.914 6.914 0 01-.231 1.777 2.3 2.3 0 10.469-4.555z"
        fill="#ffbd86"
      />
      <path
        data-name="Path 1154"
        d="M4.453 16.035a2.3 2.3 0 01-2.206-1.644 2.3 2.3 0 002.675 2.911 6.9 6.9 0 01-.213-1.281 2.3 2.3 0 01-.256.014z"
        fill="#d7a379"
      />
      <path
        data-name="Path 1155"
        d="M18.739 16.035a2.314 2.314 0 01-.256-.015 6.9 6.9 0 01-.213 1.281 2.3 2.3 0 002.675-2.911 2.3 2.3 0 01-2.206 1.645z"
        fill="#d7a379"
      />
      <path
        data-name="Path 1156"
        d="M11.597 17.399c.886 1.7 3.142 1.013 3.142 1.013s.31-2.983-3.142-2.983-3.142 2.983-3.142 2.983 2.257.686 3.142-1.013z"
        fill="#dfe5ec"
      />
      <path
        data-name="Path 1157"
        d="M6.175 22.932l2.274 5.53 3.149-2-2.828-4.03a8.472 8.472 0 00-2.595.5z"
        fill="#fcf5f5"
      />
      <path
        data-name="Path 1158"
        d="M14.426 22.433l-2.825 4.032 3.149 2 2.274-5.53a8.477 8.477 0 00-2.598-.502z"
        fill="#fcf5f5"
      />
      <path
        data-name="Path 1159"
        d="M11.598 26.465l-2.832-4.036h5.664z"
        fill="#ffbd86"
      />
    </g>
    <g data-name="Group 379">
      <path
        data-name="Path 1160"
        d="M22.649 33.658a.549.549 0 00-.549.549v2.087h-3.268v-4.282a.55.55 0 00-1.1 0v4.282H5.463v-4.282a.55.55 0 00-1.1 0v4.282H1.098v-5.336a7.9 7.9 0 01.783-3.45.549.549 0 10-.989-.475 8.988 8.988 0 00-.891 3.925v5.885a.549.549 0 00.549.549h22.1a.549.549 0 00.549-.549v-2.636a.549.549 0 00-.55-.549z"
      />
      <path
        data-name="Path 1161"
        d="M15.354 21.97a7.5 7.5 0 003.324-4.075h.078a2.854 2.854 0 002.547-4.142c.642-4.917-.049-8.577-2.057-10.878C17.578.968 15.006 0 11.599 0 8.616 0 6.356.7 4.688 2.145a.549.549 0 10.718.83A9.1 9.1 0 0111.599 1.1c3.12 0 5.35.818 6.819 2.5 1.666 1.908 2.3 4.954 1.9 9.06a2.837 2.837 0 00-1.259-.454v-.741A4.4 4.4 0 0016.934 7.7a3.1 3.1 0 01-1.04-1.372 4.818 4.818 0 00-8.591 0A3.1 3.1 0 016.263 7.7a4.4 4.4 0 00-2.13 3.762v.738a2.836 2.836 0 00-1.259.455c-.341-3.5.074-6.238 1.236-8.145a.549.549 0 10-.937-.571c-1.4 2.3-1.829 5.6-1.278 9.812A2.854 2.854 0 004.444 17.9h.077a7.5 7.5 0 003.324 4.075 9.063 9.063 0 00-5.67 3.095.549.549 0 10.834.713 7.977 7.977 0 012.856-2.121l2.071 5.036a.552.552 0 00.8.255l2.312-1.47v3.5a.55.55 0 001.1 0v-3.5l2.312 1.47a.552.552 0 00.8-.255l2.07-5.036a7.984 7.984 0 014.768 7.3v1.054a.55.55 0 101.1 0v-1.058a9.084 9.084 0 00-7.844-8.988zm3.712-6.451v-2.207a1.757 1.757 0 01-.108 3.475 7.487 7.487 0 00.105-1.267zm-16.377-.478a1.76 1.76 0 011.447-1.729v2.208a7.482 7.482 0 00.108 1.267 1.76 1.76 0 01-1.557-1.745zm2.545.478v-4.056a3.312 3.312 0 011.607-2.827 4.072 4.072 0 001.453-1.83 3.721 3.721 0 016.614 0 4.072 4.072 0 001.453 1.83 3.312 3.312 0 011.607 2.827v4.057a6.367 6.367 0 11-12.735 0zm3.47 12.151l-1.8-4.385a7.967 7.967 0 011.592-.28l2.328 3.318zm2.9-2.146l-1.782-2.539h3.564zm2.9 2.146l-2.118-1.346 2.329-3.319a7.925 7.925 0 011.592.279z"
      />
      <path
        data-name="Path 1162"
        d="M8.797 14.286v-1.55a.55.55 0 10-1.1 0v1.55a.55.55 0 001.1 0z"
      />
      <path
        data-name="Path 1163"
        d="M14.95 14.835a.549.549 0 00.549-.549v-1.55a.55.55 0 00-1.1 0v1.55a.549.549 0 00.551.549z"
      />
      <path
        data-name="Path 1164"
        d="M14.906 18.937a.549.549 0 00.386-.468 3.506 3.506 0 00-.806-2.508 4.382 4.382 0 00-5.776 0 3.506 3.506 0 00-.806 2.508.549.549 0 00.386.468 3.478 3.478 0 003.308-.623 3.019 3.019 0 002.151.786 4.1 4.1 0 001.157-.163zm-2.813-1.777c-.008-.016-.016-.032-.026-.048a.549.549 0 00-.469-.264.549.549 0 00-.469.264.539.539 0 00-.026.048 1.939 1.939 0 01-2.1.809 2.229 2.229 0 01.527-1.281 3.337 3.337 0 014.133 0 2.23 2.23 0 01.527 1.28 1.939 1.939 0 01-2.097-.808z"
      />
      <path
        data-name="Path 1165"
        d="M9.528 10.526h4.142a.55.55 0 100-1.1H9.528a.55.55 0 100 1.1z"
      />
      <path
        data-name="Path 1166"
        d="M10.446 8.436h2.313a.55.55 0 100-1.1h-2.313a.55.55 0 100 1.1z"
      />
    </g>
  </svg>
);

SvgSeniours.defaultProps = {};
export default SvgSeniours;
/* eslint-enable */
