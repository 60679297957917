import Promotion from '../../dal/entities/promotion';
import { UserProfileViewModel } from '../../types/auth.types';
import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

/**
 *
 * @param query user can be userid, email or username
 * @returns
 */

export type FullUserDetails = UserProfileViewModel & {
    promotions: Promotion[],
}

export const getUserFullDetails = (query: {user: string}) => apiAction<FullUserDetails[]>({
    method: API_METHODS.GET,
    url: '/admin/users-details',
    query,
    backdrop: true,
});

export const clearUsersToJobEmail = (payload: {jobId: number}) => apiAction<boolean>({
    method: API_METHODS.POST,
    url: '/admin/clear-users-to-job-emails',
    backdrop: true,
    payload,
});
