import { Typography } from '@mui/material';
import styled from 'styled-components';

export const BGContainer = styled.div<{bgColor?: string}>`
    background: ${({ bgColor }) => bgColor || '#f9f9fb'};
    padding: 2rem;
    border-radius: 10px;
`;

export const WhiteBGContainer = styled.div`
    background: white;
    padding: 2rem 1rem;
    border-radius: 10px;
`;

export const YellowBox = styled.span`
    background: ${props => props.theme.palette.common.yellowText}32;
    color: ${props => props.theme.palette.common.yellowText};
    padding: 0.5rem 1rem;
    border-radius: 10px;
`;

export const YellowTypography = styled(Typography)`
    font-weight: bold;
    background: ${props => props.theme.palette.common.yellowText};
    color: ${props => props.theme.palette.secondary.main};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

export const GreenTypography = styled(Typography)`
    font-weight: bold;
    background: ${props => props.theme.palette.primary.main};
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

export const SecondaryBox = styled.span`
    background: ${props => props.theme.palette.secondary.main}1c;
    color: ${props => props.theme.palette.secondary.main};
    padding: 0.5rem 1rem;
    border-radius: 10px;
`;

export const ShadowBGContainer = styled.div`
    background: white;
    box-shadow: 0 10px 36px 0 #eeeff1cc;
    padding: 2rem 1rem;
    border-radius: 10px;
`;
