import React, { useState } from 'react';
import styled from 'styled-components';
import { getParameterByName } from '../../utils/urlUtils';
import Button from '../foundation/Button';
import { LoginPopup } from './LoginPopup';

export type FacebookLoginProps = {
    smartAgentByActivity?: boolean
}

const FacebookLoginStyled = styled.div`
    .MuiButtonBase-root {
        background-color: #3b5998;   
    }        
`;

const FacebookLogin = (props: FacebookLoginProps): JSX.Element => {
    const [popup, setPopup] = useState<boolean>(false);
    const onLoginClick = () => { setPopup(true); };
    const onUserClosePopup = () => { setPopup(false); };
    const checkWhetherToClosePopup = (popupWin: Window) => {
        try {
            const url = popupWin.location && popupWin.location.href;
            const indexOfCode = url && url.indexOf('code=');
            if (indexOfCode > -1) {
                const token = url.substring(indexOfCode + 5);
                if (token) {
                    return true;
                }
            }

            return false;
        } catch (error) {
            return false;
        }
    };

    const onLogin = () => {
        const redirectUrl = getParameterByName('redirect');
        if (redirectUrl && redirectUrl !== '') {
            window.location.assign(redirectUrl);
        } else {
            window.location.reload();
        }
    };

    return <>
        {popup && <LoginPopup
            onUserClosePopup={onUserClosePopup}
            onPopupClose={onLogin}
            shouldClosePopup={checkWhetherToClosePopup}
            location={`/login/facebook?smartAgentByActivity=${props.smartAgentByActivity ? 1 : 0}`}
        />}
        <FacebookLoginStyled>
            <Button
                onClick={onLoginClick}
                title="התחברות עם Facebook"
                size="large" fullWidth>Facebook</Button>
        </FacebookLoginStyled>
    </>;
};

export default FacebookLogin;
