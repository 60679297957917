/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgAbout = (props: SVGProps<SVGSVGElement>) => (
  <svg width={56.878} viewBox="0 0 56.878 56.833" {...props}>
    <g
      transform="translate(-.713 -.75)"
      fill="#12b05e"
      stroke="#12b05e"
      strokeWidth={0.5}
    >
      <path
        data-name="Path 2527"
        d="M29.15 1A28.149 28.149 0 005.157 43.861l-3.974 9.926a2.559 2.559 0 003.327 3.327l9.926-3.974A28.149 28.149 0 1029.15 1zm0 51.18a22.893 22.893 0 01-12.987-4.015 2.559 2.559 0 00-2.4-.256l-5.6 2.224 2.247-5.614a2.559 2.559 0 00-.256-2.4A23.031 23.031 0 1129.15 52.18z"
      />
      <path
        data-name="Path 2528"
        d="M29.15 21.472h-5.118a2.559 2.559 0 100 5.118h2.559v17.913a2.559 2.559 0 005.118 0V24.031a2.559 2.559 0 00-2.559-2.559z"
      />
      <ellipse
        data-name="Ellipse 187"
        cx={3}
        cy={2}
        rx={3}
        ry={2}
        transform="translate(26.064 14.235)"
      />
    </g>
  </svg>
);

SvgAbout.defaultProps = {};
export default SvgAbout;
/* eslint-enable */
