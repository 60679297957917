/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgKnowledge = (props: SVGProps<SVGSVGElement>) => (
  <svg width={65.893} viewBox="0 0 65.893 64.662" {...props}>
    <g data-name="Group 824">
      <g data-name="Group 822">
        <path
          data-name="Path 2654"
          d="M32.946 57.404v-7.458q-.2-.122-.4-.239a22.222 22.222 0 00-14.975-2.713L5.329 49.042v4.4h14.492c4.808.001 9.008 1.636 13.125 3.962z"
          fill="#eaf0f0"
        />
      </g>
      <g data-name="Group 823">
        <path
          data-name="Path 2655"
          d="M32.946 57.404v-7.458q.2-.122.4-.239a22.222 22.222 0 0114.979-2.713l12.242 2.048v4.4h-14.49c-4.814.001-9.013 1.636-13.131 3.962z"
          fill="#eaf0f0"
        />
      </g>
    </g>
    <g data-name="Group 825">
      <path
        data-name="Path 2656"
        d="M42.62 19.308h-.007a11.605 11.605 0 10-18.954.55 13.352 13.352 0 013.006 8.491h12.564c.004-3.686 1.283-6.041 3.391-9.041z"
        fill="#ffc400"
      />
    </g>
    <g data-name="Group 826">
      <path
        data-name="Path 2657"
        d="M32.946 28.349h-6.282v4.193a5.681 5.681 0 005.6 5.6h1.371a5.681 5.681 0 005.6-5.6v-4.193z"
        fill="#bfd8d8"
      />
    </g>
    <g data-name="Group 827">
      <path
        data-name="Path 2658"
        d="M32.946 28.349v-7.118a9.8 9.8 0 011.867-5.754 9.8 9.8 0 00.849-1.4 3.022 3.022 0 10-5.431 0 9.815 9.815 0 00.848 1.4 9.8 9.8 0 011.864 5.75v7.121"
        fill="#eaf0f0"
      />
    </g>
    <g data-name="Group 828">
      <path
        data-name="Path 2659"
        d="M26.04 60.03a4.959 4.959 0 013.941 1.885 3.742 3.742 0 005.932 0 4.959 4.959 0 013.941-1.885h24.752v-6.587H46.073c-4.815 0-9 1.629-13.127 3.961-4.129-2.331-8.312-3.961-13.127-3.961H1.287v6.587z"
        fill="#12b05e"
      />
    </g>
    <g data-name="Group 829">
      <path
        data-name="Path 2660"
        d="M22.955 21.097a12.279 12.279 0 012.423 7.251v4.193a6.975 6.975 0 006.883 6.883h1.371a6.975 6.975 0 006.883-6.883v-4.1a14.021 14.021 0 013.25-8.539 12.892 12.892 0 10-23.711-7.011 14 14 0 002.901 8.206zm14.987 11.445a4.422 4.422 0 01-4.309 4.309h-1.371a4.422 4.422 0 01-4.309-4.309v-2.906h9.99zm-5.82-17.822a8.541 8.541 0 01-.737-1.22 1.735 1.735 0 113.124 0 8.529 8.529 0 01-.738 1.22 11.189 11.189 0 00-.825 1.321 11.244 11.244 0 00-.824-1.321zm.825-12.147a10.318 10.318 0 018.6 16.021c-1.756 2.634-3.292 5.238-3.55 8.467h-3.765v-5.83a8.458 8.458 0 011.622-5 11.115 11.115 0 00.961-1.589 4.309 4.309 0 10-7.738 0 11.128 11.128 0 00.961 1.591 8.459 8.459 0 011.619 4.994v5.834h-3.754a15.219 15.219 0 00-3.213-7.977 10.319 10.319 0 018.254-16.51z"
      />
      <path
        data-name="Path 2661"
        d="M64.606 52.155H61.85v-3.113a1.287 1.287 0 00-1.075-1.269l-12.242-2.048a23.748 23.748 0 00-12.586 1.284 13.091 13.091 0 0112.532-4.646l9.579 1.691a1.287 1.287 0 00.447-2.535l-9.579-1.691a15.587 15.587 0 00-15.981 7.012 15.587 15.587 0 00-15.981-7.012l-9.579 1.691a1.287 1.287 0 00.447 2.535l9.579-1.691a13.08 13.08 0 0112.529 4.645 23.747 23.747 0 00-12.584-1.284L5.117 47.772a1.287 1.287 0 00-1.075 1.269v3.114H1.287A1.287 1.287 0 000 53.442v6.587a1.287 1.287 0 001.287 1.287h7.8a1.287 1.287 0 000-2.574H2.574v-4.014h17.245a24.091 24.091 0 0112.494 3.794 1.4 1.4 0 001.432-.093c3.865-2.182 7.835-3.7 12.328-3.7h17.246v4.014H39.853a6.275 6.275 0 00-4.96 2.386 2.455 2.455 0 01-3.893 0 6.275 6.275 0 00-4.96-2.386h-5.628a1.287 1.287 0 000 2.574h5.628a3.687 3.687 0 012.921 1.383 5.029 5.029 0 007.971 0 3.686 3.686 0 012.921-1.383h24.753a1.287 1.287 0 001.287-1.287v-6.588a1.287 1.287 0 00-1.287-1.287zm-57.99 0v-2.024l11.167-1.869a20.971 20.971 0 0113.876 2.414V55.2a24.661 24.661 0 00-11.84-3.044zm39.457 0a24.664 24.664 0 00-11.84 3.044v-4.524a20.97 20.97 0 0113.876-2.414l11.167 1.869v2.024z"
      />
      <path
        data-name="Path 2662"
        d="M15.892 59.537a1.286 1.286 0 10-.422 1.526 1.3 1.3 0 00.422-1.526z"
      />
    </g>
  </svg>
);

SvgKnowledge.defaultProps = {};
export default SvgKnowledge;
/* eslint-enable */
