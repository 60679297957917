/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgReligiousProfessional = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.336} viewBox="0 0 29.336 29.34" {...props}>
    <g data-name="Religious Professional">
      <path
        data-name="Path 501"
        d="M12.059 28.224V16.137H5.888v-5.568h6.171V5.726h5.568v4.843h6.171v5.568h-6.171v12.087z"
        fill="#12b05e"
      />
      <path
        data-name="Path 502"
        d="M15.767 16.859v-2.586h6.171v-1.835h-6.171V7.595h-1.84v4.843H7.756v1.84h6.171v12.087h1.84z"
        fill="#f9f9fb"
      />
      <g data-name="Group 147" fill="#ffcd27">
        <path
          data-name="Path 503"
          d="M26.138 27.482a5.684 5.684 0 110-11.368 5.747 5.747 0 01.6.032 7.106 7.106 0 100 11.3 5.745 5.745 0 01-.6.036z"
        />
        <path
          data-name="Path 504"
          d="M25.992 18.973l.9 1.821 2.009.292-1.454 1.417.343 2-1.8-.945-1.8.945.343-2-1.454-1.417 2.009-.292z"
        />
      </g>
      <path
        data-name="Path 505"
        d="M17.653 27.811h-5.176v-7a.437.437 0 10-.874 0v7.439a.437.437 0 00.437.437h5.613a.437.437 0 100-.874z"
      />
      <path
        data-name="Path 506"
        d="M11.603 19.354a.437.437 0 10.874 0v-3.192a.437.437 0 00-.437-.437H6.306v-4.741h5.734a.437.437 0 00.437-.437V6.141h4.739v4.406a.437.437 0 00.437.437h5.734v2.119a.437.437 0 10.874 0v-2.556a.437.437 0 00-.437-.437H18.09V5.704a.437.437 0 00-.437-.437H12.04a.437.437 0 00-.437.437v4.406H5.869a.437.437 0 00-.437.437v5.613a.437.437 0 00.437.437h5.734v2.757z"
      />
      <path
        data-name="Path 507"
        d="M21.938 13.714a.437.437 0 00.437-.437v-.844a.437.437 0 00-.437-.437h-5.734V7.59a.437.437 0 00-.437-.437h-1.84a.437.437 0 00-.437.437v4.406H7.755a.437.437 0 00-.437.437v1.84a.437.437 0 00.437.437h5.73v11.651a.437.437 0 00.437.437h1.316a.437.437 0 100-.874h-.879V14.273a.437.437 0 00-.437-.437H8.188v-.967h5.734a.437.437 0 00.437-.437V8.024h.967v4.406a.437.437 0 00.437.437h5.734v.407a.437.437 0 00.441.44z"
      />
      <path
        data-name="Path 508"
        d="M15.767 13.837a.437.437 0 00-.437.437v2.585a.437.437 0 10.874 0v-2.148h2.921a.437.437 0 000-.874z"
      />
      <path
        data-name="Path 509"
        d="M26.7 27.015a5.335 5.335 0 01-.559.03 5.217 5.217 0 01-3.679-1.506.437.437 0 10-.613.623 6.079 6.079 0 003.391 1.691 6.669 6.669 0 110-12.11 6.123 6.123 0 00-4.35 9.2.437.437 0 10.749-.45 5.248 5.248 0 014.5-7.946 5.336 5.336 0 01.559.03.437.437 0 00.311-.782 7.474 7.474 0 00-4.572-1.544 7.543 7.543 0 100 15.085 7.474 7.474 0 004.572-1.544.437.437 0 00-.311-.782z"
      />
      <path
        data-name="Path 510"
        d="M29.316 20.951a.437.437 0 00-.353-.3l-1.782-.259-.8-1.615a.437.437 0 00-.783 0l-.8 1.615-1.782.259a.437.437 0 00-.242.745l1.29 1.257-.3 1.775a.437.437 0 00.634.46l1.594-.838 1.594.838a.437.437 0 00.634-.46l-.3-1.775 1.29-1.257a.436.436 0 00.106-.445zm-2.174 1.24a.437.437 0 00-.126.387l.194 1.129-1.014-.533a.437.437 0 00-.407 0l-1.014.533.194-1.129a.437.437 0 00-.126-.387l-.82-.8 1.134-.165a.437.437 0 00.329-.239l.507-1.027.507 1.027a.437.437 0 00.329.239l1.133.165z"
      />
      <path
        data-name="Path 511"
        d="M4.511 9.491h5.877a.437.437 0 00.378-.655L9.234 6.182l1.531-2.654a.437.437 0 00-.378-.655H7.323L5.791.218a.437.437 0 00-.757 0L3.502 2.873H.437a.437.437 0 00-.378.655l1.532 2.654L.059 8.836a.437.437 0 00.378.655h3.065l.782 1.355a.437.437 0 00.757-.437zm5.121-.874H4.006L2.6 6.182l1.406-2.436h2.813l1.406 2.436-.834 1.445a.437.437 0 10.757.437l.582-1.008zm0-4.871l-.9 1.562-.9-1.562zM5.413 1.311l.9 1.562h-1.8zM1.194 3.746h1.8l-.9 1.562zm0 4.871l.9-1.562.9 1.562z"
      />
    </g>
  </svg>
);

SvgReligiousProfessional.defaultProps = {};
export default SvgReligiousProfessional;
/* eslint-enable */
