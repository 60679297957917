/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width={50.823} viewBox="0 0 50.823 56.47" {...props}>
    <path
      data-name="Path 2524"
      d="M14.117 36.702a5.653 5.653 0 015.647-5.647h11.294a5.653 5.653 0 015.647 5.647v14.121h8.473V26.581L25.411 6.816 5.644 26.58v24.243h8.47z"
      fill="none"
    />
    <path
      data-name="Path 2525"
      d="M19.761 36.706h11.3v14.117h-11.3z"
      fill="none"
    />
    <path
      data-name="Path 2526"
      d="M5.647 56.47h39.529a5.653 5.653 0 005.647-5.647V25.412a2.816 2.816 0 00-.827-2L27.407.828a2.82 2.82 0 00-3.992 0L.827 23.416a2.816 2.816 0 00-.827 2v25.407a5.653 5.653 0 005.647 5.647zm14.117-5.647V36.706h11.294v14.117zM5.647 26.581L25.411 6.816l19.765 19.765v24.242h-8.471V36.706a5.653 5.653 0 00-5.647-5.647H19.764a5.653 5.653 0 00-5.647 5.647v14.117h-8.47V26.581z"
      fill="#12b05e"
    />
  </svg>
);

SvgHome.defaultProps = {};
export default SvgHome;
/* eslint-enable */
