import { Mail } from '@mui/icons-material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { resetPassword } from '../../../api/auth.api';
import { emailPattern } from '../../../utils/forms.utils';
import Alert from '../../foundation/Alert';
import Button from '../../foundation/Button';
import CustomBR from '../../foundation/CustomBR';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import TextField from '../../foundation/TextField';

export type ResetPasswordModalProps = {
    onClose: () => void
    open?: boolean
}

interface IFormResetPassword {
    email: string
}

const ResetPasswordModalStyle = styled.div`
`;

const ResetPasswordModal = (props: ResetPasswordModalProps): JSX.Element => {
    const {
        register, handleSubmit, errors,
    } = useForm<IFormResetPassword>();
    const [formErr, setFormErr] = useState<string>(null);
    const [successMsg, setSuccessMsg] = useState<string>(null);

    const onSubmit = async (data: IFormResetPassword) => {
        try {
            window.showBackdrop();
            await resetPassword(data);
            setSuccessMsg('נשלח אליך מייל לאיפוס הסיסמא, על מנת להמשיך בתהליך, יש ללחוץ על הלינק במייל שקיבלת.');
        } catch (error) {
            if (typeof error.response?.data === 'string') setFormErr(error.response.data);
            else setFormErr(error.message);
        } finally {
            window.hideBackdrop();
        }
    };

    return <DialogWithHeader open={props.open} onClose={props.onClose} title="הצטרפו ל-Mploy">
        <ResetPasswordModalStyle>
            {formErr && <Alert severity="error">{formErr}</Alert>}
            {successMsg ? <><CustomBR /><Alert severity="success">{successMsg}</Alert></> : <form onSubmit={handleSubmit(onSubmit)}>
                <CustomBR />
                <span>לאחר הזנת כתובת האימייל של המשתמש, יישלח אליך מייל עם קישור לאיפוס הסיסמא.</span>
                <CustomBR times={2} />
                <TextField
                    required
                    inputRef={register({ required: true, pattern: emailPattern })}
                    name="email"
                    id="email"
                    label="כתובת אימייל"
                    type="email"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email && 'כתובת המייל אינה תקינה'}
                    InputProps={{
                        endAdornment: <Mail />,
                    }}
                />
                <CustomBR times={2} />
                <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>קבלת מייל לאיפוס סיסמא</Button>
            </form>}
            <CustomBR times={2} />
        </ResetPasswordModalStyle>
    </DialogWithHeader>;
};

export default ResetPasswordModal;
