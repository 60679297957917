import React from 'react';
import ReactDOM from 'react-dom';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import './utils/polyfills';

declare global {
    interface Window {
        CURRENT_PAGE_COMPONENT: string
        APP_PROPS?: any
    }
}

const ReactComponent = require(`./pages/${window.CURRENT_PAGE_COMPONENT}.page`);
const container = document.getElementById('container');
const component = React.createFactory(ReactComponent.default)(window.APP_PROPS);

ReactDOM.hydrate(component, container);
