import { AxiosError, AxiosResponse } from 'axios';
import { CVViewModel, UserProfileViewModel } from '../../types/auth.types';
import { API_METHODS } from './api.enums';
import newAPIService from './newAPIService';
import { apiAction } from './utils.actions';

export type UserSettingsPayload = {
    formatted_address?: string
    latitude?: number
    longitude?: number
    smartAgentNotificationType: number
    removeAllTags?: boolean
    tags?: number[]
}
export type UserSettingsParams = { userid: number }
export type UserSettingsViewModel = {
    userid: number
}
export const postUserSettings = (
    params: UserSettingsParams, payload: UserSettingsPayload, backdrop,
) => apiAction<UserSettingsViewModel>({
    url: `/userSettings/${params.userid}`,
    method: API_METHODS.POST,
    payload,
    backdrop,
});

/**
 * @param {number} userid
 * @param {Object} data
 * @param {string} data.phone
 * @param {Object} options
 * @param {boolean} options.global
 */
export const userProfileApi = async (userid, data) => newAPIService.put(
    `/userProfile/${userid}`,
    data,
);

export type UserProfilePayload = {
    firstname?: string
    lastname?: string
    phone: string
}
export type UserProfileParams = { userid: number }
export type UserApplyResponse = UserProfileViewModel
export const postUserProfile = (
    params: UserProfileParams, payload: UserProfilePayload, backdrop?: boolean,
) => apiAction<UserApplyResponse>({
    url: `/userProfile/${params.userid}`,
    method: API_METHODS.PUT,
    payload,
    backdrop,
});

/**
 * upload user cv file
 * @param {number} userid
 * @param {Object} data
 * @param {Object} data.file form file using FormData and append('file', file)
 * e.g formData.append('file', document.querySelector('#my-file-el').files[0]);
 * @param {Object} options
 * @param {boolean} options.global
 */
type UploadUserCVParams = { userid: number };
type UploadUserCVPayload = FormData;
type UploadUserCVResponse = CVViewModel;
export const uploadUserCV = async (params: UploadUserCVParams, data: UploadUserCVPayload): Promise<AxiosResponse<UploadUserCVResponse>> => {
    try {
        return await newAPIService.put(
            `/userCV/${params.userid}`,
            data,
        );
    } catch (error) {
        const err = error as AxiosError;
        if (err.response.data === 'Internal Server Error') {
            const message = 'אירעה שגיאה והנתונים לא נשמרו. ניתן להעלות קורות חיים רק מסוג : txt, pdf, rtf, doc, docx או תמונה. גודל קובץ מקסימלי: 4MB';
            throw new Error(message);
        }
        throw new Error(err.response.data);
    }
};

type SetDefaultCVParams = { userid: number, cvid: number };
type SetDefaultCVPayload = { default: boolean };
type SetDefaultCVResponse = {
    cvid: number
};
export const setDefaultCV = async (
    params: SetDefaultCVParams, payload: SetDefaultCVPayload,
) => apiAction<SetDefaultCVResponse>({
    url: `/userCV/${params.userid}/cv/${params.cvid}`,
    method: API_METHODS.PUT,
    payload,
});

type DeleteCVParams = { userid: number, cvid: number };
export const deleteCV = async (
    params: DeleteCVParams,
) => apiAction<void>({
    url: `/userCV/${params.userid}/cv/${params.cvid}`,
    method: API_METHODS.DELETE,
});
