/* eslint-disable max-len */
import {
    Container, Typography,
} from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import { TagViewModel } from '../../../types/tags.types';
import AnchorTag from '../foundation/AnchorTag';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type AllTagsProps = {
    url: string
    user: LoggedInUser
    tags?: TagViewModel[]
}

const ROOT_LEVEL_TAG = 1;

const AllTags = (props: AllTagsProps) => {
    const categories = props.tags.filter(t => t.level === ROOT_LEVEL_TAG);
    const roles = props.tags.filter(t => t.level > ROOT_LEVEL_TAG && !t.vertical);
    const jobTypes = props.tags.filter(t => t.vertical);

    return <Page
        showBreadCrumbs={false}
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - תגיות" >
        <Container>
            <CustomBR />
            <BGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">תחומים</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    {categories.map(cat => <AnchorTag tag={cat.tag} display={cat.display} />)}
                </WhiteBGContainer>
                <CustomBR />
                <CustomBR />
                <Typography color="secondary" fontWeight="bold" variant="h4">תפקידים</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    {roles.map(cat => <AnchorTag tag={cat.tag} display={cat.display} />)}
                </WhiteBGContainer>
                <CustomBR />
                <CustomBR />
                <Typography color="secondary" fontWeight="bold" variant="h4">סוג משרה</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    {jobTypes.map(cat => <AnchorTag tag={cat.tag} display={cat.display} />)}
                </WhiteBGContainer>
                <CustomBR />
                <CustomBR />
            </BGContainer>
        </Container>
        <CustomBR />
    </Page>;
};

export default AllTags;
