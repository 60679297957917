import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    /* html, body {
        height: 100%;
        font-family: "Open Sans Hebrew";
        font-weight: 400;
        direction: rtl !important;
    }

    @font-face {
        font-family: 'Open Sans Hebrew';
        src: url('OpenSansHebrew-Bold.woff2') format('woff2'),
            url('OpenSansHebrew-Bold.woff') format('woff'),
            url('OpenSansHebrew-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Open Sans Hebrew';
        src: url('OpenSansHebrew-LightItalic.woff2') format('woff2'),
            url('OpenSansHebrew-LightItalic.woff') format('woff'),
            url('OpenSansHebrew-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Open Sans Hebrew';
        src: url('OpenSansHebrew-Italic.woff2') format('woff2'),
            url('OpenSansHebrew-Italic.woff') format('woff'),
            url('OpenSansHebrew-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Open Sans Hebrew Extra';
        src: url('OpenSansHebrew-ExtraBold.woff2') format('woff2'),
            url('OpenSansHebrew-ExtraBold.woff') format('woff'),
            url('OpenSansHebrew-ExtraBold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Open Sans Hebrew';
        src: url('OpenSansHebrew-BoldItalic.woff2') format('woff2'),
            url('OpenSansHebrew-BoldItalic.woff') format('woff'),
            url('OpenSansHebrew-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Open Sans Hebrew Extra';
        src: url('OpenSansHebrewExtraBold-Italic.woff2') format('woff2'),
            url('OpenSansHebrewExtraBold-Italic.woff') format('woff'),
            url('OpenSansHebrewExtraBold-Italic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'OpenSansHebrew';
        src: url('OpenSansHebrew-Regular.woff2') format('woff2'),
            url('OpenSansHebrew-Regular.woff') format('woff'),
            url('OpenSansHebrew-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Open Sans Hebrew';
        src: url('OpenSansHebrew-Light.woff2') format('woff2'),
            url('OpenSansHebrew-Light.woff') format('woff'),
            url('OpenSansHebrew-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    } */
`;
