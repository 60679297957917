/* eslint-disable max-len */
import {
    Container, Grid, Hidden, Typography,
} from '@mui/material';
import React from 'react';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@mui/icons-material';
import { LoggedInUser } from '../../../types/auth.types';
import Pagination from '../components/Pagination/Pagination';
import {
    ShadowBGContainer, SecondaryBox, BGContainer,
} from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import { CenteredFlex, Flexbox, VCenteredFlex } from '../foundation/Flex';
import Page from '../layout/base.page';
import Select from '../foundation/Select/Select';
import { JobViewModel } from '../../../types/jobs.types';
import Anchor from '../foundation/Anchor';
import DateFormat from '../foundation/DateFormat';
import { AppliedUserViewModel, getJobApplications } from '../../api/userToJob.api';

export type JobApplicationsProps = {
    url: string
    user: LoggedInUser
    job: JobViewModel
    appliedUsers: AppliedUserViewModel[],
    totalAppliedUsers: number
}

const pageSize = 10;

const sortOptions = [
    { value: 1, text: 'תאריך פנייה - עולה' },
    { value: 2, text: 'תאריך פנייה - יורד' },
    { value: 3, text: 'שם - עולה' },
    { value: 4, text: 'שם - יורד' },
];

const mapSortValueToType = {
    1: {
        orderby: 'dateApplied',
        direction: 'ASC',
    },
    2: {
        orderby: 'dateApplied',
        direction: 'DESC',
    },
    3: {
        orderby: 'fullname',
        direction: 'ASC',
    },
    4: {
        orderby: 'fullname',
        direction: 'DESC',
    },
} as const;

const DEFAULT_SORTBY = 2;

type State = {
    currentPage: number,
    sortby?: number
    appliedUsers: AppliedUserViewModel[],
    totalAppliedUsers: number
}

export default class JobApplications extends React.Component<JobApplicationsProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            sortby: DEFAULT_SORTBY,
            appliedUsers: props.appliedUsers,
            totalAppliedUsers: props.totalAppliedUsers,
        };
    }

    onGoToPage = async page => {
        this.setState({ currentPage: page }, this.sortApplications);
    };

    sortApplications = async () => {
        const {
            sortby, currentPage,
        } = this.state;

        const sort = mapSortValueToType[sortby];

        const jobApplicationsRes = await getJobApplications(
            { jobid: this.props.job.jobid },
            {
                start: (currentPage - 1) * pageSize,
                orderby: sort.orderby,
                direction: sort.direction,
            },
            true,
        );

        this.setState({
            appliedUsers: jobApplicationsRes.data.appliedUsers,
            totalAppliedUsers: jobApplicationsRes.data.totalAppliedUsers,
        });
    }

    getBreadCrumbs() {
        const crumbs = [];

        crumbs.push({
            text: 'ניהול משרות',
            url: '/user/postedJobs',
        });

        crumbs.push({
            text: 'פניות מועמדים למשרה',
        });

        return crumbs;
    }

    render() {
        const { props } = this;
        const {
            currentPage, sortby, appliedUsers, totalAppliedUsers,
        } = this.state;

        return <Page
            url={props.url}
            breadCrumbs={this.getBreadCrumbs()}
            user={props.user}
            pageHeaderTitle="Mploy - פניות מועמדים למשרה" >
            <Container>
                <CustomBR />
                <CenteredFlex>
                    <Typography color="secondary" fontWeight="bold" variant="h4">
                        סה"כ פניות ממועמדים למשרה <Anchor blank href={`/job/details/${props.job.jobid}`} title={props.job.title}>{props.job.title}</Anchor>:
                    </Typography>
                </CenteredFlex>
                <CustomBR />
                <CenteredFlex>
                    <SecondaryBox>
                        <Typography variant="h6" fontWeight="bold">
                            {totalAppliedUsers}
                        </Typography>
                    </SecondaryBox>
                </CenteredFlex>
                <CustomBR />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Select
                            onChange={ev => this.setState({ sortby: parseInt(ev.target.value as string, 10) }, this.sortApplications)}
                            size="small"
                            name="sort-by"
                            fullWidth
                            defaultValue={DEFAULT_SORTBY}
                            options={sortOptions} />
                    </Grid>
                    <Grid item sm={6} />
                    <Grid item xs={12} sm={4}>
                        {totalAppliedUsers > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                            gotoPage={this.onGoToPage}
                            url={props.url}
                            pages={Math.ceil(totalAppliedUsers / pageSize)}
                            currentPage={currentPage}
                        /></Flexbox></>}
                    </Grid>
                </Grid>
                {sortby && <div>
                    <CustomBR />
                    {sortby && <Typography color="secondary" display="inline">
                    סוג מיון: <b>{sortOptions.find(s => s.value === sortby).text}</b>
                    </Typography>}
                    <CustomBR />
                </div>}
                <ShadowBGContainer>
                    <Hidden lgDown>
                        <Grid container padding={2}>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={6}>פרטי המועמד</Grid>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={3}>קורות חיים</Grid>
                            <Grid fontWeight="bold" color="secondary" display="flex" justifyContent="center" item lg={3}>תאריך פנייה</Grid>
                        </Grid>
                    </Hidden>
                    {appliedUsers?.map(appliedUser => <div key={appliedUser.userid}><BGContainer>
                        <Grid container>
                            <Grid item md={6}>
                                <div>
                                    {appliedUser.firstname && <Typography><b>שם מלא:</b> {appliedUser.firstname} {appliedUser.lastname}</Typography>}
                                    {appliedUser.phone && <Typography><b>טלפון:</b> {appliedUser.phone}</Typography>}
                                    {appliedUser.email && <Typography><b>אימייל:</b> {appliedUser.email}</Typography>}
                                </div>
                            </Grid>
                            <Grid item md={3} display="flex" justifyContent="center">
                                <Anchor href={`/employer/cv/${appliedUser.userid}/job/${props.job.jobid}`} blank>
                                    <VCenteredFlex>הורדה <DescriptionOutlinedIcon /></VCenteredFlex>
                                </Anchor>
                            </Grid>
                            <Grid item md={3} display="flex" justifyContent="center">
                                <DateFormat date={appliedUser.updated_at} showTime />
                            </Grid>
                        </Grid>
                    </BGContainer><CustomBR /></div>)}
                    {appliedUsers.length === 0 && <Typography>כרגע אין פניות למשרה זו</Typography>}
                </ShadowBGContainer>
                <CustomBR />
                {totalAppliedUsers > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                    gotoPage={this.onGoToPage}
                    url={props.url}
                    pages={Math.ceil(totalAppliedUsers / pageSize)}
                    currentPage={currentPage}
                /></Flexbox><CustomBR /></>}
            </Container>
        </Page>;
    }
}
