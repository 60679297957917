import React from 'react';
import Header from './header';
import Body, { BodyProps } from './body';
import PageHeader from './pageHeader';
import NavbarAd from '../ads/NavbarAd';
import BackToTop from '../foundation/backToTop';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import AppRoot from './AppRoot';
import { LoggedInUser } from '../../../types/auth.types';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import { logoutUser } from '../../utils/AuthService';
import GlobalModal from '../modals/GlobalModal';
import BackdropSpinner from '../foundation/BackdropSpinner';
import { setCookie, getCookie } from '../infra/storageUtils';

export type GlobalModalOptions = {
    footer?: {
        leftBtnText?: string
        leftBtnClick?: () => void
        rightBtnText?: string
        rightBtnClick?: () => void
    }
}
declare global {
    interface Window {
        showGlobalModal: (title: string, text: string, options?: GlobalModalOptions) => void
        showBackdrop: (closable?: boolean) => void
        hideBackdrop: () => void
        /**
         * google analytis
         */
        ga?: any
        /**
         * google tag manager
         */
        dataLayer?: {
            push: (obj: any) => void
        }
        /**
         * google ads
         */
        adsbygoogle?: any[]
        modals?: {
            toggleRegisterModal: () => void
            toggleLoginModal: () => void
            toggleEmployerRegisterModal: () => void
        }
    }
}

export type PageProps = {
    url: string,
    pageHeaderTitle?: string,
    user?: LoggedInUser
    showAds?: boolean,
    showBreadCrumbs?: boolean,
    breadCrumbs?: { name?: string, text?: string, url: string }[]
    beforeBody?: JSX.Element
    /**
     * page class name
     */
    className?: string
    children: JSX.Element | JSX.Element[]
    crumbsSide?: JSX.Element
    showBackToTop?: boolean
} & BodyProps

type PageState = {
    modalTitle?: string
    modalText?: string
    modalOptions?: GlobalModalOptions
    showBackdrop?: boolean
    backdropCloseable?: boolean
    renderGTM?: boolean
}

class Page extends React.Component<PageProps, PageState> {
    static defaultProps = {
        showAds: false,
        showBreadCrumbs: true,
        showBackToTop: true,
    }

    constructor(props) {
        super(props);
        this.state = { showBackdrop: false };
    }

    getPageHeaderTitle() {
        return this.props.pageHeaderTitle && (
            <PageHeader title={this.props.pageHeaderTitle} />
        );
    }

    componentDidMount() {
        this.setGTMDataLayer();

        window.showGlobalModal = this.showModal;
        window.showBackdrop = this.showBackdrop;
        window.hideBackdrop = this.hideBackdrop;

        const geolocation = getCookie('geolocation');

        if (!geolocation) {
            window.navigator.geolocation.getCurrentPosition(position => {
                const jobsCriteria = getCookie('jobsCriteria');
                if (!jobsCriteria) {
                    setCookie('jobsCriteria', JSON.stringify({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }));
                }

                setCookie('geolocation', JSON.stringify({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                }));
            }, err => {
                setCookie('geolocation', JSON.stringify(undefined));
            });
        }
    }

    setGTMDataLayer() {
        const { dataLayer } = window;
        if (!dataLayer) window.dataLayer = [];
        if (this.props.user) {
            window.dataLayer.push({
                userId: this.props.user.userid,
                event: 'setUserId',
            });
        }

        const gtmScript = (w, d, s, l, i) => {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
        new Date().getTime(),
                event: 'gtm.js',
            }); const f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
        };

        gtmScript(window, document, 'script', 'dataLayer', 'GTM-KDNGP32');

        this.setState({ renderGTM: true });
    }

    showBackdrop = (closable = false) => { this.setState({ showBackdrop: true, backdropCloseable: closable }); }

    hideBackdrop = () => { this.setState({ showBackdrop: false, backdropCloseable: undefined }); }

    showModal = (modalTitle, modalText, modalOptions: GlobalModalOptions) => {
        this.setState({
            modalTitle,
            modalText,
            modalOptions,
        });
    }

    getNavbarAd() {
        return this.props.showAds && <NavbarAd />;
    }

    getBreadCrumbs() {
        if (this.props.breadCrumbs) return this.props.breadCrumbs;
        return [{ text: this.props.pageHeaderTitle, url: this.props.url }];
    }

    logout = ev => {
        if (ev) ev.preventDefault();
        logoutUser();
        window.location.assign('/auth/logout');
    }

    closeGlobalModal = () => this.setState({ modalText: null, modalTitle: null })

    closeBackdrop = () => this.setState({ showBackdrop: false })

    renderGTM = () => this.state.renderGTM && <noscript>
        <iframe title="gtm" src="https://www.googletagmanager.com/ns.html?id=GTM-KDNGP32"
            height="0" width="0" style={{ display: 'none', visibility: 'hidden' }} />
    </noscript>

    render() {
        return <AppRoot user={this.props.user}>
            {this.props.beforeBody}
            <div className={this.props.className}>
                <BackdropSpinner
                    open={this.state.showBackdrop}
                    onClose={this.state.backdropCloseable ? this.closeBackdrop : undefined} />
                <GlobalModal
                    onClose={this.closeGlobalModal}
                    open={!!this.state.modalText} text={this.state.modalText} title={this.state.modalTitle}
                    leftBtnClick={this.state.modalOptions?.footer?.leftBtnClick}
                    leftBtnText={this.state.modalOptions?.footer?.leftBtnText}
                    rightBtnClick={this.state.modalOptions?.footer?.rightBtnClick}
                    rightBtnText={this.state.modalOptions?.footer?.rightBtnText}
                />
                <Header url={this.props.url} user={this.props.user}>
                    <Navbar logoutClick={this.logout} />
                    {this.getPageHeaderTitle()}
                    {this.props.showBreadCrumbs && <Breadcrumbs
                        sideChildrens={this.props.crumbsSide}
                        crumbs={this.getBreadCrumbs()}
                    />}
                    {this.getNavbarAd()}
                </Header>
                <Body bg={this.props.bg}>
                    {this.props.children}
                </Body>
                <Footer />
                {this.props.showBackToTop && <BackToTop />}
            </div>
        </AppRoot>;
    }
}

export default Page;
