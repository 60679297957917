import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';

export type HeadrProps = {
    children: JSX.Element | JSX.Element[]
    url: string,
    user: LoggedInUser
}

const Headr = (props: HeadrProps): JSX.Element => <header>
    {props.children}
</header>;

export default Headr;
