/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgSales = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.678} viewBox="0 0 27.678 26.762" {...props}>
    <path
      data-name="Path 895"
      d="M20.69 12.818h1.277v11.767h-1.281zm0 0"
      fill="#244f5e"
    />
    <path
      data-name="Path 896"
      d="M14.381 9.284a2.06 2.06 0 110 3.778H3.294a2.06 2.06 0 110-3.778"
      fill="#ce8d77"
    />
    <path
      data-name="Path 897"
      d="M10.165 19.948h.658a3.38 3.38 0 013.167 2.2l1.569 4.2H4.504V22.14a2.19 2.19 0 012.19-2.189h.816"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 898"
      d="M8.449 19.948H6.692a2.19 2.19 0 00-2.19 2.189v4.213h1.755v-4.21a2.19 2.19 0 012.189-2.189zm0 0"
      fill="#ccc"
    />
    <path
      data-name="Path 899"
      d="M8.837 21.155a1.335 1.335 0 01-1.335-1.335v-2.487h2.669v2.487a1.335 1.335 0 01-1.334 1.335zm0 0"
      fill="#ce8d77"
    />
    <path
      data-name="Path 900"
      d="M14.38 7.546v5.543a5.543 5.543 0 11-11.086 0V7.546"
      fill="#e5baa8"
    />
    <path
      data-name="Path 901"
      d="M8.798 18.632a5.542 5.542 0 01-5.5-5.543V7.546h2.5v5.543a8.305 8.305 0 00.892 3.92 2.673 2.673 0 002.108 1.623zm0 0"
      fill="#ce8d77"
    />
    <path
      data-name="Path 902"
      d="M16.728.411a1.606 1.606 0 00-1.611 1.606 1.606 1.606 0 00-3.211 0h-5.1a3.517 3.517 0 00-3.517 3.517v4.452c2.045.044 1.717-2.439 1.717-2.439h7.788a5.529 5.529 0 005.534-5.475 1.639 1.639 0 00-1.6-1.661zm0 0"
      fill="#244f5e"
    />
    <g data-name="Group 302" fill="#183f49">
      <path
        data-name="Path 903"
        d="M13.894.466a1.606 1.606 0 00-2.016 1.553h.821a1.606 1.606 0 011.2-1.553zm0 0"
      />
      <path
        data-name="Path 904"
        d="M17.133.466a1.606 1.606 0 00-2.016 1.553h.821a1.606 1.606 0 011.2-1.553zm0 0"
      />
      <path
        data-name="Path 905"
        d="M6.811 2.017h1.407a3.517 3.517 0 00-3.517 3.517v3.758a1.488 1.488 0 01-1.407.694V5.534a3.517 3.517 0 013.517-3.517zm0 0"
      />
    </g>
    <path
      data-name="Path 906"
      d="M9.875 26.35l-1.038-2.471-1.038 2.471zm0 0"
      fill="#f25f5f"
    />
    <path
      data-name="Path 907"
      d="M8.837 23.88l1.038-1.234-1.038-1.49-1.038 1.49zm0 0"
      fill="#f25f5f"
    />
    <path
      data-name="Path 908"
      d="M21.869 23.169h-1.568a.739.739 0 00-.739.739v2.442h2.307a1.229 1.229 0 001.229-1.23v-.722a1.23 1.23 0 00-1.229-1.229zm0 0"
      fill="#e5baa8"
    />
    <path
      data-name="Path 909"
      d="M15.393 14.464h11.874v6.033H15.393zm0 0"
      fill="#f4c84d"
    />
    <path
      data-name="Path 910"
      d="M10.444 15.049a.412.412 0 00-.582-.582 1.45 1.45 0 01-2.05 0 .412.412 0 10-.582.582 2.272 2.272 0 003.214 0zm0 0"
    />
    <path
      data-name="Path 911"
      d="M11.292 12.589a.411.411 0 00.411-.411v-1.252a.411.411 0 00-.823 0v1.252a.411.411 0 00.412.411zm0 0"
    />
    <path
      data-name="Path 912"
      d="M6.383 10.516a.411.411 0 00-.411.411v1.252a.411.411 0 00.823 0v-1.252a.411.411 0 00-.412-.411zm0 0"
    />
    <path
      data-name="Path 913"
      d="M14.289 21.771a3.451 3.451 0 00-3.219-2.234h-.487v-.755a5.968 5.968 0 004.186-5.181 2.471 2.471 0 10.023-4.87V7.617a5.951 5.951 0 003.948-5.54 2.09 2.09 0 00-.601-1.476A1.994 1.994 0 0015.3.589a2.065 2.065 0 00-.183.212 2.016 2.016 0 00-3.58.809H9.39a.411.411 0 100 .823h2.516a.411.411 0 00.411-.411 1.194 1.194 0 112.388 0 .411.411 0 00.823 0A1.194 1.194 0 0116.723.823a1.163 1.163 0 01.827.355 1.259 1.259 0 01.363.891A5.118 5.118 0 0112.8 7.135H5.011a.411.411 0 00-.411.466 2.429 2.429 0 01-.369 1.634.982.982 0 01-.528.3v-4a3.109 3.109 0 013.108-3.107h.933a.411.411 0 000-.823h-.933a3.932 3.932 0 00-3.928 3.929v3.2a2.471 2.471 0 10.023 4.87 5.969 5.969 0 004.186 5.176v.755h-.4a2.584 2.584 0 00-1.35.379.387.387 0 00-.038.024 2.6 2.6 0 00-1.213 2.2v4.213a.411.411 0 00.411.411h11.057a.411.411 0 00.385-.555zm-2.825-1.381l-1.507 1.652-.43-.618a1.754 1.754 0 00.971-1.065h.573a2.6 2.6 0 01.394.03zm-3.046 5.549l.419-1 .419 1zm.419-4.065l.521.747-.521.619-.52-.619zM15.2 9.524a1.649 1.649 0 11-.412 3.246V9.576a1.65 1.65 0 01.412-.052zM.823 11.173a1.65 1.65 0 012.06-1.6v3.194a1.65 1.65 0 01-2.06-1.6zm2.883 1.916v-2.716a1.822 1.822 0 001.141-.59 2.828 2.828 0 00.594-1.825H12.8a5.987 5.987 0 001.169-.115v5.246a5.132 5.132 0 11-10.263 0zm5.132 5.954a5.97 5.97 0 00.923-.072V19.915a.923.923 0 01-1.837 0V18.97a5.969 5.969 0 00.923.072zM7.177 20.36a1.755 1.755 0 00.972 1.065l-.431.618-1.481-1.624a1.778 1.778 0 01.456-.06zm-2.262 1.778a1.774 1.774 0 01.579-1.312l2.862 3.136-.831 1.977h-2.61zm8.526 3.8l-.2-.822a.411.411 0 00-.8.193l.152.629h-2.444l-.831-1.977 3-3.284a2.619 2.619 0 011.2 1.381l1.448 3.88zm0 0"
    />
    <path
      data-name="Path 914"
      d="M27.268 14.052h-4.885v-1.234a.411.411 0 00-.411-.411h-1.281a.411.411 0 00-.411.411v1.234h-4.885a.411.411 0 00-.411.411v6.033a.411.411 0 00.411.411h4.885v1.851a1.151 1.151 0 00-1.128 1.149v2.442a.411.411 0 00.411.411h2.307a1.643 1.643 0 001.641-1.641v-.716a1.643 1.643 0 00-1.128-1.558v-1.942h1.075a.411.411 0 000-.823h-7.653v-5.21h11.051v5.21h-1.753a.411.411 0 000 .823h2.165a.411.411 0 00.411-.411v-6.029a.411.411 0 00-.411-.411zm-7.294 9.856a.328.328 0 01.328-.328h.848a.309.309 0 010 .618h-1.176zm2.714.49v.722a.819.819 0 01-.818.818h-1.9v-.918h1.179a1.129 1.129 0 001.112-1.341.819.819 0 01.427.724zm-1.128-1.641h-.458v-1.854h.458zm-.458-9.528h.458v.823h-.458zm0 0"
    />
    <path
      data-name="Path 915"
      d="M17.566 18.659a.805.805 0 01-.566-.235.387.387 0 00-.226-.123c-.154 0-.279.205-.279.34 0 .279.466.591 1.089.591a1 1 0 001.122-1.024c0-.722-.531-.947-.958-1.128-.288-.122-.537-.228-.537-.455s.187-.345.513-.345a.928.928 0 01.471.119.379.379 0 00.171.058c.179 0 .256-.222.256-.335 0-.3-.565-.386-.9-.386-.554 0-1.145.248-1.145.945 0 .624.469.832.883 1.016.328.146.612.272.612.572s-.261.39-.506.39zm0 0"
    />
    <path
      data-name="Path 916"
      d="M20.163 15.73c-.155 0-.352.056-.4.215l-.9 2.93a.258.258 0 00-.01.05c0 .162.234.265.4.265.122 0 .2-.044.218-.131l.174-.609h1.046l.174.609c.023.087.1.131.218.131.158 0 .4-.106.4-.265a.15.15 0 00-.007-.039l-.9-2.94c-.055-.16-.248-.216-.413-.216zm.387 2.216h-.774l.387-1.365zm0 0"
    />
    <path
      data-name="Path 917"
      d="M22.087 15.758c-.146 0-.317.052-.317.2v3.027c0 .141.145.2.279.2h1.476c.128 0 .2-.14.2-.279s-.061-.279-.2-.279h-1.122v-2.673c0-.134-.158-.196-.316-.196zm0 0"
    />
    <path
      data-name="Path 918"
      d="M26.165 18.915c0-.167-.084-.284-.2-.284h-1.3v-.908h.694a.222.222 0 00.2-.246c0-.127-.07-.256-.2-.256h-.694v-.9h1.3c.121 0 .2-.117.2-.284 0-.132-.064-.274-.2-.274h-1.658c-.134 0-.279.064-.279.2v3.022c0 .141.145.2.279.2h1.658c.136.004.2-.138.2-.27zm0 0"
    />
  </svg>
);

SvgSales.defaultProps = {};
export default SvgSales;
/* eslint-enable */
