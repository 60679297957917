/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgWarehouse = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.638} viewBox="0 0 29.638 29.615" {...props}>
    <path
      data-name="Path 485"
      d="M14.516 11.377H5.899V.756a.322.322 0 01.322-.322h7.969a.322.322 0 01.322.322z"
      fill="#ffcd27"
    />
    <path
      data-name="Path 486"
      d="M13.52.434v10.943h1V.756a.322.322 0 00-.322-.322z"
      fill="#e49542"
    />
    <path
      data-name="Path 487"
      d="M10.75 2.913H9.66a.259.259 0 01-.259-.259V.434h1.608v2.22a.259.259 0 01-.259.259z"
      fill="#12b05e"
    />
    <path
      data-name="Path 488"
      d="M24.41 20.858H11.204V7.325h12.884a.322.322 0 01.322.322z"
      fill="#ebb400"
    />
    <path
      data-name="Path 489"
      d="M23.413 7.325v13.533h1V7.647a.322.322 0 00-.322-.322h-.675z"
      fill="#ecbe6b"
    />
    <path
      data-name="Path 490"
      d="M18.352 9.804h-1.09a.259.259 0 01-.259-.259v-2.22h1.608v2.22a.259.259 0 01-.259.259z"
      fill="#12b05e"
    />
    <path
      data-name="Path 491"
      d="M15.998 29.181H.434V18.864a.322.322 0 01.322-.322h15.242z"
      fill="#ffcd27"
    />
    <path data-name="Path 492" d="M15.001 18.542h1v10.639h-1z" fill="#e49542" />
    <path
      data-name="Path 493"
      d="M8.761 21.024H7.668a.259.259 0 01-.259-.259v-2.22h1.608v2.22a.259.259 0 01-.256.259z"
      fill="#12b05e"
    />
    <path
      data-name="Path 494"
      d="M29.204 29.181H15.998v-8.323h12.884a.322.322 0 01.322.322v8z"
      fill="#ffcd27"
    />
    <path
      data-name="Path 495"
      d="M28.207 20.858v8.323h1v-8a.322.322 0 00-.322-.322h-.675z"
      fill="#e49542"
    />
    <path
      data-name="Path 496"
      d="M23.146 23.34h-1.093a.259.259 0 01-.259-.259v-2.22h1.608v2.22a.259.259 0 01-.256.259z"
      fill="#12b05e"
    />
    <path
      data-name="Path 497"
      d="M11.204 18.546H2.587v-6.844a.322.322 0 01.322-.322h8.3z"
      fill="#ebb400"
    />
    <path data-name="Path 498" d="M10.208 11.377h1v7.17h-1z" fill="#ecbe6b" />
    <path
      data-name="Path 499"
      d="M7.441 13.856h-1.09a.259.259 0 01-.259-.259v-2.22H7.7v2.22a.259.259 0 01-.259.259z"
      fill="#12b05e"
    />
    <path
      data-name="Path 500"
      d="M28.882 20.424h-4.038v-6.642a.434.434 0 10-.868 0v6.642h-7.544v-1.882a.434.434 0 00-.432-.434h-4.362V7.759h4.931v1.786a.694.694 0 00.693.693h1.09a.694.694 0 00.693-.693V7.759h4.931v4.287a.434.434 0 00.868 0v-4.4a.757.757 0 00-.756-.756h-9.137V.756A.757.757 0 0014.195 0H6.221a.757.757 0 00-.756.756v2.745a.434.434 0 10.868 0V.868H8.97v1.786a.694.694 0 00.693.693h1.09a.694.694 0 00.693-.693V.868h2.636v6.023H11.2a.434.434 0 00-.434.434v3.618H6.333V5.236a.434.434 0 00-.868 0v5.706H2.909a.757.757 0 00-.756.756v6.41H.756a.757.757 0 00-.756.756v10.317a.434.434 0 00.434.434h4.6a.434.434 0 000-.868H.868v-9.77h6.11v1.786a.694.694 0 00.693.693h1.09a.694.694 0 00.693-.693v-1.786h6.11v9.771h-8.8a.434.434 0 000 .868H29.2a.434.434 0 00.434-.434v-8a.757.757 0 00-.756-.756zM10.577 2.479h-.74V.868h.74zm7.6 5.28V9.37h-.74V7.759zM6.526 11.808h.74v1.611h-.74zm-3.5 0h2.631v1.786a.694.694 0 00.693.693h1.09a.694.694 0 00.693-.693v-1.786h2.637v6.3H3.021zm5.565 8.776h-.74v-1.607h.74zm14.385.7v1.611h-.74v-1.603zm5.8 7.455H16.432v-7.447h4.931v1.786a.694.694 0 00.693.693h1.09a.694.694 0 00.693-.693v-1.786h4.931v7.455z"
    />
  </svg>
);

SvgWarehouse.defaultProps = {};
export default SvgWarehouse;
/* eslint-enable */
