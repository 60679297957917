import React, { useState } from 'react';
import styled from 'styled-components';
import { getParameterByName } from '../../utils/urlUtils';
import Button from '../foundation/Button';
import { LoginPopup } from './LoginPopup';

export type LinkedinLoginProps = {
    smartAgentByActivity?: boolean
}

const LinkedinLoginStyled = styled.div`
    .MuiButtonBase-root {
        background-color: #1b86bc;    
    }        
`;

const LinkedinLogin = (props: LinkedinLoginProps): JSX.Element => {
    const [popup, setPopup] = useState<boolean>(false);
    const onLoginClick = () => { setPopup(true); };
    const onUserClosePopup = () => { setPopup(false); };
    const checkWhetherToClosePopup = (popupWin: Window) => {
        try {
            let token;
            const url = popupWin.location && popupWin.location.href;

            const indexOfCode = url && url.indexOf('code=');
            if (indexOfCode > -1) {
                [token] = url.substring(indexOfCode + 5).split('&');
                if (token) return true;
            }

            const indexOfError = url && url.indexOf('error=');
            if (indexOfError > -1) return true;

            return false;
        } catch (error) {
            return false;
        }
    };

    const onLogin = () => {
        const redirectUrl = getParameterByName('redirect');
        if (redirectUrl && redirectUrl !== '') {
            window.location.assign(redirectUrl);
        } else {
            window.location.reload();
        }
    };

    return <>
        {popup && <LoginPopup
            onUserClosePopup={onUserClosePopup}
            onPopupClose={onLogin}
            shouldClosePopup={checkWhetherToClosePopup}
            location={`/login/linkedin?smartAgentByActivity=${props.smartAgentByActivity}`}
        />}
        <LinkedinLoginStyled>
            <Button
                onClick={onLoginClick}
                title="התחברות עם Linkedin"
                size="large" fullWidth>Linkedin</Button>
        </LinkedinLoginStyled>
    </>;
};

export default LinkedinLogin;
