import React from 'react';
import styled from 'styled-components';
import { TextField as TextFieldMUI, TextFieldProps as TextFieldPropsMUI } from '@mui/material';

export type TextFieldProps = {
    onEnter?: () => void
} & TextFieldPropsMUI

const TextFieldStyled = styled(TextFieldMUI)`
    .MuiOutlinedInput-root {
        background: #f9f9fb;
    }
    .MuiSvgIcon-root {
        color: #727273;
    }
    .Mui-focused {
        .MuiSvgIcon-root {
            color: ${props => props.theme.palette.primary.main}
        }
    }
`;

const TextField = ({ onEnter, ...props }: TextFieldProps): JSX.Element => {
    const onKeyDown = ev => {
        if (ev.keyCode === 13 && onEnter) onEnter();
    };

    return <TextFieldStyled
        onKeyDown={onKeyDown}
        {...props}
    >
        {props.children}
    </TextFieldStyled>;
};

export default TextField;
