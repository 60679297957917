import {
    Container, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import { summaryCount } from '../../api/stats.api';
import SearchJobs from '../components/SearchJobs/SearchJobs';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type NotFoundProps = {
    url: string
    user?: LoggedInUser
    message?: string,
    htmlMessage?: string
}

type Counters = {
    numberOfJobs: number
    numberOfTodayJobs: number
    numberOfUsers: number
    numberOfTodayWatchedCV: number
};

const NotFound = (props: NotFoundProps) => {
    const [counters, setCounters] = useState<Counters>(null);

    const fetchCounters = async () => {
        const result = await summaryCount();
        setCounters(result.data as Counters);
    };
    useEffect(() => { fetchCounters(); }, []);

    return <Page
        showBreadCrumbs={false}
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - עמוד שגיאה 404" >
        <Container>
            <CustomBR />
            <BGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - עמוד לא קיים</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    <Typography whiteSpace="pre-line" variant="body1">
                אופס! העמוד שחיפשת לא קיים. אבל לא לוותר, זה הזמן להמשיך לגלוש ולחפש את המשרה הבאה
                    </Typography>
                </WhiteBGContainer>
                <CustomBR />
                <CustomBR />
                <WhiteBGContainer>
                    <SearchJobs
                        numberOfTodayJobs={counters?.numberOfTodayJobs}
                        totalJobs={counters?.numberOfJobs}
                    />
                </WhiteBGContainer>
                <CustomBR />
                <CustomBR />
            </BGContainer>
        </Container>
        <CustomBR />
    </Page>;
};

export default NotFound;
