import React from 'react';
import { Alert as AlertMUI, AlertProps as AlertPropsMUI } from '@mui/material';

export type AlertProps = {
    children?: JSX.Element | string
} & AlertPropsMUI

const Alert = (props: AlertProps): JSX.Element => <AlertMUI
    {...props}>
    {props.children}
</AlertMUI>;

export default Alert;
