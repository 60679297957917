import React from 'react';
import styled from 'styled-components';
import { Select as SelectMUI, SelectProps as SelectMUIProps } from '@mui/material';

type SelectOption = {
    text: string
    value: string | number
}
export type SelectProps = {
    options: SelectOption[]
} & SelectMUIProps

const SelectMUIStyled = styled(SelectMUI)`
    background: #f9f9fb;
`;
const Select = ({ options, ...selectOpts }: SelectProps): JSX.Element => <SelectMUIStyled
    native {...selectOpts} variant={selectOpts.variant || 'outlined'}>
    {options.map(opt => <option key={opt.value} value={opt.value}>
        {opt.text}
    </option>)}
</SelectMUIStyled>;

export default Select;
