/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgTourismAndHospitality = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.419} viewBox="0 0 29.419 23.151" {...props}>
    <g data-name="Tourism and Hospitality">
      <g data-name="Group 247">
        <path
          data-name="Path 777"
          d="M28.408 22.72H1.011a.581.581 0 01-.58-.58v-2.322a.58.58 0 01.58-.58h27.4a.58.58 0 01.58.58v2.322a.58.58 0 01-.583.58z"
          fill="#b78b7a"
        />
      </g>
      <g data-name="Group 248">
        <path
          data-name="Path 778"
          d="M28.408 19.237H25.97a.58.58 0 01.58.58v2.322a.58.58 0 01-.58.58h2.438a.58.58 0 00.58-.58v-2.322a.58.58 0 00-.58-.58z"
          fill="#aa7a63"
        />
      </g>
      <g data-name="Group 249">
        <path
          data-name="Path 779"
          d="M3.681 17.268h22.056v1.973H3.681z"
          fill="#ffcd27"
        />
      </g>
      <g data-name="Group 250">
        <path
          data-name="Path 780"
          d="M23.068 17.268h2.67v1.973h-2.67z"
          fill="#ffd064"
        />
      </g>
      <g data-name="Group 251">
        <path
          data-name="Path 781"
          d="M6.003 15.29h17.413v1.973H6.003z"
          fill="#595959"
        />
      </g>
      <g data-name="Group 252">
        <path
          data-name="Path 782"
          d="M13.552 2.458h2.322v1.915h-2.322z"
          fill="#c6c1c8"
        />
      </g>
      <g data-name="Group 253">
        <path
          data-name="Path 783"
          d="M16.597 2.463h-3.773a1.016 1.016 0 010-2.032h3.773a1.016 1.016 0 110 2.032z"
          fill="#ffcd27"
        />
      </g>
      <g data-name="Group 254">
        <path
          data-name="Path 784"
          d="M16.596.431h-1.509a1.016 1.016 0 110 2.032h1.509a1.016 1.016 0 100-2.032z"
          fill="#ffd064"
        />
      </g>
      <g data-name="Group 255">
        <path
          data-name="Path 785"
          d="M3.681 15.29a11.028 11.028 0 0122.057 0H3.681z"
          fill="#12b05e"
        />
      </g>
      <g data-name="Group 256">
        <path
          data-name="Path 786"
          d="M14.71 4.262a11.136 11.136 0 00-1.393.087 11.03 11.03 0 019.635 10.941h2.786A11.028 11.028 0 0014.71 4.262z"
          fill="#03984b"
        />
      </g>
      <path
        data-name="Path 787"
        d="M28.408 18.806h-2.239v-1.542a.431.431 0 00-.431-.431h-1.891v-1.112h1.891a.431.431 0 00.431-.431A11.476 11.476 0 0016.3 3.942V2.893h.3A1.447 1.447 0 0016.6 0h-3.777a1.447 1.447 0 000 2.893h.295v1.049A11.476 11.476 0 003.25 15.29a.431.431 0 00.431.431h1.891v1.112H3.681a.431.431 0 00-.431.431v1.543H1.011A1.013 1.013 0 000 19.817v2.322a1.013 1.013 0 001.011 1.013h27.4a1.013 1.013 0 001.011-1.011v-2.324a1.013 1.013 0 00-1.011-1.011zM12.238 1.447a.585.585 0 01.585-.585H16.6a.585.585 0 010 1.17h-3.777a.586.586 0 01-.585-.585zm1.741 1.447h1.46v.958c-.241-.015-.485-.024-.73-.024s-.488.009-.73.024zm-9.867 14.8h3.645a.431.431 0 000-.862H6.434v-1.111h13.843a.431.431 0 100-.862H4.121a10.6 10.6 0 0121.177 0H22.29a.431.431 0 100 .862h.7v1.112H9.769a.431.431 0 000 .862h15.538v1.112H4.112zm24.445 4.445a.15.15 0 01-.15.15H1.011a.15.15 0 01-.15-.15v-2.322a.15.15 0 01.15-.15h27.4a.15.15 0 01.15.15v2.322z"
      />
    </g>
  </svg>
);

SvgTourismAndHospitality.defaultProps = {};
export default SvgTourismAndHospitality;
/* eslint-enable */
