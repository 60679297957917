import React from 'react';
import {
    Box, FormControlLabel, FormGroup, FormHelperText, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Checkbox from '../../foundation/Checkbox';
import CustomBR from '../../foundation/CustomBR';
import DateFormat from '../../foundation/DateFormat';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import Select from '../../foundation/Select';
import { PostedJob } from '../../../../types/jobs.types';
import Button from '../../foundation/Button';
import { extendJobPromotions } from '../../../api/promotions.api';
import Anchor from '../../foundation/Anchor';
import { Flexbox } from '../../foundation/Flex';

type IFormPostJob = {
    promoted: boolean,
    jobPeriod: number
}

export type ChangeJobTimeModalProps = {
    totalPromotions?: number
    totalAllowedJobs?: number
    open?: boolean
    onClose?: () => void
    job?: PostedJob
}
const DEFAULT_JOB_PERIOD = 1;
const INTERNAL_JOB_PERIOD = 30;

const ChangeJobTimeModal = (props: ChangeJobTimeModalProps) => {
    if (!props.job) return null;

    const {
        register, watch, setValue,
    } = useForm<IFormPostJob>({
        defaultValues: {
            // promoted: !!props.job.promotion,
            promoted: true,
            jobPeriod: DEFAULT_JOB_PERIOD,
        },
    });

    const periodOptions = [];
    const promoted = watch('promoted') as boolean;

    // const totalPeriodsAllowedForJob = promoted ? props.totalPromotions : props.totalAllowedJobs;
    const totalPeriodsAllowedForJob = props.totalPromotions;

    const jobPeriod = watch('jobPeriod', 1);
    const endDate = new Date(Math.max((new Date(props.job?.jobEnd)).getTime() || 0, (new Date()).getTime()) + 86400000 * jobPeriod * INTERNAL_JOB_PERIOD);
    const promotionEndDate = props.job?.promotion ? endDate
        : new Date((new Date()).getTime() + 86400000 * jobPeriod * INTERNAL_JOB_PERIOD);

    for (let i = 0; i < totalPeriodsAllowedForJob; i += 1) {
        periodOptions.push({ text: `${i + 1} x חודש`, value: i + 1 });
    }

    const jobPromotionAllowed = props.totalPromotions >= jobPeriod && props.job.promotion > 0;

    const refreshJob = async () => {
        await extendJobPromotions({
            jobid: props.job.jobid,
            promoted,
            period: jobPeriod * INTERNAL_JOB_PERIOD,
        });

        window.location.reload();
    };

    React.useEffect(() => {
        if (promoted && jobPeriod > props.totalPromotions) setValue('promoted', false);
    }, [jobPeriod]);

    return <DialogWithHeader open={props.open}
        onClose={props.onClose} title="ניהול פרסום המשרה"><>
            <CustomBR />
            <>
                <Flexbox display="flex">
                    <Typography>קידום פרסום המשרה:&nbsp;</Typography>
                    <Anchor blank href={`/job/details/${props.job.jobid}`} text={props.job.title} />
                </Flexbox>
                <CustomBR />
                <>
                    <Select
                        name="jobPeriod"
                        id="jobPeriod"
                        inputRef={register({ required: true })}
                        size="small"
                        defaultValue={1}
                        options={periodOptions}
                    />
                    <FormHelperText>הזמן שבו תקודם המשרה</FormHelperText>
                </>
                <CustomBR h={10} />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            // checked={promoted}
                            checked
                            disabled
                            onChange={ev => setValue('promoted', ev.target.checked)}
                            inputRef={register()}
                            name="promoted"
                        />}
                        label="משרה מקודמת"
                    />
                </FormGroup><CustomBR h={10} /></>
            <div>
                {props.job.promotion ? <Box display="flex">
                    <Typography>המשרה תפורסם {jobPromotionAllowed && promoted && <b>ותקודם</b>} </Typography>&nbsp;
                    <Typography>עד תאריך: </Typography>&nbsp;
                    <DateFormat showTime date={endDate} />
                </Box> : <><Box display="flex">
                    <Typography>המשרה תקודם עד תאריך: </Typography>&nbsp;
                    <DateFormat showTime date={endDate} />
                </Box><Box display="flex">
                    {jobPromotionAllowed && promoted && <><Typography>המשרה <b>תקודם</b></Typography>&nbsp;
                        <Typography>עד תאריך: </Typography>&nbsp;
                        <DateFormat showTime date={promotionEndDate} /></>}
                </Box></>}
            </div>
            {totalPeriodsAllowedForJob > 1 && <>{promoted ? <Typography variant="caption">
                בחשבונך קיימים כעת <b>{props.totalPromotions}</b> מודעות דרושים מקודמות,
                לאחר פרסום המשרה יישארו <b>{props.totalPromotions - jobPeriod}</b> מודעות דרושים מקודמות
            </Typography> : <Typography variant="caption">
                בחשבונך קיימים כעת <b>{props.totalAllowedJobs}</b> מודעות דרושים.
                לאחר פרסום המשרה יישארו <b>{props.totalAllowedJobs - jobPeriod}</b>.
            </Typography>}</>}
            <CustomBR />
            <Button fullWidth onClick={refreshJob}>עדכון פרסום המשרה</Button>
            <CustomBR />
        </>
    </DialogWithHeader>;
};

export default ChangeJobTimeModal;
