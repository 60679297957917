import * as React from 'react';
import { Box, BoxProps } from '@mui/material';
import styled, { css } from 'styled-components';

export const Flexbox = (props: BoxProps) => <Box {...props} display="flex" />;

export const centeredFlex = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenteredFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const colCenteredFlex = css`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ColCenteredFlex = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const vCenteredFlex = css`
    display: flex;
    align-items: center;
`;

export const VCenteredFlex = styled.div`
    display: flex;
    align-items: center;
`;

export const VStartFlex = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const RightFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;
