import styled from 'styled-components';
import Anchor from './Anchor';

export type AnchorTagProps = {
    display?: string
    tag: string
    href?: string
}

const AnchorTagStyled = styled.div`
    display: inline-block;
    margin-inline-end: 0.4rem;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    a {
        color: ${props => props.theme.palette.primary.main};
        display: inline-block;
        border: solid 0.2rem #e1ebe8;
        border-radius: 0.5rem;
        padding: 0.25rem 0.6rem;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            border: solid 0.2rem ${props => props.theme.palette.primary.main};
        }
    }
`;

const getFlatTagFromDisplay = tag => tag.replace(/"/g, '')
    .replace(/ \\ /g, '-')
    .replace(/ \/ /g, '-')
    .replace(/ , /g, '-')
    .replace(/ \| /g, '-')
    .replace(/\| /g, '-')
    .replace(/ - /g, '-')
    .replace(/- /g, '-')
    .replace(/, /g, '-')
    .replace(/ \//g, '-')
    .replace(/\//g, '-')
    .replace(/\\/g, '-')
    .replace(/ {2}/g, '-')
    .replace(/ /g, '-')
    .toLowerCase();

const splitSearchTextToTagsAndText = (multiTag: string) => {
    const tagRegex = /(?:\[{1,1})([^[\]]*)(?:\]{1,1})/g;
    let tags,
        searchText;

    if (multiTag) {
        tags = multiTag.match(tagRegex);

        // remove square brackets
        if (tags && tags.length > 0) {
            tags = tags.map(tag => tag.slice(1, tag.length - 1));
        }

        searchText = multiTag.replace(tagRegex, '').replace(/-/g, ' ');
    }

    return { searchText, tags };
};

export const AnchorMultiTag = (props: { multiTag: string }): JSX.Element => {
    const { searchText, tags } = splitSearchTextToTagsAndText(props.multiTag);

    let href;

    if (searchText && !tags?.length) {
        href = `/job/search/${searchText}`;
    } else if (!searchText && tags?.length > 0) {
        href = `/job/tagged/${tags.map(getFlatTagFromDisplay).join('+')}`;
    } else {
        const urlTags = getFlatTagFromDisplay(props.multiTag.replace(searchText, ''));
        href = `/job/search/${encodeURIComponent(urlTags + searchText)}`;
    }

    return <AnchorTagStyled>
        <Anchor variant="body2" text={props.multiTag.replace(/\]/g, '] ')} href={href} />
    </AnchorTagStyled>;
};

const AnchorTag = (props: AnchorTagProps): JSX.Element => <AnchorTagStyled>
    <Anchor variant="body2" text={props.tag} href={props.href || `/job/tagged/${props.display}`} />
</AnchorTagStyled>;

export default AnchorTag;
