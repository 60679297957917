import {
    Container, Grid, Hidden, Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import { JobViewModel } from '../../../types/jobs.types';
import Page from '../layout/base.page';
import { safeStringify } from '../utils/json.utils';
import SearchJobs from '../components/SearchJobs/SearchJobs';
import { getUserSearchKeys, summaryCount } from '../../api/stats.api';
import { TagViewModel } from '../../../types/tags.types';
import AnchorTag, { AnchorMultiTag } from '../foundation/AnchorTag';
import { centeredFlex, Flexbox } from '../foundation/Flex';
import PopularDomains from '../components/PopularDomains';
import LatestJobs from '../components/LatestJobs';
import AllCategories from '../components/AllCategories';
import AppDescription from '../components/AppDescription/AppDescription';
import AppNumbers from '../components/AppNumbers';
import CustomBR from '../foundation/CustomBR';
import { CompanyViewModel } from '../../../mappers/companyMapper';
import { ShadowBGContainer, WhiteBGContainer } from '../foundation/Containers';

type Counters = {
    numberOfJobs: number
    numberOfTodayJobs: number
    numberOfUsers: number
    numberOfTodayWatchedCV: number
};

export type HomePageProps = {
    url: string,
    user?: LoggedInUser
    categories: (TagViewModel & {
        totalJobs: number
    })[],
    lastKeywords: {
        displays: string
        searchKeyId: number
        tagIds: string
        tags: string
        text?: string
    }[],
    popularKeywords: {
        displays?: string
        searchKeyId: number
        tags?: string
        text?: string
        times: number
    }[],
    latestJobs: JobViewModel[],
    companiesWithLogos: CompanyViewModel[],
    jobsCriteria: {
        loc?: string
        address?: string
        lat?: number
        lng?: number
    }
}

const popularTags = [
    { display: 'התנדבות', tag: 'התנדבות' },
    { display: 'אדמיניסטרציה', tag: 'אדמיניסטרציה' },
    { display: 'תוכנה', tag: 'תוכנה' },
    { display: 'שירות-לקוחות', tag: 'שירות לקוחות' },
    { display: 'מכירות', tag: 'מכירות' },
    { display: 'עבודה-מהבית', tag: 'עבודה מהבית' },
    { display: 'נהגים-שליחים-והפצה', tag: 'נהגים שליחים והפצה' },
];

const PopularSearchStyled = styled.div`
    ${centeredFlex}
    flex-wrap: wrap;
    p {
        margin-inline-end: 0.5rem;
        font-weight: 600;
        color: ${props => props.theme.palette.secondary.main};
        display: inline;
    }
`;

const CompanyWrapper = styled(ShadowBGContainer)`
    cursor: pointer;
    border: 1px solid;
    border-color: white;
    &:hover {
        border: 1px solid ${props => props.theme.palette.primary.main};
    }
`;

const SearchJobsStyled = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    > .MuiContainer-root {
        ${props => props.theme.breakpoints.up('lg')} {
            min-width: 40rem;
        }
    }
    > div:first-child {
        position: relative;
        > img {
            margin-bottom: -24px;
        }
    }
    > div:last-child {
        position: relative;
        > img {
            margin-bottom: -24px;
        }
    }
`;

const getSearchScheme = () => safeStringify({
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: 'Mploy',
    url: 'https://www.mploy.co.il/',
    potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.mploy.co.il/job/search/{search_term_string}',
        'query-input': 'required name=search_term_string',
    },
});

const HomePage = (props: HomePageProps): JSX.Element => {
    const [counters, setCounters] = useState<Counters>(null);
    const [userSearchKeys, setUserSearchKeys] = useState<string[]>(null);

    const fetchCounters = async () => {
        const result = await summaryCount();
        setCounters(result.data as Counters);
    };
    const fetchUserLastSearchKeys = async () => {
        if (props.user) {
            const result = await getUserSearchKeys({ userId: props.user.userid });
            setUserSearchKeys(result.data);
        }
    };
    useEffect(() => {
        fetchCounters();
        fetchUserLastSearchKeys();
    }, []);

    return <Page
        showBreadCrumbs={false}
        beforeBody={<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: getSearchScheme() }} />}
        url={props.url} user={props.user}
        pageHeaderTitle="חיפוש עבודה | דרושים | עבודה | פורטל דרושים" >
        <CustomBR h={44} />
        <SearchJobsStyled>
            <Hidden lgDown>
                <div><img src="/images/people-left.png" height="372" width="660" alt="people background" /></div>
            </Hidden>
            <Container>
                <SearchJobs
                    defaultLocation={props.jobsCriteria && (props.jobsCriteria.address || props.jobsCriteria.loc)}
                    numberOfTodayJobs={counters?.numberOfTodayJobs}
                    totalJobs={counters?.numberOfJobs}
                />
            </Container>
            <Hidden lgDown>
                <div><img src="/images/people-right.png" height="372" width="660" alt="people background" /></div>
            </Hidden>
        </SearchJobsStyled>
        <CustomBR h={44} />
        {userSearchKeys?.length > 0 && <PopularSearchStyled>
            <Typography variant="body1">חיפושים אחרונים :</Typography>
            {userSearchKeys.map(searchKey => <AnchorMultiTag key={searchKey} multiTag={searchKey} />)}
        </PopularSearchStyled>}
        <PopularSearchStyled>
            <Typography variant="body1">חיפושים פופולרים :</Typography>
            {popularTags.map(pt => <AnchorTag display={pt.display} key={pt.tag} tag={pt.tag} />)}
            <AnchorTag href="/job/popular" key="popular-jobs" tag="משרות חמות" />
        </PopularSearchStyled>
        <CustomBR h={44} />
        <PopularDomains numberOfTodayJobs={counters?.numberOfTodayJobs} categories={props.categories} />
        <LatestJobs jobs={props.latestJobs} />
        <AllCategories categories={props.categories} />
        <AppDescription />
        <AppNumbers {...counters} />
        <Container>
            <WhiteBGContainer>
                <Typography textAlign="center" variant="h3" color="secondary" fontWeight="bold">מפרסמים ב-Mploy</Typography>
                <CustomBR h={30} />
                <Grid container spacing={2}>
                    {props.companiesWithLogos.map(company => {
                        const onCompanyClick = () => { window.open(`/job/company/${company.companyid}`, '_blank'); };
                        return <Grid key={company.companyid} item lg={2} md={3} sm={4}>
                            <CompanyWrapper id={company.companyid?.toString()} tabIndex={0} role="button" onClick={onCompanyClick}>
                                <Flexbox alignItems="center" justifyContent="center" height={60}>
                                    <img title={company.name}
                                        alt={company.name} width={80} src={`/assets/logos/${company.companyid?.toString().split('').join('/')}/${company.logo}`} />
                                    {/* <Typography color="secondary" fontWeight="bold">{company.name}</Typography> */}
                                </Flexbox>
                            </CompanyWrapper>
                        </Grid>;
                    })}
                </Grid>
            </WhiteBGContainer>
        </Container>
    </Page>;
};

export default HomePage;
