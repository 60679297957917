import {
    Box, Container, Grid, Hidden, Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { LoggedInUser } from '../../../types/auth.types';
import TagsFilters from '../components/TagsFilters/TagsFilters';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import Select from '../foundation/Select';
import Anchor from '../foundation/Anchor';
import Pagination from '../components/Pagination/Pagination';
import { GeoAutocomplete, LocationOption, TagsSearchAutocomplete } from '../components/AutocompleteRemote';
import { ExtendedTagViewModel, TagViewModel } from '../../../types/tags.types';
import { BGContainer } from '../foundation/Containers';
import DateFormat from '../foundation/DateFormat';
import Button from '../foundation/Button';
import TextField from '../foundation/TextField';
import { systemUserApplyToJob } from '../../api/userToJob.api';

const ROOT_TAG_LEVEL = 1;
const criteriaDefaults = {
    loc: 'תל אביב',
    distance: 20,
    showAppliedJobs: false,
    showJobsSentByEmail: true,
};

type UserSearchKey = {
    searchKey: string,
    date: string,
    address: string
}
type CandidateViewModel = {
    userid: number
    firstname?: string
    lastname?: string
    email: string
    profileEmail: string
    phone?: string
    latitude?: number
    longitude?: number
    address?: number
    hasCV?: number
    lastActive: string
    lastLogin: string
    userSearchKeys: UserSearchKey[]
}

type SearchCVPageProps = {
    url: string,
    user?: LoggedInUser,
    totalCandidates: number,
    criteria: any,
    allTags: { key: TagViewModel }
    rootTags: ExtendedTagViewModel[],
    verticalTags: ExtendedTagViewModel[],
    relatedTags: ExtendedTagViewModel[],
    tags: ExtendedTagViewModel[],
    candidates: CandidateViewModel[]
}

type SearchCVPageState = {
    pages: number
    currentPage: number
} & SearchCVPageProps

const SearchFieldsWrapperStyled = styled.div`
    padding: 2rem;
    border-radius: 10px;
    background: ${props => props.theme.palette.primary.extraLight};
    a.MuiLink-root {
        text-decoration: underline;
    }
`;
const KeyValue = styled.div`
    padding: 0.5rem;
`;

const styleWidthCauseOfAds = { width: '100%' };

type CandidateProps = {
    candidate: CandidateViewModel,
    allTags: { key: TagViewModel }
}
const Candidate = ({ candidate, allTags }: CandidateProps) => {
    const jobIdRef = useRef<HTMLInputElement>();

    const postCandidateToJob = async () => {
        try {
            const jobId = parseInt(jobIdRef.current.value, 10);
            await systemUserApplyToJob(
                { userid: candidate.userid, jobid: jobId },
            );
            window.showGlobalModal('הגשת מועמדות', `המועמד ${candidate.firstname} הופנה בהצלחה למעסיק השייך למשרה ${jobId}`);
        } catch (error) {
            window.showGlobalModal('accounted error', `${error.toString()} | ${error.response?.data}`);
        }
    };

    const renderUserSearchKeys = () => {
        const searchKeys = candidate.userSearchKeys?.split(';');

        const texts = new Set();
        const tags = new Set();
        const addresses = new Set();

        searchKeys.forEach(sk => {
            const [text, searchTags, address] = sk.split('|');
            if (text) texts.add(text);
            if (address) addresses.add(address);
            if (searchTags) searchTags?.split(',').forEach(t => tags.add(allTags[t]?.tag));
        });

        return <Box display="flex" flexWrap="wrap">
            <Box>
                <KeyValue key="1"><Typography>{[...texts].join(' | ')}</Typography></KeyValue>
                <KeyValue key="2"><Typography>{[...addresses].join(' | ')}</Typography></KeyValue>
                <KeyValue key="3"><Typography>{[...tags].join(' | ')}</Typography></KeyValue>
            </Box>
        </Box>;
    };

    return <><BGContainer key={candidate.userid}>
        <Box display="flex" flexWrap="wrap">
            <KeyValue key="firstname"><Typography><b>שם פרטי:</b> {candidate.firstname}</Typography></KeyValue>
            <KeyValue key="lastname"><Typography><b>שם משפחה:</b> {candidate.lastname}</Typography></KeyValue>
            <KeyValue key="phone"><Typography><b>טלפון:</b> {candidate.phone}</Typography></KeyValue>
            <KeyValue key="email"><Typography><b>אימייל:</b> {candidate.email}</Typography></KeyValue>
            <KeyValue key="address"><Typography><b>כתובת:</b> {candidate.address}</Typography></KeyValue>
            <KeyValue key="cv"><Anchor blank href={`/employer/authorized-watchcv/${candidate.userid}`}>קורות חיים</Anchor></KeyValue>
            <KeyValue key="lastActive">
                <Typography display="inline"><b>תאריך פעילות אחרונה: </b></Typography>
                <DateFormat date={candidate.lastActive || candidate.lastLogin} /></KeyValue>
        </Box>

        <Typography fontWeight="bold" variant="h6">חיפושים</Typography>
        {renderUserSearchKeys()}
        <CustomBR />
        <Box display="inline">
            <TextField
                inputRef={jobIdRef}
                name="username"
                label="מספר משרה"
                type="number"
                size="small"
            />
        &nbsp;&nbsp;
            <Button onClick={postCandidateToJob}>שלח מועמד למשרה</Button>
        </Box>
    </BGContainer><CustomBR /></>;
};

export default class SearchCVPage extends React.Component<SearchCVPageProps, SearchCVPageState> {
    searchTagsText: string

    searchValue: string

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            currentPage: props.criteria.page || 1,
            pages: Math.ceil(props.totalCandidates / props.criteria.pageSize),
        };
    }

    removeTag = tagItem => {
        const verticalTags = [...(this.state.verticalTags || [])];
        const relatedTags = [...(this.state.relatedTags || [])];
        const tags = this.state.tags ? this.state.tags.filter(t => t.tagid !== tagItem.tagid) : this.state.tags;
        relatedTags.forEach(t => { if (t.tagid === tagItem.tagid) t.selected = false; });
        verticalTags.forEach(t => { if (t.tagid === tagItem.tagid) t.selected = false; });
        this.setState({ tags, relatedTags, verticalTags }, this.searchCandidates);
    }

    onTagSelect = (tag: ExtendedTagViewModel) => {
        let currentTags = this.state.tags || [];
        if (tag.level === ROOT_TAG_LEVEL) currentTags = [];
        currentTags.push(tag);
        this.setState({ tags: currentTags }, this.searchCandidates);
    }

    getNoneRootTag() {
        return this.state.tags?.filter(tag => tag.level > ROOT_TAG_LEVEL) || [];
    }

    onCategorySelect = (categoryTag?: ExtendedTagViewModel) => {
        if (categoryTag?.tagid > 0) {
            this.setState({ tags: [categoryTag] }, this.searchCandidates);
        } else { // select all
            this.setState({ tags: this.getNoneRootTag() }, this.searchCandidates);
        }
    }

    getQuery() {
        let query = '';
        const { criteria } = this.state;
        const { distance } = criteria;

        if (distance !== criteriaDefaults.distance) {
            query += `&distance=${distance}`;
        }

        if (criteria.loc && criteria.loc !== criteriaDefaults.loc) {
            query += `&loc=${encodeURIComponent(criteria.loc.replace(/\s/g, '-'))}`;
        } else if (criteria.address && criteria.address !== criteriaDefaults.loc) {
            query += `&lat=${criteria.lat}&lng=${criteria.lng}&address=${encodeURIComponent(criteria.address)}`;
        }

        if (this.state.user && criteria.showAppliedJobs !== undefined
            && criteria.showAppliedJobs !== criteriaDefaults.showAppliedJobs) {
            query += `&showAppliedJobs=${criteria.showAppliedJobs}`;
        }

        if (criteria.orderby) {
            query += `&orderby=${criteria.orderby}&direction=${criteria.direction}`;
        }

        return query !== '' ? query.slice(1, query.length) : undefined;
    }

    getTagsForUrl() {
        const { tags } = this.state;
        if (!tags?.length) return '';
        if (tags.length === 1) return `[${tags[0].display}]`;
        return `[${tags.map(tagItem => tagItem.display).join('][')}]`;
    }

    searchCandidates = () => {
        window.showBackdrop();
        const query = this.getQuery();
        const queryString = query ? `?${query}` : '';

        let searchTxt = (this.searchTagsText || this.searchValue)?.trim();

        if (searchTxt && !this.state.tags?.find(t => t.tag === searchTxt)) {
            searchTxt = this.getTagsForUrl() + searchTxt.replace(/ /g, '-');
            window.location.assign(`/employer/searchcv/${encodeURIComponent(searchTxt)}${queryString}`);
        } else if (this.state.tags && this.state.tags.length > 0) {
            searchTxt = this.getTagsForUrl();
            window.location.assign(`/employer/searchcv/${encodeURIComponent(searchTxt)}${queryString}`);
        } else {
            window.location.assign(`/employer/searchcv/${queryString}`);
        }
    }

    onTagItemSelect = (ev, val: ExtendedTagViewModel | string) => {
        if (typeof val === 'string') {
            this.searchTagsText = val;
            if (ev) ev.preventDefault();
            this.searchCandidates();
            return;
        }

        if (!val.tagid) return;
        if (val.level === ROOT_TAG_LEVEL) {
            this.onCategorySelect(val);
        } else {
            this.onTagSelect(val);
        }
    }

    distanceChange = ev => {
        this.setState(
            { criteria: { ...this.state.criteria, distance: parseInt(ev.target.value, 10) } },
            this.searchCandidates,
        );
    }

    onSearchInputChange = (ev, val, reason) => this.searchValue = val;

    locationChange = (ev, val: LocationOption) => {
        const { criteria } = this.state;

        if ((val.areaid || val.cityid) && criteria.loc !== val.formatted_addres) {
            criteria.loc = val.formatted_address;
            criteria.lat = null;
            criteria.lng = null;
            criteria.address = null;
        } else if (criteria.address !== val.formatted_address && val.latitude) {
            criteria.lat = val.latitude?.toString();
            criteria.lng = val.longitude?.toString();
            criteria.address = val.formatted_address;
            criteria.loc = null;
        }

        this.setState({ criteria: { ...criteria } }, this.searchCandidates);
    }

    getDistanceOptions() {
        return [
            { id: 1, value: 2, text: '2 ק"מ' },
            { id: 2, value: 5, text: '5 ק"מ' },
            { id: 3, value: 10, text: '10 ק"מ' },
            { id: 4, value: 20, text: '20 ק"מ' },
            { id: 5, value: 50, text: '50 ק"מ' },
            { id: 6, value: 100, text: '100 ק"מ' },
            { id: 7, value: 1000, text: 'כל הארץ' },
        ];
    }

    render() {
        return (
            <Page pageHeaderTitle="Mploy - ממשק חיפוש מועמדים" url={this.props.url} user={this.props.user}>
                <Container>
                    <Grid container>
                        <Hidden mdDown>
                            <Grid item md={3}>
                                <Box paddingRight={5}>
                                    <TagsFilters
                                        tags={this.props.tags}
                                        rootTags={this.props.rootTags}
                                        verticalTags={this.props.verticalTags}
                                        relatedTags={this.props.relatedTags}
                                        removeTag={this.removeTag}
                                        onTagSelect={this.onTagSelect}
                                        onRootTagSelect={this.onTagSelect}
                                        onCategorySelect={this.onCategorySelect} />
                                </Box>
                            </Grid>
                        </Hidden>
                        <Grid item md={9} sm={12} style={styleWidthCauseOfAds}>
                            <CustomBR />
                            <SearchFieldsWrapperStyled>
                                <TagsSearchAutocomplete
                                    defaultValue={this.props.criteria.originalText}
                                    onEnter={this.searchCandidates}
                                    onInputChange={this.onSearchInputChange}
                                    onChange={this.onTagItemSelect}
                                />
                                <CustomBR />
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={12}>
                                        <GeoAutocomplete
                                            defaultValue={this.props.criteria.loc || this.props.criteria.address}
                                            onChange={this.locationChange} />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Select
                                            fullWidth
                                            defaultValue={this.state.criteria.distance}
                                            onChange={this.distanceChange}
                                            options={this.getDistanceOptions()}
                                        />
                                    </Grid>
                                </Grid>
                            </SearchFieldsWrapperStyled>
                            <CustomBR />

                            <Pagination
                                url={this.state.url}
                                pages={this.state.pages}
                                currentPage={this.state.currentPage}
                            />
                            <CustomBR />
                            {this.props.candidates.map(candidate => <Candidate allTags={this.props.allTags} candidate={candidate} key={candidate.userid} />)}
                            <Pagination
                                url={this.state.url}
                                pages={this.state.pages}
                                currentPage={this.state.currentPage}
                            />
                        </Grid>
                    </Grid>
                    <CustomBR />
                </Container>
            </Page>
        );
    }
}
