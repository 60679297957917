/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgDesigns = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.783} viewBox="0 0 27.783 26" {...props}>
    <path
      data-name="Path 238"
      d="M9.454 19.39h8.876v3.929H9.454z"
      fill="#8195a2"
    />
    <path
      data-name="Path 239"
      d="M25.255 20.573a152.376 152.376 0 01-22.721 0 1.753 1.753 0 01-1.609-1.534 67.977 67.977 0 010-16.671A1.753 1.753 0 012.534.834a152.389 152.389 0 0122.721 0 1.753 1.753 0 011.609 1.534 67.977 67.977 0 010 16.671 1.753 1.753 0 01-1.609 1.534z"
      fill="#c2ced6"
    />
    <path
      data-name="Path 241"
      d="M25.455 16.759a.633.633 0 01-.584.575q-.663.046-1.327.087-1.43.088-2.865.15-.716.031-1.431.055-1.529.052-3.055.075-1.153.018-2.3.018t-2.3-.017q-1.224-.018-2.452-.055-1.679-.051-3.359-.138-.845-.044-1.687-.1-.591-.036-1.181-.077a.632.632 0 01-.584-.575 66.61 66.61 0 01.222-14.193.112.112 0 01.1-.1c3.728-.278 7.51-.42 11.238-.42s7.509.142 11.238.42a.112.112 0 01.1.1 66.682 66.682 0 01.227 14.195z"
      fill="#f9f9fb"
    />
    <path
      data-name="Path 242"
      d="M20.235 25.584H7.549a1.135 1.135 0 010-2.27h12.686a1.135 1.135 0 010 2.27z"
      fill="#8195a2"
    />
    <path
      data-name="Path 243"
      d="M10.955 25.585H7.549a1.135 1.135 0 010-2.269h3.406a1.135 1.135 0 100 2.269z"
      fill="#6c818f"
    />
    <path
      data-name="Path 244"
      d="M23.544 9.022v8.4q-2.144.133-4.3.205V9.021l2.148-4.5z"
      fill="#12b05e"
    />
    <path
      data-name="Path 245"
      d="M20.678 9.022v8.551q-.715.031-1.431.055V9.022l.715-1.383z"
      fill="#03984b"
    />
    <path
      data-name="Path 246"
      d="M23.543 9.02l-1.074-.478-1.074.478-1.074-.478-1.074.478.764-2.581.335-1.131a1.094 1.094 0 012.1 0l.335 1.131z"
      fill="#ffc3ac"
    />
    <path
      data-name="Path 247"
      d="M21.395 4.527c-.176 0-.33.319-.38.784l-.363 3.381-.332-.148-1.074.478 1.1-3.712a1.1 1.1 0 011.049-.783z"
      fill="#eaaa89"
    />
    <path
      data-name="Path 248"
      d="M22.779 6.442h-2.768l.335-1.131a1.095 1.095 0 012.1 0z"
      fill="#3e5564"
    />
    <path
      data-name="Path 249"
      d="M21.015 5.311c.05-.464.2-.782.379-.784a1.1 1.1 0 00-1.048.784l-.335 1.131h.883z"
      fill="#3e5564"
    />
    <path
      data-name="Path 250"
      d="M9.165 16.772q0 .446-.025.877a157.151 157.151 0 01-5.046-.234 15.058 15.058 0 01-.014-.642 12.679 12.679 0 01.556-3.867l1.88-1.8 2.093 1.8a12.7 12.7 0 01.556 3.866z"
      fill="#fb6672"
    />
    <path
      data-name="Path 251"
      d="M5.959 12.905a36.959 36.959 0 00-.186 3.867q0 .376.006.739-.845-.044-1.687-.1a15.058 15.058 0 01-.014-.642 12.677 12.677 0 01.557-3.867l1.88-1.8.054.046z"
      fill="#fb4a59"
    />
    <path
      data-name="Path 252"
      d="M6.622 11.194l-.035-.09-.016.046z"
      fill="#ffac33"
    />
    <path
      data-name="Path 253"
      d="M4.636 12.905l.593-3.284L6.94 7.979l1.076 1.642.593 3.284"
      fill="#fb4a59"
    />
    <path
      data-name="Path 254"
      d="M5.96 12.905l.274-3.284H5.229l-.593 3.284z"
      fill="#fb6672"
    />
    <path
      data-name="Path 255"
      d="M8.015 9.621c.831-1.907.2-3.39-1.393-4.979-1.589 1.589-2.225 3.072-1.393 4.979"
      fill="#3e5564"
    />
    <path
      data-name="Path 256"
      d="M6.622 4.641A4.861 4.861 0 004.863 8.01a4 4 0 00.366 1.61h.772a8.427 8.427 0 01-.163-1.61 8.047 8.047 0 01.784-3.369z"
      fill="#344b5a"
    />
    <path
      data-name="Path 257"
      d="M16.192 4.586v13.116q-1.153.018-2.3.018t-2.3-.017V4.586z"
      fill="#ffcd27"
    />
    <path
      data-name="Path 258"
      d="M13.892 2.462c3.636 0 7.322.135 10.961.4.052.435.1.885.146 1.343a.412.412 0 10.819-.081 64.28 64.28 0 00-.178-1.608.524.524 0 00-.48-.457c-3.739-.279-7.53-.421-11.269-.421s-7.531.142-11.269.421a.525.525 0 00-.481.458 67.157 67.157 0 00-.224 14.282 1.041 1.041 0 00.965.948c3.661.256 7.365.385 11.008.385s7.347-.13 11.008-.386a1.041 1.041 0 00.965-.948 67.231 67.231 0 00.1-11.031.412.412 0 10-.821.061 66.406 66.406 0 01-.094 10.9.22.22 0 01-.2.2c-.3.021-.591.039-.886.058V9.023a.41.41 0 00-.017-.112l-1.1-3.712a1.506 1.506 0 00-2.888 0l-1.1 3.712a.411.411 0 00-.017.119v8.2q-1.117.035-2.232.056V4.587a.412.412 0 00-.412-.412h-4.6a.412.412 0 00-.412.412v12.7q-.8-.014-1.611-.037c0-.159.007-.319.007-.476a13.076 13.076 0 00-.568-3.967l-.567-3.14a3.958 3.958 0 00.177-2.778 6.362 6.362 0 00-1.708-2.542.412.412 0 00-.582 0 6.363 6.363 0 00-1.706 2.538 3.958 3.958 0 00.177 2.778l-.567 3.14a13.063 13.063 0 00-.567 3.965v.2c-.243-.016-.486-.031-.728-.048a.22.22 0 01-.2-.2 66.343 66.343 0 01.192-13.862c3.638-.266 7.325-.4 10.962-.4zm6.427 4.391h2.153l.423 1.43-.258-.115a.412.412 0 00-.335 0l-.907.4-.907-.4a.412.412 0 00-.335 0l-.258.115zm1.077-1.914a.687.687 0 01.655.489l.178.6h-1.666l.178-.6a.687.687 0 01.655-.489zM19.66 9.29l.662-.295.907.4a.412.412 0 00.335 0l.907-.4.663.3v7.745q-1.734.1-3.473.166V9.29zm-7.656-4.291h3.777v1.038h-1.97a.412.412 0 000 .823h1.97v.81h-1.147a.412.412 0 100 .823h1.146v.81h-1.146a.412.412 0 100 .823h1.146v.81h-1.97a.412.412 0 000 .823h1.97v.81h-1.146a.412.412 0 100 .823h1.146v.81h-1.146a.412.412 0 100 .823h1.146v.81h-1.97a.412.412 0 100 .823h1.97v.633q-.946.012-1.888.013t-1.888-.013zm-6.43 5.034h2.1l.444 2.461H5.129zm1.049-4.8c1.3 1.4 1.63 2.591 1.116 3.974H5.507c-.514-1.381-.182-2.569 1.116-3.971zM4.492 16.772a12.756 12.756 0 01.453-3.455h3.36a12.768 12.768 0 01.448 3.455c0 .149 0 .3-.007.451q-2.126-.07-4.252-.2-.002-.123-.002-.251z"
    />
    <path
      data-name="Path 259"
      d="M27.27 2.317A2.17 2.17 0 0025.283.423a153.508 153.508 0 00-22.782 0A2.169 2.169 0 00.514 2.317a68.7 68.7 0 000 16.771 2.17 2.17 0 001.987 1.895c.686.051 1.381.1 2.063.14a.412.412 0 10.051-.822c-.679-.042-1.37-.089-2.053-.14a1.344 1.344 0 01-1.231-1.173 67.88 67.88 0 010-16.57 1.344 1.344 0 011.23-1.174 152.693 152.693 0 0122.66 0 1.344 1.344 0 011.231 1.173 67.88 67.88 0 010 16.57 1.344 1.344 0 01-1.231 1.171 152.385 152.385 0 01-18.973.23.412.412 0 10-.042.822q1.415.072 2.835.116v1.577H7.549a1.547 1.547 0 000 3.093h12.686a1.547 1.547 0 100-3.093h-1.493V21.33q3.278-.1 6.541-.347a2.17 2.17 0 001.987-1.894 68.7 68.7 0 000-16.771zm-6.312 22.141a.724.724 0 01-.723.723H7.549a.723.723 0 010-1.446h12.686a.724.724 0 01.723.723zm-3.039-1.547H9.866v-1.553q2 .053 4.014.053t4.039-.053v1.554z"
    />
  </svg>
);

SvgDesigns.defaultProps = {};
export default SvgDesigns;
/* eslint-enable */
