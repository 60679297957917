import { TagSynonymViewModel } from '../../types/tags.types';
import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

export type GetTagSynonymQuery = {
    /**
     * the tag
     */
    origin: string
}

export const getTagSynonym = (query: GetTagSynonymQuery) => apiAction<TagSynonymViewModel[]>({
    method: API_METHODS.GET,
    url: '/tag-synonyms',
    query,
    backdrop: true,
});

export const deleteTagSynonym = (synonymid: number) => apiAction({
    method: API_METHODS.DELETE,
    url: `/tag-synonyms/${synonymid}`,
    backdrop: true,
});

export type CreateTagSynonymPayload = {
    synonym: string
    tagid: number
}
export const createTagSynonym = (payload: CreateTagSynonymPayload) => apiAction<TagSynonymViewModel>({
    method: API_METHODS.POST,
    payload,
    url: '/tag-synonyms',
    backdrop: true,
});

export type CreateTagPayload = {
    tag: string
    groupid: number
    parentName?: string
    level: number
    vertical?: boolean
}
export const createTag = (payload: CreateTagPayload) => apiAction({
    method: API_METHODS.POST,
    payload,
    url: '/tags',
    backdrop: true,
});

export type MergeTagsPayload = {
    oldTagId: number
    newTagId: number
}
export const mergeTags = (payload: MergeTagsPayload) => apiAction({
    method: API_METHODS.POST,
    payload,
    url: '/mergeTags',
    backdrop: true,
});
