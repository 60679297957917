/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgBuildingConstructionAndInfra = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.802} viewBox="0 0 29.802 30.199" {...props}>
    <g data-name="Building, Construction and Infra...">
      <path
        data-name="Path 419"
        d="M27.015 14.075v13.9l-10.693 1.781h-5.186L.442 27.975V10.263l10.694-5.044c2.7 0 3.4.879 3.4 2.2s-.887 4.259 1.389 4.623 11.09-.979 11.09 2.033z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 420"
        d="M11.136 22.931H2.223a1.781 1.781 0 00-1.781 1.781v3.263a1.781 1.781 0 001.781 1.782h8.913a1.781 1.781 0 001.781-1.781v-3.263a1.781 1.781 0 00-1.781-1.782z"
        fill="#12b05e"
      />
      <path
        data-name="Path 421"
        d="M3.842 29.756H2.223a1.782 1.782 0 01-1.781-1.781v-3.263a1.781 1.781 0 011.781-1.781h1.619a1.781 1.781 0 00-1.781 1.781v3.263a1.781 1.781 0 001.781 1.781z"
        fill="#03984b"
      />
      <path
        data-name="Path 422"
        d="M25.234 22.931h-8.913a1.781 1.781 0 00-1.781 1.781v3.263a1.781 1.781 0 001.781 1.781h8.913a1.781 1.781 0 001.781-1.781v-3.263a1.781 1.781 0 00-1.781-1.781z"
        fill="#12b05e"
      />
      <path
        data-name="Path 423"
        d="M17.94 29.756h-1.618a1.782 1.782 0 01-1.781-1.781v-3.263a1.781 1.781 0 011.781-1.781h1.618a1.781 1.781 0 00-1.781 1.781v3.263a1.781 1.781 0 001.781 1.781z"
        fill="#03984b"
      />
      <path
        data-name="Path 424"
        d="M27.015 14.075V20.9h-3.644a1.782 1.782 0 01-1.781-1.781v-3.263a1.781 1.781 0 011.781-1.781z"
        fill="#12b05e"
      />
      <path
        data-name="Path 425"
        d="M24.991 20.9h-1.618a1.782 1.782 0 01-1.781-1.781v-3.263a1.781 1.781 0 011.781-1.781h1.618a1.781 1.781 0 00-1.781 1.781v3.263a1.781 1.781 0 001.781 1.781z"
        fill="#03984b"
      />
      <path
        data-name="Path 426"
        d="M5.867 15.852v3.263a1.781 1.781 0 01-1.781 1.781H.442v-6.825h3.641a1.781 1.781 0 011.784 1.781z"
        fill="#12b05e"
      />
      <path
        data-name="Path 427"
        d="M11.136 5.219H2.223A1.781 1.781 0 00.442 7v3.263a1.781 1.781 0 001.781 1.781h8.913a1.781 1.781 0 001.781-1.781V7a1.781 1.781 0 00-1.781-1.781z"
        fill="#12b05e"
      />
      <path
        data-name="Path 428"
        d="M3.842 12.044H2.223a1.782 1.782 0 01-1.781-1.781V7a1.781 1.781 0 011.781-1.781h1.619A1.781 1.781 0 002.061 7v3.263a1.781 1.781 0 001.781 1.781z"
        fill="#03984b"
      />
      <path
        data-name="Path 429"
        d="M18.185 14.075H9.272a1.781 1.781 0 00-1.781 1.781v3.263A1.781 1.781 0 009.272 20.9h8.913a1.781 1.781 0 001.781-1.781v-3.263a1.781 1.781 0 00-1.781-1.781z"
        fill="#12b05e"
      />
      <path
        data-name="Path 430"
        d="M10.891 20.9H9.273a1.782 1.782 0 01-1.781-1.781v-3.263a1.781 1.781 0 011.781-1.781h1.618a1.781 1.781 0 00-1.781 1.781v3.263a1.781 1.781 0 001.781 1.781z"
        fill="#03984b"
      />
      <path
        data-name="Path 431"
        d="M28.231 3.829L19.937.566a1.781 1.781 0 00-2.31 1.005l-1.195 3.037a1.781 1.781 0 001.011 2.31l8.294 3.263a1.781 1.781 0 002.31-1.005l1.195-3.037a1.781 1.781 0 00-1.005-2.31z"
        fill="#12b05e"
      />
      <path
        data-name="Path 432"
        d="M21.415 1.148a1.781 1.781 0 00-2.309 1.005L17.912 5.19a1.781 1.781 0 001.005 2.309l-1.479-.581a1.782 1.782 0 01-1.005-2.309l1.194-3.037A1.781 1.781 0 0119.938.567z"
        fill="#03984b"
      />
      <path
        data-name="Path 433"
        d="M29.287 4.065a.442.442 0 00-.681.565 1.34 1.34 0 01.217 1.347l-1.194 3.036a1.341 1.341 0 01-1.736.756l-8.294-3.263a1.338 1.338 0 01-.755-1.736l1.194-3.036a1.339 1.339 0 011.736-.756l7.47 2.939a.442.442 0 10.324-.823L20.098.156a2.224 2.224 0 00-2.887 1.255l-1.19 3.036a2.223 2.223 0 001.255 2.883l8.294 3.263a2.224 2.224 0 002.883-1.255L29.647 6.3a2.225 2.225 0 00-.36-2.236z"
      />
      <path
        data-name="Path 434"
        d="M7.049 15.857v3.263a2.226 2.226 0 002.224 2.224h.734a.443.443 0 000-.885h-.734a1.34 1.34 0 01-1.339-1.339v-3.263a1.34 1.34 0 011.339-1.339h8.912a1.34 1.34 0 011.339 1.339v3.263a1.34 1.34 0 01-1.339 1.339h-6.412a.443.443 0 100 .885h6.409a2.226 2.226 0 002.224-2.224v-3.263a2.226 2.226 0 00-2.224-2.224H9.273a2.226 2.226 0 00-2.224 2.224z"
      />
      <path
        data-name="Path 435"
        d="M18.92 11.642a20.911 20.911 0 01-2.921-.034 1.23 1.23 0 01-1.111-1.068 7.039 7.039 0 01.019-2.127c.039-.363.076-.706.076-.992 0-1.8-1.222-2.644-3.847-2.644H2.223A2.226 2.226 0 000 7v20.975a2.226 2.226 0 002.223 2.224h23.014a2.226 2.226 0 002.224-2.223v-13.9a1.737 1.737 0 00-.547-1.284c-1.351-1.315-5.034-1.223-7.994-1.15zm7.653 8.816h-3.2a1.34 1.34 0 01-1.339-1.339v-3.263a1.34 1.34 0 011.339-1.339h3.2v5.94zM14.098 7.421c0 .239-.034.559-.071.9a7.784 7.784 0 000 2.413 2.114 2.114 0 001.832 1.749 20.988 20.988 0 003.083.045c2.646-.066 6.27-.156 7.351.9a.987.987 0 01.165.208h-3.087a2.226 2.226 0 00-2.224 2.223v3.263a2.226 2.226 0 002.224 2.223h3.2v1.6a2.212 2.212 0 00-1.339-.45h-8.91a2.226 2.226 0 00-2.224 2.223v3.263a2.212 2.212 0 00.45 1.339H12.91a2.212 2.212 0 00.45-1.339v-3.263a2.226 2.226 0 00-2.223-2.229H2.223a2.212 2.212 0 00-1.339.45v-1.6h3.2a2.226 2.226 0 002.224-2.223v-3.26a2.226 2.226 0 00-2.221-2.223h-3.2v-1.6a2.211 2.211 0 001.339.45h8.911a2.226 2.226 0 002.224-2.223V7a2.209 2.209 0 00-.285-1.088A1.386 1.386 0 0114.1 7.421zm-13.213 7.1h3.2a1.34 1.34 0 011.339 1.339v3.263a1.34 1.34 0 01-1.337 1.335h-3.2zm0-7.517a1.34 1.34 0 011.339-1.339h8.913a1.34 1.34 0 011.339 1.339v3.259a1.34 1.34 0 01-1.339 1.339H2.223a1.34 1.34 0 01-1.338-1.339zm0 20.975v-3.263a1.34 1.34 0 011.338-1.343h8.914a1.34 1.34 0 011.339 1.339v3.263a1.34 1.34 0 01-1.339 1.339H2.223a1.34 1.34 0 01-1.338-1.339zm24.352 1.335h-8.915a1.34 1.34 0 01-1.339-1.339v-3.263a1.34 1.34 0 011.339-1.339h8.915a1.34 1.34 0 011.339 1.339v3.263a1.34 1.34 0 01-1.339 1.339z"
      />
    </g>
  </svg>
);

SvgBuildingConstructionAndInfra.defaultProps = {};
export default SvgBuildingConstructionAndInfra;
/* eslint-enable */
