/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgProcurementAndLogistics = (props: SVGProps<SVGSVGElement>) => (
  <svg width={26.25} viewBox="0 0 26.25 32.079" {...props}>
    <g data-name="Procurement and Logistics">
      <g data-name="Group 319">
        <g data-name="Group 318">
          <path
            data-name="Path 970"
            d="M24.642 17.903l-.14-3.166-.2-3.975h-5.158l.151 3.2.051.774.14 3.166.106 1.6 5.251 1.441z"
            fill="#f7f3e2"
          />
          <path
            data-name="Path 971"
            d="M19.439 16.848h5.156l-.093-2.11h-5.156z"
            fill="#12b05e"
          />
          <g data-name="Group 305">
            <path
              data-name="Path 972"
              d="M24.662 10.343v.841a.459.459 0 01-.459.459h-4.781l-.051-.774-.065-.985h4.9a.459.459 0 01.456.459z"
              fill="#919196"
            />
          </g>
          <path
            data-name="Path 973"
            d="M24.204 10.658h-4.781l-.051-.774h-.065l.065.985.051.774h4.781a.459.459 0 00.459-.459v-.841a.452.452 0 00-.006-.072.458.458 0 01-.453.387z"
            fill="#7c7c82"
          />
          <g data-name="Group 306">
            <path
              data-name="Path 974"
              d="M1.951 10.763l-.2 3.975-.14 3.166-.2 3.045 4.9-1.441.106-1.6.14-3.166.051-.774.151-3.2z"
              fill="#f7f3e2"
            />
          </g>
          <path
            data-name="Path 975"
            d="M6.459 16.848l.093-2.11h-4.8l-.093 2.11z"
            fill="#12b05e"
          />
          <g data-name="Group 307">
            <path
              data-name="Path 976"
              d="M6.874 9.884l-.065.985-.051.774H2.047a.459.459 0 01-.459-.459v-.841a.459.459 0 01.459-.459z"
              fill="#919196"
            />
          </g>
          <path
            data-name="Path 977"
            d="M6.757 10.658h-4.71a.458.458 0 01-.452-.387.463.463 0 00-.006.072v.841a.459.459 0 00.459.459h4.711l.051-.774.065-.985H6.81z"
            fill="#7c7c82"
          />
          <path
            data-name="Path 978"
            d="M6.383 6.824L6.241 8.97l-.065.985-.051.774v.035l-.151 3.2-.051.774-.14 3.166-.106 1.6 4.447-1.336.171-3.643.037-.557.1-2.468.1-1.542.014-.217.139-2.11.053-.809z"
            fill="#eae1c3"
          />
          <g data-name="Group 308">
            <path
              data-name="Path 979"
              d="M10.365 5.945l-.116 1.759h-3.77a.459.459 0 01-.459-.459v-.841a.459.459 0 01.459-.459z"
              fill="#919196"
            />
          </g>
          <path
            data-name="Path 980"
            d="M10.3 5.945a.828.828 0 01-.827.774H6.479a.458.458 0 01-.452-.387.463.463 0 00-.006.072v.841a.459.459 0 00.459.459h3.77l.116-1.759z"
            fill="#7c7c82"
          />
          <path
            data-name="Path 981"
            d="M6.117 10.869l-.146 3.1h4.358l.1-2.462.042-.633z"
            fill="#12b05e"
          />
          <path
            data-name="Path 982"
            d="M20.577 19.507l-.106-1.6-.14-3.166-.051-.774-.151-3.2v-.035l-.051-.774-.065-.985-.146-2.149H15.23l.053.809.139 2.11.014.217.1 1.542.1 2.468.037.557.171 3.643z"
            fill="#eae1c3"
          />
          <g data-name="Group 309">
            <path
              data-name="Path 983"
              d="M20.231 6.404v.841a.459.459 0 01-.459.459H15.58l-.116-1.759h4.308a.459.459 0 01.459.459z"
              fill="#919196"
            />
          </g>
          <path
            data-name="Path 984"
            d="M19.772 6.719H15.58l-.051-.774h-.065l.116 1.759h4.192a.459.459 0 00.459-.459v-.841a.456.456 0 00-.006-.072.458.458 0 01-.453.387z"
            fill="#7c7c82"
          />
          <path
            data-name="Path 985"
            d="M15.497 10.869l.042.633.1 2.462h4.639l-.146-3.1z"
            fill="#12b05e"
          />
          <path
            data-name="Path 986"
            d="M16.451 14.527l-.037-.557-.1-2.468-.1-1.542-.014-.217-.139-2.11-.053-.809v-.035l-.116-1.759-.246-3.623h-5.043l-.239 3.623-.116 1.759v.035l-.053.809-.139 2.11-.014.217-.1 1.542-.1 2.468-.037.557-.171 3.643 3.5-1.181 3.5 1.181z"
            fill="#f7f3e2"
          />
          <g data-name="Group 310">
            <path
              data-name="Path 987"
              d="M12.138 14.527l.037-.557.1-2.468.1-1.542.014-.217.139-2.11.053-.809v-.035l.116-1.759.241-3.623H10.6l-.239 3.623-.116 1.759v.035l-.053.809-.139 2.11-.014.217-.1 1.542-.1 2.468-.037.557-.171 3.643 2.376-.8z"
              fill="#ede4c5"
            />
          </g>
          <path
            data-name="Path 996"
            d="M24.844 19.893l-4.266-1.441-3.955-1.336-3.498-1.182-3.5 1.181-3.955 1.336-4.266 1.441-.6.2a.319.319 0 00-.206.384l.525 1.97 2.244 8.414a.927.927 0 00.9.688h17.711a.927.927 0 00.9-.688l2.244-8.414.525-1.97a.319.319 0 00-.206-.384z"
            fill="#12b05e"
          />
          <path
            data-name="Path 997"
            d="M15.497 29.371H6.641a.927.927 0 01-.9-.688l-2.244-8.414-.265-.993-1.829.618-.6.2a.319.319 0 00-.206.384l.525 1.97 2.244 8.414a.927.927 0 00.9.688h17.711a.927.927 0 00.9-.688l.4-1.493z"
            fill="#03984b"
          />
          <g data-name="Group 315">
            <path
              data-name="Path 998"
              d="M25.646 20.479l-.525 1.97-12-4.053v-2.462l3.5 1.181 3.955 1.336 4.266 1.441.6.2a.319.319 0 01.204.387z"
              fill="#fdc202"
            />
          </g>
          <g data-name="Group 316">
            <path
              data-name="Path 999"
              d="M25.125 22.449l-2.244 8.414a.927.927 0 01-.9.688h-8.856V18.396z"
              fill="#12b05e"
            />
          </g>
          <path
            data-name="Path 1000"
            d="M15.496 29.371h-2.371v2.181h8.856a.926.926 0 00.5-.149l.061-.043a.926.926 0 00.16-.157.916.916 0 00.119-.193.929.929 0 00.052-.146l.4-1.493z"
            fill="#03984b"
          />
          <g data-name="Group 317">
            <path
              data-name="Path 1001"
              d="M13.125 15.934v2.462l-12 4.053-.525-1.97a.319.319 0 01.206-.384l.6-.2 4.266-1.441 3.955-1.336z"
              fill="#fdc202"
            />
          </g>
          <path
            data-name="Path 1002"
            d="M3.502 20.268l-.265-.993-1.829.618-.6.2a.319.319 0 00-.206.384l.525 1.97 2.709-.915z"
            fill="#e2ad00"
          />
        </g>
      </g>
      <g data-name="Group 320">
        <path
          data-name="Path 1003"
          d="M25.892 19.69l-.545-.184-.5-7.574a.984.984 0 00.345-.748v-.841a.988.988 0 00-.986-.987h-3.7l-.09-1.364a.984.984 0 00.345-.748V6.4a.988.988 0 00-.987-.987H16.38l-.187-2.841a.984.984 0 00.345-.748V.983A.988.988 0 0015.551 0H10.7a.988.988 0 00-.987.987v.841a.984.984 0 00.345.748l-.187 2.841H6.479a.988.988 0 00-.987.987v.841a.984.984 0 00.345.748l-.09 1.364h-3.7a.988.988 0 00-.987.987v.841a.984.984 0 00.345.748l-.5 7.574-.545.184a.528.528 0 00-.341.636L2.865 31a1.456 1.456 0 001.405 1.08h17.711A1.456 1.456 0 0023.386 31l2.846-10.674a.528.528 0 00-.34-.636zm-24.726.841l11.433-3.863v1.348l-11.1 3.751zM6.252 17.7l.212-3.207h2.807l-.148 2.237zm4.435-8.624h4.876l.07 1.055h-5.015zm5.88-.809h2.804l.137 2.075h-2.803zm-1.074-.246h-4.736l.341-5.171h4.054zm-5.947 2.322H6.74l.137-2.075h2.806zm-.07 1.055l-.135 2.04H6.535l.136-2.038zm.955 1.548h.9a.528.528 0 000-1.055h-.83l.046-.7h5.154l.046.7h-2.305a.528.528 0 000 1.055h2.377L15.888 14h-5.525zm6.34-1.546h2.81l.135 2.04h-2.807zm4.121 3.869h3.114l.07 1.055h-3.111zm-.07-1.055l-.133-2h3.114l.133 2zm-15.266-2l-.133 2H2.312l.133-2zm-3.316 3.06h3.113l-.07 1.055H2.171zm11.052.168a.528.528 0 00-.338 0l-2.75.929.086-1.309h5.665l.086 1.309zm3.832 1.294l-.148-2.237h2.808l.212 3.207zm3.954 1.336l-.046-.689h3.114l.117 1.765zm3.055-7.653v.7H20.62l-.047-.7zm-4.432-3.94v.7h-3.205l-.046-.7zm-8.93-5.424h4.713v.7h-4.713zM6.55 6.472h3.254l-.046.7h-3.21zm-4.432 3.94H5.68l-.047.7H2.116zm-.013 6.965h3.114l-.046.689-3.185 1.076zm1.782 13.352l-2.116-7.938 10.83-3.659v11.892h-8.33a.4.4 0 01-.387-.296zm18.482 0a.4.4 0 01-.385.3h-8.331V19.131l5.924 2a.528.528 0 00.338-1l-6.261-2.115v-1.347l11.433 3.863-.33 1.237-2.841-.96a.528.528 0 10-.338 1l2.906.982z"
        />
      </g>
    </g>
  </svg>
);

SvgProcurementAndLogistics.defaultProps = {};
export default SvgProcurementAndLogistics;
/* eslint-enable */
