import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
    export interface CommonColors {
        yellowText: string // React.CSSProperties['color'],
    }
    export interface SimplePaletteColorOptions {
        extraLight?: string // React.CSSProperties['color'],
    }
}

// Create a theme instance.
const theme = responsiveFontSizes(createTheme({
    breakpoints: {
        values: {
            xs: 320,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    direction: 'rtl',
    typography: {
        fontFamily: 'Open Sans Hebrew',
        h1: {
            fontSize: '2.2rem',
            fontWeight: 600,
        },
    },
    palette: {
        common: {
            yellowText: '#FFC400',
        },
        primary: {
            extraLight: '#eef6f2',
            light: '#12B05E',
            main: '#00843f',
        },
        secondary: {
            main: '#10334b',
            dark: '#051a29',
        },
        background: {
            default: '#fff',
        },
    },
}));

// theme.typography.h4[theme.breakpoints.down('sm')] = {
//     fontSize: '1.8rem',
// };

// theme.typography.h1[theme.breakpoints.down('sm')] = {
//     fontSize: '1.8rem',
// };

// theme.typography.h3[theme.breakpoints.down('sm')] = {
//     fontSize: '2.5rem',
// };

export default theme;
