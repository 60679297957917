import { PureComponent } from 'react';
import dayjs from 'dayjs';
import Plot from '../Plot';
import { getDates } from '../../utils/dates.utils';

export type PlotDatesProps = {
    startDate: Date,
    endDate: Date,
    /**
     * x format yyyy-mm-dd
     */
    scatter: any,
    layout?: any,
    style?: any,
}
export default class PlotDates extends PureComponent<PlotDatesProps> {
    render() {
        const { startDate, endDate, scatter } = this.props;
        const { x, y } = scatter;
        const xaxis = getDates(startDate, endDate).map(d => dayjs(d).format('YYYY-MM-DD'));
        const yaxis = xaxis.map(() => 0);

        for (let i = 0; i < x.length; i += 1) {
            const date = x[i];
            yaxis[xaxis.indexOf(date)] = y[i];
        }

        return <Plot
            data={[
                {
                    fill: 'tozeroy',
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: { color: 'green' },
                    x: xaxis,
                    y: yaxis,
                },
            ]}
            layout={{
                height: 300,
                ...(this.props.layout || {}),
            }}
            style={this.props.style}
        />;
    }
}
