import { Typography } from '@mui/material';
import { memo } from 'react';
import SwiperCore, {
    Pagination, A11y, Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { JobViewModel } from '../../../../types/jobs.types';
import Anchor from '../../foundation/Anchor';
import Button from '../../foundation/Button/Button';
import { centeredFlex } from '../../foundation/Flex';
import CustomBR from '../../foundation/CustomBR';

export type LatestJobsProps = {
    jobs: JobViewModel[]
}

const LatestJobsStyled = styled.div`
    background-color: ${props => props.theme.palette.secondary.main};
    ${props => props.theme.breakpoints.up('md')} {
        padding: 5rem;
    }

    h3.MuiTypography-root {
        font-weight: bold;
        text-align: center;
    }
    .MuiContainer-root {
    }

    .swiper-container {
        padding: 0 0 3rem 0;
        .swiper-pagination-bullet {
            background: white;
            opacity: 0.8;
            &.swiper-pagination-bullet-active {
                border: solid 3px #ffcd27;
                background-color: ${props => props.theme.palette.secondary.main};
                width: 14px;
                height: 14px;
            }
        }

        .swiper-pagination-bullets {
            ${centeredFlex}
        }
    }
`;

const LatestJobItemStyled = styled.div`
    background-color: white;
    border-radius: 10px;
    height: 16rem;
    ${props => props.theme.breakpoints.up('sm')} {
        height: 18rem;
    }
    padding: 2rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    h5 {
        font-weight: 600;
    }
    a:hover {
        text-decoration: none;
    }
    button {
        margin-top: auto;
        align-self: flex-start;
    }
    &:hover {
        background-color: #e3f8ed;
    }
`;

const TEXT_MAX_LENGTH = 200;

// install Swiper modules
SwiperCore.use([Pagination, A11y, Autoplay]);

const LatestJobs = (props: LatestJobsProps): JSX.Element => <LatestJobsStyled>
    <Typography variant="h3" color="white">משרות דרושים אחרונות</Typography>
    <CustomBR times={2} />
    <Swiper
        spaceBetween={5}
        a11y={{ enabled: true }}
        slidesPerView={2}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        autoplay={{ delay: 5000 }}
        breakpoints={{
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            960: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1280: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        }}
    >
        {props.jobs?.map(job => <SwiperSlide key={job.jobid}>
            <LatestJobItemStyled>
                <Anchor href={`/job/details/${job.jobid}`}>
                    <Typography variant="h5" color="secondary">{job.title}</Typography>
                </Anchor>
                <CustomBR />
                <Typography title={job.description} color="body2">{job.description.length > TEXT_MAX_LENGTH
                    ? `${job.description.slice(0, TEXT_MAX_LENGTH)}...` : job.description}</Typography>
                <CustomBR />
                <Button onClick={() => window.location.assign(`/job/details/${job.jobid}`)}
                    color="secondary" size="small">פרטים מלאים</Button>
            </LatestJobItemStyled>
        </SwiperSlide>)}
    </Swiper>
</LatestJobsStyled>;

export default memo(LatestJobs);
