/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgArtEntertainmentAndMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.833} viewBox="0 0 28.833 24.169" {...props}>
    <g data-name="Art, Entertainment and Media">
      <g data-name="Group 322">
        <path
          data-name="Path 1004"
          d="M28.404 2.159V22.01a1.731 1.731 0 01-1.731 1.731H2.158A1.73 1.73 0 01.425 22.01V2.159A1.73 1.73 0 012.155.428h24.519a1.731 1.731 0 011.73 1.731z"
          fill="#e5e9ea"
        />
        <path
          data-name="Path 1005"
          d="M28.404 2.159V5.11H.425V2.159A1.73 1.73 0 012.155.428h24.519a1.731 1.731 0 011.73 1.731z"
          fill="#ffcd27"
        />
        <path
          data-name="Rectangle 167"
          fill="#12b05e"
          d="M2.712 7.116h23.409v14.341H2.712z"
        />
        <g data-name="Group 321" transform="translate(2.312 1.684)">
          <circle
            data-name="Ellipse 70"
            cx={1.085}
            cy={1.085}
            r={1.085}
            fill="#fa3f68"
          />
          <circle
            data-name="Ellipse 71"
            cx={1.085}
            cy={1.085}
            r={1.085}
            transform="translate(6.794)"
            fill="#47c97f"
          />
          <circle
            data-name="Ellipse 72"
            cx={1.085}
            cy={1.085}
            r={1.085}
            transform="translate(3.397)"
            fill="#fcb65f"
          />
        </g>
        <path
          data-name="Rectangle 168"
          fill="#d1d1d1"
          d="M9.563 19.522H26.12v1.935H9.563z"
        />
        <path
          data-name="Rectangle 169"
          fill="#566368"
          d="M2.712 19.522h7.936v1.935H2.712z"
        />
        <path
          data-name="Path 1006"
          d="M17.258 12.845l-4.232-2.443a1 1 0 00-1.508.871v4.886a1 1 0 001.508.871l4.232-2.443a1 1 0 000-1.742z"
          fill="#fff"
        />
      </g>
      <g data-name="Group 323">
        <path
          data-name="Path 1007"
          d="M26.674 0H2.158A2.161 2.161 0 000 2.154v15.047a.428.428 0 00.856 0V5.538h27.12V22.01a1.3 1.3 0 01-1.3 1.3H2.158a1.3 1.3 0 01-1.3-1.3v-3.368a.428.428 0 00-.856 0v3.368a2.161 2.161 0 002.158 2.159h24.514a2.161 2.161 0 002.159-2.159V2.154A2.161 2.161 0 0026.674 0zM.857 4.682V2.154a1.3 1.3 0 011.3-1.3h24.517a1.3 1.3 0 011.3 1.3v2.523z"
        />
        <path
          data-name="Path 1008"
          d="M3.397 1.256A1.513 1.513 0 104.91 2.769a1.515 1.515 0 00-1.513-1.513zm0 2.17a.657.657 0 11.657-.657.657.657 0 01-.657.657z"
        />
        <path
          data-name="Path 1009"
          d="M10.192 1.256a1.513 1.513 0 101.513 1.513 1.515 1.515 0 00-1.513-1.513zm0 2.17a.657.657 0 11.657-.657.657.657 0 01-.657.657z"
        />
        <path
          data-name="Path 1010"
          d="M6.794 1.256a1.513 1.513 0 101.513 1.513 1.515 1.515 0 00-1.513-1.513zm0 2.17a.657.657 0 11.657-.657.657.657 0 01-.657.657z"
        />
        <path
          data-name="Path 1011"
          d="M13.24 10.031a1.433 1.433 0 00-2.15 1.241v4.886a1.43 1.43 0 002.15 1.241l4.232-2.443a1.433 1.433 0 000-2.483zm3.8 4.184l-4.232 2.443a.577.577 0 01-.866-.5v-4.886a.576.576 0 01.866-.5l4.232 2.443a.577.577 0 010 1z"
        />
        <path
          data-name="Path 1012"
          d="M26.121 10.37a.428.428 0 00.428-.428V7.116a.428.428 0 00-.428-.428H2.712a.428.428 0 00-.428.428v14.341a.428.428 0 00.428.428h23.409a.428.428 0 00.428-.428V11.37a.428.428 0 10-.856 0v7.724H3.14V7.544h22.553v2.4a.428.428 0 00.428.426zm-.428 10.658H11.081V19.95h14.612zM10.22 19.95v1.078H3.14V19.95z"
        />
      </g>
    </g>
  </svg>
);

SvgArtEntertainmentAndMedia.defaultProps = {};
export default SvgArtEntertainmentAndMedia;
/* eslint-enable */
