/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgUpToDateJobsFromToday = (props: SVGProps<SVGSVGElement>) => (
  <svg width={43.816} viewBox="0 0 43.816 53.998" {...props}>
    <g data-name="Up-to-date jobs from today">
      <g data-name="Group 103">
        <g data-name="Group 101">
          <g data-name="Group 100">
            <path
              data-name="Path 322"
              d="M36.091 48.592H3.111a2.319 2.319 0 01-2.319-2.319V3.11A2.319 2.319 0 013.111.792h32.98a2.319 2.319 0 012.319 2.319v43.162a2.319 2.319 0 01-2.319 2.319z"
              fill="#12b05e"
            />
            <path
              data-name="Path 323"
              d="M36.091.791h-6.678a2.319 2.319 0 012.319 2.319v43.163a2.319 2.319 0 01-2.319 2.319h6.678a2.319 2.319 0 002.319-2.319V3.11A2.32 2.32 0 0036.091.791z"
              fill="#03984b"
            />
            <path
              data-name="Path 324"
              d="M40.706 53.208H7.725a2.319 2.319 0 01-2.319-2.32V7.725a2.319 2.319 0 012.319-2.319h32.981a2.319 2.319 0 012.32 2.319v43.163a2.32 2.32 0 01-2.32 2.32z"
              fill="#f4fbff"
            />
            <path
              data-name="Path 325"
              d="M40.705 5.406h-6.686a2.32 2.32 0 012.319 2.319v43.163a2.32 2.32 0 01-2.319 2.32h6.686a2.319 2.319 0 002.319-2.32V7.725a2.32 2.32 0 00-2.319-2.319z"
              fill="#e4f6ff"
            />
          </g>
        </g>
        <g data-name="Group 102" transform="translate(9.99 10.443)">
          <path
            data-name="Path 326"
            d="M9.95 13.577H1.631a1.631 1.631 0 01-1.627-1.631V1.631A1.631 1.631 0 011.635 0h8.319a1.631 1.631 0 011.631 1.631v10.316a1.631 1.631 0 01-1.635 1.63z"
            fill="#f4fbff"
          />
          <path
            data-name="Path 327"
            d="M5.79 8.368a6.088 6.088 0 00-5.727 4.024 1.63 1.63 0 001.567 1.185h8.319a1.63 1.63 0 001.567-1.185A6.088 6.088 0 005.79 8.368z"
            fill="#f4fbff"
          />
          <circle
            data-name="Ellipse 36"
            cx={2.597}
            cy={2.597}
            r={2.597}
            transform="translate(3.193 3.786)"
            fill="#ffcd27"
          />
        </g>
      </g>
      <g data-name="Group 104">
        <path
          data-name="Path 328"
          d="M37.548 28.965H10.054a.791.791 0 000 1.582h27.494a.791.791 0 100-1.582z"
        />
        <path
          data-name="Path 329"
          d="M37.548 34.061H10.054a.791.791 0 000 1.582h27.494a.791.791 0 100-1.582z"
        />
        <path
          data-name="Path 330"
          d="M37.548 39.158H10.054a.791.791 0 100 1.582h27.494a.791.791 0 100-1.582z"
        />
        <path
          data-name="Path 331"
          d="M37.548 44.254H10.054a.791.791 0 000 1.582h27.494a.791.791 0 100-1.582z"
        />
        <path
          data-name="Path 332"
          d="M37.548 18.528h-10.1a.791.791 0 000 1.582h10.1a.791.791 0 100-1.582z"
        />
        <path
          data-name="Path 333"
          d="M37.548 14.35h-10.1a.791.791 0 000 1.582h10.1a.791.791 0 100-1.582z"
        />
        <path
          data-name="Path 334"
          d="M43.028 43.987a.791.791 0 00.791-.791V7.725a3.114 3.114 0 00-3.11-3.11h-1.5V3.11A3.114 3.114 0 0036.091 0H3.11A3.114 3.114 0 000 3.11v43.163a3.114 3.114 0 003.11 3.11h1.5v1.5A3.114 3.114 0 007.728 54h32.978a3.114 3.114 0 003.11-3.11v-3.996a.791.791 0 00-1.582 0v3.994a1.53 1.53 0 01-1.529 1.529H7.728a1.53 1.53 0 01-1.529-1.529V7.725A1.53 1.53 0 017.728 6.2h32.978a1.53 1.53 0 011.529 1.529V43.2a.791.791 0 00.793.787zM4.615 7.725V47.8h-1.5a1.53 1.53 0 01-1.529-1.529V3.11a1.53 1.53 0 011.529-1.529h32.976A1.53 1.53 0 0137.62 3.11v1.5H7.728a3.114 3.114 0 00-3.11 3.11z"
        />
        <path
          data-name="Path 335"
          d="M9.99 15.624a.791.791 0 00-.791.791v5.973a2.415 2.415 0 002.158 2.407c.3.033 8.823 0 8.836 0h.009a2.415 2.415 0 002.16-2.407V12.073a2.424 2.424 0 00-2.422-2.422h-8.319a2.424 2.424 0 00-2.422 2.422v.619a.791.791 0 001.582 0v-.619a.841.841 0 01.84-.84h8.319a.841.841 0 01.84.84v8.1a6.845 6.845 0 00-2.139-1.53 3.388 3.388 0 10-5.721 0 6.843 6.843 0 00-2.139 1.53v-3.758a.791.791 0 00-.791-.791zm5.79-.606a1.807 1.807 0 11-1.806 1.806 1.808 1.808 0 011.806-1.805zm-1.554 4.815a3.373 3.373 0 003.109 0 5.275 5.275 0 013.32 3 .835.835 0 01-.625.393c-.051 0-8.361.012-8.481 0h-.017a.835.835 0 01-.625-.393 5.276 5.276 0 013.319-2.995z"
        />
      </g>
    </g>
  </svg>
);

SvgUpToDateJobsFromToday.defaultProps = {};
export default SvgUpToDateJobsFromToday;
/* eslint-enable */
