export const getParameterByName = (name, url?: string) => {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const buildQueryString = obj => new URLSearchParams(obj).toString();

export const tryNavigateToRedirectUrl = (defaultUrl?: string) => {
    const redirectUrl = getParameterByName('redirect');
    if (redirectUrl && redirectUrl !== '') {
        window.location.assign(redirectUrl);
    } else if (defaultUrl) window.location.assign(defaultUrl);
    else window.location.reload();
};

export const updateQueryStringParameter = (key: string, value: string, url?: string) => {
    if (!url) {
        url = window.location.href;
    }
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = url.indexOf('?') !== -1 ? '&' : '?';
    if (url.match(re)) {
        return url.replace(re, `$1${key}=${value}$2`);
    }
    return `${url + separator + key}=${value}`;
};

export const removeURLParameter = (parameter, url?: string) => {
    if (!url) {
        url = window.location.href;
    }
    // prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
        const prefix = `${encodeURIComponent(parameter)}=`;
        const pars = urlparts[1].split(/[&;]/g);

        // reverse iteration as may be destructive
        for (let i = pars.length - 1; i >= 0; i -= 1) {
            // idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
    }
    return url;
};
