import {
    Box,
    Container, Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { CompanyViewModel } from '../../../mappers/companyMapper';
import { LoggedInUser } from '../../../types/auth.types';
import { uploadCompanyLogo } from '../../api/companies.api';
import { CompanyAutocomplete } from '../components/AutocompleteRemote';
import Button from '../foundation/Button';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type AdminCompaniesProps = {
    url: string
    user?: LoggedInUser
}

const AdminCompanies = (props: AdminCompaniesProps): JSX.Element => {
    const [company, setCompany] = useState<Partial<CompanyViewModel>>();
    const companyChange = (ev, val) => { setCompany(val); };
    const companyInputChange = (ev, val) => { setCompany({ name: val }); };
    const [file, setFile] = useState<File>(null);
    const fileEl = useRef<HTMLInputElement>(null);

    const uploadFile = async () => {
        try {
            const formData = new FormData();
            window.showBackdrop();
            formData.append('file', file);
            await uploadCompanyLogo({ companyId: company.companyid }, formData);
        } catch (error) {
            window.showGlobalModal('אירעה שגיאה', error.message);
        } finally {
            window.hideBackdrop();
            setFile(null);
            fileEl.current.value = '';
        }
    };

    useEffect(() => {
        if (file) uploadFile();
    }, [file]);

    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - דרושים - ניהול חברות" >
        <Container>
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול חברות</Typography>
            <CustomBR />
            <CompanyAutocomplete
                onInputChange={companyInputChange}
                onChange={companyChange} />
            {company?.companyid && <div>
                <CustomBR />
                <Typography color="secondary" fontWeight="bold" variant="h4">ניהול החברה</Typography>
                <CustomBR />
                <Typography variant="h6">שם החברה: {company.name}</Typography>
                <Typography variant="h6">האם החברה היא כח אדם: {company.hrCompany ? 'yes' : 'no'}</Typography>
                <Typography variant="h6">מזהה חברה: {company.companyid}</Typography>
                <Typography variant="h6">שם קובץ לוגו: {company.logo}</Typography>
                <CustomBR />
                <CustomBR />
                <div>
                    <input onChange={ev => { setFile(ev.target.files?.[0]); }} hidden ref={fileEl} type="file" id="cv-file" aria-describedby="העלאת קובץ" />
                    <div><Box display="flex"><Button
                        color="secondary" onClick={() => { fileEl.current.click(); }}>הוספת לוגו</Button>
                    </Box>
                    </div>
                    <CustomBR />
                    {file && <div><Typography color="secondary" variant="body1">הקובץ {file.name} נשמר במערכת ברגעים אלו...</Typography></div>}
                    <CustomBR />
                </div>
            </div>}
        </Container>
        <CustomBR />
    </Page>;
};

export default AdminCompanies;
