import styled from 'styled-components';
import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { TagViewModel } from '../../../../types/tags.types';
import mapCatsToSvg from './mapCatsToSvg';
import { colCenteredFlex, vCenteredFlex } from '../../foundation/Flex';
import CustomBR from '../../foundation/CustomBR';

export type AllCategoriesProps = {
    categories: (TagViewModel & {
        totalJobs: number
    })[],
}

const CategoryItemStyled = styled.div`
    border: 1px solid #e6eaec;
    border-radius: 0.6rem;
    height: 4rem;
    background-color: white;
    padding: 1rem;
    ${vCenteredFlex}
    &:hover {
        cursor: pointer;
        border: 1px solid ${props => props.theme.palette.primary.main};
    }
    svg {
        margin-inline-end: 1rem;
    }
    > div {
        > p:first-child {
            font-weight: bold;
        }
    }
`;
const AllCategoriesStyled = styled.div`
    background-color:#fafafb;
    .MuiContainer-root {
        ${colCenteredFlex}
        > h3 {
            font-weight: bold;
        }
    }
`;

const AllCategories = (props: AllCategoriesProps): JSX.Element => <AllCategoriesStyled>
    <Container id="all-categories">
        <CustomBR h={44} />
        <Typography color="secondary" variant="h3">כל התחומים</Typography>
        <CustomBR h={44} />
        <Grid container spacing={2}>
            {props.categories?.map(cat => {
                const onClick = () => window.location.assign(`/job/tagged/${cat.display}`);
                const Icon = mapCatsToSvg[cat.tagid];
                if (!Icon) {
                    console.log('error for cat ', cat.tagid);
                    return null;
                }
                return <Grid item lg={3} md={4} key={cat.tagid}>
                    <CategoryItemStyled
                        role="button"
                        tabIndex={0}
                        onKeyUp={onClick}
                        onClick={onClick}>
                        <div><Icon /></div>
                        <div>
                            <Typography variant="body1" color="secondary">{cat.tag}</Typography>
                            <Typography variant="body2" color="primary">{cat.totalJobs} משרות</Typography>
                        </div>
                    </CategoryItemStyled>
                </Grid>;
            })}
        </Grid>
        <CustomBR h={44} />
    </Container>
</AllCategoriesStyled>;

export default React.memo(AllCategories);
