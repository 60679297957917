import React from 'react';
import styled, { css } from 'styled-components';

const BodyStyled = styled.div<{ bg?: 'main' }>`
    ${({ bg }) => bg && css`
        background: #f9f9fb; 
        border-top: solid 1px #10334b1a;
    `};
`;

export type BodyProps = {
    bg?: 'main'
}
export default class Body extends React.Component<BodyProps> {
    render() {
        return (
            <BodyStyled bg={this.props.bg}>
                {this.props.children}
            </BodyStyled>
        );
    }
}
