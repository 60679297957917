import {
    Container, Grid, Typography,
} from '@mui/material';
import {
    Download, InsertEmoticon as InsertEmoticonIcon,
    SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
    CancelPresentation as CancelPresentationIcon,
} from '@mui/icons-material';
import React from 'react';

import config from '../../../config/config';
import { LoggedInUser, UserProfileViewModel } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import { CenteredFlex, VCenteredFlex } from '../foundation/Flex';
import { BGContainer, ShadowBGContainer } from '../foundation/Containers';
import TawkTo from '../components/TawkTo';

export type WatchCVPageProps = {
    url: string
    user?: LoggedInUser
    userAppliedId: number
    jobAppliedId: number
    safeid: string
    hasCV: boolean
    isImage: boolean
    candidate: UserProfileViewModel
    candidateMessage?: string
}

export default class WatchCVPage extends React.Component<WatchCVPageProps> {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
    }

    iframeRef: React.RefObject<HTMLIFrameElement>

    iframeTimeoutId: number

    iframeLoaded = () => {
        clearInterval(this.iframeTimeoutId);
    }

    getDownloadLink() {
        return `${config.baseUrl}employer/cv/${this.props.userAppliedId}/job/${this.props.jobAppliedId}?safeid=${this.props.safeid}`;
    }

    updateIframeSrc = () => {
        if (this.iframeRef?.current) {
            this.iframeRef.current.src = `https://docs.google.com/gview?url=${this.getDownloadLink()}&embedded=true`;
        }
    }

    componentDidMount() {
        this.iframeTimeoutId = window.setInterval(
            this.updateIframeSrc, 1000 * 3,
        );
    }

    getCV() {
        if (this.props.hasCV) {
            if (this.props.isImage) {
                return (
                    <img alt="קורות חיים" ref="img" src={this.getDownloadLink()} style={{ width: '100%' }} />
                );
            }
            return (
                <iframe title="קורות החיים" onLoad={this.iframeLoaded}
                    onError={this.updateIframeSrc} ref="iframe"
                    style={{ width: '100%', height: '700px' }}
                    src={`https://docs.google.com/gview?url=${this.getDownloadLink()}&embedded=true`} />
            );
        }

        return '';
    }

    renderKeyValue(key, value) {
        if (value && value !== '') {
            return <Typography><b>{key} : </b>{value}</Typography>;
        }
        return '';
    }

    renderDontHaveCV() {
        if (!this.props.hasCV) {
            return <div><CustomBR /> <Typography>
                    המשתמש הגיש מועמדות למשרה זו, אך לא הגיש קובץ קורות חיים.
            </Typography></div>;
        }

        return '';
    }

    renderCandidateDetails() {
        if (!this.props.candidate) return '';

        return <><CustomBR /><BGContainer>
            <Typography>להלן פרטי המועמד איתם ניתן ליצור קשר:</Typography>
            <CustomBR />
            {this.renderKeyValue('שם הפונה למשרה', this.props.candidate.firstname || `${this.props.candidate.lastname}` || '')}
            {this.renderKeyValue('מספר טלפון', this.props.candidate.phone)}
            {this.renderKeyValue('כתובת אימייל', this.props.candidate.email)}
            {this.renderKeyValue('הודעה מהמועמד', this.props.candidateMessage)}
            {this.renderDontHaveCV()}
        </BGContainer></>;
    }

    renderWantMoreCandidates() {
        return (
            <div>
                <h4>מעוניינים במועמדים איכותיים נוספים ?</h4>
                <h6 style={{ lineHeight: '1.7rem' }}>
                    בדיוק בשביל זה אנחנו פה - הצטרפו לפלטפורמת דרושים חדשנית.
                    <br />
                    לפרטים נוספים צרו איתנו קשר במייל : <a href={`mailto:${config.contact.email}`}>{config.contact.email}</a>
                </h6>
            </div>
        );
    }

    renderJobItem() {
        return <><CustomBR /><Anchor href={`/job/details/${this.props.jobAppliedId}`} blank>
                    לחץ כאן לצפייה במשרה המתאימה המופיעה באתר
        </Anchor></>;
    }

    renderDownloadCV() {
        if (!this.props.hasCV) return '';

        return <div>
            <CustomBR />
            <Anchor blank href={this.getDownloadLink()}>
                <VCenteredFlex><Download /> לחץ כאן להורדת קובץ קורות החיים </VCenteredFlex>
            </Anchor>
            <CustomBR />
        </div>;
    }

    renderApplicationResponse() {
        const { candidate, jobAppliedId, safeid } = this.props;
        return (
            <div>
                <Typography><b>חשוב! עדכנו את המועמד בקליק</b> - לחצו על האופציה המתאימה ושלחו עדכון מיידי למועמד :</Typography>
                <CustomBR />
                <CustomBR />
                <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${candidate.userid}/job/${jobAppliedId}?safeid=${safeid}&response=2`}>
                                <CenteredFlex><InsertEmoticonIcon />&nbsp;אני אצור קשר עם המועמד בקרוב</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${candidate.userid}/job/${jobAppliedId}?safeid=${safeid}&response=3`}>
                                <CenteredFlex><SentimentVeryDissatisfiedIcon />&nbsp;לצערי, המועמד אינו מתאים למשרה</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${candidate.userid}/job/${jobAppliedId}?safeid=${safeid}&response=4`}>
                                <CenteredFlex><CancelPresentationIcon />&nbsp;המשרה אוישה</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        return (
            <Page pageHeaderTitle="Mploy - צפייה במועמדות למשרה" url={this.props.url} user={this.props.user}>
                <TawkTo />
                <Container>
                    <CustomBR />
                    <CenteredFlex>
                        <Typography color="secondary" fontWeight="bold" variant="h4">התקבלה מועמדות חדשה למשרה שלך</Typography>
                    </CenteredFlex>
                    <CustomBR />
                    {this.renderApplicationResponse()}
                    {this.renderJobItem()}
                    {this.renderCandidateDetails()}
                    {this.renderDownloadCV()}
                    <div style={{ textAlign: 'center' }}>
                        {this.getCV()}
                    </div>
                </Container>
            </Page>
        );
    }
}
