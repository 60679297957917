import {
    Container, Divider, Grid, Typography,
} from '@mui/material';
import * as React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import RegisterSmartAgent from '../containers/RegisterSmartAgent';
import Alert from '../foundation/Alert';
import Anchor from '../foundation/Anchor';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type RegisterSmartAgentPageProps = {
    children: JSX.Element | JSX.Element[]
    url: string,
    user?: LoggedInUser
}

const RegisterSmartAgentPage = (props: RegisterSmartAgentPageProps): JSX.Element => {
    const [completedData, setCompletedData] = React.useState<any>(null);
    return <Page
        user={props.user}
        showBreadCrumbs={false}
        url={props.url}
        pageHeaderTitle="Mploy - סוכן משרות דרושים חכם" >
        <Container>
            <div>
                <CustomBR />
                <Typography color="secondary" fontWeight="bold" variant="h3">רק ב Mploy - עדכון מיידי למייל על משרות רלוונטיות שפורסמו בפייסבוק!</Typography>
                <CustomBR />
                <Divider />
                <CustomBR />
                <Typography variant="body1">
                    <span>בהרשמה ל-Mploy תוכלו להצטרף לסוכן חכם שלנו ולקבל עדכונים במייל אודות משרות רלוונטיות שפורסמו בפייסבוק או ישירות אצלנו. </span>
                    <span>המנוע של Mploy סורק בכל 3 שעות יותר מ-400 קבוצות דרושים בפייסבוק. המשרות מפולחות לתחומים, תפקידים, מיקום ונתונים נוספים. </span>
                    <span>את המשרות הרלוונטיות אליכם תקבלו ישירות למייל.</span>
                </Typography>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    {completedData ? <><CustomBR /><CustomBR /><Alert severity="success"><>
                    ברכות נרשמת בהצלחה!
אנו נדאג לשלוח לך עדכונים במייל אודות משרות רלוונטיות באיזור: {completedData.userSettings.formatted_address} במרחק: {completedData.distance} ק"מ.
תחומים ותפקידים שנרשמת אליהם : {completedData.registeredTags.map(t => t.tag).join(', ')}
                        <CustomBR />
                        <Anchor text="הינך מוזמן להמשיך לגלוש באתר" href="/" />
                    </></Alert></> : <>
                        <CustomBR />
                        <CustomBR />
                        <Typography color="secondary" fontWeight="bold" variant="h4">הצטרפות ל-Mploy</Typography>
                        <CustomBR />
                        <RegisterSmartAgent
                            onSmartAgentRegistrationComplete={(userSettings, registeredTags, distance) => {
                                setCompletedData({ userSettings, registeredTags, distance });
                            }} />
                        <CustomBR />
                    </>}
                </Grid>
                <Grid display="flex" alignItems="center" justifyContent="center" item xs={12} md={6}>
                    <img
                        style={{
                            maxWidth: '95vw',
                        }}
                        src="/images/smart-agent.gif"
                        title="סוכן משרות חכם"
                        alt="סוכן משרות חכם"
                    />
                </Grid>
            </Grid>
            <CustomBR />
        </Container>
    </Page>;
};

export default RegisterSmartAgentPage;
