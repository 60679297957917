import { Container, Typography } from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import Button from '../foundation/Button/Button';

export type NoPermissionPageProps = {
    url: string
    user?: LoggedInUser
    message?: string,
    htmlMessage?: string
}

export default class NoPermissionPage extends React.Component<NoPermissionPageProps> {
    loginPopupClick(ev) {
        ev.preventDefault();
        window.modals.toggleLoginModal();
    }

    renderNoPremissions() {
        if (this.props.user) {
            return (
                <Typography variant="body1">
                    אין לך די הרשאות לצפות בעמוד זה, או שהגעת מקישור שפג תוקפו.
                </Typography>
            );
        }

        return (
            <>
                <Typography variant="body1">
                    אין לך די הרשאות לצפות בעמוד זה, או שהגעת מקישור שפג תוקפו.
                    <br />
                    <br />
                כמו כן, נראה כי אינך מחובר, נסה לבצע <Anchor href="#" onClick={this.loginPopupClick} text="התחברות" /> תחילה.
                </Typography>
                <br />
                <Button onClick={this.loginPopupClick} fullWidth>התחברות</Button>
            </>
        );
    }

    render() {
        return (
            <Page
                showBreadCrumbs={false}
                url={this.props.url}
                user={this.props.user}
                pageHeaderTitle="Mploy - אין הרשאות" >
                <Container>
                    <CustomBR />
                    <BGContainer>
                        <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - אין הרשאות</Typography>
                        <CustomBR />
                        <WhiteBGContainer>
                            {this.renderNoPremissions()}
                        </WhiteBGContainer>
                    </BGContainer>
                    <CustomBR />
                </Container>
            </Page>
        );
    }
}
