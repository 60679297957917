/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width={49.543} viewBox="0 0 49.543 49.537" {...props}>
    <g fill="#11af5d">
      <path
        data-name="Path 2545"
        d="M44.039 0H5.51A5.51 5.51 0 00.004 5.5v11.013h5.5V5.5h38.535v38.533H5.51V33.025H.004v11.008a5.51 5.51 0 005.5 5.5h38.535a5.51 5.51 0 005.5-5.5V5.5a5.511 5.511 0 00-5.5-5.5z"
      />
      <path
        data-name="Path 2546"
        d="M22.017 35.777l13.76-11.008-13.76-11.009v8.259H0v5.5h22.017z"
      />
    </g>
  </svg>
);

SvgLogout.defaultProps = {};
export default SvgLogout;
/* eslint-enable */
