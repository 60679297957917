import {
    Container, Divider, Grid, Hidden, IconButton, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EventAvailableOutlined as EventAvailableOutlinedIcon, DeleteForever } from '@mui/icons-material';
import { LoggedInUser } from '../../../types/auth.types';
import { SavedJobViewModel } from '../../../types/jobs.types';
import { getUserSavedJobs, removeUserSavedJob } from '../../api/userToJob.api';
import Pagination from '../components/Pagination/Pagination';
import Anchor from '../foundation/Anchor';
import { ShadowBGContainer, YellowBox } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import DateFormat from '../foundation/DateFormat';
import { CenteredFlex, Flexbox, VCenteredFlex } from '../foundation/Flex';
import Page from '../layout/base.page';
import Button from '../foundation/Button';

export type UserSavedJobsProps = {
    url: string
    user: LoggedInUser
    savedJobs: SavedJobViewModel[],
    totalSavedJobs: number
}

const SavedJobItemStyled = styled.div`
`;

const pageSize = 10;

const direction = 'DESC';
const orderby = 'created_at';

const UserSavedJobs = (props: UserSavedJobsProps) => {
    const [savedJobs, setSavedJobs] = useState<SavedJobViewModel[]>(props.savedJobs);
    const [totalSavedJobs, setTotalSavedJobs] = useState<number>(props.totalSavedJobs);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const onDeleteJobClick = async jobid => {
        await removeUserSavedJob({ userid: props.user.userid, jobid }, true);
        setSavedJobs(savedJobs.filter(savedJob => savedJob.job.jobid !== jobid));
        setTotalSavedJobs(totalSavedJobs - 1);
    };
    const onGoToPage = async page => {
        const res = await getUserSavedJobs(
            { userid: props.user.userid },
            {
                start: (page - 1) * pageSize,
                orderby,
                direction,
            },
            true,
        );

        window.scroll({ top: 0, left: 0 });
        setSavedJobs(res.data.savedJobs);
        setTotalSavedJobs(res.data.totalSavedJobs);
        setCurrentPage(page);
    };
    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - המשרות השמורות שלי" >
        <Container>
            <CustomBR />
            <CenteredFlex>
                <Typography color="secondary" fontWeight="bold" variant="h4">סה"כ משרות שמורות</Typography>
            </CenteredFlex>
            <CustomBR />
            <CenteredFlex>
                <YellowBox>
                    <Typography variant="h6" fontWeight="bold">
                        {totalSavedJobs}
                    </Typography>
                </YellowBox>
            </CenteredFlex>
            <CustomBR />
            {totalSavedJobs === 0 && <Typography>לא נמצאו במערכת משרות שמורות</Typography>}
            {totalSavedJobs > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                gotoPage={onGoToPage}
                url={props.url}
                pages={Math.ceil(totalSavedJobs / pageSize)}
                currentPage={currentPage}
            /></Flexbox><CustomBR /></>}
            {savedJobs?.map(savedJob => <div key={savedJob.job.jobid}><SavedJobItemStyled>
                <ShadowBGContainer>
                    <Grid container>
                        <Grid item xs={12} sm={10}>
                            <div><Anchor fontWeight="bold" color="secondary" blank href={`/job/details/${savedJob.job.jobid}`} text={savedJob.job.title} /></div>
                            <CustomBR h={10} />
                            <div><Typography style={{ opacity: 0.8 }} color="secondary" variant="body2">
                                {`${savedJob.job.description}\n${savedJob.job.requirement || ''}`}
                            </Typography></div>
                            <CustomBR />
                            <VCenteredFlex>
                                <EventAvailableOutlinedIcon color="secondary" />
                                <Typography color="secondary" fontWeight="bold" display="inline">שמרת משרה זו בתאריך:&nbsp;</Typography>
                                <DateFormat date={savedJob.createdAt} showTime />
                            </VCenteredFlex>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item sm={1}><Divider orientation="vertical" /></Grid>
                        </Hidden>
                        <Grid item xs={12} sm={1}>
                            <CustomBR />
                            <CenteredFlex>
                                <Hidden smDown>
                                    <IconButton onClick={() => onDeleteJobClick(savedJob.job.jobid)} title="הסר משרה מהשמורים שלי">
                                        <DeleteForever fontSize="medium" color="error" />
                                    </IconButton>
                                </Hidden>
                                <Hidden smUp>
                                    <Button fullWidth color="secondary" onClick={() => onDeleteJobClick(savedJob.job.jobid)}>
                                        <><DeleteForever fontSize="medium" color="inherit" /> הסר משרה מהשמורים שלי</>
                                    </Button>
                                </Hidden>
                            </CenteredFlex>
                        </Grid>
                    </Grid>
                </ShadowBGContainer>
            </SavedJobItemStyled><CustomBR /></div>)}
            {totalSavedJobs > 10 && <><Flexbox flexDirection="row-reverse"><Pagination
                gotoPage={onGoToPage}
                url={props.url}
                pages={Math.ceil(totalSavedJobs / pageSize)}
                currentPage={currentPage}
            /></Flexbox><CustomBR /></>}
        </Container>
        <CustomBR />
    </Page>;
};

export default UserSavedJobs;
