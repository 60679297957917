import {
    Container, Grid, Hidden, Typography,
} from '@mui/material';
import styled from 'styled-components';
import { memo } from 'react';
import Anchor from '../../foundation/Anchor';
import CustomBR from '../../foundation/CustomBR';
import { colCenteredFlex } from '../../foundation/Flex';
import SvgFreeJobPosting from '../../svg/generated/components/FreeJobPosting';
import SvgHotJobFromFacebook from '../../svg/generated/components/HotJobFromFacebook';
import SvgQualityApp from '../../svg/generated/components/QualityApp';
import SvgStatusAtAnyMoment from '../../svg/generated/components/StatusAtAnyMoment';

export type AppDescriptionProps = {
}

const EllipsisStyle = styled.div`
    width: 23px;
    height: 23px;
    border: solid 4px #ffd83d;
    background-color: #ffffff;
    border-radius: 100%;
    padding-inline-end: 0.8rem;
    margin-inline-end: 0.8rem;
`;

const DescriptionItemStyled = styled.div`
    margin: 1rem;
    background-color: white;
    width: 25rem;
    ${props => props.theme.breakpoints.down('sm')} {
        width: initial;
    }
    color: ${props => props.theme.palette.secondary.main};
    border-radius: 1rem;
    padding: 1.5rem;
    > h4 {
        font-weight: bold;
        margin-bottom: 1rem;
        display: flex;
        align-items: baseline;
    }
    > p {
        opacity: 0.6;
    }
    &:hover {
        ${EllipsisStyle} {
            border: solid 4px ${props => props.theme.palette.primary.main};
        }
        background-color: #e3f8ed;
    }
`;

const DescriptionItemsStyled = styled.div`
    width: 100%;
    z-index: 2;
    ${props => props.theme.breakpoints.up('md')} {
        > div {
            display: flex;
            justify-content: space-between;
        }
    }
    ${props => props.theme.breakpoints.down('md')} {
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    ${props => props.theme.breakpoints.up('lg')} {
        > div:first-child {
            ${DescriptionItemStyled} {
                margin-inline-end: 15rem;
                margin-inline-start: 4rem;
            }
        }
        > div:last-child {
            ${DescriptionItemStyled} {
                margin-inline-end: 9rem;
                margin-inline-start: 11rem;
            }
        }
    }
`;

const AppDescriptionStyled = styled.div`
    ${colCenteredFlex}
    background-color:#fafafb;
    z-index: 0;
    position: relative;
    color: white;
    > h3 {
        font-weight: bold;
        text-align: center;
    }
    > p {
        text-align: center;
        opacity: 0.6;
    }
    > div > img {
        position: absolute;
        z-index: -1;
        top: 48rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const DownloadLinksWrapperStyled = styled.div`
    text-align: center;
    > a {
        margin-inline-end: 2rem;
    }
`;

const AppDescriptionBG = styled.div`
    position: absolute;
    background-color: ${props => props.theme.palette.secondary.main};
    height: 55rem;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
`;

const GoodReasonsStyled = styled.div`
    margin-top: 5rem;
    width: 100%;
    h3, h5 {
        font-weight: bold;
    }
    p {
        opacity: 0.6;
    }
`;

const GoodReasonItemStyled = styled.div`
    padding: 2rem;
    height: 22rem;
    border-radius: 20px;
    box-shadow: 0 23px 26px 0 #b5b5c91a;
    background: white;
`;

const SmallHR = styled.div`
    width: 2rem;
    border-top: 0.3rem solid ${props => props.theme.palette.primary.main};
    margin: 1rem 0;
`;

const AppDescriptionDownloadLinks = (): JSX.Element => <>
    <Hidden lgDown>
        <div>
            <img src="/images/phone-mploy.png" alt="phone mploy" />
        </div>
    </Hidden>
    <CustomBR times={3} />
    <Typography variant="h3">Mploy - חיפוש עבודה גם באפליקציה</Typography>
    <CustomBR />
    <Typography variant="body1">
    ניתן לצפות בכל המשרות דרושים באתר ישירות מהמכשיר הנייד באפליקציה קלה ופשוטה לשימוש
    </Typography>
    <CustomBR times={2} />
    <DownloadLinksWrapperStyled>
        <Anchor blank href="https://play.google.com/store/apps/details?id=com.chikichak.phone">
            <img width="201.5" height="60" src="images/icon-google-play.png" alt="download mploy android app" />
        </Anchor>
        {/* <Anchor blank
    // eslint-disable-next-line max-len
    href="https://itunes.apple.com/us/app/chikichak-%D7%97%D7%99%D7%A4%D7%95%D7%A9-%D7%A2%D7%91%D7%95%D7%93%D7%94-%D7%93%D7%A8%D7%95%D7%A9%D7%99%D7%9D/id1201748100?ls=1&mt=8">
    <img width="201.5" height="60" src="images/icon-app-store_eng.svg" alt="download mploy iphone app" />
</Anchor> */}
    </DownloadLinksWrapperStyled>
    <CustomBR times={4} />
    <DescriptionItemsStyled>
        <div>
            <DescriptionItemStyled>
                <Typography variant="h4"><EllipsisStyle />חוויה לחפש עבודה</Typography>
                <Typography variant="body1">
            האפליקציה קלה ופשוטה לשימוש, ומבוססת על כרטיסיות.
            זריקה ימינה של כרטיסיה, והגשתם מועמדות למשרה.
            אפשר לראות באפליקציה את כל המשרות אליהן פניתם, והאם המעסיק צפה בפנייה שלכם.
                </Typography>
            </DescriptionItemStyled>
            <DescriptionItemStyled>
                <Typography variant="h4"><EllipsisStyle />משרות מרשתות חברתיות</Typography>
                <Typography variant="body1">
            נמאס לעבור על משרות דרושים לא רלוונטיות ברשתות חברתיות?
            הורידו את האפליקציה,
            ותוכלו להתחיל לסנן בקלות את המשרות מרשתות חברתיות לפי מיקום, תחומים ומילות חיפוש.
                </Typography>
            </DescriptionItemStyled>
        </div>
        <CustomBR times={3} />
        <div>
            <DescriptionItemStyled>
                <Typography variant="h4"><EllipsisStyle />לוח דרושים בשקיפות מלאה</Typography>
                <Typography variant="body1">
            באמצעות האפליקציה ניתן לצפות בקלות במשרות אליהן הגשתם מועמדות,
            לראות האם המעסיק קיבל את הפנייה, האם צפה בפנייה שלכם והאם ייצור איתכם קשר בקרוב.
                </Typography>
            </DescriptionItemStyled>
            <DescriptionItemStyled>
                <Typography variant="h4"><EllipsisStyle />פורטל דרושים מכל מקום וכל זמן</Typography>
                <Typography variant="body1">
            באפליקציה ניתן להגדיר את המיקום ממנו אתם רוצים לחפש עבודה,
            וכמה רחוק מהבית אתם מוכנים לעבוד, בקלות ובפשטות תוכלו לחפש עבודה מכל מקום ובכל זמן.
                </Typography>
            </DescriptionItemStyled>
        </div>
    </DescriptionItemsStyled>
</>;
const AppDescription = (): JSX.Element => <AppDescriptionStyled>
    <AppDescriptionBG />
    {/* <AppDescriptionDownloadLinks /> */}
    {/* <CustomBR times={5} /> */}
    <GoodReasonsStyled>
        <Container>
            <Typography variant="h3" color="secondary">רק אצלנו ב-Mploy</Typography>
            <CustomBR />
            <Typography variant="body1" color="secondary">
            הנה כמה סיבות שיסבירו לכם למה הכי כיף, קל ושווה לחפש אצלנו עבודה
            </Typography>
            <CustomBR times={3} />
            <Grid container spacing={2}>
                <Grid lg={3} md={6} item><GoodReasonItemStyled>
                    <SvgQualityApp />
                    <Typography variant="h5" color="secondary">משרות בוואטסאפ</Typography>
                    <SmallHR />
                    <Typography variant="body1" color="secondary">
                        אנחנו יודעים שהזמן שלך חשוב.
                        לכן יצרנו עבורכם קבוצות בוואטסאפ לחיפוש עבודה בצורה נגישה ומהירה.
                    </Typography>
                    <Anchor blank href="https://chat.whatsapp.com/FdTeLEGHXkX0qt942bG6GR">הצטרפו אלינו בוואטסאפ</Anchor>
                </GoodReasonItemStyled></Grid>
                <Grid lg={3} md={6} item><GoodReasonItemStyled>
                    <SvgStatusAtAnyMoment />
                    <Typography variant="h5" color="secondary">סטטוס בכל רגע</Typography>
                    <SmallHR />
                    <Typography variant="body1" color="secondary">
                        בכל רגע נתון בתהליך חיפוש העבודה, תוכלו לראות עבור כל משרה אליה פניתם,
                        האם הקורות חיים שלכם הגיעו למעסיק, האם הם נבדקו ועוד.
                    </Typography>
                </GoodReasonItemStyled></Grid>
                <Grid lg={3} md={6} item><GoodReasonItemStyled>
                    <SvgFreeJobPosting />
                    <Typography variant="h5" color="secondary">פרסום משרות</Typography>
                    <SmallHR />
                    <Typography variant="body1" color="secondary">
                        מעבר לכך שאנו דואגים לאסוף אלפי משרות מהרשת, אצלנו מעסיקים יכולים לפרסם משרות בקלות.
                        ולכן המאגר משרות אצלנו הוא מקיף וייחודי.
                    </Typography>
                </GoodReasonItemStyled></Grid>
                <Grid lg={3} md={6} item><GoodReasonItemStyled>
                    <SvgHotJobFromFacebook />
                    <Typography variant="h5" color="secondary">משרות חמות מהפייסבוק</Typography>
                    <SmallHR />
                    <Typography variant="body1" color="secondary">
                        רק אצלנו יש הכי הרבה משרות עדכניות.
                        בכל 3 שעות האלגוריתם שלנו אוסף משרות מ 400 קבוצות בפייסבוק ומרכז לכם הכל במקום אחד.&nbsp;
                        <Anchor href="/qsa">הרשמה מהירה לסוכן חכם שלנו</Anchor>
                    </Typography>
                </GoodReasonItemStyled></Grid>
            </Grid>
            <CustomBR times={3} />
        </Container>
    </GoodReasonsStyled>
</AppDescriptionStyled>;

export default memo(AppDescription);
