/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgImportAndExport = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.106} viewBox="0 0 28.106 29.855" {...props}>
    <g data-name="Import and Export">
      <g data-name="Group 276">
        <path
          data-name="Path 858"
          d="M25.508 6.132L14.676.586a1.361 1.361 0 00-1.241 0L2.604 6.132a1.361 1.361 0 00-.741 1.212v20.05h24.386V7.344a1.361 1.361 0 00-.741-1.212z"
          fill="#12b05e"
        />
        <path
          data-name="Path 859"
          d="M5.182 8.656a1.361 1.361 0 01.736-1.212l10.836-5.548a1.359 1.359 0 01.278-.105L14.677.586a1.361 1.361 0 00-1.241 0L2.604 6.132a1.361 1.361 0 00-.741 1.212v20.05h3.319z"
          fill="#009b4b"
        />
        <path
          data-name="Path 860"
          d="M22.388 10.389H5.681l-.6.46v3.891l.6.721h4.9v12.333h12.012l.442-.4V10.849z"
          fill="#ffcd27"
        />
        <g data-name="Group 270">
          <path
            data-name="Path 861"
            d="M7.876 10.849l.646-.46H5.681l-.6.46v3.891l.6.721h2.195z"
            fill="#ebb400"
          />
        </g>
        <path
          data-name="Path 862"
          d="M23.478 10.539v-1.4a.31.31 0 00-.31-.31H4.945a.31.31 0 00-.31.31v1.4a.31.31 0 00.31.31h18.223a.31.31 0 00.31-.31z"
          fill="#dbedff"
        />
        <path
          data-name="Path 863"
          d="M7.55 10.539v-1.4a.31.31 0 01.31-.31H4.944a.31.31 0 00-.31.31v1.4a.31.31 0 00.31.31H7.86a.31.31 0 01-.31-.31z"
          fill="#b5dcff"
        />
        <path
          data-name="Path 864"
          d="M20.072 21.067h-2.7l-.257.254H15.5l-.254-.254h-2.7a.455.455 0 00-.455.455v5.417a.455.455 0 00.455.455h7.525a.455.455 0 00.455-.455v-5.417a.455.455 0 00-.454-.455z"
          fill="#00d2fc"
        />
        <path
          data-name="Path 865"
          d="M11.638 21.067h-2.7l-.392.206H7.081l-.269-.206H4.117a.455.455 0 00-.455.455v5.417a.455.455 0 00.455.455h7.525a.455.455 0 00.455-.455v-5.417a.455.455 0 00-.459-.455z"
          fill="#6ed6a2"
        />
        <path
          data-name="Path 866"
          d="M11.638 14.741h-2.7l-.334.2H6.993l-.182-.2H4.117a.455.455 0 00-.455.455v5.417a.455.455 0 00.455.455h7.525a.455.455 0 00.455-.455v-5.417a.455.455 0 00-.459-.455z"
          fill="#ffc365"
        />
        <g data-name="Group 271">
          <path
            data-name="Path 867"
            d="M13.799 26.939v-5.417a.455.455 0 01.455-.455h-1.706a.455.455 0 00-.455.455v5.417a.455.455 0 00.455.455h1.706a.455.455 0 01-.455-.455z"
            fill="#00c2fb"
          />
        </g>
        <g data-name="Group 272">
          <path
            data-name="Path 868"
            d="M5.365 26.939v-5.417a.455.455 0 01.455-.455H4.117a.455.455 0 00-.455.455v5.417a.455.455 0 00.455.455h1.7a.455.455 0 01-.452-.455z"
            fill="#00c479"
          />
        </g>
        <g data-name="Group 273">
          <path
            data-name="Path 869"
            d="M5.365 20.613v-5.417a.455.455 0 01.455-.455H4.117a.455.455 0 00-.455.455v5.417a.455.455 0 00.455.455h1.7a.455.455 0 01-.452-.455z"
            fill="#ffa90f"
          />
        </g>
        <path
          data-name="Path 870"
          d="M11.23 16.939l3.235-2.317a.3.3 0 01.47.241v1.094a.213.213 0 00.213.213h3.927a.31.31 0 01.31.31v1.4a.31.31 0 01-.31.31h-3.93a.213.213 0 00-.213.213v1.094a.3.3 0 01-.47.241l-3.235-2.317a.3.3 0 01.003-.482z"
          fill="#f27182"
        />
        <g data-name="Group 274" fill="#ed5469">
          <path
            data-name="Path 871"
            d="M14.465 18.65l-2.812-2.014-.423.3a.3.3 0 000 .483l3.235 2.317a.3.3 0 00.47-.241v-1.089a.3.3 0 01-.47.244z"
          />
          <path
            data-name="Path 872"
            d="M19.075 17.102h-3.927a.213.213 0 00-.213.213v1.088a.213.213 0 01.213-.213h3.927a.31.31 0 00.31-.31v-1.089a.31.31 0 01-.31.311z"
          />
        </g>
        <g data-name="Group 275" fill="#f27182">
          <path
            data-name="Path 873"
            d="M15.246 21.068v3a.28.28 0 00.28.28h1.567a.28.28 0 00.28-.28v-3z"
          />
          <path
            data-name="Path 874"
            d="M6.812 21.068v3a.28.28 0 00.28.28h1.569a.28.28 0 00.28-.28v-3z"
          />
          <path
            data-name="Path 875"
            d="M6.812 14.741v3a.28.28 0 00.28.28h1.569a.28.28 0 00.28-.28v-3z"
          />
        </g>
        <path
          data-name="Path 876"
          d="M16.31 24.067v-3h-1.064v3a.28.28 0 00.28.28h1.067a.28.28 0 01-.283-.28z"
          fill="#ed5469"
        />
        <path
          data-name="Path 877"
          d="M7.876 24.067v-3H6.812v3a.28.28 0 00.28.28h1.064a.28.28 0 01-.28-.28z"
          fill="#ed5469"
        />
        <path
          data-name="Path 878"
          d="M7.876 17.741v-3H6.812v3a.28.28 0 00.28.28h1.064a.28.28 0 01-.28-.28z"
          fill="#ed5469"
        />
        <path
          data-name="Path 879"
          d="M27.362 27.336H.75a.31.31 0 00-.31.31v1.459a.31.31 0 00.31.31h26.612a.31.31 0 00.31-.31v-1.462a.31.31 0 00-.31-.307z"
          fill="#938493"
        />
        <path
          data-name="Path 880"
          d="M5.77 29.105v-1.463a.31.31 0 01.31-.31H.75a.31.31 0 00-.31.31v1.459a.31.31 0 00.31.31h5.33a.31.31 0 01-.31-.306z"
          fill="#7b6c79"
        />
      </g>
      <g data-name="Group 277">
        <path
          data-name="Path 881"
          d="M.747 29.855h26.612a.748.748 0 00.747-.747v-1.4a.748.748 0 00-.747-.747h-.676V7.347a1.791 1.791 0 00-.979-1.6L14.873.2a1.8 1.8 0 00-1.64 0L2.403 5.746a1.791 1.791 0 00-.979 1.6v19.613H.748a.748.748 0 00-.747.747v1.4a.748.748 0 00.747.747zm1.55-22.508a.92.92 0 01.5-.822L13.632.976a.926.926 0 01.842 0l10.829 5.548a.92.92 0 01.5.822v19.613h-2.34V11.224a.748.748 0 00.444-.682v-1.4a.748.748 0 00-.747-.747h-8.086a.438.438 0 100 .875h7.964v1.146H5.069V9.268h7.964a.438.438 0 100-.875H4.942a.748.748 0 00-.747.747v1.4a.748.748 0 00.443.682v3.082h-.527a.893.893 0 00-.892.892v5.417a.885.885 0 00.126.455.885.885 0 00-.126.455v5.434h-.922zm18.228 17.586a.437.437 0 00-.437.437v1.572a.017.017 0 01-.017.017h-7.526a.017.017 0 01-.017-.017v-5.417a.017.017 0 01.017-.017h2.258v2.562a.718.718 0 00.717.717h1.569a.718.718 0 00.717-.717v-2.563h2.261a.017.017 0 01.017.017v1.8a.438.438 0 00.875 0v-1.8a.893.893 0 00-.892-.892h-7.54v-1.738l1.68 1.2a.734.734 0 001.162-.6v-.869h3.7a.748.748 0 00.747-.747v-1.4a.748.748 0 00-.747-.747h-3.7v-.869a.735.735 0 00-1.162-.6l-1.68 1.2V15.2a.893.893 0 00-.892-.892H5.513v-3.019h17.081v15.67h-1.633V25.37a.437.437 0 00-.436-.437zm-4.844-1.02v-2.406h1.254v2.405zm-8.592.875h1.569a.718.718 0 00.717-.717v-2.564h2.261a.017.017 0 01.017.017v5.417a.017.017 0 01-.017.017H4.111a.017.017 0 01-.017-.017v-5.416a.017.017 0 01.017-.017h2.261v2.562a.718.718 0 00.717.717zm.158-.875v-2.406h1.256v2.405zm4.389-3.28H4.111a.017.017 0 01-.017-.017V15.2a.017.017 0 01.017-.017h2.261v2.563a.718.718 0 00.717.717h1.569a.718.718 0 00.717-.717v-2.565h2.261a.017.017 0 01.017.017v.9l-.68.487a.735.735 0 000 1.194l.68.487v2.348a.018.018 0 01-.017.019zm-4.389-3.047V15.18h1.256v2.405zm4.4-.4l2.854-2.044v.821a.651.651 0 00.65.65h3.8v1.146h-3.8a.651.651 0 00-.65.65v.821zM.875 27.834h26.357v1.146H.875z"
        />
      </g>
    </g>
  </svg>
);

SvgImportAndExport.defaultProps = {};
export default SvgImportAndExport;
/* eslint-enable */
