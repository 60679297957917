import { Paper, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { centeredFlex } from '../foundation/Flex';

export type PageHeaderProps = {
    title: string
}

const PageH1Styled = styled(Paper)`
    ${centeredFlex};
    background-color: #10334b;
    color: white;
    border-radius: 0;
    min-height: 4rem;
    text-align: center;
`;

const PageHeader = (props: PageHeaderProps): JSX.Element => <div>
    <PageH1Styled>
        <div><Typography variant="h1">{props.title}</Typography></div>
    </PageH1Styled>
</div>;

export default PageHeader;
