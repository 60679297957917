/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgWorkAbroad = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.362} viewBox="0 0 27.362 27.324" {...props}>
    <g data-name="Work Abroad">
      <path
        data-name="Path 547"
        d="M26.955 16.533A10.373 10.373 0 1118.937 6.42a10.387 10.387 0 018.018 10.113z"
        fill="#3180b5"
      />
      <path
        data-name="Path 548"
        d="M26.954 16.533a10.387 10.387 0 01-8.018 10.113 10.387 10.387 0 010-20.226 10.387 10.387 0 018.018 10.113z"
        fill="#428fce"
      />
      <g data-name="Group 181" fill="#12b05e">
        <path
          data-name="Path 549"
          d="M7.432 21.467a10.4 10.4 0 01-.183-9.514s2.624.661 3.113 2.374-1.954 2.855-.975 3.834-1.955 3.306-1.955 3.306z"
        />
        <path
          data-name="Path 550"
          d="M12.485 26.082a10.418 10.418 0 01-4.39-3.549s1.9-1.8 2.761-.945.407 1.877 2.366 2.366.974 2.128-.737 2.128z"
        />
        <path
          data-name="Path 551"
          d="M18.236 6.282a4.357 4.357 0 01-5.848.744 10.441 10.441 0 015.846-.743z"
        />
      </g>
      <path
        data-name="Path 552"
        d="M26.755 18.566a10.364 10.364 0 01-3.094 5.554c-3.4-1.63-2.524-3.673-.975-6.773s-2.2-2.366-3.263-5.792a2.934 2.934 0 012.392-3.985 10.412 10.412 0 014.585 5.606 5.008 5.008 0 00-1.1 2.784 3.891 3.891 0 001.458 2.609z"
        fill="#12b05e"
      />
      <path
        data-name="Path 553"
        d="M6.116 10.619l-1.149 1.147-.929-1.556-1.5-1.5-1.556-.929 1.147-1.147 1.707.262 2.016 2.016z"
        fill="#e1dde1"
      />
      <g data-name="Group 182" fill="#f2eff2">
        <path
          data-name="Path 554"
          d="M4.475 7.533L2.919 9.089 2.54 8.71.984 7.781l1.147-1.147 1.707.262z"
        />
        <path
          data-name="Path 555"
          d="M8.939 1.63l.653-.707a1.603 1.603 0 112.312 2.22l-.047.045-.707.653"
        />
      </g>
      <path
        data-name="Path 556"
        d="M11.908 10.995l-1.376 1.376-3.443-4.62-2.061-2.062-1.343-1L.408 2.246 1.784.87l3.94 1.649 1.639.686 2.21 2.21z"
        fill="#f2eff2"
      />
      <path
        data-name="Path 557"
        d="M8.394 4.235L5.91 6.571l-.882-.882-1.343-1 2.04-2.169 1.639.686z"
        fill="#e1dde1"
      />
      <path
        data-name="Path 558"
        d="M2.966 9.815a1.363 1.363 0 01-.029-1.9l5.667-6.028a1.617 1.617 0 012.322-.036 1.617 1.617 0 01-.036 2.322L4.863 9.841a1.363 1.363 0 01-1.897-.026z"
        fill="#f2eff2"
      />
      <path
        data-name="Path 559"
        d="M3.559 8.54l5.668-6.027a1.618 1.618 0 011.967-.3 1.613 1.613 0 00-.268-.354 1.617 1.617 0 00-2.322.036L2.936 7.922a1.363 1.363 0 00.029 1.9 1.359 1.359 0 00.363.26 1.363 1.363 0 01.231-1.542z"
        fill="#e1dde1"
      />
      <path
        data-name="Path 560"
        d="M16.57 5.741a10.807 10.807 0 00-5.144 1.3.408.408 0 10.389.717q.262-.142.533-.268a4.974 4.974 0 001.779.68 4.5 4.5 0 00.757.064 5 5 0 003.485-1.516 9.892 9.892 0 012.392.761 4.173 4.173 0 00-.406.21 2.95 2.95 0 00-1.539 2.45.408.408 0 00.385.43h.023a.408.408 0 00.407-.386 2.532 2.532 0 012.105-2.185 10.031 10.031 0 014.2 5.1 5.207 5.207 0 00-1.051 2.855 4.059 4.059 0 001.418 2.761 9.941 9.941 0 01-4.374 6.227.408.408 0 10.439.688 10.793 10.793 0 00-5.8-19.893zm-3.235 1.355a10.019 10.019 0 013.235-.539c.234 0 .467.009.7.025a3.81 3.81 0 01-3.935.514zm13.012 10.471a2.744 2.744 0 01-.643-1.6 3.9 3.9 0 01.548-1.831 9.959 9.959 0 01.294 2.405 10.105 10.105 0 01-.071 1.188c-.042-.062-.085-.11-.128-.162z"
      />
      <path
        data-name="Path 561"
        d="M20.665 25.632a10 10 0 01-6.8.5 1.565 1.565 0 00.836-1.117c.031-.254.024-1.109-1.381-1.461a1.792 1.792 0 01-1.522-1.227 3.169 3.169 0 00-.654-1.032c-.868-.868-2.287.074-2.966.621q-.114-.178-.221-.36c.612-.525 1.976-1.8 2.035-2.833a1.086 1.086 0 00-.317-.855c-.207-.207-.148-.395.349-1.118a2.953 2.953 0 00.73-2.54c-.414-1.448-2.078-2.208-2.919-2.506q.215-.388.465-.758a.408.408 0 00-.676-.457 10.8 10.8 0 0013.375 15.882.408.408 0 10-.335-.744zm-13.2-13.187a6.677 6.677 0 01.979.42 2.936 2.936 0 011.524 1.573 2.264 2.264 0 01-.618 1.854c-.437.637-.982 1.429-.253 2.157a.271.271 0 01.08.231 3.17 3.17 0 01-.982 1.53c-.226.235-.452.446-.633.608a9.984 9.984 0 01-.1-8.373zM8.652 22.59c.592-.481 1.552-1.078 1.917-.714a2.43 2.43 0 01.486.789 2.572 2.572 0 002.069 1.685c.5.125.8.344.769.569-.037.3-.554.722-1.321.751a10.032 10.032 0 01-3.92-3.08z"
      />
      <path
        data-name="Path 562"
        d="M21.965 14.026a4.746 4.746 0 01-2.154-2.593.408.408 0 00-.779.241 5.5 5.5 0 002.467 3.021c1.047.73 1.524 1.063.822 2.469-1.251 2.5-2.432 4.865.017 6.653a.408.408 0 10.481-.659c-1.713-1.251-1.245-2.676.231-5.629 1.018-2.037-.051-2.782-1.085-3.503z"
      />
      <path
        data-name="Path 563"
        d="M6.519 10.557l-.229-1.5.746-.7 3.17 4.253a.408.408 0 00.3.163h.03a.408.408 0 00.288-.119l1.376-1.371a.408.408 0 00.088-.446l-2.229-5.319 2.133-2.086a2.011 2.011 0 000-2.843A2.011 2.011 0 009.3.639L7.263 2.721 1.941.493A.408.408 0 001.5.581L.12 1.957a.408.408 0 00.045.616L4.42 5.747l-.68.723-1.547-.239a.408.408 0 00-.35.115L.7 7.492a.408.408 0 00.079.639l1.383.826a1.759 1.759 0 00.263.825l-.433.433a.408.408 0 00.577.577L3 10.359a1.757 1.757 0 00.812.262l.808 1.354a.408.408 0 00.639.079L6.4 10.908a.408.408 0 00.119-.351zM10.746.816a1.194 1.194 0 011.032 1.831 2.027 2.027 0 00-1.648-1.644 1.184 1.184 0 01.616-.187zM1.03 2.201l.849-.854 4.788 2-1.685 1.8zm1.243 4.868l.788.121-.422.449a1.787 1.787 0 00-.317.466l-.679-.406zm3.388.05a.408.408 0 00-.577 0L3.03 9.174a.95.95 0 01.2-.976l5.7-6.058a1.21 1.21 0 011.713 1.707L8.355 6a.408.408 0 10.559.594l.51-.48 2 4.784-.849.849-2.941-3.948.481-.452a.41.41 0 00-.559-.6l-.742.7-1.236 1.165-.995.935a.952.952 0 01-.556.255h-.045a.948.948 0 01-.376-.055l2.056-2.051a.408.408 0 000-.577zm-.61 3.988l-.385-.644a1.79 1.79 0 00.476-.321l.426-.4.113.736z"
      />
    </g>
  </svg>
);

SvgWorkAbroad.defaultProps = {};
export default SvgWorkAbroad;
/* eslint-enable */
