import {
    Container, Divider, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { promotionTypes, PromotionTypesValues } from '../../../common/promotion_type.enum';
import { LoggedInUser } from '../../../types/auth.types';
import { FullUserDetails, getUserFullDetails } from '../../api/admin.actions';
import { addPromotions, removePromotions } from '../../api/promotions.api';
import Button from '../foundation/Button';
import { BGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import DateFormat from '../foundation/DateFormat';
import { Flexbox } from '../foundation/Flex';
import Select from '../foundation/Select';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';

export type AdminUsersProps = {
    url: string
    user?: LoggedInUser
}

interface IFormAdminUsers {
    user: string
}

const promotionTypeToText = {
    [promotionTypes.jobs]: 'פרסום משרות רגיל',
    [promotionTypes.promotion]: 'פרסום משרות מקודם',
    [promotionTypes.jobs_tiles]: 'פרסום משרות רגיל משבצות',
    [promotionTypes.promotion_jobs_tiles]: 'פרסום משרות מקודם משבצות',
};

interface IFormAdminUserPromotion {
    promotionAmount: number
    promotionType: PromotionTypesValues
}

const UserPackages = (props: {user: FullUserDetails, refreshUsers: () => void}) => {
    const { register, getValues } = useForm<IFormAdminUserPromotion>();

    const addPackageToUser = async () => {
        const data = getValues();
        const { promotionAmount } = data;
        const { promotionType } = data;
        await addPromotions({
            userid: props.user.userid,
            amount: promotionAmount,
            type: promotionType,
        });
        props.refreshUsers();
    };

    const onRemovePromotion = async promotionId => {
        await removePromotions({ promotionId });
        props.refreshUsers();
    };

    return <>
        <Typography color="secondary" variant="h5">חבילות</Typography>
        <CustomBR />
        <Typography color="secondary">שים לב: למשתמש לא יכול להיות גם חבילות רגילות וגם חבילות השייכות למודול משבצות!</Typography>
        <CustomBR />
        <Flexbox>
            <TextField
                inputRef={register({ valueAsNumber: true })}
                name="promotionAmount"
                id="promotionAmount"
                style={{ width: '300px' }}
                type="number" label="כמות" />
            &nbsp;&nbsp;&nbsp;
            <Select
                size="small"
                name="promotionType"
                inputRef={register({ required: true, valueAsNumber: true })}
                required
                options={[
                    { value: promotionTypes.jobs, text: promotionTypeToText[promotionTypes.jobs] },
                    { value: promotionTypes.promotion, text: promotionTypeToText[promotionTypes.promotion] },
                    { value: promotionTypes.jobs_tiles, text: promotionTypeToText[promotionTypes.jobs_tiles] },
                    { value: promotionTypes.promotion_jobs_tiles, text: promotionTypeToText[promotionTypes.promotion_jobs_tiles] },
                ]} />
                &nbsp;&nbsp;&nbsp;
            <Button size="large" onClick={addPackageToUser}>הוספת חבילה</Button>
        </Flexbox>
        <CustomBR />
        {props.user.promotions?.map(promotion => <div key={promotion.promotionid}><BGContainer>
            <Flexbox>
                <div>
                    <Typography>סוג: {promotionTypeToText[promotion.type]}</Typography>
                    <Typography>כמות: {promotion.amount}</Typography>
                    <Typography>כמות שנוצלה: {promotion.amount_used || 0}</Typography>
                    <Flexbox><Typography>תאריך רכישה: </Typography>&nbsp;<DateFormat date={promotion.created_at} /></Flexbox>
                </div>
                <div style={{ flexGrow: 1, textAlign: 'left' }}>
                    <Button color="error" onClick={() => onRemovePromotion(promotion.promotionid)}>הסר חבילה</Button>
                </div>
            </Flexbox>
        </BGContainer><CustomBR /></div >)}
    </>;
};

const AdminCompanies = (props: AdminUsersProps): JSX.Element => {
    const [users, setUsers] = useState<FullUserDetails[]>(null);

    const { register, getValues } = useForm<IFormAdminUsers>();

    const loadUsers = async () => {
        const userStr = getValues('user');
        const userRes = await getUserFullDetails({ user: userStr });
        setUsers(userRes.data);
    };

    return <Page
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - דרושים - ניהול משתמשים" >
        <Container>
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - ניהול משתמשים</Typography>
            <CustomBR />
            <CustomBR />
            <CustomBR />
            <div><Typography color="secondary" variant="h4">טעינת משתמש</Typography></div>
            <CustomBR />
            <Flexbox>
                <TextField
                    inputRef={register()}
                    name="user"
                    id="user"
                    style={{ width: '300px' }}
                    type="text" label="מספר, שם או אימייל המשתמש" />
                &nbsp;&nbsp;&nbsp;
                <Button size="large" onClick={loadUsers}>חיפוש משתמש</Button>
            </Flexbox>
            <CustomBR />
            <div><Typography color="secondary" variant="h4">תוצאות</Typography></div>
            <CustomBR />
            <Divider />
            <CustomBR />
            {users?.map(user => <div key={user.userid}>
                <Typography>מספר זיהוי: {user.userid}</Typography>
                <Typography>שם פרטי: {user.firstname}</Typography>
                <Typography>שם משפחה: {user.lastname}</Typography>
                <Typography>טלפון: {user.phone}</Typography>
                <Typography>אימייל: {user.email}</Typography>
                <CustomBR />
                <UserPackages user={user} refreshUsers={loadUsers} />
                <CustomBR />
                <Divider />
                <CustomBR />
            </div>)}
        </Container>
        <CustomBR />
    </Page>;
};

export default AdminCompanies;
