import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Underline } from '../svg';

export type UnderlineBowProps = {
    children: JSX.Element | JSX.Element[]
}

const UnderlineBowStyled = styled.div<{ bottom: number }>`
    font-weight: 600;
    display: inline-block;
    position: relative;
    svg {
        position: absolute;
        width: 105%;
        bottom: ${props => props.bottom}px;
        right: -2px;
    }
`;

const UnderlineBow = (props: UnderlineBowProps): JSX.Element => {
    const ref = useRef<HTMLDivElement>();
    const [bottom, setBottom] = useState<number>(-2);
    useEffect(() => {
        const relation = ref.current.clientWidth / ref.current.clientHeight;
        if (relation > 2.8) setBottom(-5);
        if (relation > 4) setBottom(-10);
    }, []);

    return <UnderlineBowStyled ref={ref} bottom={bottom}>
        {props.children}
        <Underline />
    </UnderlineBowStyled>;
};

export default UnderlineBow;
