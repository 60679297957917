import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import RegisterSmartAgent from '../../containers/RegisterSmartAgent';
import { LoggedInUser } from '../../../../types/auth.types';
import { TagViewModel } from '../../../../types/tags.types';
import Anchor from '../../foundation/Anchor';
import { GeoLocation } from '../../../../types/geo.types';

export type SmartAgentModalProps = {
    open?: boolean
    onClose: () => void
    user?: LoggedInUser
    defaultTags: TagViewModel[]
    gotoLogin: () => void
    defaultLocation?: GeoLocation
};

const SmartAgentModalStyle = styled.div`
`;

const SmartAgentModal = (props: SmartAgentModalProps) => {
    const onRegisterComplete = async () => {
        window.location.reload();
    };

    const renderFooter = () => <Typography variant="body2">
        רשום כבר? <Anchor text="לחץ כאן להתחברות והגדרת סוכן חכם" href="#" onClick={ev => {
            props.onClose();
            ev.preventDefault();
            props.gotoLogin();
        }} />
    </Typography>;

    return <DialogWithHeader
        open={props.open}
        renderFooter={renderFooter}
        onClose={props.onClose}
        title="הרשמה לסוכן חכם">
        <SmartAgentModalStyle>
            <RegisterSmartAgent
                user={props.user}
                defaultTags={props.defaultTags}
                defaultLocation={props.defaultLocation}
                showDefaultText
                onSmartAgentRegistrationComplete={onRegisterComplete} />
        </SmartAgentModalStyle>
    </DialogWithHeader>;
};

export default SmartAgentModal;
