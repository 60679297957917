import { Container, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { MailOutlined } from '@mui/icons-material';
import { useState } from 'react';
import CircleDivider from '../../foundation/CircleDivider';
import CustomBR from '../../foundation/CustomBR';
import { CenteredFlex, VCenteredFlex } from '../../foundation/Flex';
import SvgCampaignCall from '../../svg/generated/components/CampaignCall';
import SvgKnowledge from '../../svg/generated/components/Knowledge';
import SvgMail from '../../svg/generated/components/Mail';
import SvgMarketing from '../../svg/generated/components/Marketing';
import { BGContainer, WhiteBGContainer } from '../../foundation/Containers';
import Button from '../../foundation/Button';
import Anchor from '../../foundation/Anchor';
import BackdropSpinner from '../../foundation/BackdropSpinner';
import { postTransaction } from '../../../api/transaction.api';

const packages = {
    promotionStart: 'promotionStart',
    promotionMedium: 'promotionMedium',
    promotionLarge: 'promotionLarge',
};

const packageToPurchasePage = {
    [packages.promotionStart]: 'https://app.icount.co.il/m/c0a54/c79117p1u5f99c2cd6d?utm_source=iCount&utm_medium=paypage&utm_campaign=1',
    [packages.promotionMedium]: 'https://app.icount.co.il/m/c248b/c79117p2u5fa5044d0e?utm_source=iCount&utm_medium=paypage&utm_campaign=2',
    [packages.promotionLarge]: 'https://app.icount.co.il/m/22afd/c79117p3u5fa505acdb?utm_source=iCount&utm_medium=paypage&utm_campaign=3',
};

export type BuyPromotedJobsContainer = {
    onTokenGenerated?: (token: string) => void
}

const BuyPromtoedJobsStyled = styled.div`
`;
const PlanCardStyled = styled.div`
    padding: 1rem;
    box-shadow: 0 40px 50px 0 #10334b14;
    border: solid 3px #f9f9fb;
    background-color: #ffffff;
`;

const WhiteBGContainer1Rem = styled(WhiteBGContainer)` padding: 1rem; `;
const BGContainer1Rem = styled(BGContainer)`margin: 1rem 0; padding: 1rem;`;

const BuyPromotedJobs = (props: BuyPromotedJobsContainer) => {
    const [iframeSrc, setIFrameSrc] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const purchasePackage = packageName => async ev => {
        ev.preventDefault();
        window.showBackdrop();
        const transactionRes = await postTransaction({
            package: packageName,
        });
        if (props.onTokenGenerated) props.onTokenGenerated(transactionRes.data.token);
        const iframe = `${packageToPurchasePage[packageName]}&m__token=${transactionRes.data.token}&is_iframe=1`;
        setIFrameSrc(iframe);
        window.hideBackdrop();
    };

    const onIframeLoaded = () => {
        setLoading(false);
    };

    const renderIframe = () => <iframe title="עמוד רכישה" onLoad={onIframeLoaded} style={{
        border: 0,
        height: loading ? '0' : '75vh',
        width: '100%',
    }} src={iframeSrc} />;

    const goBack = () => {
        setLoading(false);
        setIFrameSrc(undefined);
    };

    return <BuyPromtoedJobsStyled>
        <CustomBR />
        <Container>
            <div>
                <Typography color="primary" display="inline">•</Typography>&nbsp;
                <Typography display="inline" color="secondary" style={{ opacity: 0.8 }} variant="body1">
                על מנת לקדם משרה באתר יש לרכוש קמפיינים, כל קמפיין הוא למעשה קידום משרה אחת לחודש.
                </Typography>
            </div>
            <div>
                <Typography color="primary" display="inline">•</Typography>&nbsp;
                <Typography display="inline" color="secondary" style={{ opacity: 0.8 }} variant="body1">
                 ניתן להפעיל את הקמפיין בכל עת לאחר הרכישה.
                </Typography>
            </div>
            <div>
                <Typography color="primary" display="inline">•</Typography>&nbsp;
                <Typography display="inline" color="secondary" style={{ opacity: 0.8 }} variant="body1">
                 על מנת לקדם מספר משרות יש לרכוש מספר קמפיינים.
                </Typography>
            </div>
        </Container>
        <CustomBR />
        <CircleDivider />
        <CustomBR />
        <CenteredFlex>
            <Typography color="secondary" variant="h5">תקופת הקמפיין</Typography>
        </CenteredFlex>
        <CustomBR />
        <Grid container spacing={2}>
            <Grid item md={6} sm={12}><WhiteBGContainer1Rem><VCenteredFlex>
                <SvgCampaignCall width={30} style={{ marginInlineEnd: '20px' }} />
                <Typography color="secondary" variant="body1" display="inline">ניתן להתחיל את הקמפיין בכל עת</Typography>
            </VCenteredFlex></WhiteBGContainer1Rem></Grid>
            <Grid item md={6} sm={12}><WhiteBGContainer1Rem><VCenteredFlex>
                <SvgKnowledge width={30} style={{ marginInlineEnd: '20px' }} />
                <Typography color="secondary" variant="body1" display="inline">המשרה תקודם בתוצאות החיפוש בתחומים והתפקידים הרלוונטים בעדיפות גבוהה</Typography>
            </VCenteredFlex></WhiteBGContainer1Rem></Grid>
            <Grid item md={6} sm={12}><WhiteBGContainer1Rem><VCenteredFlex>
                <SvgMarketing width={30} style={{ marginInlineEnd: '20px' }} />
                <Typography color="secondary" variant="body1" display="inline">משרה מקודמת תופיע בצורה מודגשת ובולטת בתוצאות החיפוש</Typography>
            </VCenteredFlex></WhiteBGContainer1Rem></Grid>
            <Grid item md={6} sm={12}><WhiteBGContainer1Rem><VCenteredFlex>
                <SvgMail width={30} style={{ marginInlineEnd: '20px' }} />
                <Typography color="secondary" variant="body1" display="inline">אנו נשלח את המשרה למועמדים רלוונטים בדיוור ישיר בעדיפות גבוהה</Typography>
            </VCenteredFlex></WhiteBGContainer1Rem></Grid>
        </Grid>
        <CustomBR />
        <CustomBR />
        <CenteredFlex><Typography color="secondary" fontWeight="bold" variant="h4">התוכניות שלנו</Typography></CenteredFlex>
        <CustomBR />
        <BackdropSpinner open={loading} />
        {!loading && iframeSrc && <><Button onClick={goBack}>→ חזור אחורה</Button><CustomBR /></>}
        {iframeSrc ? renderIframe()
            : <Grid container spacing={4}>
                <Grid item md={4} sm={12}>
                    <PlanCardStyled>
                        <Typography display="flex" justifyContent="center" fontWeight="bold" color="secondary" variant="h6">קמפיין בודד</Typography>
                        <div>
                            <CenteredFlex>
                                <Typography display="inline" color="primary" variant="body1">300₪</Typography>
                                <Typography display="inline" color="secondary" variant="body1">&nbsp;/ לקמפיין</Typography>
                            </CenteredFlex>
                        </div>
                        <BGContainer1Rem>
                            <Typography>
                            מסלול למתחילים בקטנה: אפשרות זו מתאימה לבעלי מספר בודד של משרות.
                            </Typography>
                        </BGContainer1Rem>
                        <CenteredFlex>
                            <Button onClick={purchasePackage(packages.promotionStart)} variant="outlined" size="large">המשך לרכישה</Button>
                        </CenteredFlex>
                    </PlanCardStyled>
                </Grid>
                <Grid item md={4} sm={12}>
                    <PlanCardStyled>
                        <Typography display="flex" justifyContent="center" fontWeight="bold" color="secondary" variant="h6">5 קמפיינים</Typography>
                        <div>
                            <CenteredFlex>
                                <Typography display="inline" color="primary" variant="body1">270₪</Typography>
                                <Typography display="inline" color="secondary" variant="body1">&nbsp;/ לקמפיין</Typography>
                            </CenteredFlex>
                        </div>
                        <BGContainer1Rem>
                            <Typography>
                            מסלול למתקדמים: ברכישת 5-10 קמפיינים, רק <strike>300₪</strike> 270₪ לקמפיין
                            </Typography>
                        </BGContainer1Rem>
                        <CenteredFlex>
                            <Button size="large" onClick={purchasePackage(packages.promotionMedium)}>המשך לרכישה</Button>
                        </CenteredFlex>
                    </PlanCardStyled>
                </Grid>
                <Grid item md={4} sm={12}>
                    <PlanCardStyled>
                        <Typography display="flex" justifyContent="center" fontWeight="bold" color="secondary" variant="h6">10 קמפיינים</Typography>
                        <div>
                            <CenteredFlex>
                                <Typography display="inline" color="primary" variant="body1">250₪</Typography>
                                <Typography display="inline" color="secondary" variant="body1">&nbsp;/ לקמפיין</Typography>
                            </CenteredFlex>
                        </div>
                        <BGContainer1Rem>
                            <Typography>
                            מסלול עסקים: ברכישת יותר מ 10 קמפיינים, רק <strike>300₪</strike> 250₪ לקמפיין
                            </Typography>
                        </BGContainer1Rem>
                        <CenteredFlex>
                            <Button size="large" variant="outlined" onClick={purchasePackage(packages.promotionLarge)}>המשך לרכישה</Button>
                        </CenteredFlex>
                    </PlanCardStyled>
                </Grid>
            </Grid>}
        <CustomBR />
        <CustomBR />
        <div>
            <Typography color="secondary">
                * המחירים אינם כוללים מע"מ
            </Typography>
            <Typography color="secondary">
                <>** רכישת והפעלת קמפיינים בכפוף לתקנון! <Anchor blank href="/home/regulations" text="ותנאי השימוש" /></>
            </Typography>
        </div>
        <CenteredFlex>
            <WhiteBGContainer>
                <Typography color="secondary">מעוניינים לקדם יותר משרות? דברו איתנו!</Typography>
                <CenteredFlex>
                    <MailOutlined color="primary" />&nbsp;<Anchor href="mailto:info@mploy.co.il" text="info@mploy.co.il" />
                </CenteredFlex>
            </WhiteBGContainer>
        </CenteredFlex>
        <CustomBR />
    </BuyPromtoedJobsStyled>;
};

export default BuyPromotedJobs;
