import axios, {
    AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, CancelTokenSource,
} from 'axios';
import { API_METHODS } from './api.enums';

type Options = {
    requestInterceptor?: any,
    responseInterceptorSuccess?: any,
    responseInterceptorError?: any,
}

// saves last five calls
export const lastAPICalls = [];

export const updateAPILog = (path, type, body = {}) => {
    lastAPICalls.unshift({
        path,
        type,
        body,
    });
    lastAPICalls.splice(5, 1);
};

export class AxiosWrapper {
    axiosService: AxiosInstance

    constructor(defaultConfig, options?: Options) {
        this.axiosService = axios.create(this.getAxiosConfiguration(defaultConfig));
        this.addInterceptors(options);
    }

    addInterceptors(options: Options = {}) {
        if (options.responseInterceptorSuccess || options.responseInterceptorError) {
            this.axiosService.interceptors.response.use(
                options.responseInterceptorSuccess,
                options.responseInterceptorError,
            );
        }
        if (options.requestInterceptor) this.axiosService.interceptors.request.use(options.requestInterceptor);
    }

    getAxiosConfiguration(defaultConfig) {
        return {
            ...(defaultConfig || {}),
        };
    }

    get<T, R = AxiosResponse<T>>(path: string, apiConfig?: AxiosRequestConfig): Promise<R> {
        updateAPILog(path, API_METHODS.GET);
        return this.axiosService.get(path, apiConfig);
    }

    head<T, R = AxiosResponse<T>>(path: string, apiConfig?: AxiosRequestConfig): Promise<R> {
        updateAPILog(path, API_METHODS.HEAD);
        return this.axiosService.head(path, apiConfig);
    }

    post<T = any, R = AxiosResponse<T>>(path: string, data?: any, apiConfig?: AxiosRequestConfig): Promise<R> {
        updateAPILog(path, API_METHODS.POST, data);
        return this.axiosService.post(path, data, apiConfig);
    }

    put<T = any, R = AxiosResponse<T>>(path: string, data?: any, apiConfig?: AxiosRequestConfig): Promise<R> {
        updateAPILog(path, API_METHODS.PUT, data);
        return this.axiosService.put(path, data, apiConfig);
    }

    patch<T = any, R = AxiosResponse<T>>(path: string, data?: any, apiConfig?: AxiosRequestConfig): Promise<R> {
        updateAPILog(path, API_METHODS.PATCH, data);
        return this.axiosService.patch(path, data, apiConfig);
    }

    delete<T = any, R = AxiosResponse<T>>(path: string, apiConfig?: AxiosRequestConfig): Promise<R> {
        updateAPILog(path, API_METHODS.DELETE);
        return this.axiosService.delete(path, apiConfig);
    }
}

const isProd = process.env.NODE_ENV === 'production';

const newAPIService = new AxiosWrapper({
    withCredentials: true,
    timeout: 1000 * 30,
    baseURL: isProd ? '' : 'http://localhost',
} as AxiosRequestConfig,
{
});

export const getCancelSource = (): CancelTokenSource => axios.CancelToken.source();

export default newAPIService;
