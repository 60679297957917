/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgDataSecurity = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.517} viewBox="0 0 27.517 36.43" {...props}>
    <g data-name="Data Security" transform="translate(-62.63)">
      <path
        data-name="Path 607"
        d="M63.164 19.221h26.45v6.612h-26.45z"
        fill="#e2dfe2"
      />
      <path
        data-name="Path 608"
        d="M88.176 19.221h1.433v6.612h-1.437z"
        fill="#d3ced5"
      />
      <path
        data-name="Path 609"
        d="M64.601 16.49h23.575v2.731H64.601z"
        fill="#544f57"
      />
      <path
        data-name="Path 610"
        d="M87.168 16.49v1.006a.719.719 0 01-.719.719H64.601v1.006h23.575V16.49z"
        fill="#454045"
      />
      <path
        data-name="Path 611"
        d="M64.601 7.146h23.575v2.731H64.601z"
        fill="#544f57"
      />
      <path
        data-name="Path 612"
        d="M87.168 7.146v1.006a.719.719 0 01-.719.719H64.601v1.006h23.575V7.146z"
        fill="#454045"
      />
      <path
        data-name="Path 613"
        d="M63.164 9.877h26.45v6.613h-26.45z"
        fill="#e2dfe2"
      />
      <path
        data-name="Path 614"
        d="M88.176 9.877h1.433v6.613h-1.437z"
        fill="#d3ced5"
      />
      <path
        data-name="Path 615"
        d="M63.164.534h26.45v6.613h-26.45z"
        fill="#e2dfe2"
      />
      <path
        data-name="Path 616"
        d="M88.176.534h1.433v6.613h-1.437z"
        fill="#d3ced5"
      />
      <circle
        data-name="Ellipse 51"
        cx={1.078}
        cy={1.078}
        r={1.078}
        transform="translate(65.967 2.762)"
        fill="#aad540"
      />
      <circle
        data-name="Ellipse 52"
        cx={1.078}
        cy={1.078}
        r={1.078}
        transform="translate(70.998 2.762)"
        fill="#e27a66"
      />
      <circle
        data-name="Ellipse 53"
        cx={1.078}
        cy={1.078}
        r={1.078}
        transform="translate(65.967 12.106)"
        fill="#aad540"
      />
      <circle
        data-name="Ellipse 54"
        cx={1.078}
        cy={1.078}
        r={1.078}
        transform="translate(70.998 12.106)"
        fill="#e27a66"
      />
      <path
        data-name="Path 617"
        d="M72.076 21.378v3.881h2.156v-3.881a2.156 2.156 0 114.313 0v3.881h2.156v-3.881a4.313 4.313 0 10-8.625 0z"
        fill="#665e66"
      />
      <path
        data-name="Path 618"
        d="M76.389 35.897h5.031a1.437 1.437 0 001.438-1.437v-7.763a1.438 1.438 0 00-1.438-1.434H71.357a1.438 1.438 0 00-1.437 1.438v7.762a1.437 1.437 0 001.438 1.438z"
        fill="#f4dd45"
      />
      <path
        data-name="Path 619"
        d="M81.42 25.263h-1.366a1.437 1.437 0 011.438 1.438v7.762a1.437 1.437 0 01-1.437 1.438h1.366a1.437 1.437 0 001.438-1.437v-7.763a1.437 1.437 0 00-1.439-1.438z"
        fill="#ecc32e"
      />
      <circle
        data-name="Ellipse 55"
        cx={1.294}
        cy={1.294}
        r={1.294}
        transform="translate(75.095 27.846)"
        fill="#665e66"
      />
      <path
        data-name="Path 620"
        d="M89.614 7.68a.534.534 0 00.534-.534V.534A.534.534 0 0089.614 0h-26.45a.534.534 0 00-.534.534v6.612a.534.534 0 00.534.534h.9v1.664h-.9a.534.534 0 00-.534.534v6.612a.534.534 0 00.534.534h.9v1.664h-.9a.534.534 0 00-.534.534v6.613a.534.534 0 00.534.534h6.251a1.978 1.978 0 00-.028.329v7.763a1.973 1.973 0 001.971 1.971H81.42a1.973 1.973 0 001.971-1.971V26.7a1.978 1.978 0 00-.028-.329h6.251a.534.534 0 00.534-.534v-6.616a.534.534 0 00-.534-.534h-.9v-1.663h.9a.534.534 0 00.534-.534V9.877a.534.534 0 00-.534-.534h-.9V7.68h.9zM63.7 19.755h2.9a.534.534 0 100-1.067h-1.465v-1.664h9.128a4.879 4.879 0 00-1.9 1.664h-3.254a.534.534 0 100 1.067h2.714a4.822 4.822 0 00-.28 1.623v3.348h-.185a1.965 1.965 0 00-1.39.575H63.7zm16.47 1.623v3.348h-1.091v-3.349a2.69 2.69 0 00-5.38 0v3.348H72.61v-3.348a3.779 3.779 0 017.558 0zm-5.4 3.348v-3.349a1.623 1.623 0 113.245 0v3.348zm7.558 9.734a.9.9 0 01-.9.9H71.357a.9.9 0 01-.9-.9V26.7a.9.9 0 01.9-.9H81.42a.9.9 0 01.9.9v7.763zm6.752-9.16h-6.27a1.965 1.965 0 00-1.39-.575h-.185v-3.348a4.823 4.823 0 00-.28-1.623h8.125zm-1.437-6.613h-7.226a4.88 4.88 0 00-1.9-1.664h9.128zm0-9.344h-1.465a.534.534 0 000 1.067h2.9v5.545H63.7v-5.544h19.985a.534.534 0 100-1.067h-18.55V7.68h5.722a.534.534 0 000-1.067H63.7V1.067h25.38v5.546h-15.7a.534.534 0 100 1.067h14.26z"
      />
      <path
        data-name="Path 621"
        d="M67.045 2.228a1.612 1.612 0 101.612 1.612 1.614 1.614 0 00-1.612-1.612zm0 2.156a.544.544 0 11.545-.544.545.545 0 01-.545.544z"
      />
      <path
        data-name="Path 622"
        d="M73.687 3.84a1.612 1.612 0 10-1.612 1.612 1.614 1.614 0 001.612-1.612zm-1.612.544a.544.544 0 11.544-.544.545.545 0 01-.543.544z"
      />
      <path
        data-name="Path 623"
        d="M67.045 11.572a1.612 1.612 0 101.612 1.612 1.614 1.614 0 00-1.612-1.612zm0 2.156a.544.544 0 11.545-.544.545.545 0 01-.545.544z"
      />
      <path
        data-name="Path 624"
        d="M72.076 11.572a1.612 1.612 0 101.612 1.612 1.614 1.614 0 00-1.612-1.612zm0 2.156a.544.544 0 11.544-.544.545.545 0 01-.544.544z"
      />
      <path
        data-name="Path 625"
        d="M76.388 27.313a1.827 1.827 0 00-.534 3.575v1.99a.534.534 0 101.067 0v-1.99a1.827 1.827 0 00-.534-3.575zm0 2.588a.76.76 0 11.76-.76.761.761 0 01-.76.76z"
      />
    </g>
  </svg>
);

SvgDataSecurity.defaultProps = {};
export default SvgDataSecurity;
/* eslint-enable */
