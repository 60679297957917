/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgEditingContentAndLiterature = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.856} viewBox="0 0 27.856 27.855" {...props}>
    <g data-name="Editing, Content and Literature">
      <path
        data-name="Path 952"
        d="M26.9 9.347H.957a.536.536 0 00-.535.536v17.011a.535.535 0 00.535.535H26.9a.535.535 0 00.535-.535V9.888a.536.536 0 00-.535-.541z"
        fill="#fdc202"
      />
      <path
        data-name="Path 953"
        d="M25.598 6.588a14.157 14.157 0 00-11.67.039 14.157 14.157 0 00-11.67-.039.148.148 0 00-.087.134v18.5a.147.147 0 00.205.135 14.157 14.157 0 0111.552.092 14.157 14.157 0 0111.552-.092.147.147 0 00.205-.135v-18.5a.148.148 0 00-.087-.134z"
        fill="#fff5f4"
      />
      <path
        data-name="Path 954"
        d="M25.48 23.424a14.188 14.188 0 00-11.49.06.141.141 0 01-.125 0 14.207 14.207 0 00-9.611-.733.142.142 0 01-.182-.137v-16.7a14.182 14.182 0 00-1.815.672.148.148 0 00-.087.135v18.5a.147.147 0 00.206.135 14.484 14.484 0 011.463-.549 14.151 14.151 0 0110.089.641 14.156 14.156 0 0111.552-.092.147.147 0 00.205-.135v-1.932a.147.147 0 01-.205.135z"
        fill="#ebe0da"
      />
      <path
        data-name="Path 955"
        d="M21.522 21.959a2.26 2.26 0 00.664-1.6V5.239a.236.236 0 00-.02-.1L20.131.56a.232.232 0 00-.425 0l-2.034 4.584a.237.237 0 00-.02.1v15.113a2.267 2.267 0 003.87 1.6z"
        fill="#12b05e"
      />
      <path
        data-name="Path 956"
        d="M19.614 22.603a2.256 2.256 0 01-.446-1.349V6.136a.236.236 0 01.02-.1l1.687-3.8-.745-1.68a.232.232 0 00-.425 0L17.671 5.14a.237.237 0 00-.02.1v15.117a2.267 2.267 0 001.963 2.246z"
        fill="#12b05e"
      />
      <path
        data-name="Path 957"
        d="M22.186 5.24v.038l-1.275 1.163a.113.113 0 01-.154 0L20 5.722a.113.113 0 00-.155 0l-.757.718a.113.113 0 01-.154 0l-1.246-1.152-.033-.033v-.016a.241.241 0 01.02-.1l1.021-2.3.007-.014L19.71.555a.232.232 0 01.425 0l.976 2.216.032.073 1.021 2.3a.242.242 0 01.022.096z"
        fill="#ffe5a0"
      />
      <path
        data-name="Path 958"
        d="M19.169 6.36v-.223c0-.073-.107.191 1.708-3.9l-.745-1.68a.232.232 0 00-.425 0L18.7 2.828l-1.027 2.314a.244.244 0 00-.02.111l.033.033 1.246 1.152a.113.113 0 00.155 0z"
        fill="#ffdd81"
      />
      <path
        data-name="Path 959"
        d="M19.169 6.36v-.223c0-.073-.107.191 1.708-3.9l-.745-1.68a.232.232 0 00-.425 0L18.7 2.828l-1.027 2.314a.244.244 0 00-.02.111l.033.033 1.246 1.152a.113.113 0 00.155 0z"
        fill="#ffdd81"
      />
      <path
        data-name="Path 960"
        d="M19.614 22.603a2.256 2.256 0 01-.446-1.349v-2.24h-1.517v1.342a2.284 2.284 0 001.092 1.939 2.26 2.26 0 00.871.308z"
        fill="#60c8dd"
      />
      <path
        data-name="Path 961"
        d="M21.145 2.844h-2.452l.007-.014L19.707.56a.232.232 0 01.424 0l.982 2.212z"
        fill="#777078"
      />
      <path
        data-name="Path 962"
        d="M21.522 21.959a2.26 2.26 0 00.664-1.6v-1.345h-4.534v1.342a2.267 2.267 0 003.87 1.6z"
        fill="#fdc202"
      />
      <path
        data-name="Path 963"
        d="M19.614 22.603a2.256 2.256 0 01-.446-1.349v-2.24h-1.517v1.342a2.267 2.267 0 001.963 2.246z"
        fill="#fdc202"
      />
      <path
        data-name="Path 964"
        d="M26.912 8.939h-.807V6.715a.558.558 0 00-.328-.507A14.574 14.574 0 0022.6 5.196c-.023-.189.114.16-2.094-4.815a.642.642 0 00-1.173 0l-2.021 4.562a.892.892 0 00-.064.216 13.672 13.672 0 00-3.321 1.006 14.075 14.075 0 00-3.517-1.04.408.408 0 10-.131.805 13.261 13.261 0 013.24.946v17.947a14.645 14.645 0 00-10.953.012v-5.357a.408.408 0 00-.816 0v5.751a.555.555 0 00.777.509 13.57 13.57 0 015.484-1.155 13.535 13.535 0 015.725 1.234.408.408 0 00.291.034 13.854 13.854 0 019.021-.885.408.408 0 10.193-.793 14.749 14.749 0 00-8.907.65V6.876a13.018 13.018 0 012.901-.886v14.362a2.688 2.688 0 105.377 0V6.03a13.758 13.758 0 012.678.854v17.951c-.147-.06-.294-.117-.441-.172a.408.408 0 00-.283.765c.775.287.786.355.986.355a.556.556 0 00.555-.555V9.755h.807a.128.128 0 01.128.128v17.028a.128.128 0 01-.128.128H.944a.128.128 0 01-.128-.128V9.884a.128.128 0 01.128-.128h.807v8.09a.408.408 0 10.816 0V6.884A13.577 13.577 0 018.7 5.764a.408.408 0 10.04-.815 14.4 14.4 0 00-6.661 1.259.556.556 0 00-.328.507v2.224H.944A.945.945 0 000 9.884v17.027a.945.945 0 00.944.944h25.968a.945.945 0 00.944-.944V9.884a.945.945 0 00-.944-.945zm-6.988-7.868l.6 1.354h-1.2zm-.963 2.17h1.926l.76 1.714c-.054.034-.015 0-.931.851l-.516-.488a.408.408 0 00-.562 0l-.516.489c-.026-.024-.9-.822-.929-.843zm-.909 2.693l.67.61V18.59h-.67zM21.8 20.351a1.872 1.872 0 11-3.744 0v-.942H21.8zm0-1.758h-.674v-2.969a.408.408 0 10-.816 0v2.966h-.768V6.541l.384-.365.384.365v7.449a.408.408 0 00.816 0V6.541l.674-.623V18.59z"
      />
      <path
        data-name="Path 965"
        d="M12.159 8.776a14.365 14.365 0 00-8.228-.024.408.408 0 00.234.782 13.55 13.55 0 017.761.024.408.408 0 10.233-.782z"
      />
      <path
        data-name="Path 966"
        d="M12.159 11.877a14.365 14.365 0 00-8.228-.024.408.408 0 00.234.782 13.55 13.55 0 017.761.024.408.408 0 10.233-.782z"
      />
      <path
        data-name="Path 967"
        d="M12.159 14.978a14.365 14.365 0 00-8.228-.024.408.408 0 00.234.782 13.55 13.55 0 017.761.024.408.408 0 10.233-.782z"
      />
      <path
        data-name="Path 968"
        d="M12.159 18.08a14.365 14.365 0 00-8.228-.024.408.408 0 00.234.782 13.55 13.55 0 017.761.024.408.408 0 10.233-.782z"
      />
      <path
        data-name="Path 969"
        d="M12.159 21.181a14.364 14.364 0 00-8.228-.024.408.408 0 10.234.782 13.55 13.55 0 017.761.024.408.408 0 10.233-.782z"
      />
    </g>
  </svg>
);

SvgEditingContentAndLiterature.defaultProps = {};
export default SvgEditingContentAndLiterature;
/* eslint-enable */
