/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgInternet = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.118} viewBox="0 0 29.118 27.296" {...props}>
    <g transform="translate(0 -16.017)">
      <path
        data-name="Path 223"
        d="M27.327 41.062H1.795a1.368 1.368 0 01-1.368-1.367v-3.647a1.368 1.368 0 011.368-1.368h25.532a1.368 1.368 0 011.368 1.368v3.647a1.368 1.368 0 01-1.368 1.367z"
        fill="#12b05e"
      />
      <circle
        data-name="Ellipse 27"
        cx={0.912}
        cy={0.912}
        r={0.912}
        transform="translate(10 36.96)"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 28"
        cx={0.912}
        cy={0.912}
        r={0.912}
        transform="translate(17.294 36.96)"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 29"
        cx={0.912}
        cy={0.912}
        r={0.912}
        transform="translate(13.647 36.96)"
        fill="#fff"
      />
      <path
        data-name="Path 224"
        d="M27.327 39.695H1.795a1.368 1.368 0 01-1.368-1.368v1.368a1.368 1.368 0 001.368 1.368h25.532a1.368 1.368 0 001.368-1.368v-1.368a1.368 1.368 0 01-1.368 1.368z"
        fill="#03984b"
      />
      <g data-name="Group 73" fill="#03984b">
        <path
          data-name="Path 225"
          d="M3.162 42.886h-.917a.456.456 0 01-.456-.456v-1.368h1.824v1.368a.456.456 0 01-.451.456z"
        />
        <path
          data-name="Path 226"
          d="M26.868 42.886h-.912a.456.456 0 01-.456-.456v-1.368h1.824v1.368a.456.456 0 01-.456.456z"
        />
      </g>
      <path
        data-name="Path 227"
        d="M2.962 20.709a.427.427 0 10.508.685 1.184 1.184 0 011.353-.037.427.427 0 00.47-.712 2.039 2.039 0 00-2.331.064z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 228"
        d="M6.683 19.521a.427.427 0 00-.1-.594 4.173 4.173 0 00-4.86.031.427.427 0 10.5.69 3.321 3.321 0 013.867-.025.427.427 0 00.593-.102z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 229"
        d="M.745 17.97a.425.425 0 00.248-.08 5.469 5.469 0 016.325-.026.428.428 0 10.491-.7 6.323 6.323 0 00-7.313.03.427.427 0 00.249.773z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 230"
        d="M4.529 40.636H1.794a.942.942 0 01-.941-.941v-3.647a.942.942 0 01.941-.941h20.515a.427.427 0 000-.853H4.5V22.828a.427.427 0 10-.853 0v11.426H1.794A1.8 1.8 0 000 36.048v3.647a1.8 1.8 0 001.368 1.743v.993a.883.883 0 00.882.882h.912a.883.883 0 00.882-.882v-.941h.485a.427.427 0 000-.853zm-1.338 1.792a.029.029 0 01-.029.029H2.25a.029.029 0 01-.029-.029v-.941h.97v.941z"
      />
      <path
        data-name="Path 231"
        d="M27.323 34.254H25.47V22.828a.427.427 0 10-.853 0v11.426h-.485a.427.427 0 100 .853h3.191a.942.942 0 01.941.941v3.647a.942.942 0 01-.941.941H6.353a.427.427 0 000 .853h18.72v.941a.883.883 0 00.882.882h.912a.883.883 0 00.882-.882v-.993a1.8 1.8 0 001.368-1.742v-3.647a1.8 1.8 0 00-1.794-1.794zm-.456 8.206h-.912a.029.029 0 01-.029-.029v-.941h.97v.941a.029.029 0 01-.029.029z"
      />
      <path
        data-name="Path 232"
        d="M9.573 37.871a1.338 1.338 0 101.338-1.338 1.34 1.34 0 00-1.338 1.338zm1.823 0a.485.485 0 11-.485-.485.486.486 0 01.485.485z"
      />
      <path
        data-name="Path 233"
        d="M16.871 37.871a1.338 1.338 0 101.338-1.338 1.34 1.34 0 00-1.338 1.338zm1.823 0a.485.485 0 11-.485-.485.486.486 0 01.482.485z"
      />
      <path
        data-name="Path 234"
        d="M13.22 37.871a1.338 1.338 0 101.338-1.338 1.34 1.34 0 00-1.338 1.338zm1.824 0a.485.485 0 11-.485-.485.486.486 0 01.485.485z"
      />
      <path
        data-name="Path 235"
        d="M23.932 20.709a.427.427 0 10.508.685 1.184 1.184 0 011.353-.037.427.427 0 00.47-.712 2.039 2.039 0 00-2.331.064z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 236"
        d="M27.654 19.521a.426.426 0 00-.1-.594 4.174 4.174 0 00-4.86.031.427.427 0 10.5.69 3.321 3.321 0 013.867-.025.427.427 0 00.593-.102z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 237"
        d="M21.715 17.97a.424.424 0 00.248-.08 5.469 5.469 0 016.325-.026.428.428 0 00.491-.7 6.323 6.323 0 00-7.313.03.427.427 0 00.249.773z"
        fill="#ffcd27"
      />
    </g>
  </svg>
);

SvgInternet.defaultProps = {};
export default SvgInternet;
/* eslint-enable */
