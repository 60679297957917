/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgGeneralAndInexperienced = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25.147} viewBox="0 0 25.147 22.102" {...props}>
    <g data-name="General and Inexperienced" transform="translate(0 -31)">
      <path
        data-name="Path 142"
        d="M15.888 31.368h-1.473l1.473 2.21v2.21h1.473v-2.947a1.473 1.473 0 00-1.473-1.473z"
        fill="#6c818f"
      />
      <path
        data-name="Path 143"
        d="M14.416 31.368H9.259a1.473 1.473 0 00-1.473 1.473v2.947h1.473v-2.21h6.626v-.737a1.473 1.473 0 00-1.469-1.473z"
        fill="#8195a2"
      />
      <path
        data-name="Path 144"
        d="M21.095 45.366v7.367h1.473a1.473 1.473 0 001.473-1.473v-5.894z"
        fill="#314654"
      />
      <path
        data-name="Path 145"
        d="M19.622 45.366v7.367h1.473a1.473 1.473 0 001.473-1.473v-5.894z"
        fill="#3e5564"
      />
      <path
        data-name="Path 146"
        d="M18.148 45.366v7.367h1.473a1.473 1.473 0 001.473-1.473v-5.894z"
        fill="#6c818f"
      />
      <path
        data-name="Path 147"
        d="M1.105 45.366v5.894a1.473 1.473 0 001.473 1.473h2.947v-7.367z"
        fill="#6c818f"
      />
      <path
        data-name="Path 148"
        d="M19.622 51.26v-5.894H4.052v5.894a1.473 1.473 0 001.473 1.473h12.627a1.473 1.473 0 001.47-1.473z"
        fill="#8195a2"
      />
      <path
        data-name="Path 149"
        d="M23.305 35.052h-1.473V46.84h1.473a1.473 1.473 0 001.473-1.473v-8.841a1.473 1.473 0 00-1.473-1.474z"
        fill="#314654"
      />
      <path
        data-name="Path 150"
        d="M23.305 45.366v-8.841a1.473 1.473 0 00-1.473-1.473H1.841a1.473 1.473 0 00-1.473 1.473v8.841a1.473 1.473 0 001.473 1.473h8.527l2.21-.737 2.21.737h7.048a1.473 1.473 0 001.469-1.473z"
        fill="#3e5564"
      />
      <path
        data-name="Path 151"
        d="M13.31 41.683l-2.21 4.42h3.684v-4.42z"
        fill="#6c818f"
      />
      <path
        data-name="Path 152"
        d="M10.363 41.683h2.947v4.42h-2.947z"
        fill="#8195a2"
      />
      <path
        data-name="Path 153"
        d="M12.574 46.103v5.157a2.21 2.21 0 002.21-2.21v-2.947z"
        fill="#03984b"
      />
      <path
        data-name="Path 154"
        d="M13.31 49.05v-2.947h-2.947v2.947a2.21 2.21 0 002.21 2.21c.407 0 .737-.989.737-2.21z"
        fill="#12b05e"
      />
      <path
        data-name="Path 155"
        d="M13.31 45.366v1.473h1.473a.737.737 0 000-1.473z"
        fill="#ebb400"
      />
      <path
        data-name="Path 156"
        d="M9.627 46.103a.737.737 0 00.737.737h2.947a.737.737 0 100-1.473h-2.947a.737.737 0 00-.737.736z"
        fill="#ffcd27"
      />
      <circle
        data-name="Ellipse 8"
        cx={0.737}
        cy={0.737}
        r={0.737}
        transform="translate(11.837 48.313)"
        fill="#ffcd27"
      />
      <path
        data-name="Path 157"
        d="M23.305 34.684h-5.574v-1.842A1.844 1.844 0 0015.889 31H9.258a1.844 1.844 0 00-1.842 1.842v2.21a.368.368 0 00.368.368h15.521a1.106 1.106 0 011.105 1.105v8.841a1.106 1.106 0 01-1.105 1.105h-7.48a1.1 1.1 0 00-.673-1.41v-3.378a.368.368 0 00-.368-.368h-4.42a.368.368 0 00-.368.368v3.379a1.1 1.1 0 00-.673 1.41H1.842a1.106 1.106 0 01-1.105-1.106v-8.841a1.106 1.106 0 011.105-1.105h4.47a.368.368 0 000-.737h-4.47A1.844 1.844 0 000 36.525v8.841a1.84 1.84 0 00.737 1.472v4.422a1.844 1.844 0 001.842 1.84h19.99a1.844 1.844 0 001.841-1.84v-4.421a1.84 1.84 0 00.737-1.472v-8.842a1.844 1.844 0 00-1.842-1.841zm-7.785 0H9.627v-.737h5.894zm1.473 0h-.737v-1.105a.368.368 0 00-.368-.368h-6.63a.368.368 0 00-.368.368v1.105h-.737v-1.842a1.106 1.106 0 011.105-1.105h6.631a1.106 1.106 0 011.105 1.105zm-2.579 7.367V45h-3.682v-2.949zM10 46.1a.369.369 0 01.368-.368h4.42a.368.368 0 110 .737h-4.42A.369.369 0 0110 46.1zm.737 1.105h3.684v1.845a1.842 1.842 0 01-3.684 0zM1.473 51.26v-4.089a1.844 1.844 0 00.368.037h1.843v4.052a1.832 1.832 0 00.369 1.105H2.579a1.106 1.106 0 01-1.106-1.105zm4.052 1.105A1.106 1.106 0 014.42 51.26v-4.052H10v1.842a2.579 2.579 0 005.157 0v-1.842h5.575v4.052a1.106 1.106 0 01-1.105 1.105zm18.149-1.105a1.106 1.106 0 01-1.105 1.105h-1.475a1.832 1.832 0 00.369-1.105v-4.052h1.842a1.844 1.844 0 00.368-.037z"
      />
      <path
        data-name="Path 158"
        d="M12.574 50.155a1.105 1.105 0 10-1.105-1.105 1.106 1.106 0 001.105 1.105zm0-1.473a.368.368 0 11-.368.368.369.369 0 01.368-.368z"
      />
    </g>
  </svg>
);

SvgGeneralAndInexperienced.defaultProps = {};
export default SvgGeneralAndInexperienced;
/* eslint-enable */
