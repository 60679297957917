import SvgAdministration from '../../svg/generated/components/Administration';
import SvgAdvertisingMarketingAndPublish from '../../svg/generated/components/AdvertisingMarketingAndPublish';
import SvgAlternativeMedicine from '../../svg/generated/components/AlternativeMedicine';
import SvgArtEntertainmentAndMedia from '../../svg/generated/components/ArtEntertainmentAndMedia';
import SvgAviationAndNavigation from '../../svg/generated/components/AviationAndNavigation';
import SvgBeautyCareAndSpa from '../../svg/generated/components/BeautyCareAndSpa';
import SvgBiotechnology from '../../svg/generated/components/Biotechnology';
import SvgBuildingConstructionAndInfra from '../../svg/generated/components/BuildingConstructionAndInfra';
import SvgComputerAndNetworks from '../../svg/generated/components/ComputerAndNetworks';
import SvgCourierAndDistributionsDrivers from '../../svg/generated/components/CourierAndDistributionsDrivers';
import SvgCustomerServices from '../../svg/generated/components/CustomerServices';
import SvgDataSecurity from '../../svg/generated/components/DataSecurity';
import SvgDesigns from '../../svg/generated/components/Designs';
import SvgEditingContentAndLiterature from '../../svg/generated/components/EditingContentAndLiterature';
import SvgElectricityAndElectronics from '../../svg/generated/components/ElectricityAndElectronics';
import SvgEngineering from '../../svg/generated/components/Engineering';
import SvgEntrepreneurship from '../../svg/generated/components/Entrepreneurship';
import SvgExactSciences from '../../svg/generated/components/ExactSciences';
import SvgFashionAndTextile from '../../svg/generated/components/FashionAndTextile';
import SvgFinanceEconomy from '../../svg/generated/components/FinanceEconomy';
import SvgFoodRestaurantAndEvents from '../../svg/generated/components/FoodRestaurantAndEvents';
import SvgGeneralAndInexperienced from '../../svg/generated/components/GeneralAndInexperienced';
import SvgHardware from '../../svg/generated/components/Hardware';
import SvgHumanResources from '../../svg/generated/components/HumanResources';
import SvgImportAndExport from '../../svg/generated/components/ImportAndExport';
import SvgInformationSystems from '../../svg/generated/components/InformationSystems';
import SvgInsurance from '../../svg/generated/components/Insurance';
import SvgInternet from '../../svg/generated/components/Internet';
import SvgLifeScienceNatureAndAgriculture from '../../svg/generated/components/LifeScienceNatureAndAgriculture';
import SvgManufacturingAndProduction from '../../svg/generated/components/ManufacturingAndProduction';
import SvgMedicineAndPharma from '../../svg/generated/components/MedicineAndPharma';
import SvgOptics from '../../svg/generated/components/Optics';
import SvgProcurementAndLogistics from '../../svg/generated/components/ProcurementAndLogistics';
import SvgQaQualityAssurance from '../../svg/generated/components/QaQualityAssurance';
import SvgRealEstate from '../../svg/generated/components/RealEstate';
import SvgReligiousProfessional from '../../svg/generated/components/ReligiousProfessional';
import SvgRetail from '../../svg/generated/components/Retail';
import SvgSales from '../../svg/generated/components/Sales';
import SvgSecurityAndProtection from '../../svg/generated/components/SecurityAndProtection';
import SvgSeniours from '../../svg/generated/components/Seniours';
import SvgSocialSciences from '../../svg/generated/components/SocialSciences';
import SvgSoftware from '../../svg/generated/components/Software';
import SvgSportsAndFitness from '../../svg/generated/components/SportsAndFitness';
import SvgStudents from '../../svg/generated/components/Students';
import SvgSystemsAnalysis from '../../svg/generated/components/SystemsAnalysis';
import SvgTeachingEducationAndTraining from '../../svg/generated/components/TeachingEducationAndTraining';
import SvgTourismAndHospitality from '../../svg/generated/components/TourismAndHospitality';
import SvgTransportsAndVehicles from '../../svg/generated/components/TransportsAndVehicles';
import SvgTrials from '../../svg/generated/components/Trials';
import SvgVolunteering from '../../svg/generated/components/Volunteering';
import SvgWarehouse from '../../svg/generated/components/Warehouse';
import SvgWorkAbroad from '../../svg/generated/components/WorkAbroad';
import SvgWorkingFromHome from '../../svg/generated/components/WorkingFromHome';
import SvgWrench from '../../svg/generated/components/Wrench';

export default {
    // 'אבטחה, שמירה וביטחון'
    1: SvgSecurityAndProtection,
    // 'אבטחת איכות QA'
    2: SvgQaQualityAssurance,
    // 'אבטחת מידע'
    3: SvgDataSecurity,
    // 'אדמיניסטרציה'
    4: SvgAdministration,
    // 'אומנות, בידור ומדיה'
    5: SvgArtEntertainmentAndMedia,
    // 'אופטיקה'
    6: SvgOptics,
    // 'אופנה וטקסטיל'
    7: SvgFashionAndTextile,
    // 'אחזקה אנשי מקצוע'
    8: SvgWrench,
    // 'אינטרנט'
    9: SvgInternet,
    // 'ביוטכנולוגיה'
    10: SvgBiotechnology,
    // 'ביטוח'
    11: SvgInsurance,
    // 'בניין, בינוי ותשתיות'
    12: SvgBuildingConstructionAndInfra,
    // 'הוראה, חינוך והדרכה'
    13: SvgTeachingEducationAndTraining,
    // 'הנדסה'
    14: SvgEngineering,
    // 'התנדבות'
    15: SvgVolunteering,
    // 'חומרה'
    16: SvgHardware,
    // 'חשמל ואלקטרוניקה'
    17: SvgElectricityAndElectronics,
    // 'יבוא יצוא'
    18: SvgImportAndExport,
    // 'יופי, טיפוח וספא'
    19: SvgBeautyCareAndSpa,
    // 'יזמות'
    20: SvgEntrepreneurship,
    // 'תעשייה ייצור'
    21: SvgManufacturingAndProduction,
    // 'כללי וללא ניסיון'
    22: SvgGeneralAndInexperienced,
    // 'כספים כלכלה'
    23: SvgFinanceEconomy,
    // 'מדעי החברה'
    24: SvgSocialSciences,
    // 'מדעי החיים, טבע וחקלאות'
    25: SvgLifeScienceNatureAndAgriculture,
    // 'מדעים מדוייקים'
    26: SvgExactSciences,
    // 'מזון, מסעדנות ואירועים'
    27: SvgFoodRestaurantAndEvents,
    // 'מחסנאות'
    28: SvgWarehouse,
    // 'מחשבים ורשתות'
    29: SvgComputerAndNetworks,
    // 'מכירות'
    30: SvgSales,
    // 'מערכות מידע'
    31: SvgInformationSystems,
    // 'מקצועות דת'
    32: SvgReligiousProfessional,
    // 'משאבי אנוש'
    33: SvgHumanResources,
    // 'משפטים'
    34: SvgTrials,
    // 'נדל"ן'
    35: SvgRealEstate,
    // 'נהגים שליחים והפצה'
    36: SvgCourierAndDistributionsDrivers,
    // 'ניתוח מערכות'
    37: SvgSystemsAnalysis,
    // 'סטודנטים'
    38: SvgStudents,
    // 'ספורט וכושר'
    39: SvgSportsAndFitness,
    // 'עבודה בחו"ל'
    40: SvgWorkAbroad,
    // 'עיצוב'
    41: SvgDesigns,
    // 'עריכה, תוכן וספרות'
    42: SvgEditingContentAndLiterature,
    // 'פרסום שיווק ויחסי ציבור'
    43: SvgAdvertisingMarketingAndPublish,
    // 'קמעונאות'
    44: SvgRetail,
    // 'רכב תחבורה'
    45: SvgTransportsAndVehicles,
    // 'רכש ולוגיסטיקה'
    46: SvgProcurementAndLogistics,
    // 'רפואה ופארמה'
    47: SvgMedicineAndPharma,
    // 'רפואה משלימה'
    48: SvgAlternativeMedicine,
    // 'שירות לקוחות'
    49: SvgCustomerServices,
    // 'תוכנה'
    50: SvgSoftware,
    // 'תיירות ומלונאות'
    51: SvgTourismAndHospitality,
    // 'תעופה וימאות'
    52: SvgAviationAndNavigation,
    // 'בכירים'
    53: SvgSeniours,
    // 'עבודה מהבית'
    945: SvgWorkingFromHome,
};
