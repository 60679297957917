import React from 'react';
import { Link, TypographyProps } from '@mui/material';
import styled from 'styled-components';
import config from '../../../config/config';

export type Props = {
    href?: string,
    text?: string,
    title?: string,
    blank?: boolean,
    reload?: boolean,
    onClick?: (ev) => void
    children?: JSX.Element | JSX.Element[] | string
} & TypographyProps

const LinkStyled = styled(Link)`
    text-decoration: none;
`;

export default class Anchor extends React.Component<Props> {
    reload(ev) {
        ev.preventDefault();
        window.location.reload();
    }

    render() {
        const {
            title,
            text,
            href,
            blank,
            children,
            reload,
            ...other
        } = this.props;

        const attributes: any = {
            title: title || text,
            href,
        };

        if (reload) {
            attributes.onClick = this.reload;
            attributes.href = '#';
        }

        if (blank) {
            attributes.target = '_blank';
            if (href && href.indexOf('www') !== -1 && href.indexOf(config.baseUrl) === -1) attributes.rel = 'noopener';
        }

        return <LinkStyled {...(other || {})} {...attributes}>
            {children || text}
        </LinkStyled>;
    }
}
