import { CircularProgress, Backdrop, BackdropProps } from '@mui/material';
import styled from 'styled-components';
import React from 'react';

export type BackdropSpinnerProps = BackdropProps & {
    open?: boolean
    onClose?: () => void
}

const BackdropStyled = styled(Backdrop)`
    z-index: 2000;
`;

const BackdropSpinner = ({ open, onClose, ...backdropProps }: BackdropSpinnerProps): JSX.Element => <BackdropStyled
    {...backdropProps}
    open={open}
    onClick={onClose}>
    <CircularProgress color="secondary" />
</BackdropStyled>;

export default BackdropSpinner;
