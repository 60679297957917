/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgSocialSciences = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25.041} viewBox="0 0 25.041 30.008" {...props}>
    <g data-name="Social Sciences">
      <path
        data-name="Path 476"
        d="M20.43 18.634A10.047 10.047 0 104.61 9.04l-.135.691-3.762 3.062a.734.734 0 00-.019 1.122l2.136 1.861-.8.892a.584.584 0 00.033.812l.265.252a.134.134 0 01.007.187l-.363.4a.584.584 0 00.025.807l.542.533-.4 2.06a2.017 2.017 0 001.98 2.4h5a.638.638 0 01.572.355l2.2 4.449a1.142 1.142 0 001.53.517l8.427-4.171a1.142 1.142 0 00.517-1.53l-2.135-4.303a.639.639 0 01.2-.8z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 477"
        d="M20.235 19.435a.639.639 0 01.2-.8A10.045 10.045 0 0017.114.776a10.034 10.034 0 011.365 15.587 2.712 2.712 0 00-.55 3.16l1.409 2.847a1.142 1.142 0 01-.517 1.53l-7.564 3.744.637 1.287a1.142 1.142 0 001.53.517l8.427-4.171a1.142 1.142 0 00.517-1.53z"
        fill="#e8edf2"
      />
      <path
        data-name="Path 478"
        d="M13.302 4.837a1.144 1.144 0 00.129-.574 1.15 1.15 0 00-2.3.044 1.143 1.143 0 00.13.53H9.625a.3.3 0 00-.3.3v1.644a1.144 1.144 0 01.574-.129 1.15 1.15 0 01-.044 2.3 1.143 1.143 0 01-.53-.13v1.629a.3.3 0 00.3.3h5.311a.3.3 0 00.3-.3V5.14a.3.3 0 00-.3-.3z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 479"
        d="M14.938 4.837h-.883v4.429a.3.3 0 01-.3.3H9.326v.883a.3.3 0 00.3.3h5.311a.3.3 0 00.3-.3V5.14a.3.3 0 00-.299-.303z"
        fill="#ebb400"
      />
      <path
        data-name="Path 480"
        d="M15.099 14.209a1.144 1.144 0 00-.129.574 1.15 1.15 0 002.3-.044 1.143 1.143 0 00-.13-.53h1.636a.3.3 0 00.3-.3v-1.636a1.143 1.143 0 00.574.129 1.15 1.15 0 00-.044-2.3 1.143 1.143 0 00-.53.13V8.596a.3.3 0 00-.3-.3h-5.311a.3.3 0 00-.3.3v5.311a.3.3 0 00.3.3h1.634z"
        fill="#12b05e"
      />
      <path
        data-name="Path 481"
        d="M19.615 10.101v.06a1.159 1.159 0 01-1.1 1.1h-.006a.577.577 0 00-.567.572v.935a.3.3 0 01-.3.3h-1a.513.513 0 00-.508.524v.006a1.148 1.148 0 01-1.167 1.152 1.15 1.15 0 002.3-.013 1.143 1.143 0 00-.13-.53h1.636a.3.3 0 00.3-.3v-1.636a1.149 1.149 0 10.541-2.168z"
        fill="#009e4c"
      />
      <path
        data-name="Path 482"
        d="M10.087 24.284a1.074 1.074 0 00-.968-.6h-5a1.575 1.575 0 01-1.546-1.876l.4-2.06a.442.442 0 00-.124-.4l-.542-.533a.142.142 0 01-.006-.2l.363-.4a.573.573 0 00-.031-.8l-.265-.252a.141.141 0 01-.008-.2l.8-.892a.442.442 0 00-.039-.628L.986 13.582a.292.292 0 01.008-.446l3.762-3.062a.443.443 0 00.155-.258l.132-.677c0-.012 0-.024.007-.035A9.6 9.6 0 0123.129 6.17a.442.442 0 00.788-.4 10.576 10.576 0 00-3.838-4.187 10.482 10.482 0 00-15.9 7.378l-.1.528-3.65 2.961a1.176 1.176 0 00-.03 1.8l1.8 1.567-.5.558a1.021 1.021 0 00.058 1.427l.048.046-.163.181a1.024 1.024 0 00.043 1.419l.373.366-.356 1.827a2.459 2.459 0 002.414 2.929h5a.2.2 0 01.176.109l1.317 2.661a.442.442 0 00.792-.392z"
      />
      <path
        data-name="Path 483"
        d="M20.63 19.239a.2.2 0 01.062-.247 10.486 10.486 0 003.987-11.235.442.442 0 00-.853.231 9.6 9.6 0 01-3.652 10.288 1.078 1.078 0 00-.337 1.355l2.133 4.31a.7.7 0 01-.317.938l-8.427 4.171a.7.7 0 01-.938-.317.442.442 0 00-.792.392 1.582 1.582 0 002.122.717l8.427-4.171a1.584 1.584 0 00.717-2.122z"
      />
      <path
        data-name="Path 484"
        d="M19.516 13.906v-1.067h.148a1.6 1.6 0 001.529-1.526 1.591 1.591 0 00-1.59-1.656h-.088V8.595a.746.746 0 00-.745-.745h-5.311a.746.746 0 00-.745.745v1.717H9.769v-.931h.088a1.591 1.591 0 001.59-1.656 1.6 1.6 0 00-1.529-1.526H9.77v-.918h1.5a.442.442 0 00.392-.646.707.707 0 01.657-1.032.719.719 0 01.677.679.7.7 0 01-.079.353.442.442 0 00.392.646h1.5v.8a.442.442 0 10.884 0v-.945a.746.746 0 00-.745-.745h-1.079v-.148a1.6 1.6 0 00-1.526-1.529 1.591 1.591 0 00-1.656 1.59v.088H9.62a.746.746 0 00-.745.745v1.644a.442.442 0 00.646.392.7.7 0 01.353-.079.719.719 0 01.679.677.706.706 0 01-1.032.657.442.442 0 00-.646.392v1.631a.746.746 0 00.745.745h3.089v2.71a.746.746 0 00.745.745h1.067v.148a1.6 1.6 0 001.526 1.529h.066a1.591 1.591 0 001.59-1.591v-.088h1.066a.746.746 0 00.747-.743zm-.884-1.636v1.5h-1.5a.442.442 0 00-.392.646.707.707 0 01-.657 1.032.719.719 0 01-.677-.679.7.7 0 01.079-.353.442.442 0 00-.392-.646h-1.5V8.734h5.033v1.5a.442.442 0 00.646.392.707.707 0 011.032.657.719.719 0 01-.679.677.7.7 0 01-.353-.08.442.442 0 00-.646.392z"
      />
    </g>
  </svg>
);

SvgSocialSciences.defaultProps = {};
export default SvgSocialSciences;
/* eslint-enable */
