import { Container, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { TagViewModel } from '../../../../types/tags.types';
import Button from '../../foundation/Button';
import CustomBR from '../../foundation/CustomBR';
import { ColCenteredFlex } from '../../foundation/Flex';
import UnderlineBow from '../../foundation/UnderlineBow';
import { fixNumberCommas } from '../../utils/math.utils';
import { GeoAutocomplete, LocationOption, TagsSearchAutocomplete } from '../AutocompleteRemote';

export type SearchJobsProps = {
    totalJobs?: number
    numberOfTodayJobs?: number
    defaultLocation?: any,
}

const NumberWrapper = styled.span<{ showBG: boolean }>`
    ${({ showBG }) => (showBG ? 'background: #f9f9fb' : '')};
    color: #ffcd27;
    font-size: 3.5rem;
    padding: ${({ showBG }) => (showBG ? '0 0.4rem' : '0 0.1rem')};;
    margin: 0 0.1rem;
    font-weight: 600;
`;

const SubtitleStyled = styled.div`
    color: ${props => props.theme.palette.secondary.main};
    font-size: 3rem;
    ${props => props.theme.breakpoints.down('sm')} {
        font-size: 2rem;
    }
`;

const PointsStyled = styled.div`
    margin-top: 1rem;
    p {
        color: ${props => props.theme.palette.secondary.main};
        display: inline-block;
        &:first-child:after, &:nth-child(2):after {
            content: '•';
            margin: 0 0.4rem;
        }
    }
`;

const SearchJobsFormStyled = styled.div`
    max-width: 38rem;
    margin-top: 2.5rem;
    margin-right: auto;
    margin-left: auto;
    .MuiButtonBase-root {
        margin-top: 1rem;
    }
`;

const SearchJobs = (props: SearchJobsProps): JSX.Element => {
    const [search, setSearch] = useState<TagViewModel | string>(null);
    const [location, setLocation] = useState<LocationOption>(null);

    const locationChange = (ev, val) => { setLocation(val); };
    const searchChange = useCallback((ev, val: TagViewModel | string) => {
        setSearch(val);
    }, []);

    const onSearchClick = () => {
        window.showBackdrop();
        let url = '';

        if (search) {
            url = typeof search === 'string' ? `/job/search/${encodeURIComponent(search.replace(/ /g, '-'))}`
                : `/job/tagged/${encodeURIComponent(search.display)}`;
        } else {
            url = '/job/search';
        }

        if (location) {
            if (location.areaid || location.cityid) url += `?loc=${location.formatted_address.replace(/ /g, '-')}`;
            else url += `?lat=${location.latitude}&lng=${location.longitude}&address=${encodeURIComponent(location.formatted_address)}`;
        }
        window.location.assign(url || '/job/search');
    };

    return <>
        <Container>
            <ColCenteredFlex>
                {/*
                <div>
                    // spaces are for placeholder since this loaded dynamic
                    {fixNumberCommas(props.totalJobs?.toString() || '    ').split('').map(
                        (c, i) => <NumberWrapper key={i} showBG={c !== ','}>{c}</NumberWrapper>,
                    )}
                </div>
                <CustomBR />
                */}
                <SubtitleStyled>
                    <div>אלפי משרות <UnderlineBow><span>דרושים</span></UnderlineBow> באתר</div>
                </SubtitleStyled>
                <PointsStyled>
                    <Typography variant="body1">פורטל דרושים</Typography>
                    <Typography variant="body1">משרות מהפייסבוק</Typography>
                    <Typography variant="body1">חיפוש עבודה</Typography>
                    {/* <Typography variant="body1">{props.numberOfTodayJobs} משרות מהיום</Typography> */}
                </PointsStyled>
            </ColCenteredFlex>
        </Container>
        <SearchJobsFormStyled>
            <Grid container spacing={2}>
                <Grid item sm={8} xs={12}>
                    <TagsSearchAutocomplete
                        onEnter={onSearchClick}
                        onInputChange={searchChange}
                        size="medium" onChange={searchChange} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <GeoAutocomplete
                        defaultValue={props.defaultLocation}
                        size="medium"
                        onChange={locationChange} />
                </Grid>
            </Grid>
            <Button onClick={onSearchClick} fullWidth size="large">חפש משרות</Button>
        </SearchJobsFormStyled>
    </>;
};

export default React.memo(SearchJobs);
