import {
    Container, Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { LoggedInUser } from '../../../types/auth.types';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type BlogPageProps = {
    url: string
    user?: LoggedInUser
    title: string
    img: string
    htmlContent?: string
}

const BlogPageStyle = styled.div`
    img {
        float: right;
        padding: 15px;
        width: 400px;
        max-width: 70vw;
    }
`;

const BlogPage = (props: BlogPageProps): JSX.Element => <Page
    showBreadCrumbs
    url={props.url}
    user={props.user}
    breadCrumbs={[{
        text: 'Mploy - כתבות',
        url: '/blogs',
    }, {
        text: props.title,
        url: props.url,
    }]}
    pageHeaderTitle={`Mploy - ${props.title}`} >
    <Container>
        <BlogPageStyle>
            <CustomBR />
            <BGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - {props.title}</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    <img src={props.img} alt={props.title} />
                    {props.htmlContent && <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />}
                </WhiteBGContainer>
                <CustomBR />
            </BGContainer>
        </BlogPageStyle>
    </Container>
    <CustomBR />
</Page>;

export default BlogPage;
