import React from 'react';
import { LocalOfferOutlined } from '@mui/icons-material';
import styled from 'styled-components';
import { CenteredFlex } from './Flex';

const PromotedTagStyle = styled(CenteredFlex)`
    background: #ffc400;
    border-radius: 8px;
    padding: 0 0.8rem;
    margin-inline-end: 10px;
    font-weight: bold;
    color: ${props => props.theme.palette.secondary.main};
    font-weight: bold;
`;

export type PromotedTagProps = {
    text: string
}

const PromotedTag = (props: PromotedTagProps): JSX.Element => <PromotedTagStyle>
    <LocalOfferOutlined />
    <span>&nbsp;{props.text}</span>
</PromotedTagStyle>;

export default PromotedTag;
