/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width={38.194} viewBox="0 0 38.194 35.411" {...props}>
    <path d="M38.194 0H0v4.774h38.194z" fill="#10334b" />
    <path
      data-name="bx-menu-alt-left"
      d="M38.194 15.318H12.982v4.774h25.212zM38.194 30.637H0v4.774h38.194z"
      fill="#10334b"
    />
  </svg>
);

SvgMenu.defaultProps = {};
export default SvgMenu;
/* eslint-enable */
