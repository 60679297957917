import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

type ReverseGeocodeQuery = {
    lat: number
    lng: number
}
type ReverseGeocodeResponse = {
    latitude: number
    longitude: number
    formatted_address: string
}
export const reverseGeocode = (query: ReverseGeocodeQuery) => apiAction<ReverseGeocodeResponse>({
    url: '/reverse-geocode',
    method: API_METHODS.GET,
    query,
});
