/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgSecurityAndProtection = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.418} viewBox="0 0 28.418 31.875" {...props}>
    <g data-name="Security and Protection" transform="translate(-27.764)">
      <path
        data-name="Path 50"
        d="M44.912.444a3.929 3.929 0 00-3.986 3.924v3.248h1.719V4.368a2.207 2.207 0 012.257-2.2 2.231 2.231 0 012.151 2.245v3.203h1.721V4.428A3.963 3.963 0 0044.912.444z"
        fill="#dfebfa"
      />
      <path
        data-name="Path 51"
        d="M45.728.544a3.862 3.862 0 00-.817-.1 3.929 3.929 0 00-3.986 3.924v3.253h1.72V4.368a2.2 2.2 0 01.086-.607 3.935 3.935 0 013-3.216z"
        fill="#b1dbfc"
      />
      <path
        data-name="Path 52"
        d="M49.195 22.507a.831.831 0 00.57-.153l3.715-2.672a1.417 1.417 0 01.651-.257.118.118 0 00.1-.12 1.637 1.637 0 00-.609-1.268 1.424 1.424 0 00-2 .353l-2.621 3.78a.844.844 0 01-.335.283z"
        fill="#ffbd86"
      />
      <path
        data-name="Path 53"
        d="M53.496 18.39a1.454 1.454 0 01.142-.175.118.118 0 00-.013-.177 1.424 1.424 0 00-2 .353l-2.621 3.78a.844.844 0 01-.335.283l.53.054a.831.831 0 00.57-.153l1.942-1.4a.118.118 0 00.028-.029z"
        fill="#f6a96c"
      />
      <path
        data-name="Path 54"
        d="M55.517 20.027a1.442 1.442 0 00-2.033-.383l-3.679 2.592a.961.961 0 01-.649.171l-1.931-.192a.2.2 0 01-.1-.366 1.571 1.571 0 00-.658-2.85l-6.387-.977a2.829 2.829 0 00-2.056.483l-3.391 2.389a.118.118 0 00-.028.165l3.789 5.321a.118.118 0 00.155.034 8.94 8.94 0 014.521-1.164l5.756.07a4.6 4.6 0 002.764-.837l3.527-2.485a1.447 1.447 0 00.4-1.971z"
        fill="#fed2a4"
      />
      <path
        data-name="Path 55"
        d="M37.48 20.2l2.405-1.695a2.8 2.8 0 01.4-.233.118.118 0 00-.032-.224l-.171-.026a2.829 2.829 0 00-2.056.483l-3.391 2.389a.118.118 0 00-.028.165l3.789 5.321a.118.118 0 00.155.034 8.937 8.937 0 011.159-.551.118.118 0 00.054-.179l-2.594-3.643a1.317 1.317 0 01.314-1.841z"
        fill="#ffbd86"
      />
      <path
        data-name="Path 56"
        d="M31.975 21.246a.118.118 0 00-.165-.028l-3.268 2.317a.657.657 0 00-.171.912l4.726 6.673a.657.657 0 00.917.141l3.269-2.315a.118.118 0 00.028-.165z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 57"
        d="M30.367 24.445a.657.657 0 01.171-.912l1.96-1.343a.118.118 0 00.03-.166l-.551-.778a.118.118 0 00-.165-.028l-3.269 2.317a.657.657 0 00-.171.912l4.726 6.673a.657.657 0 00.917.141l.688-.487a.118.118 0 00.028-.165z"
        fill="#ebb400"
      />
      <path
        data-name="Path 58"
        d="M37.253 28.822a.118.118 0 00.165.028l1.341-.95a.65.65 0 00.161-.9l-4.726-6.673a.65.65 0 00-.907-.149l-1.341.95a.118.118 0 00-.028.165z"
        fill="#d9edff"
      />
      <path
        data-name="Path 59"
        d="M34.431 20.866a.118.118 0 00.03-.166l-.268-.378a.65.65 0 00-.907-.149l-1.341.95a.118.118 0 00-.028.165l.553.781a.118.118 0 00.163.029z"
        fill="#b4dbff"
      />
      <path
        data-name="Path 60"
        d="M48.941 7.497h-8.18a1.21 1.21 0 00-1.207 1.207v6.253a1.21 1.21 0 001.207 1.207h8.18a1.21 1.21 0 001.207-1.207V8.704a1.21 1.21 0 00-1.207-1.207z"
        fill="#12b05e"
      />
      <path
        data-name="Path 61"
        d="M41.733 14.957V8.704a1.21 1.21 0 011.207-1.207h-2.179a1.211 1.211 0 00-1.207 1.207v6.253a1.21 1.21 0 001.207 1.207h2.179a1.21 1.21 0 01-1.207-1.207z"
        fill="#03984b"
      />
      <circle
        data-name="Ellipse 7"
        cx={1.408}
        cy={1.408}
        r={1.408}
        transform="translate(43.443 9.713)"
        fill="#12b05e"
      />
      <path
        data-name="Path 62"
        d="M45.046 11.121a1.406 1.406 0 01.606-1.157 1.408 1.408 0 100 2.314 1.406 1.406 0 01-.606-1.157z"
        fill="#03984b"
      />
      <path
        data-name="Path 63"
        d="M42.952 11.144a1.875 1.875 0 101.875-1.875 1.877 1.877 0 00-1.875 1.875zm2.816 0a.941.941 0 11-.941-.941.942.942 0 01.941.941z"
      />
      <path
        data-name="Path 64"
        d="M53.402 5.024l-1.32.607a.467.467 0 10.39.849l1.32-.607a.467.467 0 00-.39-.849z"
      />
      <path
        data-name="Path 65"
        d="M52.502 7.386a.467.467 0 10-.324.876l1.362.5a.467.467 0 10.324-.876z"
      />
      <path
        data-name="Path 66"
        d="M50.815 5.289a.467.467 0 00.6-.276l.5-1.363a.467.467 0 00-.876-.324l-.5 1.363a.467.467 0 00.276.6z"
      />
      <path
        data-name="Path 67"
        d="M54.821 21.634l-3.527 2.485a4.18 4.18 0 01-2.49.752l-2.556-.031h-.006a.467.467 0 00-.006.934l2.551.031a5.108 5.108 0 003.045-.923l3.527-2.485a1.925 1.925 0 00.521-2.609 1.891 1.891 0 00-1.237-.823 2.019 2.019 0 00-.769-1.289 1.889 1.889 0 00-2.658.467l-2.608 3.761-.787-.078a2.039 2.039 0 00-1.307-3.27l-6.388-.977a3.278 3.278 0 00-2.4.563l-3.026 2.14-.147-.207a1.118 1.118 0 00-1.558-.26l-4.744 3.36a1.125 1.125 0 00-.282 1.563l4.727 6.673a1.125 1.125 0 001.568.252L39 28.304a1.118 1.118 0 00.272-1.556l-.094-.132a8.514 8.514 0 013.855-.877l1.021.012h.006a.467.467 0 00.006-.934l-1.021-.012a9.4 9.4 0 00-4.413 1.039l-3.4-4.8 3.031-2.135a2.351 2.351 0 011.717-.4l6.388.977a1.105 1.105 0 01-.078 2.2l-3.36-.316a.467.467 0 10-.088.93l3.4.32 2.482.247a1.687 1.687 0 001.316-.219l3.679-2.592a.975.975 0 011.375.259.985.985 0 01-.279 1.332zm-21.1 9.264a.191.191 0 01-.267-.03l-4.727-6.673a.191.191 0 01.06-.262l2.984-2.114 4.933 6.965zm4.793-3.614a.183.183 0 01-.049.254l-1 .707-4.933-6.965 1-.707a.183.183 0 01.256.038zm14.674-8l-3.179 2.24 1.973-2.846a.956.956 0 011.348-.239 1.058 1.058 0 01.369.588 1.96 1.96 0 00-.511.257z"
      />
      <path
        data-name="Path 68"
        d="M50.124 11.231a.467.467 0 00.467-.467V8.727a1.676 1.676 0 00-1.373-1.646v-2.63A4.44 4.44 0 0044.895 0a4.4 4.4 0 00-4.46 4.391v2.69a1.676 1.676 0 00-1.373 1.646v6.253a1.676 1.676 0 001.674 1.674h8.18a1.676 1.676 0 001.674-1.674v-2.037a.467.467 0 10-.934 0v2.037a.741.741 0 01-.74.74h-8.18a.741.741 0 01-.74-.74V8.727a.741.741 0 01.74-.74h8.18a.741.741 0 01.74.74v2.037a.467.467 0 00.467.467zm-3.56-4.178H43.09V4.392a1.739 1.739 0 011.779-1.737 1.769 1.769 0 011.7 1.778zm1.721 0h-.787v-2.62a2.708 2.708 0 00-2.607-2.711 2.673 2.673 0 00-2.735 2.67v2.661h-.787V4.392A3.462 3.462 0 0144.881.935a3.5 3.5 0 013.4 3.517v2.6z"
      />
    </g>
  </svg>
);

SvgSecurityAndProtection.defaultProps = {};
export default SvgSecurityAndProtection;
/* eslint-enable */
