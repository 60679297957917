/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgSportsAndFitness = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.146} viewBox="0 0 29.146 17.192" {...props}>
    <g data-name="Sports and Fitness">
      <path
        data-name="Path 750"
        d="M9.079 16.765H6.802a1.252 1.252 0 01-1.252-1.252V1.679A1.252 1.252 0 016.802.427h2.277a1.252 1.252 0 011.252 1.252v13.833a1.252 1.252 0 01-1.252 1.253z"
        fill="#546e7a"
      />
      <path
        data-name="Path 751"
        d="M9.08.427H7.372a1.252 1.252 0 011.252 1.252v13.833a1.252 1.252 0 01-1.252 1.252H9.08a1.252 1.252 0 001.252-1.252V1.679A1.252 1.252 0 009.08.427z"
        fill="#455a64"
      />
      <path
        data-name="Path 752"
        d="M4.754 14.971H2.935a.8.8 0 01-.8-.8V3.02a.8.8 0 01.8-.8h1.822a.8.8 0 01.8.8v11.157a.8.8 0 01-.803.794z"
        fill="#546e7a"
      />
      <path
        data-name="Path 753"
        d="M4.754 2.22H3.046a.8.8 0 01.8.8v11.157a.8.8 0 01-.8.8h1.708a.8.8 0 00.8-.8V3.02a.8.8 0 00-.8-.8z"
        fill="#455a64"
      />
      <path
        data-name="Path 754"
        d="M2.135 10.873H.996a.569.569 0 01-.569-.569V6.888a.569.569 0 01.569-.569h1.139z"
        fill="#ffd54f"
      />
      <path
        data-name="Path 755"
        d="M10.332 7.132h8.482v2.927h-8.482z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 756"
        d="M17.106 7.132h1.708v2.927h-1.708z"
        fill="#bdbdbd"
      />
      <path
        data-name="Path 757"
        d="M22.343 16.765h-2.277a1.252 1.252 0 01-1.252-1.252V1.679A1.252 1.252 0 0120.066.427h2.277a1.252 1.252 0 011.252 1.252v13.833a1.252 1.252 0 01-1.252 1.253z"
        fill="#546e7a"
      />
      <path
        data-name="Path 758"
        d="M22.344.427h-1.708a1.252 1.252 0 011.252 1.252v13.833a1.252 1.252 0 01-1.252 1.252h1.708a1.252 1.252 0 001.252-1.252V1.679A1.252 1.252 0 0022.344.427z"
        fill="#455a64"
      />
      <path
        data-name="Path 759"
        d="M26.215 14.971h-1.819a.8.8 0 01-.8-.8V3.02a.8.8 0 01.8-.8h1.822a.8.8 0 01.8.8v11.157a.8.8 0 01-.803.794z"
        fill="#546e7a"
      />
      <path
        data-name="Path 760"
        d="M26.215 2.22h-1.708a.8.8 0 01.8.8v11.157a.8.8 0 01-.8.8h1.708a.8.8 0 00.8-.8V3.02a.8.8 0 00-.8-.8z"
        fill="#455a64"
      />
      <path
        data-name="Path 761"
        d="M28.15 10.873h-1.139V6.319h1.139a.569.569 0 01.569.569v3.416a.569.569 0 01-.569.569z"
        fill="#ffd54f"
      />
      <path
        data-name="Path 762"
        d="M28.15 5.892h-.712V3.017a1.225 1.225 0 00-1.224-1.224h-1.821a1.219 1.219 0 00-.37.057v-.171A1.681 1.681 0 0022.343 0h-2.277a1.681 1.681 0 00-1.679 1.679v5.026h-7.628V1.679A1.681 1.681 0 009.08 0H6.8a1.681 1.681 0 00-1.679 1.679v.171a1.219 1.219 0 00-.37-.057H2.932a1.225 1.225 0 00-1.224 1.224v.551a.427.427 0 10.854 0v-.551a.37.37 0 01.37-.37h1.821a.37.37 0 01.37.37v11.157a.37.37 0 01-.37.37H2.932a.37.37 0 01-.37-.37V5.4a.427.427 0 00-.854 0v.5H1a1 1 0 00-1 1v3.4a1 1 0 001 1h.712v2.875a1.225 1.225 0 001.22 1.225h1.821a1.218 1.218 0 00.37-.058v.171A1.681 1.681 0 006.8 17.192h2.28a1.681 1.681 0 001.679-1.679v-5.026h.621a.427.427 0 000-.854h-.621V7.56h7.628v2.073h-5.156a.427.427 0 000 .854h5.156v5.026a1.681 1.681 0 001.679 1.679h2.277a1.681 1.681 0 001.679-1.679v-.171a1.218 1.218 0 00.37.058h1.822a1.225 1.225 0 001.224-1.224V13.6a.427.427 0 10-.854 0v.577a.37.37 0 01-.37.37h-1.821a.37.37 0 01-.37-.37V3.017a.37.37 0 01.37-.37h1.822a.37.37 0 01.37.37v8.738a.427.427 0 00.854 0V11.3h.712a1 1 0 001-1V6.884a1 1 0 00-1.001-.992zM1 10.446a.143.143 0 01-.142-.142V6.888A.143.143 0 011 6.746h.712v3.7zm8.909 5.066a.826.826 0 01-.825.825H6.8a.826.826 0 01-.825-.825V1.679A.826.826 0 016.8.854h2.28a.826.826 0 01.825.825zm13.264 0a.826.826 0 01-.825.825h-2.282a.826.826 0 01-.825-.825V1.679a.826.826 0 01.825-.825h2.277a.826.826 0 01.825.825zm5.123-5.209a.143.143 0 01-.142.142h-.712v-3.7h.712a.143.143 0 01.142.142z"
      />
    </g>
  </svg>
);

SvgSportsAndFitness.defaultProps = {};
export default SvgSportsAndFitness;
/* eslint-enable */
