import React from 'react';
import { Button as ButtonMUI, ButtonProps as ButtonPropsMUI } from '@mui/material';
import styled from 'styled-components';

export type ButtonProps = {
    children: JSX.Element | string
} & ButtonPropsMUI

const Button = ({ variant = 'contained', ...props }: ButtonProps): JSX.Element => <ButtonMUI
    {...props}
    variant={variant}>
    {props.children}
</ButtonMUI>;

export const TextButton = (props: ButtonProps): JSX.Element => <ButtonMUI
    {...props}>
    {props.children}
</ButtonMUI>;

const GoldenButtonStyled = styled(ButtonMUI)`
    &.MuiButton-root {
        background-color: ${props => props.theme.palette.common.yellowText};
        color: ${props => props.theme.palette.secondary.main};
        font-weight: 600;
        border: 2px solid #fff3cd0d;
    }
`;
export const GoldenButton = (props: ButtonProps): JSX.Element => <GoldenButtonStyled
    variant="contained"
    {...props}>
    {props.children}
</GoldenButtonStyled>;

export default Button;
