import {
    Container, Divider, Typography,
} from '@mui/material';
import { LoggedInUser } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type AboutProps = {
    url: string
    user?: LoggedInUser
}

const About = (props: AboutProps): JSX.Element => <Page
    url={props.url}
    user={props.user}
    pageHeaderTitle="Mploy - דרושים - אודות" >
    <Container>
        <CustomBR />
        <BGContainer>
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - לוח דרושים | חיפוש עבודה</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1">
        Mploy היא פלטפורמה חברתית, שנוצרה על מנת לספק פתרון לשוק העבודה בישראל עם דגש על איכות, וחדשנות.
                    <CustomBR />
הפלטפורמה Mploy הוקמה במהלך 2018 במטרה ליצור התאמה מדויקת ואיכותית בין מועמד ומעסיק,
על ידי אלגוריתמים מתקדמים ומתודולוגיות שפותחו לאורך זמן.
הפלטפורמה מורכבת גם מאפליקציה איכותית וראשונה מסוגה,
וגם אתר המבוסס על טכנולוגיות חדשניות, שנועדו להקל על המשתמשים.
                    <CustomBR />
                    <CustomBR />
בנוסף, אנו מעוניינים להנגיש את כל המשרות בשוק העבודה תחת קורת גג אחת,
ולכן אנו מאפשרים לפרסם משרה בקלות וגם מבצעים אגריגציה על אלפי משרות ברשת כאשר כל משרה מפולחת לתחומים,
תפקידים ומיקום.
                </Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">אפליקצייה איכותית</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1">
בMploy ניתן לחפש משרות פנויות ישירות מהמכשיר הנייד.
אנו מספקים אפליקציה ראשונה מסוגה בארץ שמלבד זאת שניתן לחפש משרה לפי מיקום, תחומים ומילות חיפוש,
השימוש באפליקציה נוח וקל וניתן בקלות לשלוח קורות חיים למשרות רלוונטיות, בכל מקום ובכל זמן.
                </Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">משרות מהרשת</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1">
על מנת להגיע אל רוב המשרות בשוק העבודה בישראל,
בין השאר, אנו אוספים משרות מרשתות חברתיות ומקומות שונים ברשת.
                    <CustomBR />
במקום לחפש עבודה ברשתות חברתיות, ולעבור על המון משרות שאינן רלוונטיות כלל אליכם,
ריכזנו את כל המשרות בפלטפורמה שלנו וכך תוכלו לסנן בקלות את המשרות לפי תחומים,
 תפקידים, מילות חיפוש ומרחק, גם באתר וגם בעזרת האפליקציה בנייד.
                    <CustomBR />
                    <CustomBR />
                    <Anchor text="הצטרפו לסוכן החכם שלנו" href="/qsa" blank /> ותקבלו עדכונים אודות משרות חדשות מהרשת ישירות אל תיבת המייל שלכם.
                </Typography>
            </WhiteBGContainer>
            <CustomBR />
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">פרסום משרות</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1">
                 ב Mploy פרסום משרה הוא בקלות.
                    <CustomBR />
מעבר לכך, כל מעסיק מקבל מסך שליטה (dashboard), שדרכו ניתן לראות את המשרות שפורסמו, פרטי המעוניינים במשרה,
לערוך ולהסיר משרות קיימות בקלות ובמהירות.
הצוות ב Mploy שם דגש על מהימנות המועמדים וכן אמינות המשרות המוצעות למועמדים.
כל משרה ומועמד באתר חייבים לעמוד ב<Anchor text="תקנון האתר" href="/home/regulations" blank />
                </Typography>
            </WhiteBGContainer>
            <CustomBR />
            <Divider />
            <CustomBR />
            <WhiteBGContainer>
                <Typography color="secondary" variant="body1">
אנו עושים את מירב המאמצים על מנת להציע למועמדים את המשרות המתאימות ביותר בשוק העבודה,
על ידי סינון מהיר בעזרת אלגוריתמים המבוססים על תחומים, תפקידים, מילות החיפוש ומיקום המועמד.
                    <CustomBR />
אנחנו מזמינים אותכם לקרוא את <Anchor text="תנאי השימוש ומדיניות הפרטיות" href="/home/privacy" blank /> שלנו.
                </Typography>
            </WhiteBGContainer>
            <CustomBR />
        </BGContainer>
    </Container>
    <CustomBR />
</Page>;

export default About;
