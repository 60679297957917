export const setLocalStorage = (key, value) => {
    window.localStorage[key] = value;
};

export const getLocalStorage = key => window.localStorage[key];

export const setCookie = (cname, cvalue, exdays = 30) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${encodeURIComponent(cvalue)};${expires};path=/`;
};

export const getCookie = cname => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
