import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyleSheetManager, ThemeProvider as StyledThemeProvider } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import GlobalStyle from './globalStyle';
import theme from './theme';
import { UserProvider } from '../context/user.context';
import { LoggedInUser } from '../../../types/auth.types';

export type AppRootProps = {
    children: JSX.Element | JSX.Element[]
    user?: LoggedInUser
}

const RTL = props => (
    <StyleSheetManager stylisPlugins={[rtlPlugin]}>
        <>{props.children}</>
    </StyleSheetManager>
);

const AppRoot = (props: AppRootProps): JSX.Element => <ThemeProvider theme={theme}>
    <CssBaseline />
    <StyledThemeProvider theme={theme}>
        <UserProvider value={props.user}>
            <GlobalStyle />
            <RTL>{props.children}</RTL>
        </UserProvider>
    </StyledThemeProvider>
</ThemeProvider>;

export default AppRoot;
