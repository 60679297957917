/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgActiveUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg width={53.819} viewBox="0 0 53.819 53.964" {...props}>
    <g data-name="Active Users">
      <g data-name="Group 113">
        <g data-name="Group 105">
          <path
            data-name="Path 336"
            d="M26.909.793a9 9 0 11-9 9 9 9 0 019-9z"
            fill="#dff6fd"
          />
        </g>
        <g data-name="Group 106">
          <path
            data-name="Path 337"
            d="M33.815 15.546a8.993 8.993 0 01-13.811 0 6.886 6.886 0 012.107-4.93l2.409-1.7h5.083l2.1 1.7a6.886 6.886 0 012.112 4.93z"
            fill="#12b05e"
          />
        </g>
        <path
          data-name="Path 338"
          d="M31.708 10.62l-2.1-1.7h-4.095l-2.2.856-1.195.842a6.886 6.886 0 00-2.107 4.93 9.027 9.027 0 002.115 1.851 6.863 6.863 0 01.488-2.047 1.881 1.881 0 012.251-1.094 7.648 7.648 0 007.865-2.4 6.964 6.964 0 00-1.022-1.238z"
          fill="#03984b"
        />
        <path
          data-name="Path 339"
          d="M26.909.789a6.087 6.087 0 00-6.087 6.087c0 .137.006.272.015.406l.478-.2.96 2.817 3.862 2.433 1.622.346h1A6.088 6.088 0 0026.909.789z"
          fill="#ffe5c2"
        />
        <path
          data-name="Path 340"
          d="M24.531 6.876a6.091 6.091 0 014.237-5.8 6.088 6.088 0 100 11.6 6.091 6.091 0 01-4.237-5.8z"
          fill="#fed2a4"
        />
        <g data-name="Group 107">
          <path
            data-name="Path 341"
            d="M44.034 17.846a9 9 0 11-9 9 9 9 0 019-9z"
            fill="#dff6fd"
          />
        </g>
        <g data-name="Group 108">
          <path
            data-name="Path 342"
            d="M50.94 32.608a8.993 8.993 0 01-13.811 0 6.886 6.886 0 012.107-4.93l2.409-1.7h5.083l2.1 1.7a6.887 6.887 0 012.112 4.93z"
            fill="#ffcd27"
          />
        </g>
        <path
          data-name="Path 343"
          d="M48.832 27.679l-2.1-1.7h-4.095l-2.2.856-1.195.842a6.886 6.886 0 00-2.107 4.93 9.026 9.026 0 002.115 1.851 6.861 6.861 0 01.488-2.047 1.881 1.881 0 012.251-1.094 7.648 7.648 0 007.865-2.4 6.959 6.959 0 00-1.022-1.238z"
          fill="#eeb700"
        />
        <path
          data-name="Path 344"
          d="M44.032 17.847a6.087 6.087 0 00-6.087 6.087c0 .137.006.272.015.406l.478-.2.96 2.817 3.862 2.433 1.622.346h1a6.088 6.088 0 00-1.852-11.887z"
          fill="#ffe5c2"
        />
        <path
          data-name="Path 345"
          d="M41.651 23.934a6.091 6.091 0 014.237-5.8 6.088 6.088 0 100 11.6 6.091 6.091 0 01-4.237-5.8z"
          fill="#fed2a4"
        />
        <g data-name="Group 109">
          <path
            data-name="Path 346"
            d="M26.909 35.183a9 9 0 11-9 9 9 9 0 019-9z"
            fill="#dff6fd"
          />
        </g>
        <g data-name="Group 110">
          <path
            data-name="Path 347"
            d="M33.815 49.944a8.994 8.994 0 01-13.811 0 6.887 6.887 0 012.107-4.93l2.409-1.7h5.083l2.1 1.7a6.887 6.887 0 012.112 4.93z"
            fill="#12b05e"
          />
        </g>
        <path
          data-name="Path 348"
          d="M31.708 45.011l-2.1-1.7h-4.095l-2.2.856-1.195.842a6.887 6.887 0 00-2.107 4.93 9.027 9.027 0 002.115 1.851 6.863 6.863 0 01.488-2.047 1.881 1.881 0 012.251-1.094 7.648 7.648 0 007.865-2.4 6.964 6.964 0 00-1.022-1.238z"
          fill="#03984b"
        />
        <path
          data-name="Path 349"
          d="M26.909 35.183a6.087 6.087 0 00-6.087 6.087c0 .137.006.272.015.406l.478-.2.96 2.817 3.862 2.433 1.622.346h1a6.088 6.088 0 00-1.852-11.887z"
          fill="#ffe5c2"
        />
        <path
          data-name="Path 350"
          d="M24.526 41.271a6.092 6.092 0 014.237-5.8 6.088 6.088 0 100 11.6 6.091 6.091 0 01-4.237-5.8z"
          fill="#fed2a4"
        />
        <g data-name="Group 111">
          <path
            data-name="Path 351"
            d="M9.784 17.846a9 9 0 11-9 9 9 9 0 019-9z"
            fill="#dff6fd"
          />
        </g>
        <g data-name="Group 112">
          <path
            data-name="Path 352"
            d="M16.69 32.608a8.993 8.993 0 01-13.811 0 6.887 6.887 0 012.107-4.93l2.409-1.7h5.083l2.1 1.7a6.887 6.887 0 012.112 4.93z"
            fill="#ffcd27"
          />
        </g>
        <path
          data-name="Path 353"
          d="M14.583 27.679l-2.1-1.7H8.384l-2.2.856-1.195.842a6.886 6.886 0 00-2.107 4.93 9.026 9.026 0 002.115 1.851 6.862 6.862 0 01.488-2.047 1.881 1.881 0 012.251-1.094 7.648 7.648 0 007.865-2.4 6.959 6.959 0 00-1.018-1.238z"
          fill="#eeb700"
        />
        <path
          data-name="Path 354"
          d="M9.784 17.847a6.087 6.087 0 00-6.087 6.087c0 .137.006.272.015.406l.478-.2.96 2.817 3.862 2.433 1.622.346h1a6.088 6.088 0 00-1.852-11.887z"
          fill="#ffe5c2"
        />
        <path
          data-name="Path 355"
          d="M7.401 23.934a6.091 6.091 0 014.237-5.8 6.088 6.088 0 100 11.6 6.091 6.091 0 01-4.237-5.8z"
          fill="#fed2a4"
        />
      </g>
      <g data-name="Group 114">
        <path
          data-name="Path 356"
          d="M26.91 19.569a9.788 9.788 0 009.462-7.287.788.788 0 10-1.525-.4 8.189 8.189 0 01-.561 1.506 7.614 7.614 0 00-1.565-2.842 6.863 6.863 0 00.769-5.666 8.154 8.154 0 011.569 3.936.788.788 0 001.566-.184 9.784 9.784 0 10-9.715 10.94zm0-1.577a8.189 8.189 0 01-6.108-2.732 6.052 6.052 0 011.309-3.467 6.859 6.859 0 009.6 0 6.05 6.05 0 011.31 3.473 8.21 8.21 0 01-6.111 2.727zm0-5.818a5.3 5.3 0 115.3-5.3 5.305 5.305 0 01-5.3 5.301zm-6.581-7.289a6.859 6.859 0 00.77 5.658 7.609 7.609 0 00-1.563 2.842 8.19 8.19 0 01.793-8.5z"
        />
        <path
          data-name="Path 357"
          d="M26.91 34.395a9.785 9.785 0 109.785 9.785 9.8 9.8 0 00-9.785-9.785zm-5.3 6.876a5.3 5.3 0 115.3 5.3 5.305 5.305 0 01-5.3-5.305zm-1.281-1.992a6.857 6.857 0 00.77 5.66 7.609 7.609 0 00-1.563 2.842 8.191 8.191 0 01.793-8.5zm6.581 13.109a8.189 8.189 0 01-6.108-2.732 6.051 6.051 0 011.309-3.467 6.859 6.859 0 009.6 0 6.049 6.049 0 011.31 3.466 8.188 8.188 0 01-6.111 2.733zm7.375-4.609a7.612 7.612 0 00-1.564-2.84 6.857 6.857 0 00.77-5.66 8.191 8.191 0 01.794 8.5z"
        />
        <path
          data-name="Path 358"
          d="M44.035 17.197a9.785 9.785 0 109.785 9.785 9.8 9.8 0 00-9.785-9.785zm8.208 9.785a8.157 8.157 0 01-.835 3.6 7.611 7.611 0 00-1.564-2.841 6.858 6.858 0 00.771-5.662 8.165 8.165 0 011.628 4.904zm-13.507-2.909a5.3 5.3 0 115.3 5.3 5.305 5.305 0 01-5.3-5.3zm-1.281-1.992a6.857 6.857 0 00.77 5.661 7.614 7.614 0 00-1.564 2.841 8.191 8.191 0 01.794-8.5zm6.581 13.109a8.189 8.189 0 01-6.108-2.732 6.052 6.052 0 011.309-3.467 6.859 6.859 0 009.6 0 6.05 6.05 0 011.309 3.467 8.188 8.188 0 01-6.111 2.732z"
        />
        <path
          data-name="Path 359"
          d="M9.785 17.197a9.785 9.785 0 109.785 9.786 9.8 9.8 0 00-9.785-9.786zm8.208 9.785a8.157 8.157 0 01-.834 3.6 7.613 7.613 0 00-1.56-2.837 6.876 6.876 0 00.766-5.663 8.165 8.165 0 011.628 4.901zM3.204 22.083a6.866 6.866 0 00.768 5.661 7.611 7.611 0 00-1.562 2.839 8.192 8.192 0 01.794-8.5zm6.581 13.109a8.189 8.189 0 01-6.108-2.732 6.05 6.05 0 011.307-3.464 6.88 6.88 0 001.464 1.087.788.788 0 00.766-1.378 5.3 5.3 0 112.849.658.788.788 0 00.04 1.576h.041a6.845 6.845 0 004.442-1.944 6.05 6.05 0 011.306 3.462 8.188 8.188 0 01-6.108 2.732z"
        />
        <path
          data-name="Path 360"
          d="M38.607 10.137a20.517 20.517 0 015.168 5.154.788.788 0 001.294-.9 22.083 22.083 0 00-5.562-5.55.79.79 0 10-.9 1.3z"
        />
        <path
          data-name="Path 361"
          d="M44.872 38.478a.789.789 0 00-1.1.2 20.519 20.519 0 01-5.168 5.154.79.79 0 10.9 1.3 22.088 22.088 0 005.564-5.548.789.789 0 00-.196-1.106z"
        />
        <path
          data-name="Path 362"
          d="M15.216 43.757a20.522 20.522 0 01-5.064-5.08.788.788 0 00-1.293.9 22.1 22.1 0 005.452 5.469.789.789 0 10.906-1.291z"
        />
        <path
          data-name="Path 363"
          d="M9.054 15.482a.788.788 0 001.1-.2 20.523 20.523 0 015.064-5.08.789.789 0 00-.906-1.291A22.1 22.1 0 008.86 14.38a.788.788 0 00.194 1.102z"
        />
      </g>
    </g>
  </svg>
);

SvgActiveUsers.defaultProps = {};
export default SvgActiveUsers;
/* eslint-enable */
