import user_to_job_stateEnum from '../../common/user_to_job_state.enum';
import { UserProfileViewModel } from '../../types/auth.types';
import { AppliedJobViewModel, SavedJobViewModel } from '../../types/jobs.types';
import { API_METHODS } from './api.enums';
import newAPIService from './newAPIService';
import { apiAction } from './utils.actions';

export type UserToJobApplyPayload = {
    cvid: number,
    message: string
}
export type UserApplyToJobParams = { jobid: number, userid: number }
export type UserApplyResponse = { userid: number }
export const userApplyToJob = (
    params: UserApplyToJobParams, payload: UserToJobApplyPayload, backdrop,
) => apiAction<UserApplyResponse>({
    url: `/jobs/${params.jobid}/users/${params.userid}`,
    method: API_METHODS.POST,
    payload: { ...payload, state: user_to_job_stateEnum.LIKE },
    backdrop,
});

export type SystemUserToJobApplyPayload = {
}
export type SystemUserApplyToJobParams = { jobid: number, userid: number }
export type SystemUserApplyResponse = { userid: number }
export const systemUserApplyToJob = (
    params: SystemUserApplyToJobParams,
) => apiAction<SystemUserApplyResponse>({
    url: `/system/jobs/${params.jobid}/users/${params.userid}`,
    method: API_METHODS.POST,
    backdrop: true,
});

export const userDislikeJob = async (jobid: number, userid: number) => newAPIService.post(
    `/jobs/${jobid}/users/${userid}`,
    { state: user_to_job_stateEnum.DISLIKE },
);

export const userSaveJob = async (jobid: number, userid: number) => newAPIService.post(
    `/users/${userid}/savedJobs/${jobid}`,
);
export const userUnsaveJob = async (jobid: number, userid: number) => newAPIService.delete(
    `/users/${userid}/savedJobs/${jobid}`,
);

export type GetUserAppliedJobsParams = { userid: number }
export type GetUserAppliedJobsQuery = {
    start: number,
    orderby: string,
    direction: 'ASC' | 'DESC'
}
export type GetUserAppliedJobsResponse = {
    appliedJobs: AppliedJobViewModel[]
    totalAppliedJobs: number
}
export const getUserAppliedJobs = (
    params: GetUserAppliedJobsParams, query: GetUserAppliedJobsQuery, backdrop?: boolean,
) => apiAction<GetUserAppliedJobsResponse>({
    url: `/users/${params.userid}/appliedJobs`,
    method: API_METHODS.GET,
    query,
    backdrop,
});

export type GetUserSavedJobsParams = { userid: number }
export type GetUserSavedJobsQuery = {
    start: number,
    orderby: string,
    direction: 'ASC' | 'DESC'
}
export type GetUserSavedJobsResponse = {
    savedJobs: SavedJobViewModel[]
    totalSavedJobs: number
}
export const getUserSavedJobs = (
    params: GetUserSavedJobsParams, query: GetUserSavedJobsQuery, backdrop?: boolean,
) => apiAction<GetUserSavedJobsResponse>({
    url: `/users/${params.userid}/savedJobs`,
    method: API_METHODS.GET,
    query,
    backdrop,
});

type RemoveUserSavedJobParams = {
    jobid: number
    userid: number
}
export const removeUserSavedJob = (
    params: RemoveUserSavedJobParams, backdrop?: boolean,
) => apiAction<GetUserSavedJobsResponse>({
    url: `/users/${params.userid}/savedJobs/${params.jobid}`,
    method: API_METHODS.DELETE,
    backdrop,
});

export type GetJobApplicationsParams = { jobid: number }
export type GetJobApplicationsQuery = {
    start: number,
    orderby: string,
    direction: 'ASC' | 'DESC'
}
export type AppliedUserViewModel = UserProfileViewModel & { /** תאריך הגשת מועמדות */ updated_at: string }
export type GetJobApplicationsResponse = {
    appliedUsers: AppliedUserViewModel[]
    totalAppliedUsers: number
}
export const getJobApplications = (
    params: GetJobApplicationsParams, query: GetJobApplicationsQuery, backdrop?: boolean,
) => apiAction<GetJobApplicationsResponse>({
    url: `/jobs/${params.jobid}/appliedUsers`,
    method: API_METHODS.GET,
    query,
    backdrop,
});
