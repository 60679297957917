/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgTransportsAndVehicles = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.943} viewBox="0 0 28.943 21.905" {...props}>
    <g data-name="Transports and Vehicles" transform="translate(0 -62.25)">
      <path
        data-name="Path 1122"
        d="M19.192 77.371v2.544h-5.568l-3.392-2.544z"
        fill="#03984b"
      />
      <path
        data-name="Path 1123"
        d="M27.359 72.745l-1.384-.461v5.936l2.544-.848v-3.018a1.7 1.7 0 00-1.16-1.609z"
        fill="#03984b"
      />
      <path
        data-name="Path 1124"
        d="M26.823 78.22H10.232l3.392-11.306a1.7 1.7 0 011.7-1.7h3.968a3.392 3.392 0 012.785 1.455l3.9 5.611a1.7 1.7 0 01.848 1.469z"
        fill="#12b05e"
      />
      <path
        data-name="Path 1125"
        d="M10.232 76.524l1.7 3.392h1.7v-1.7z"
        fill="#eeb700"
      />
      <path
        data-name="Path 1126"
        d="M8.055 76.524v3.392h3.872v-1.7z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 1127"
        d="M13.623 74.404v3.816h-1.7l-2.172-6.36z"
        fill="#eeb700"
      />
      <path
        data-name="Path 1128"
        d="M11.924 69.316v8.9h-9.8l-1.7-.848V74.4z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 1129"
        d="M13.623 74.404h-1.7L6.84 68.539l5.088-5.865h1.7z"
        fill="#ececec"
      />
      <path
        data-name="Path 1130"
        d="M.424 62.674h11.5v11.73H.424z"
        fill="#fff"
      />
      <path
        data-name="Path 1131"
        d="M20.679 67.641a1.7 1.7 0 00-1.392-.727h-2.066l4.622 5.37h2.066z"
        fill="#e0e5eb"
      />
      <path
        data-name="Path 1132"
        d="M18.614 67.641a1.7 1.7 0 00-1.392-.727h-1.919v5.37h6.541z"
        fill="#fff"
      />
      <path
        data-name="Path 1133"
        d="M28.519 77.371v2.544h-1.7l-1.7-1.272 1.7-1.272z"
        fill="#eeb700"
      />
      <path
        data-name="Path 1134"
        d="M.424 77.371h5.195v2.544H.424z"
        fill="#ffcd27"
      />
      <path
        data-name="Path 1135"
        d="M23.307 77.371h3.516v2.544h-3.516z"
        fill="#ffcd27"
      />
      <circle
        data-name="Ellipse 80"
        cx={3.816}
        cy={3.816}
        r={3.816}
        transform="translate(17.496 76.1)"
        fill="#2d5093"
      />
      <path
        data-name="Path 1136"
        d="M21.311 77.795v4.24a2.12 2.12 0 000-4.24z"
        fill="#e5f2ff"
      />
      <path
        data-name="Path 1137"
        d="M21.736 79.915c0-1.171-.19-2.12-.424-2.12a2.12 2.12 0 100 4.24c.234 0 .424-.949.424-2.12z"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 81"
        cx={3.816}
        cy={3.816}
        r={3.816}
        transform="translate(2.12 76.1)"
        fill="#2d5093"
      />
      <path
        data-name="Path 1138"
        d="M5.936 77.795v4.24a2.12 2.12 0 100-4.24z"
        fill="#e5f2ff"
      />
      <path
        data-name="Path 1139"
        d="M6.36 79.915c0-1.171-.19-2.12-.424-2.12a2.12 2.12 0 100 4.24c.234 0 .424-.949.424-2.12z"
        fill="#fff"
      />
      <path
        data-name="Path 1140"
        d="M5.936 77.371a2.544 2.544 0 102.544 2.544 2.547 2.547 0 00-2.544-2.544zm0 4.24a1.7 1.7 0 111.7-1.7 1.7 1.7 0 01-1.7 1.7z"
      />
      <path
        data-name="Path 1141"
        d="M21.312 77.371a2.544 2.544 0 102.544 2.544 2.547 2.547 0 00-2.544-2.544zm0 4.24a1.7 1.7 0 111.7-1.7 1.7 1.7 0 01-1.7 1.7z"
      />
      <path
        data-name="Path 1142"
        d="M27.493 72.343l-1.251-.417-3.823-5.5a3.817 3.817 0 00-3.132-1.637h-3.968a2.109 2.109 0 00-1.272.425v-2.54a.424.424 0 00-.424-.424H.424a.424.424 0 00-.424.424v17.241a.424.424 0 00.424.424h1.293a4.24 4.24 0 008.437 0h6.939a4.24 4.24 0 008.437 0h2.989a.424.424 0 00.424-.424v-5.561a2.117 2.117 0 00-1.45-2.011zM13.2 63.1v9.61a.424.424 0 00.848 0v-5.796a1.273 1.273 0 011.272-1.272h3.968a2.969 2.969 0 012.436 1.273l3.9 5.611a.424.424 0 00.214.16l1.384.461a1.27 1.27 0 01.87 1.207v2.593h-3.76a4.237 4.237 0 00-7.242 2.544h-3.043V74.4a.424.424 0 00-.424-.424H.848V63.1zM2.912 76.947H.848v-2.12H13.2V77.8H9.6a4.233 4.233 0 00-6.693-.848zm7.068 1.7h3.22v.848h-3.046a4.21 4.21 0 00-.174-.852zM.848 77.8h1.418a4.208 4.208 0 00-.549 1.7H.848zm5.088 5.512a3.392 3.392 0 113.392-3.392 3.4 3.4 0 01-3.392 3.387zm15.376 0a3.392 3.392 0 113.388-3.397 3.4 3.4 0 01-3.389 3.392zm4.218-3.816a4.208 4.208 0 00-.549-1.7h3.114v1.7z"
      />
      <path
        data-name="Path 1143"
        d="M15.303 72.708h8.606a.424.424 0 00.348-.666l-3.23-4.643a2.121 2.121 0 00-1.74-.909h-3.984a.424.424 0 00-.424.424v5.37a.424.424 0 00.424.424zm.424-5.37h3.56a1.272 1.272 0 011.044.546l2.766 3.977h-7.371z"
      />
    </g>
  </svg>
);

SvgTransportsAndVehicles.defaultProps = {};
export default SvgTransportsAndVehicles;
/* eslint-enable */
