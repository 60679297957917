/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgElectricityAndElectronics = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25.24} viewBox="0 0 25.24 33.638" {...props}>
    <g data-name="Electricity and Electronics">
      <path
        data-name="Path 175"
        d="M9.997 30.515v.009a2.623 2.623 0 005.245 0v-.009z"
        fill="#3e5564"
      />
      <path
        data-name="Path 176"
        d="M15.078 30.524h-4.917a1.475 1.475 0 110-2.95h4.917a1.475 1.475 0 010 2.95z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 177"
        d="M12.128 30.524h-1.967a1.475 1.475 0 110-2.95h1.967a1.475 1.475 0 000 2.95z"
        fill="#d1d1df"
      />
      <path
        data-name="Path 178"
        d="M15.078 27.574h-4.917a1.475 1.475 0 110-2.95h4.917a1.475 1.475 0 010 2.95z"
        fill="#f9f9fb"
      />
      <path
        data-name="Path 179"
        d="M12.128 27.574h-1.967a1.475 1.475 0 110-2.95h1.967a1.475 1.475 0 000 2.95z"
        fill="#d1d1df"
      />
      <path
        data-name="Path 180"
        d="M21.568 12.727a8.948 8.948 0 10-14.39 7.1 3.9 3.9 0 011.508 3.1 1.7 1.7 0 001.7 1.7h4.47a1.7 1.7 0 001.7-1.7 3.966 3.966 0 011.55-3.129 8.932 8.932 0 003.462-7.071z"
        fill="#12b05e"
      />
      <path
        data-name="Path 181"
        d="M11.15 24.127a1.694 1.694 0 001.2.5h-1.967a1.7 1.7 0 01-1.7-1.7 3.9 3.9 0 00-1.508-3.1 8.948 8.948 0 015.549-16.05c.3 0 .589.022.878.053a8.948 8.948 0 00-4.46 16 3.9 3.9 0 011.508 3.1 1.693 1.693 0 00.5 1.197z"
        fill="#03984b"
      />
      <g data-name="Group 46">
        <path
          data-name="Path 182"
          d="M4.46 14.958a8.573 8.573 0 017.147-10.623.492.492 0 00-.118-.976A9.556 9.556 0 003.511 15.22q.23.442.6.345a.492.492 0 00.349-.607z"
        />
        <path
          data-name="Path 183"
          d="M13.748 3.354a.492.492 0 00-.116.976 8.456 8.456 0 014.172 15.078 4.493 4.493 0 00-1.74 3.518 1.326 1.326 0 01-1.475 1.206v-9.321h1.092a2.065 2.065 0 100-4.13h-.01a2.067 2.067 0 00-2.067 2.065v1.082h-1.967v-1.082a2.067 2.067 0 00-2.065-2.065h-.01a2.065 2.065 0 000 4.13h1.092v9.325a1.324 1.324 0 01-1.475-1.206 4.412 4.412 0 00-1.7-3.487 8.438 8.438 0 01-2.246-2.6.492.492 0 10-.859.479 9.416 9.416 0 002.506 2.9 3.423 3.423 0 011.316 2.707 2.337 2.337 0 00.737 1.638 2.09 2.09 0 00-.737 1.535 1.962 1.962 0 00.667 1.474 2.1 2.1 0 00-.667 1.476 1.973 1.973 0 001.336 1.863 3.113 3.113 0 006.178 0 2.089 2.089 0 001.336-1.863 1.962 1.962 0 00-.667-1.474 2.1 2.1 0 00.667-1.476 1.964 1.964 0 00-.739-1.535 2.185 2.185 0 00.739-1.638 3.551 3.551 0 011.36-2.741 9.44 9.44 0 00-4.655-16.831zm.837 9.392a1.083 1.083 0 011.082-1.082h.01a1.082 1.082 0 010 2.163h-1.092zM9.56 13.828a1.082 1.082 0 010-2.163h.01a1.083 1.083 0 011.082 1.082v1.082zm2.075.983h1.969v9.325h-1.967zm.983 17.843a2.134 2.134 0 01-2.073-1.639h4.145a2.134 2.134 0 01-2.071 1.64zm2.458-7.539a.984.984 0 010 1.967h-.71a.492.492 0 000 .983h.71a.984.984 0 01.983.983 1.018 1.018 0 01-.983.983h-4.917a.984.984 0 010-1.967h2.064a.492.492 0 000-.983h-2.064a.984.984 0 010-1.967z"
        />
      </g>
      <path
        data-name="Path 184"
        d="M12.62 2.36a.492.492 0 01-.492-.492V.492a.492.492 0 11.983 0v1.376a.492.492 0 01-.491.492z"
      />
      <g data-name="Group 47">
        <path
          data-name="Path 185"
          d="M5.017 5.509a.49.49 0 01-.348-.144l-.973-.973a.495.495 0 01.7-.7l.973.973a.492.492 0 01-.348.839z"
        />
        <path
          data-name="Path 186"
          d="M21.195 21.687a.49.49 0 01-.348-.144l-.973-.973a.495.495 0 11.7-.7l.973.973a.492.492 0 01-.348.839z"
        />
      </g>
      <g data-name="Group 48">
        <path
          data-name="Path 187"
          d="M1.868 13.111H.492a.492.492 0 110-.983h1.377a.492.492 0 110 .983z"
        />
        <path
          data-name="Path 188"
          d="M24.747 13.111h-1.376a.492.492 0 110-.983h1.377a.492.492 0 010 .983z"
        />
      </g>
      <g data-name="Group 49">
        <path
          data-name="Path 189"
          d="M4.044 21.687a.492.492 0 01-.348-.839l.973-.973a.495.495 0 01.7.7l-.973.973a.49.49 0 01-.352.139z"
        />
        <path
          data-name="Path 190"
          d="M20.222 5.509a.492.492 0 01-.348-.839l.973-.973a.495.495 0 01.7.7l-.973.973a.49.49 0 01-.352.139z"
        />
      </g>
    </g>
  </svg>
);

SvgElectricityAndElectronics.defaultProps = {};
export default SvgElectricityAndElectronics;
/* eslint-enable */
