import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import CustomBR from '../../foundation/CustomBR';
import { colCenteredFlex } from '../../foundation/Flex';
import SvgActiveUsers from '../../svg/generated/components/ActiveUsers';
import SvgJobsOnSite from '../../svg/generated/components/JobsOnSite';
import SvgResumeViewedToday from '../../svg/generated/components/ResumeViewedToday';
import SvgUpToDateJobsFromToday from '../../svg/generated/components/UpToDateJobsFromToday';
import { fixNumberCommas } from '../../utils/math.utils';

export type AppNumbersProps = {
    numberOfJobs: number
    numberOfTodayJobs: number
    numberOfUsers: number
    numberOfTodayWatchedCV: number
}

const AppNumbersStyled = styled.div`
    background-color:#fafafb;
    z-index: 0;
    position: relative;
    h3.MuiTypography-root {
        font-weight: bold;
    }
    p.MuiTypography-root {
        opacity: 0.6;
    }
    .MuiContainer-root {
        ${colCenteredFlex}
    }
    ${props => props.theme.breakpoints.up('md')} {
        .MuiGrid-container {
            .MuiGrid-item:nth-child(2) {
                > div {
                    margin-top: -4rem;
                }
            }
            .MuiGrid-item:nth-child(4) {
                > div {
                    margin-top: -4rem;
                }
            }
        }
    }
`;
const NumbersItemStyled = styled.div`
    background: white;
    padding: 3rem 2rem;
    box-shadow: 5px 5px 0 1px #ffd83d;
    border-radius: 1rem;

    h3 {
        margin: 1rem 0;
    }
    &:hover {
        background-color: #ffd83d;
    }
`;

const AppNumbersBG = styled.div`
    position: absolute;
    background-color: ${props => props.theme.palette.secondary.main};
    height: 26rem;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
`;

const AppNumbers = (props: AppNumbersProps): JSX.Element => <AppNumbersStyled>
    <AppNumbersBG />
    <Container>
        <CustomBR times={2} />
        <Typography variant="h3" color="white">המספרים מדברים</Typography>
        <CustomBR />
        <Typography variant="body1" color="white">
            רק משרות חמות ועדכניות, שקיפות מלאה ומעקב מלא אחר תהליך הגשת המועמדות
        </Typography>
        <CustomBR times={5} />
        <Grid container spacing={3}>
            <Grid item lg={3} md={6} xs={12}>
                <NumbersItemStyled>
                    <SvgJobsOnSite />
                    <Typography color="secondary" variant="h3">
                        {fixNumberCommas(props.numberOfJobs?.toString())}
                    </Typography>
                    <Typography color="secondary" variant="body1">משרות באתר</Typography>
                </NumbersItemStyled>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
                <NumbersItemStyled>
                    <SvgActiveUsers />
                    <Typography color="secondary" variant="h3">
                        {fixNumberCommas(props.numberOfUsers?.toString())}
                    </Typography>
                    <Typography color="secondary" variant="body1">משתמשים פעילים</Typography>
                </NumbersItemStyled>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
                <NumbersItemStyled>
                    <SvgResumeViewedToday />
                    <Typography color="secondary" variant="h3">
                        {fixNumberCommas(props.numberOfTodayWatchedCV?.toString())}
                    </Typography>
                    <Typography color="secondary" variant="body1">קורות חיים נצפו היום</Typography>
                </NumbersItemStyled>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
                <NumbersItemStyled>
                    <SvgUpToDateJobsFromToday />
                    <Typography color="secondary" variant="h3">
                        {fixNumberCommas(props.numberOfTodayJobs?.toString())}
                    </Typography>
                    <Typography color="secondary" variant="body1">משרות עדכניות מהיום</Typography>
                </NumbersItemStyled>
            </Grid>
        </Grid>
        <CustomBR times={6} />
    </Container>
</AppNumbersStyled>;

export default React.memo(AppNumbers);
