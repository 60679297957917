import {
    Container, Grid, Typography,
} from '@mui/material';
import styled from 'styled-components';
import {
    QueryBuilder, EmailOutlined, LocationOnOutlined, Mail, PersonOutline, FacebookOutlined, Title, PhoneAndroid,
} from '@mui/icons-material';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { LoggedInUser } from '../../../types/auth.types';
import { postContactUs } from '../../api/contactus.api';
import { emailPattern } from '../../utils/forms.utils';
import Alert from '../foundation/Alert';
import Anchor from '../foundation/Anchor';
import Button from '../foundation/Button';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import { VCenteredFlex } from '../foundation/Flex';
import TextArea from '../foundation/TextArea';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';

export type AboutProps = {
    url: string
    user?: LoggedInUser
}

interface IFormContactUs {
    fullname: string;
    email: string;
    subject: string
    message: string
}

const IconBoxWrapper = styled.div`
    border: 1px solid;
    border-radius: 5px;
    padding: 0.4rem 0.5rem 0.2rem;
    margin-inline-end: 1rem;
    border-color: ${props => props.theme.palette.secondary.main};
`;

const About = (props: AboutProps): JSX.Element => {
    const {
        register, handleSubmit, errors,
    } = useForm<IFormContactUs>();

    const [success, setSuccess] = useState<boolean>(null);

    const onSubmit = async (data: IFormContactUs) => {
        window.showBackdrop();
        await postContactUs(data);
        window.hideBackdrop();
        setSuccess(true);
    };

    return <Page
        showBreadCrumbs={false}
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - דרושים - צור קשר" >
        <Container>
            <CustomBR />
            <Typography fontWeight="bold" color="secondary" variant="h3">צור איתנו קשר!</Typography>
            <CustomBR />
            <BGContainer>
                <Typography variant="h6">צריך עזרה? מידע נוסף? אנחנו נשמח לשמוע ממך!</Typography>
                <CustomBR />
                <Grid container>
                    <Grid item md={3}>
                        <Typography variant="h5" color="secondary">המשרדים שלנו</Typography>
                        <div>
                            <CustomBR />
                            <CustomBR />
                            <VCenteredFlex>
                                <IconBoxWrapper>

                                    <LocationOnOutlined color="secondary" />
                                </IconBoxWrapper>
                                <Typography display="inline">בן יהודה, תל אביב</Typography>
                            </VCenteredFlex>
                            <CustomBR />
                            <VCenteredFlex>
                                <IconBoxWrapper>
                                    <EmailOutlined color="secondary" />
                                </IconBoxWrapper>
                                <Anchor text="info@mploy.co.il" href="mailto:info@mploy.co.il" />
                            </VCenteredFlex>
                            <CustomBR />
                        </div>
                        <CustomBR />
                        <CustomBR />
                        <Typography variant="h5" color="secondary">שעות פעילות</Typography>
                        <div>
                            <CustomBR />
                            <VCenteredFlex>
                                <IconBoxWrapper>
                                    <QueryBuilder color="secondary" />
                                </IconBoxWrapper>
                                <Typography display="inline">ראשון - חמישי : 17:00 - 9:00</Typography>
                            </VCenteredFlex>
                        </div>
                        <CustomBR />
                        <CustomBR />
                        <Typography variant="h5" color="secondary">Mploy ברשת</Typography>
                        <div>
                            <CustomBR />
                            <VCenteredFlex>
                                <IconBoxWrapper>
                                    <Anchor href="https://www.facebook.com/mploy100/" blank>
                                        <FacebookOutlined color="secondary" />
                                    </Anchor>
                                </IconBoxWrapper>
                                <Anchor href="https://www.facebook.com/mploy100/" blank>הצטרפו אלינו בפייסבוק</Anchor>
                            </VCenteredFlex>
                        </div>
                    </Grid>
                    <Grid item md={9}>
                        <WhiteBGContainer>
                            {success ? <Alert severity="success">ההודעה נשלחה בהצלחה! נשתדל לחזור אליך במהרה.</Alert> : <>
                                <div>
                                    <TextField
                                        required
                                        inputRef={register({ required: true })}
                                        name="fullname"
                                        id="fullname"
                                        label="שם מלא"
                                        type="text"
                                        fullWidth
                                        error={!!errors.fullname}
                                        helperText={errors.fullname && 'חובה להזין שם'}
                                        InputProps={{
                                            endAdornment: <PersonOutline />,
                                        }}
                                    />
                                </div>
                                <CustomBR />
                                <div>
                                    <TextField
                                        inputRef={register({ required: false })}
                                        name="phone"
                                        id="phone"
                                        label="מספר טלפון"
                                        type="text"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <PhoneAndroid />,
                                        }}
                                    />
                                </div>
                                <CustomBR />
                                <div>
                                    <TextField
                                        required
                                        inputRef={register({ required: true, pattern: emailPattern })}
                                        name="email"
                                        id="email"
                                        label="כתובת אימייל"
                                        type="email"
                                        autoComplete="username"
                                        fullWidth
                                        error={!!errors.email}
                                        helperText={errors.email && 'כתובת המייל אינה תקינה'}
                                        InputProps={{
                                            endAdornment: <Mail />,
                                        }}
                                    />
                                </div>
                                <CustomBR />
                                <div>
                                    <TextField
                                        required
                                        inputRef={register({ required: true })}
                                        name="subject"
                                        id="subject"
                                        label="נושא"
                                        type="text"
                                        fullWidth
                                        error={!!errors.subject}
                                        helperText={errors.subject && 'חובה להזין נושא'}
                                        InputProps={{
                                            endAdornment: <Title />,
                                        }}
                                    />
                                </div>
                                <CustomBR />
                                <div>
                                    <TextArea
                                        required
                                        inputRef={register({ required: true })}
                                        name="message"
                                        id="message"
                                        label="הודעה"
                                        type="text"
                                        fullWidth
                                        error={!!errors.message}
                                        helperText={errors.message && 'חובה להזין הודעה'}
                                        rows={6}
                                    />
                                </div>
                                <CustomBR />
                                <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>שלח הודעה</Button>
                            </>}
                        </WhiteBGContainer>
                    </Grid>
                </Grid>
            </BGContainer>
        </Container>
        <CustomBR />
    </Page>;
};

export default About;
