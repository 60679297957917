/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgOptics = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30.215} viewBox="0 0 30.215 9" {...props}>
    <path
      data-name="Path 807"
      d="M8.622 8.442a5.2 5.2 0 01-6.128-5.114V1.986A1.514 1.514 0 014.008.472h8.072a1.514 1.514 0 011.514 1.514v.5a6.055 6.055 0 01-4.972 5.956z"
      fill="#12b05e"
    />
    <path
      data-name="Path 808"
      d="M5.518 6.004A5.547 5.547 0 0110.683.473H4.006a1.514 1.514 0 00-1.514 1.514v1.342a5.192 5.192 0 003.5 4.906 5.526 5.526 0 01-.474-2.231z"
      fill="#03984b"
    />
    <path
      data-name="Path 809"
      d="M21.593 8.442a5.2 5.2 0 006.128-5.114V1.986A1.514 1.514 0 0026.207.472h-8.074a1.514 1.514 0 00-1.514 1.514v.5a6.055 6.055 0 004.974 5.956z"
      fill="#12b05e"
    />
    <path
      data-name="Path 810"
      d="M19.647 6.004A5.547 5.547 0 0124.812.473h-6.677a1.514 1.514 0 00-1.514 1.514v.5a6.055 6.055 0 003.368 5.424 5.528 5.528 0 01-.342-1.907z"
      fill="#03984b"
    />
    <g data-name="Group 259" fill="#959cb5">
      <path
        data-name="Path 811"
        d="M.978 1.463a.5.5 0 00-.5.5v.5a1.009 1.009 0 001.009 1.009h1.009V1.453H.978z"
      />
      <path
        data-name="Path 812"
        d="M29.237 1.463h-1.514v2.019h1.009a1.009 1.009 0 001.009-1.009v-.5a.5.5 0 00-.504-.51z"
      />
    </g>
    <path
      data-name="Path 813"
      d="M29.237 1.009h-1.3A1.988 1.988 0 0026.208 0H4.006a1.988 1.988 0 00-1.729 1.009H.978A.979.979 0 000 1.987v.5a1.484 1.484 0 001.482 1.482h.573A5.666 5.666 0 008.7 8.908a6.526 6.526 0 005.2-4.975 1.231 1.231 0 012.4 0 6.527 6.527 0 005.2 4.976 5.671 5.671 0 006.649-4.934h.573a1.484 1.484 0 001.482-1.482v-.5a.979.979 0 00-.967-.984zm-27.219.978v1.041h-.536a.537.537 0 01-.536-.536v-.5a.031.031 0 01.032-.031h1.041v.026zm11.1.5A5.58 5.58 0 018.534 7.98a4.725 4.725 0 01-5.57-4.648V1.99A1.042 1.042 0 014.005.949h8.075a1.042 1.042 0 011.041 1.041v.5zm3.028-.5v.3a2.146 2.146 0 00-2.082 0v-.3a1.975 1.975 0 00-.3-1.041h2.672a1.974 1.974 0 00-.288 1.041zm11.1 1.342a4.725 4.725 0 01-5.571 4.648 5.58 5.58 0 01-4.584-5.492v-.5A1.042 1.042 0 0118.132.944h8.074a1.042 1.042 0 011.041 1.041v1.342zm2.019-.837a.537.537 0 01-.536.536H28.2V1.956h1.042a.031.031 0 01.032.031v.5z"
    />
    <path
      data-name="Path 814"
      d="M11.567 3.023a.473.473 0 00-.575.342 3.564 3.564 0 01-2.819 2.627 2.778 2.778 0 01-.5.045.473.473 0 100 .946 3.719 3.719 0 00.664-.06 4.51 4.51 0 003.572-3.328.473.473 0 00-.342-.572z"
    />
    <path
      data-name="Path 815"
      d="M25.705 2.855a.473.473 0 00-.473.473 2.7 2.7 0 01-2.7 2.708 2.786 2.786 0 01-.5-.046.473.473 0 00-.169.931 3.736 3.736 0 00.666.061 3.652 3.652 0 003.641-3.654.473.473 0 00-.465-.473z"
    />
  </svg>
);

SvgOptics.defaultProps = {};
export default SvgOptics;
/* eslint-enable */
