/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgRetail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.176} viewBox="0 0 27.176 26.413" {...props}>
    <path
      data-name="Path 564"
      d="M.398 7.836v15.752a.991.991 0 00.991.991h15.626a.991.991 0 00.991-.991V7.836a.991.991 0 00-.991-.991H1.389a.991.991 0 00-.991.991zm0 0"
      fill="#f2eff2"
    />
    <path
      data-name="Path 565"
      d="M17.015 6.845h-1.194a.991.991 0 01.991.991v15.752a.991.991 0 01-.991.991h1.194a.991.991 0 00.991-.991V7.836a.991.991 0 00-.991-.991zm0 0"
      fill="#e8edf2"
    />
    <path
      data-name="Path 566"
      d="M14.113 8.815h-1.122a.208.208 0 01-.208-.208v-5.6a1.073 1.073 0 00-1.073-1.073H6.695a1.073 1.073 0 00-1.073 1.073v5.6a.208.208 0 01-.208.208H4.292a.208.208 0 01-.208-.208V2.681A2.283 2.283 0 016.366.399h5.672a2.283 2.283 0 012.283 2.283v5.926a.208.208 0 01-.208.208zm0 0"
      fill="#c0694e"
    />
    <path
      data-name="Path 567"
      d="M26.044 14.872H13.652a.739.739 0 00-.737.791l.682 9.572a.84.84 0 00.838.78h10.823a.84.84 0 00.838-.78l.682-9.572a.739.739 0 00-.734-.791zm-9.786 3.329a.963.963 0 11.963-.963.963.963 0 01-.968.962zm7.185 0a.963.963 0 11.963-.963.963.963 0 01-.968.962zm0 0"
      fill="#12b05e"
    />
    <path
      data-name="Path 568"
      d="M26.039 14.872h-1.288a.739.739 0 01.737.791l-.682 9.572a.84.84 0 01-.838.78h1.288a.84.84 0 00.838-.78l.682-9.572a.739.739 0 00-.737-.791zm0 0"
      fill="#009e4c"
    />
    <path
      data-name="Path 569"
      d="M26.872 14.836a1.14 1.14 0 00-.832-.362h-2.2v-.216a3.991 3.991 0 10-7.981 0v.216h-2.2a1.137 1.137 0 00-1.134 1.218l.436 6.123H.8V7.836a.593.593 0 01.593-.593h2.3v1.364a.607.607 0 00.606.606h1.114a.607.607 0 00.606-.606V7.243h6.365v1.364a.607.607 0 00.606.606h1.122a.607.607 0 00.606-.606V7.243h2.3a.594.594 0 01.593.593v1.68a.4.4 0 00.8 0v-1.68a1.391 1.391 0 00-1.389-1.389h-2.3V2.681a2.684 2.684 0 00-2.684-2.68H6.366a2.684 2.684 0 00-2.681 2.68v1.926a.4.4 0 00.8 0V2.681A1.887 1.887 0 016.366.801h5.672a1.887 1.887 0 011.885 1.88v5.736h-.742V3.009a1.473 1.473 0 00-1.471-1.471H6.694a1.473 1.473 0 00-1.471 1.471v5.408h-.742V6.201a.4.4 0 00-.8 0v.248h-2.3A1.391 1.391 0 000 7.836v15.753a1.391 1.391 0 001.389 1.389h11.79l.02.286a1.242 1.242 0 001.235 1.15h10.823a1.242 1.242 0 001.235-1.15l.221-3.1a.4.4 0 00-.794-.057l-.221 3.1a.443.443 0 01-.441.41H14.434a.443.443 0 01-.441-.41l-.046-.647v-.018l-.167-2.34v-.035l-.465-6.532a.341.341 0 01.34-.365h2.2v.667a1.361 1.361 0 10.8 0v-.667h6.385v.667a1.361 1.361 0 10.8 0v-.667h2.2a.341.341 0 01.34.365l-.348 4.888a.4.4 0 10.794.057l.348-4.888a1.14 1.14 0 00-.3-.856zM6.694 2.335h5.016a.675.675 0 01.675.674v3.438H6.02V3.009a.675.675 0 01.675-.674zM1.389 24.181a.594.594 0 01-.589-.593v-.977h12.21l.112 1.571zm15.429-6.944a.565.565 0 11-.913-.444.4.4 0 00.7 0 .564.564 0 01.216.444zm7.185 0a.565.565 0 11-.913-.444.4.4 0 00.7 0 .564.564 0 01.216.444zm-5.6-2.764v-1.436a.4.4 0 10-.8 0v1.437h-.957v-.216a3.195 3.195 0 116.389 0v.216zm0 0"
    />
  </svg>
);

SvgRetail.defaultProps = {};
export default SvgRetail;
/* eslint-enable */
