/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgInformationSystems = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18.618} viewBox="0 0 18.618 26.444" {...props}>
    <g data-name="Information Systems">
      <g data-name="Group 232">
        <g data-name="Group 227">
          <path
            data-name="Path 702"
            d="M.387 22.509h14.609v2.5a1.043 1.043 0 01-1.043 1.043H1.43a1.043 1.043 0 01-1.043-1.043z"
            fill="#12b05e"
          />
        </g>
        <g data-name="Group 228">
          <path
            data-name="Path 703"
            d="M13.534 22.509v2.5a1.043 1.043 0 01-1.043 1.043h1.461a1.043 1.043 0 001.043-1.043v-2.5z"
            fill="#12b05e"
          />
        </g>
        <g data-name="Group 229">
          <path
            data-name="Path 704"
            d="M14.996 22.509H.387V1.43A1.043 1.043 0 011.43.387h12.522a1.043 1.043 0 011.043 1.043v21.079z"
            fill="#12b05e"
          />
        </g>
        <g data-name="Group 230">
          <path
            data-name="Path 705"
            d="M13.952.387h-1.463a1.043 1.043 0 011.043 1.043v1.409a.522.522 0 01-.522.522H4.662a2.087 2.087 0 00-2.087 2.087v10.439a2.087 2.087 0 002.087 2.087h.1v2.413a.992.992 0 001.693.7l2.816-2.816a1.043 1.043 0 01.738-.306h3a.522.522 0 01.522.522v4.017h1.461V1.43a1.043 1.043 0 00-1.04-1.043z"
            fill="#12b05e"
          />
        </g>
        <g data-name="Group 231">
          <path
            data-name="Path 706"
            d="M4.561.387h6.261l-.3 1.025a1.043 1.043 0 01-1 .749H5.866a1.043 1.043 0 01-1-.749z"
            fill="#12b05e"
          />
        </g>
      </g>
      <g data-name="Group 239">
        <g data-name="Group 233">
          <path
            data-name="Path 707"
            d="M17.187 4.405H4.665a1.043 1.043 0 00-1.043 1.043v10.435a1.043 1.043 0 001.043 1.043h1.148v3.339l3.339-3.339h8.035a1.043 1.043 0 001.043-1.043V5.448a1.043 1.043 0 00-1.043-1.043z"
            fill="#ffe07d"
          />
        </g>
        <g data-name="Group 234">
          <path
            data-name="Path 708"
            d="M17.187 4.405H15.57a1.043 1.043 0 011.043 1.043v10.435a1.043 1.043 0 01-1.043 1.039h1.617a1.043 1.043 0 001.043-1.043V5.448a1.043 1.043 0 00-1.043-1.043z"
            fill="#ffd064"
          />
        </g>
        <g data-name="Group 235">
          <path
            data-name="Path 709"
            d="M5.813 18.649v1.617l3.339-3.339H7.967a1.044 1.044 0 00-.738.306z"
            fill="#ffd064"
          />
        </g>
        <g data-name="Group 238">
          <g data-name="Group 236">
            <path
              data-name="Path 710"
              d="M12.283 13.587V9.413h-2.4v1.67h.73v2.5h-.73v1.67h3.13v-1.67z"
              fill="#5766cb"
            />
          </g>
          <g data-name="Group 237">
            <circle
              data-name="Ellipse 56"
              cx={0.835}
              cy={0.835}
              r={0.835}
              fill="#5766cb"
              transform="translate(10.613 6.074)"
            />
          </g>
        </g>
      </g>
      <g data-name="Group 240">
        <path
          data-name="Path 711"
          d="M14.996 20.659a.387.387 0 00-.387.387v1.075H.775V8.396a.387.387 0 00-.775 0v16.617a1.432 1.432 0 001.431 1.431h12.521a1.432 1.432 0 001.431-1.431v-3.967a.387.387 0 00-.387-.387zm-1.043 5.01H1.431a.657.657 0 01-.656-.656v-2.117h13.834v2.117a.657.657 0 01-.657.656z"
        />
        <path
          data-name="Path 712"
          d="M18.231 13.261a.387.387 0 00.387-.387V5.448a1.432 1.432 0 00-1.431-1.431h-1.8V1.431A1.432 1.432 0 0013.952 0H1.431A1.432 1.432 0 000 1.431v5.156a.387.387 0 00.775 0V1.431a.657.657 0 01.656-.656h2.841l.22.747a1.439 1.439 0 001.373 1.027h3.656a1.439 1.439 0 001.373-1.027l.22-.747h2.841a.657.657 0 01.656.656v2.586H4.666a1.432 1.432 0 00-1.428 1.431v10.435a1.432 1.432 0 001.431 1.431h.76v2.952a.387.387 0 00.661.274l3.226-3.226h5.3v1.923a.387.387 0 10.775 0v-1.923h1.8a1.432 1.432 0 001.431-1.431v-1.2a.387.387 0 10-.775 0v1.2a.657.657 0 01-.656.656H9.153a.388.388 0 00-.274.113L6.201 19.33v-2.4a.387.387 0 00-.387-.387H4.666a.657.657 0 01-.656-.656V5.448a.657.657 0 01.656-.656h12.521a.657.657 0 01.656.656v7.426a.387.387 0 00.387.387zM10.149 1.3a.66.66 0 01-.629.471H5.864a.66.66 0 01-.626-.471L5.079.775h5.225z"
        />
        <path
          data-name="Path 713"
          d="M9.885 13.2a.387.387 0 00-.387.387v1.67a.387.387 0 00.387.387h3.13a.387.387 0 00.387-.387v-1.67a.387.387 0 00-.387-.387h-.343V9.413a.387.387 0 00-.387-.387h-2.4a.387.387 0 00-.387.387v1.67a.387.387 0 00.387.387h.343v1.73zm.387.775h.343a.387.387 0 00.387-.387v-2.5a.387.387 0 00-.387-.387h-.343v-.895h1.625v3.787a.387.387 0 00.387.387h.343v.895h-2.356v-.895z"
        />
        <path
          data-name="Path 714"
          d="M12.672 6.909a1.222 1.222 0 10-1.222 1.222 1.224 1.224 0 001.222-1.222zm-1.67 0a.447.447 0 11.447.447.448.448 0 01-.448-.447z"
        />
      </g>
    </g>
  </svg>
);

SvgInformationSystems.defaultProps = {};
export default SvgInformationSystems;
/* eslint-enable */
