import React from 'react';
import {
    FormControlLabel, FormGroup, Hidden, Typography,
} from '@mui/material';
import RemovableTag from '../../foundation/RemovableTag';
import CollapseableArea from '../CollapseableArea';
import Checkbox from '../../foundation/Checkbox';
import CustomBR from '../../foundation/CustomBR';
import { ExtendedTagViewModel } from '../../../../types/tags.types';
import Anchor from '../../foundation/Anchor';

type SelectableTagsProps = {
    tags: ExtendedTagViewModel[]
    removeTag: (tag: ExtendedTagViewModel) => void
    onTagSelect: (tag: ExtendedTagViewModel) => void
}
const SelectableTags = ({ tags, removeTag, onTagSelect }: SelectableTagsProps) => <>{tags.map(tag => <div key={tag.tagid}>
    <FormControlLabel
        control={<Checkbox
            onChange={() => (tag.selected ? removeTag(tag) : onTagSelect(tag))}
            defaultChecked={tag.selected}
            name="showAppliedJobs"
        />}
        label={`${tag.tag} ${tag.totalJobs ? `(${tag.totalJobs})` : ''}`}
    />
</div>)}</>;

type TagsFiltersProps = {
    tags: ExtendedTagViewModel[],
    rootTags: ExtendedTagViewModel[],
    verticalTags: ExtendedTagViewModel[],
    relatedTags: ExtendedTagViewModel[],
    removeTag: (tag: ExtendedTagViewModel) => void
    onRootTagSelect?: (tag: ExtendedTagViewModel) => void
    onTagSelect: (tag: ExtendedTagViewModel) => void
    onCategorySelect: (tag?: ExtendedTagViewModel) => void
}
const ROOT_TAG_LEVEL = 1;

const TagsFilters = React.memo((props: TagsFiltersProps) => {
    const {
        verticalTags, tags, rootTags, relatedTags,
    } = props;
    const selectedRootTags = tags?.find(t => t.level === ROOT_TAG_LEVEL);
    const group1 = verticalTags.filter(t => t.groupid === 1);
    const group2 = verticalTags.filter(t => t.groupid === 2);
    const group3 = verticalTags.filter(t => t.groupid === 3);
    const group4 = verticalTags.filter(t => t.groupid === 4);
    const group5 = verticalTags.filter(t => t.groupid === 5);

    return <>
        <Hidden mdDown>
            <CustomBR h={33} />
        </Hidden>
        {tags && <>
            <div>{tags.map(t => <RemovableTag
                key={t.display}
                onRemoveClick={() => props.removeTag(t)}
                text={t.tag} />,
            )}</div>
            <CustomBR />
        </>}
        <CollapseableArea title="תחומים">
            <>
                <div>
                    <Anchor text="כל התחומים" href="#"
                        color={selectedRootTags ? '#9cadb8' : 'secondary'}
                        onClick={ev => { ev.preventDefault(); props.onCategorySelect(); }} />
                </div>
                {rootTags.map(tag => <div key={tag.tagid}>
                    <Anchor
                        onClick={ev => {
                            ev.preventDefault();
                            if (props.onRootTagSelect) props.onRootTagSelect(tag);
                            else window.location.assign(`/job/tagged/${tag.display}`);
                        }}
                        href="#"
                        text={tag.tag}
                        color={tags?.find(t => t.display === tag.display) ? 'secondary' : '#9cadb8'} />
                </div>)}
            </>
        </CollapseableArea>
        <CustomBR />
        {selectedRootTags && <CollapseableArea showAll title="תפקידים">
            <FormGroup>
                <SelectableTags tags={relatedTags?.filter(t => t.level !== ROOT_TAG_LEVEL)}
                    onTagSelect={props.onTagSelect} removeTag={props.removeTag} />
            </FormGroup>
        </CollapseableArea>}
        <CustomBR />
        <CollapseableArea showAll title={group1[0].groupname}>
            <FormGroup>
                <SelectableTags tags={group1} onTagSelect={props.onTagSelect} removeTag={props.removeTag} />
            </FormGroup>
        </CollapseableArea>
        <CustomBR />
        <CollapseableArea showAll title={group2[0].groupname}>
            <FormGroup>
                <SelectableTags tags={group2} onTagSelect={props.onTagSelect} removeTag={props.removeTag} />
            </FormGroup>
        </CollapseableArea>
        <CustomBR />
        <CollapseableArea showAll title="מאפיינים נוספים">
            <CustomBR />
            <FormGroup>
                <Typography color="secondary" variant="h5" fontWeight="bold">{group4[0].groupname}</Typography>
                <SelectableTags tags={group4} onTagSelect={props.onTagSelect} removeTag={props.removeTag} />
                <CustomBR />
                <Typography color="secondary" variant="h5" fontWeight="bold">{group3[0].groupname}</Typography>
                <SelectableTags tags={group3} onTagSelect={props.onTagSelect} removeTag={props.removeTag} />
                <CustomBR />
                <Typography color="secondary" variant="h5" fontWeight="bold">{group5[0].groupname}</Typography>
                <SelectableTags tags={group5} onTagSelect={props.onTagSelect} removeTag={props.removeTag} />
            </FormGroup>
        </CollapseableArea>
    </>;
});

export default TagsFilters;
