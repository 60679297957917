/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgB2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} viewBox="0 0 60 60" {...props}>
    <circle
      data-name="Ellipse 13"
      cx={30}
      cy={30}
      r={30}
      fill="#fff"
      opacity={0.1}
    />
    <g data-name="Group 40">
      <path
        data-name="Path 174"
        d="M32.631 30.371l-6.4-6.4a.567.567 0 01.8-.8l6.8 6.8a.567.567 0 010 .8l-6.8 6.8a.567.567 0 01-.8-.8z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={2.5}
      />
    </g>
  </svg>
);

SvgB2.defaultProps = {};
export default SvgB2;
/* eslint-enable */
