import { AxiosError } from 'axios';
import newAPIService from './newAPIService';

 type UploadCompanyCVParams = { companyId: number };
 type UploadCompanyCVPayload = FormData;
export const uploadCompanyLogo = async (params: UploadCompanyCVParams, data: UploadCompanyCVPayload): Promise<void> => {
    try {
        return await newAPIService.put(
            `/companies/${params.companyId}/logo`,
            data,
        );
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.response.data);
    }
};
