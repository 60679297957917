import { Component } from 'react';

let PlotComponent;

export type PlotProps = {
    data: any[],
    layout: any,
    style: any,
}
type PlotState = {
    showPlot?: boolean
}
export default class Plot extends Component<PlotProps, PlotState> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    Plotly: any

    async componentDidMount() {
        this.Plotly = (await import(/* webpackChunkName: "plotly-basic" */ 'plotly.js-dist-min')).default;
        const createPlotlyComponent = (await import(/* webpackChunkName: "react-plotly-factory" */ 'react-plotly.js/factory')).default;
        PlotComponent = createPlotlyComponent(this.Plotly);
        this.setState({ showPlot: true });
    }

    render() {
        const { showPlot } = this.state;
        const { layout = {}, style = {} } = this.props;

        layout.xaxis = {
            ...(layout.xaxis || {}),
        };
        layout.yaxis = {
            ticks: 'outside',
            linewidth: 1,
            ...(layout.yaxis || {}),
        };
        layout.autosize = true;

        // if (layout.title) layout.title = layout.title.split('').reverse().join('');
        return showPlot ? <PlotComponent
            useResizeHandler
            data={this.props.data}
            layout={layout}
            style={{ ...style }}
        /> : '';
    }
}
