/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgHotJobFromFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width={52.138} viewBox="0 0 52.138 52.138" {...props}>
    <g data-name="Hot Job From Facebook" transform="translate(-.001)">
      <circle
        data-name="Ellipse 34"
        cx={25.246}
        cy={25.246}
        r={25.246}
        transform="translate(.824 .823)"
        fill="#12b05e"
      />
      <path
        data-name="Path 297"
        d="M26.07.823a25.478 25.478 0 00-3.293.214 25.248 25.248 0 010 50.064A25.247 25.247 0 1026.07.823z"
        fill="#03984b"
      />
      <path
        data-name="Path 298"
        d="M17.289 26.078v1.1H9.605v7.688h7.684v14.9a25.13 25.13 0 007.683 1.546v-16.45h12.074v-7.685H24.972v-1.1c0-4.246 3.44-6.59 7.683-6.59h4.391v-7.688h-4.391c-7.88.001-15.366 6.394-15.366 14.279z"
        fill="#fcfcfc"
      />
      <path
        data-name="Path 299"
        d="M44.5 7.635A26.07 26.07 0 1017 50.516a.825.825 0 00.944-.275.853.853 0 00.166-.52V34.85a.823.823 0 00-.823-.823h-6.86V27.99h6.86a.823.823 0 00.823-.823v-1.1c0-7.163 6.8-13.446 14.544-13.446h3.567v6.039h-3.565a9.5 9.5 0 00-5.948 1.883 6.832 6.832 0 00-2.558 5.526v1.1a.823.823 0 00.823.823h3.246a.823.823 0 00.823-.823.823.823 0 00-.823-.823H25.8v-.274c0-4.253 3.7-5.763 6.86-5.763h4.391a.823.823 0 00.823-.823V11.8a.823.823 0 00-.823-.823h-4.395a16.934 16.934 0 00-11.191 4.392 14.4 14.4 0 00-5 10.7v.274h-6.86a.823.823 0 00-.823.823v7.684a.823.823 0 00.823.823h6.86v12.856a24.434 24.434 0 119.6 1.962h-.274V35.673h11.255a.823.823 0 00.823-.823v-7.684a.823.823 0 00-.823-.823h-4.818a.823.823 0 00-.823.823.823.823 0 00.823.823h4v6.037H24.972a.823.823 0 00-.823.823v16.44a.823.823 0 00.787.822c.408.017.779.026 1.134.026A26.069 26.069 0 0044.5 7.635z"
      />
    </g>
  </svg>
);

SvgHotJobFromFacebook.defaultProps = {};
export default SvgHotJobFromFacebook;
/* eslint-enable */
