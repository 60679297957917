/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgExactSciences = (props: SVGProps<SVGSVGElement>) => (
  <svg width={26.767} viewBox="0 0 26.767 29.507" {...props}>
    <g data-name="Exact Sciences">
      <g data-name="Group 278">
        <circle
          data-name="Ellipse 58"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#8ac9fe"
          transform="translate(23.606 .432)"
        />
      </g>
      <g data-name="Group 279">
        <circle
          data-name="Ellipse 59"
          cx={1.365}
          cy={1.365}
          r={1.365}
          transform="translate(19.024 24.665)"
          fill="#8ac9fe"
        />
      </g>
      <g data-name="Group 280">
        <circle
          data-name="Ellipse 60"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#fdb441"
          transform="translate(23.606 5.939)"
        />
      </g>
      <g data-name="Group 281">
        <circle
          data-name="Ellipse 61"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#fdb441"
          transform="translate(12.56 26.345)"
        />
      </g>
      <g data-name="Group 282">
        <circle
          data-name="Ellipse 62"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#fdb441"
          transform="translate(23.023 20.747)"
        />
      </g>
      <g data-name="Group 283">
        <circle
          data-name="Ellipse 63"
          cx={1.365}
          cy={1.365}
          r={1.365}
          transform="translate(.432 16.762)"
          fill="#8ac9fe"
        />
      </g>
      <g data-name="Group 284">
        <circle
          data-name="Ellipse 64"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#ee6161"
          transform="translate(5.939 9.595)"
        />
      </g>
      <g data-name="Group 285">
        <circle
          data-name="Ellipse 65"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#ee6161"
          transform="translate(5.939 16.762)"
        />
      </g>
      <g data-name="Group 286">
        <circle
          data-name="Ellipse 66"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#ee6161"
          transform="translate(19.245 9.595)"
        />
      </g>
      <g data-name="Group 287">
        <circle
          data-name="Ellipse 67"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#ee6161"
          transform="translate(19.245 16.762)"
        />
      </g>
      <g data-name="Group 288">
        <circle
          data-name="Ellipse 68"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#ee6161"
          transform="translate(12.56 5.406)"
        />
      </g>
      <g data-name="Group 289">
        <circle
          data-name="Ellipse 69"
          cx={1.365}
          cy={1.365}
          r={1.365}
          fill="#ee6161"
          transform="translate(12.56 20.838)"
        />
      </g>
      <g data-name="Group 290">
        <path
          data-name="Path 882"
          d="M26.335 1.797a1.365 1.365 0 01-2.636.5 1.367 1.367 0 001.584-1.35 1.349 1.349 0 00-.093-.5 1.365 1.365 0 011.145 1.35z"
          fill="#60b7ff"
        />
      </g>
      <g data-name="Group 291">
        <path
          data-name="Path 883"
          d="M21.354 26.995a1.365 1.365 0 01-2.237-.47 1.365 1.365 0 001.492-1.842 1.365 1.365 0 01.745 2.312z"
          fill="#60b7ff"
        />
      </g>
      <g data-name="Group 292">
        <path
          data-name="Path 884"
          d="M26.335 7.304a1.365 1.365 0 01-2.636.5 1.367 1.367 0 001.584-1.348 1.345 1.345 0 00-.093-.495 1.365 1.365 0 011.145 1.343z"
          fill="#fea613"
        />
      </g>
      <g data-name="Group 293">
        <path
          data-name="Path 885"
          d="M15.29 27.71a1.364 1.364 0 01-2.636.495 1.31 1.31 0 00.219.018 1.365 1.365 0 001.365-1.365 1.346 1.346 0 00-.093-.495 1.364 1.364 0 011.145 1.347z"
          fill="#fea613"
        />
      </g>
      <g data-name="Group 294">
        <path
          data-name="Path 886"
          d="M25.353 23.077a1.365 1.365 0 01-2.237-.469 1.365 1.365 0 001.491-1.842 1.365 1.365 0 01.745 2.312z"
          fill="#fea613"
        />
      </g>
      <g data-name="Group 295">
        <path
          data-name="Path 887"
          d="M3.161 18.127a1.365 1.365 0 01-2.636.5 1.368 1.368 0 001.584-1.347 1.348 1.348 0 00-.093-.5 1.365 1.365 0 011.145 1.347z"
          fill="#60b7ff"
        />
      </g>
      <g data-name="Group 296">
        <path
          data-name="Path 888"
          d="M8.668 10.96a1.365 1.365 0 01-2.636.5 1.367 1.367 0 001.584-1.347 1.346 1.346 0 00-.093-.5 1.365 1.365 0 011.145 1.347z"
          fill="#e94444"
        />
      </g>
      <g data-name="Group 297">
        <path
          data-name="Path 889"
          d="M8.668 18.126a1.365 1.365 0 01-2.636.5 1.367 1.367 0 001.584-1.347 1.351 1.351 0 00-.093-.5 1.366 1.366 0 011.145 1.347z"
          fill="#e94444"
        />
      </g>
      <g data-name="Group 298">
        <path
          data-name="Path 890"
          d="M21.975 10.96a1.365 1.365 0 01-2.637.495 1.311 1.311 0 00.219.018 1.365 1.365 0 001.365-1.365 1.346 1.346 0 00-.093-.5 1.365 1.365 0 011.146 1.352z"
          fill="#e94444"
        />
      </g>
      <g data-name="Group 299">
        <path
          data-name="Path 891"
          d="M21.975 18.126a1.365 1.365 0 01-2.637.5 1.31 1.31 0 00.219.018 1.365 1.365 0 001.365-1.365 1.351 1.351 0 00-.093-.5 1.366 1.366 0 011.146 1.347z"
          fill="#e94444"
        />
      </g>
      <g data-name="Group 300">
        <path
          data-name="Path 892"
          d="M15.29 6.771a1.364 1.364 0 01-2.636.495 1.311 1.311 0 00.219.018 1.365 1.365 0 001.365-1.365 1.345 1.345 0 00-.093-.495 1.364 1.364 0 011.145 1.347z"
          fill="#e94444"
        />
      </g>
      <g data-name="Group 301">
        <path
          data-name="Path 893"
          d="M15.29 22.203a1.364 1.364 0 01-2.636.495 1.31 1.31 0 00.219.018 1.365 1.365 0 001.365-1.365 1.345 1.345 0 00-.093-.495 1.364 1.364 0 011.145 1.347z"
          fill="#e94444"
        />
      </g>
      <path
        data-name="Path 894"
        d="M25.402 5.56V3.541a1.8 1.8 0 10-.864 0V5.56a1.8 1.8 0 00-1.197 2.511l-1.756 1.381a1.79 1.79 0 00-1.787-.1l-4.1-2.317a1.8 1.8 0 10-3.553 0l-1.332.765a.432.432 0 10.428.748l1.246-.7a1.8 1.8 0 002.881 0l3.749 2.12a1.8 1.8 0 001.062 2.74v3.679a1.8 1.8 0 00-1.115 2.657l-3.7 2.091a1.8 1.8 0 00-2.881 0l-3.65-2.064a1.8 1.8 0 00-1.1-2.683V12.7a1.8 1.8 0 001.048-2.762l.721-.408a.432.432 0 10-.425-.752l-.994.562a1.8 1.8 0 10-1.21 3.363v3.679a1.8 1.8 0 00-1.312 1.312h-2.02a1.8 1.8 0 100 .864H5.56a1.8 1.8 0 002.626 1.133l3.963 2.241a1.8 1.8 0 001.344 2.014v2.019a1.8 1.8 0 10.864 0v-2.018a1.8 1.8 0 001.344-2.014l3.995-2.259a1.792 1.792 0 001.707.065l1.446 1.446a1.8 1.8 0 000 1.855l-1.483 1.483a1.8 1.8 0 10.591.631l1.5-1.5a1.8 1.8 0 002.537-.738.432.432 0 00-.773-.385.933.933 0 11-1.495-1.075.93.93 0 011.142-.138.432.432 0 10.448-.739 1.791 1.791 0 00-1.859 0l-1.395-1.395a1.795 1.795 0 00-1.024-2.8V12.7a1.8 1.8 0 001.137-2.618l1.717-1.342a1.8 1.8 0 101.51-3.181zM13.925 7.7a.933.933 0 11.933-.933.934.934 0 01-.933.933zm-7.554 3.26a.933.933 0 11.933.933.934.934 0 01-.933-.933zm-4.574 8.1a.933.933 0 11.933-.933.934.934 0 01-.933.933zm4.574-.933a.933.933 0 111.865 0 .945.945 0 01-.933.933.934.934 0 01-.933-.933zm8.487 9.583a.933.933 0 11-.933-.933.934.934 0 01.933.933zm-.933-4.574a.933.933 0 11.933-.933.934.934 0 01-.933.933zm7.123 3.554a.932.932 0 110-1.319.934.934 0 010 1.318zM24.041 1.8a.933.933 0 11.933.933.934.934 0 01-.933-.933zm-2.5 16.33a.95.95 0 01-.933.933.933.933 0 11.933-.933zm-.933-6.234a.933.933 0 11.933-.933.934.934 0 01-.932.93zm4.361-3.656a.933.933 0 11.933-.933.934.934 0 01-.932.93z"
      />
    </g>
  </svg>
);

SvgExactSciences.defaultProps = {};
export default SvgExactSciences;
/* eslint-enable */
