import { AxiosError, AxiosResponse } from 'axios';
import { LoggedInUser } from '../../types/auth.types';
import { RESPONSE_TEXT } from './api.enums';
import newAPIService from './newAPIService';

export type RegisterUserBody = {
    email: string
    password: string
    lastname?: string
    firstname?: string
    smartAgentByActivity?: boolean
}

export const registerUser = async (data: RegisterUserBody): Promise<AxiosResponse<LoggedInUser>> => {
    try {
        return await newAPIService.post('/register', data);
    } catch (error) {
        let message;
        const err = error as AxiosError;
        switch (err.response.status) {
        case 400: {
            if (RESPONSE_TEXT.BAD_REQUEST === err.response.data) {
                message = 'שם משתמש או סיסמא שגויים';
            } else {
                message = err.response.data;
            }
            break;
        }
        case 419:
            message = 'כתובת המייל כבר קיימת במערכת. נסה לבצע התחברות.';
            break;
        default:
            message = 'אירעה שגיאה, אנא נסה שנית';
            break;
        }
        throw new Error(message);
    }
};

/**
 * @param {Object} data
 * @param {string} data.password
 * @param {string} data.username
 */
export type LoginApiPayload = {
    password: string,
    username: string,
}
export const loginApi = async (data: LoginApiPayload) => {
    try {
        return await newAPIService.post('/login', data);
    } catch (error) {
        const err = error as AxiosError;
        let message;
        switch (err.response.status) {
        case 401:
            message = 'שם משתמש או סיסמא שגויים';
            break;
        default:
            message = 'אירעה שגיאה, אנא נסה שנית';
            break;
        }
        throw new Error(message);
    }
};

export type ResetPasswordBody = {
    email: string;
}
export const resetPassword = async (data: ResetPasswordBody) => newAPIService.post('/users/resetPassword', data);

export type ResetUserPasswordQuery = {
    userid: string;
    safeid?: string;
}

export type ResetUserPasswordBody = {
    password: string;
}

export const resetUserPassword = async (
    query: ResetUserPasswordQuery, data: ResetUserPasswordBody,
) => newAPIService.post(
    `/users/resetPassword/${query.userid}?safeid=${query.safeid}`, data,
);

type SendUserEmailConfirmationParams = { userid: number }
export const sendUserEmailConfirmation = async (
    params: SendUserEmailConfirmationParams,
) => newAPIService.get(
    `/message/users/${params.userid}/email-confirmation`,
);
