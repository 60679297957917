/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgComputerAndNetworks = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28.883} viewBox="0 0 28.883 23.144" {...props}>
    <g data-name="Computer and Networks" transform="translate(0 -50.868)">
      <path
        data-name="Path 1082"
        d="M19.996 69.356H1.736a1.313 1.313 0 01-1.313-1.313V52.604a1.313 1.313 0 011.313-1.313h18.26a1.313 1.313 0 011.313 1.313v15.439a1.313 1.313 0 01-1.313 1.313z"
        fill="#f9f6f6"
      />
      <path
        data-name="Path 1083"
        d="M19.996 51.291h-.989a1.313 1.313 0 011.313 1.313 1.349 1.349 0 01-1.349 1.349H18a1.27 1.27 0 00-1.27 1.27v14.133h3.266a1.313 1.313 0 001.313-1.313V52.604a1.313 1.313 0 00-1.313-1.313z"
        fill="#efe7e4"
      />
      <path
        data-name="Path 1084"
        d="M2.285 67.262V53.386a.233.233 0 01.233-.233h16.7a.233.233 0 01.233.233v13.876a.233.233 0 01-.233.233h-16.7a.233.233 0 01-.233-.233z"
        fill="#12b05e"
      />
      <path
        data-name="Path 1085"
        d="M19.214 53.153h-1.018a.233.233 0 01.233.233v.138a.43.43 0 01-.43.43 1.27 1.27 0 00-1.27 1.27v12.271h2.484a.233.233 0 00.233-.233V53.386a.233.233 0 00-.232-.233z"
        fill="#03984b"
      />
      <path
        data-name="Path 1086"
        d="M15.667 71.01h-9.6a.377.377 0 01-.377-.377v-1.277h10.356v1.277a.377.377 0 01-.379.377z"
        fill="#544f57"
      />
      <path
        data-name="Path 1087"
        d="M14.976 69.352v1.277a.377.377 0 01-.377.377h1.068a.377.377 0 00.377-.377v-1.277z"
        fill="#454045"
      />
      <path
        data-name="Path 1088"
        d="M8.124 71.01h5.473v2.58H8.124z"
        fill="#f9f6f6"
      />
      <path
        data-name="Path 1089"
        d="M8.124 71.01v.732h3.837a.784.784 0 01.784.784v1.064h.852v-2.58z"
        fill="#efe7e4"
      />
      <path
        data-name="Path 1090"
        d="M27.377 73.588h-8.443a1.082 1.082 0 01-1.082-1.082V56.084a1.082 1.082 0 011.082-1.082h8.443a1.082 1.082 0 011.082 1.082v16.422a1.082 1.082 0 01-1.082 1.082z"
        fill="#544f57"
      />
      <path
        data-name="Path 1091"
        d="M27.377 55.002h-1.054a1.082 1.082 0 011.082 1.082v16.422a1.082 1.082 0 01-1.082 1.082h1.054a1.082 1.082 0 001.082-1.082V56.084a1.082 1.082 0 00-1.082-1.082z"
        fill="#454045"
      />
      <circle
        data-name="Ellipse 77"
        cx={1.175}
        cy={1.175}
        r={1.175}
        transform="translate(21.981 65.712)"
        fill="#ffcd27"
      />
      <path
        data-name="Path 1092"
        d="M23.156 65.712a1.17 1.17 0 00-.4.073 1.174 1.174 0 010 2.2 1.175 1.175 0 10.4-2.277z"
        fill="#ebb501"
      />
      <circle
        data-name="Ellipse 78"
        cx={1.175}
        cy={1.175}
        r={1.175}
        transform="translate(21.981 69.562)"
        fill="#ffcd27"
      />
      <path
        data-name="Path 1093"
        d="M23.156 69.562a1.17 1.17 0 00-.4.073 1.174 1.174 0 010 2.2 1.175 1.175 0 10.4-2.277z"
        fill="#ebb501"
      />
      <g data-name="Group 360">
        <path
          data-name="Path 1094"
          d="M25.607 56.542h-4.9a.423.423 0 000 .846h4.9a.423.423 0 000-.846z"
        />
        <path
          data-name="Path 1095"
          d="M25.607 58.758h-4.9a.423.423 0 000 .846h4.9a.423.423 0 000-.846z"
        />
        <path
          data-name="Path 1096"
          d="M26.03 61.397a.423.423 0 00-.423-.423h-4.9a.423.423 0 000 .846h4.9a.423.423 0 00.423-.423z"
        />
        <path
          data-name="Path 1097"
          d="M23.156 65.289a1.6 1.6 0 101.6 1.6 1.6 1.6 0 00-1.6-1.6zm0 2.35a.752.752 0 11.752-.752.753.753 0 01-.752.752z"
        />
        <path
          data-name="Path 1098"
          d="M23.156 69.139a1.6 1.6 0 101.6 1.6 1.6 1.6 0 00-1.6-1.6zm0 2.35a.752.752 0 11.752-.752.753.753 0 01-.752.752z"
        />
        <path
          data-name="Path 1099"
          d="M20.011 63.308h-.615a.423.423 0 000 .846h.615a.423.423 0 100-.846z"
        />
        <path
          data-name="Path 1100"
          d="M22.525 63.731a.423.423 0 00-.423-.423h-.615a.423.423 0 100 .846h.615a.423.423 0 00.423-.423z"
        />
        <path
          data-name="Path 1101"
          d="M28.46 62.835a.423.423 0 00.423-.423v-6.328a1.507 1.507 0 00-1.505-1.505h-5.646V52.6A1.738 1.738 0 0020 50.868H5.688a.423.423 0 000 .846H20a.891.891 0 01.89.89v1.975h-1.02v-1.194a.657.657 0 00-.656-.656H2.518a.657.657 0 00-.656.656v13.877a.657.657 0 00.656.656H4a.423.423 0 100-.846H2.708v-13.5h16.316v1h-.09a1.507 1.507 0 00-1.505 1.505v10.994H5.688a.423.423 0 000 .846h11.741v1.015H1.736a.891.891 0 01-.89-.89V52.6a.891.891 0 01.89-.89H4a.423.423 0 000-.846H1.736A1.738 1.738 0 000 52.6v15.443a1.738 1.738 0 001.736 1.736h3.529v.854a.8.8 0 00.8.8h1.641v1.733H6.127a.423.423 0 100 .846H15.6a.423.423 0 100-.846h-1.574v-1.733h1.641a.8.8 0 00.8-.8v-.854h.962v2.727a1.507 1.507 0 001.505 1.505h8.443a1.507 1.507 0 001.505-1.505V64.1a.423.423 0 10-.846 0v8.4a.66.66 0 01-.659.659h-8.443a.66.66 0 01-.659-.659V56.084a.66.66 0 01.659-.659h8.443a.66.66 0 01.659.659v6.328a.423.423 0 00.424.423zm-15.28 10.33H8.552v-1.732h4.628zm2.441-2.579h-9.51v-.808h9.509z"
        />
      </g>
    </g>
  </svg>
);

SvgComputerAndNetworks.defaultProps = {};
export default SvgComputerAndNetworks;
/* eslint-enable */
