import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import Alert from '../foundation/Alert';

export type UserNotificationProps = {
    id: string | number
    children?: JSX.Element | JSX.Element[]
    severity: 'error' | 'success' | 'info' | 'warning'
    message?: string
    blink?: boolean
}

const UserNotification = (props: UserNotificationProps): JSX.Element => {
    const [fadeIn, setFadeIn] = useState<boolean>(true);
    useEffect(() => {
        window.scroll({ top: 0, left: 0 });
        if (props.blink) {
            setFadeIn(false);
            setTimeout(() => setFadeIn(true), 250);
        }
    }, [props.id]);
    return <div>
        <Fade in={fadeIn}>
            <div>
                <Alert severity={props.severity}><>
                    {props.message}
                    {props.children}
                </></Alert>
            </div>
        </Fade>
    </div>;
};

export default UserNotification;
