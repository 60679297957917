import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Container, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import Anchor from '../foundation/Anchor';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

type BlogItem = {
    title: string,
    img: string
    link: string
}

export type BlogPageProps = {
    url: string
    blogItems?: { [key: string]: BlogItem }
    title: string
    user?: LoggedInUser
}

const BlogPage = (props: BlogPageProps): JSX.Element => <Page
    showBreadCrumbs
    url={props.url}
    user={props.user}
    pageHeaderTitle={`Mploy - ${props.title}`} >
    <Container>
        <CustomBR />
        <BGContainer>
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - {props.title}</Typography>
            <CustomBR />
            <Grid container spacing={5}>
                {Object.keys(props.blogItems).map(key => {
                    const blogItem = props.blogItems[key];
                    return <Grid item lg={3}> <Card>
                        <CardMedia
                            component="img"
                            height="140"
                            image={blogItem.img}
                            alt={blogItem.title}
                        />
                        <CardContent >
                            <Box flexDirection="column" display="flex" alignItems="center">
                                <Anchor href={blogItem.link} color="secondary" fontWeight="bold" variant="h6">
                                    {blogItem.title}
                                </Anchor>
                            </Box>
                        </CardContent>
                    </Card></Grid>;
                })}
            </Grid>
        </BGContainer>
    </Container>
    <CustomBR />
</Page>;

export default BlogPage;
