/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgNext = (props: SVGProps<SVGSVGElement>) => (
  <svg width={6.01} viewBox="0 0 6.01 9.308" {...props}>
    <g data-name="Group 141" opacity={0.2}>
      <path
        data-name="Path 401"
        d="M4.267 4.658l-3.11-3.11a.275.275 0 01.389-.389l3.3 3.3a.275.275 0 010 .389l-3.3 3.3a.275.275 0 01-.389-.389z"
        fill="#10334b"
        stroke="#10334b"
        strokeWidth={2}
      />
    </g>
  </svg>
);

SvgNext.defaultProps = {};
export default SvgNext;
/* eslint-enable */
