/* eslint-disable max-len */
import {
    Container, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import { JobSearchResultViewModel } from '../../../types/jobs.types';
import JobItem from '../components/JobItem';
import Anchor from '../foundation/Anchor';
import { ShadowBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import { CenteredFlex } from '../foundation/Flex';
import Page from '../layout/base.page';

export type UnkownEmployerRemoveJobProps = {
    url: string
    user?: LoggedInUser
    job: JobSearchResultViewModel
    safeid: string
    jobid: string
}

const UnkownEmployerRemoveJob = (props: UnkownEmployerRemoveJobProps): JSX.Element => <Page
    showBreadCrumbs={false}
    url={props.url}
    user={props.user}
    pageHeaderTitle="Mploy - הסרת משרה מהמערכת" >
    <Container>
        <CustomBR />
        <Typography color="secondary" fontWeight="bold" variant="h3">הסרת משרה</Typography>
        <CustomBR />
        <JobItem
            lightMode
            showRemoveJob={false}
            job={props.job}
            showFullDetails
        />
        <CustomBR />
        <ShadowBGContainer>
            <Typography>
                אנו עושים כל מאמץ על מנת לשלוח אליכם קורות חיים של מועמדים מתאימים, השירות ניתן ללא תשלום.
            </Typography>
            <CustomBR />
            <div>
                <Typography display="inline">נשמח לעמוד לשירותכם ולענות לכם על כל שאלה,</Typography>
                <Anchor href="/home/contactus" text="לחץ כאן ליצירת קשר" />.
            </div>
            <CustomBR />
            <CustomBR />
            <Typography>
                יחד עם זאת, במידה ואינך מעוניין לקבל יותר קורות חיים ממערכת Mploy אנא בחר אחת מהאפשרויות הבאות :
            </Typography>
        </ShadowBGContainer>

        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
                <ShadowBGContainer><CenteredFlex>
                    <Anchor blank href={`/unkownEmployer/removeJob/${props.jobid}?safeid=${props.safeid}`}>
                        <CenteredFlex>&nbsp;הפסקת קבלת קו"ח למשרה הנ"ל</CenteredFlex>
                    </Anchor>
                </CenteredFlex></ShadowBGContainer>
            </Grid>
            <Grid item sm={12} md={6}>
                <ShadowBGContainer><CenteredFlex>
                    <Anchor blank href={`/unkownEmployer/removeAll/${props.jobid}?safeid=${props.safeid}`}>
                        <CenteredFlex>&nbsp;הפסקת קבלת קו"ח עבור כל המשרות שפירסמתי ואפרסם</CenteredFlex>
                    </Anchor>
                </CenteredFlex></ShadowBGContainer>
            </Grid>
        </Grid>
    </Container>
    <CustomBR />
</Page>;

export default UnkownEmployerRemoveJob;
