import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import CustomBR from '../../foundation/CustomBR';
import Button from '../../foundation/Button/Button';

export type GlobalModalProps = {
    title: string
    text: string
    open?: boolean
    onClose: () => void
    leftBtnClick?: () => void
    leftBtnText?: string
    rightBtnClick?: () => void
    rightBtnText?: string
};

const GlobalModalStyle = styled.div`
`;
const GlobalModalFooterStyle = styled.div`
    .MuiButton-root {
        margin-inline-end: 1rem;
    }
`;

const GlobalModal = (props: GlobalModalProps) => <DialogWithHeader
    open={props.open}
    renderFooter={props.leftBtnClick ? () => (<GlobalModalFooterStyle><Box display="flex" justifyContent="end">
        {props.rightBtnClick && <Button onClick={props.rightBtnClick} variant="outlined">{props.rightBtnText}</Button>}
        <Button onClick={props.leftBtnClick}>{props.leftBtnText}</Button>
    </Box></GlobalModalFooterStyle>) : null}
    onClose={props.onClose} title={props.title}>
    <GlobalModalStyle>
        <CustomBR />
        {props.text}
        <CustomBR />
    </GlobalModalStyle>
</DialogWithHeader>;

export default GlobalModal;
