/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgWrench = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30.468} viewBox="0 0 30.468 28.823" {...props}>
    <path
      data-name="Path 402"
      d="M26.174 13.353a7.561 7.561 0 001.757-7.94l-3.848 3.848-3.556-.953-.953-3.556L23.422.904a7.562 7.562 0 00-10 8.645 3.235 3.235 0 01-1.014 3.088l-10.721 9.48a3.568 3.568 0 105.031 5.031l9.48-10.722a3.235 3.235 0 013.088-1.014 7.553 7.553 0 006.888-2.056z"
      fill="#12b05e"
    />
    <path
      data-name="Path 403"
      d="M27.931 5.413l-.693.693a7.561 7.561 0 01-9.061 8.195 3.235 3.235 0 00-3.088 1.014L5.61 26.037a3.569 3.569 0 01-4.613.69 3.569 3.569 0 005.721.418l9.479-10.724a3.235 3.235 0 013.088-1.014 7.562 7.562 0 008.645-10z"
      fill="#03984b"
    />
    <path
      data-name="Path 404"
      d="M29.76 17.959l-2.666-1.539a.522.522 0 00-.522 0l-2.667 1.539a.522.522 0 00-.261.452v3.083a.522.522 0 00.261.452l2.667 1.539a.522.522 0 00.522 0l2.666-1.539a.522.522 0 00.261-.452v-3.079a.522.522 0 00-.261-.456zm-2.927 3.193a1.2 1.2 0 111.2-1.2 1.2 1.2 0 01-1.2 1.2z"
      fill="#3e5564"
    />
    <path
      data-name="Path 405"
      d="M20.532 21.56l-2.666-1.539a.522.522 0 00-.522 0l-2.666 1.539a.522.522 0 00-.261.452v3.079a.522.522 0 00.261.452l2.666 1.539a.522.522 0 00.522 0l2.666-1.539a.522.522 0 00.261-.452v-3.079a.522.522 0 00-.261-.452zm-2.928 3.193a1.2 1.2 0 111.2-1.2 1.2 1.2 0 01-1.2 1.2z"
      fill="#3e5564"
    />
    <path
      data-name="Path 406"
      d="M29.76 17.959l-2.666-1.539a.522.522 0 00-.522 0l-.47.272 2.2 1.268a.522.522 0 01.261.452v3.082a.522.522 0 01-.261.452l-2.2 1.268.47.272a.522.522 0 00.522 0l2.666-1.539a.522.522 0 00.261-.452v-3.079a.522.522 0 00-.261-.457z"
      fill="#344b5a"
    />
    <path
      data-name="Path 407"
      d="M20.533 21.56l-2.666-1.539a.522.522 0 00-.522 0l-.47.271 2.2 1.268a.522.522 0 01.261.452v3.079a.522.522 0 01-.261.452l-2.2 1.268.47.272a.522.522 0 00.522 0l2.666-1.539a.522.522 0 00.261-.452v-3.079a.522.522 0 00-.261-.453z"
      fill="#344b5a"
    />
    <g data-name="Group 143">
      <path
        data-name="Path 408"
        d="M16.897 8.283a.446.446 0 00-.631 0l-.49.49a.446.446 0 10.631.631l.491-.49a.446.446 0 00-.001-.631z"
      />
      <path
        data-name="Path 409"
        d="M17.308 10.757a.445.445 0 00.316-.131l.49-.491a.446.446 0 10-.631-.631l-.49.49a.446.446 0 00.316.762z"
      />
      <path
        data-name="Path 410"
        d="M18.7 10.722l-.49.49a.446.446 0 00.631.631l.49-.49a.446.446 0 10-.631-.631z"
      />
      <path
        data-name="Path 411"
        d="M19.426 13.059a.446.446 0 00.631 0l.49-.49a.446.446 0 10-.631-.631l-.491.49a.446.446 0 00.001.631z"
      />
      <path
        data-name="Path 412"
        d="M3.683 24.521l-.824.823a.446.446 0 00.631.631l.824-.823a.446.446 0 00-.631-.631z"
      />
      <path
        data-name="Path 413"
        d="M20.756 21.173l-2.666-1.539a.971.971 0 00-.968 0l-2.667 1.539a.971.971 0 00-.484.839v3.079a.971.971 0 00.484.839l2.667 1.539a.971.971 0 00.968 0l2.666-1.538a.971.971 0 00.484-.839v-3.079a.972.972 0 00-.484-.84zm-.408 3.918a.076.076 0 01-.038.066l-2.666 1.539a.076.076 0 01-.076 0l-2.666-1.539a.076.076 0 01-.038-.066v-3.079a.076.076 0 01.038-.066l2.666-1.539a.076.076 0 01.076 0l2.666 1.539a.076.076 0 01.038.066v3.079z"
      />
      <path
        data-name="Path 414"
        d="M17.606 21.908a1.647 1.647 0 101.647 1.647 1.649 1.649 0 00-1.647-1.647zm0 2.4a.755.755 0 11.755-.755.756.756 0 01-.755.755z"
      />
      <path
        data-name="Path 415"
        d="M25.185 19.95a1.647 1.647 0 101.647-1.647 1.649 1.649 0 00-1.647 1.647zm2.4 0a.755.755 0 11-.755-.755.756.756 0 01.757.755z"
      />
      <path
        data-name="Path 416"
        d="M29.982 17.573l-2.666-1.539a.971.971 0 00-.968 0l-2.667 1.539a.971.971 0 00-.484.839v3.079a.971.971 0 00.484.839l2.666 1.539a.971.971 0 00.968 0l.957-.552a.446.446 0 10-.446-.773l-.957.552a.075.075 0 01-.076 0l-2.667-1.539a.076.076 0 01-.038-.066v-3.08a.076.076 0 01.038-.066l2.667-1.54a.076.076 0 01.076 0l2.666 1.54a.076.076 0 01.038.066v3.079a.076.076 0 01-.038.066l-.16.093a.446.446 0 10.446.773l.16-.093a.971.971 0 00.484-.839v-3.079a.971.971 0 00-.483-.838z"
      />
      <path
        data-name="Path 417"
        d="M19.195 15.846A8.009 8.009 0 0028.351 5.26a.446.446 0 00-.735-.163L23.949 8.76l-3.059-.819-.82-3.059.44-.439a.446.446 0 10-.631-.631l-.622.622a.446.446 0 00-.115.431l.953 3.556a.446.446 0 00.316.316l3.556.953a.447.447 0 00.431-.115l3.325-3.325a7.115 7.115 0 01-8.347 8.72 3.692 3.692 0 00-3.513 1.156L6.384 26.852a3.1 3.1 0 01-1.061.8.446.446 0 00.373.811 3.982 3.982 0 001.362-1.027l9.478-10.717a2.8 2.8 0 012.659-.873z"
      />
      <path
        data-name="Path 418"
        d="M3.757 27.932a3.121 3.121 0 01-1.78-5.477l10.725-9.479a3.693 3.693 0 001.156-3.513 7.115 7.115 0 018.72-8.347l-1.437 1.437a.446.446 0 10.631.631l1.965-1.965a.446.446 0 00-.163-.735 8.008 8.008 0 00-10.586 9.155 2.8 2.8 0 01-.873 2.663L1.393 21.783a4.013 4.013 0 002.292 7.042.446.446 0 00.072-.89z"
      />
    </g>
  </svg>
);

SvgWrench.defaultProps = {};
export default SvgWrench;
/* eslint-enable */
