import * as React from 'react';

export type LoginPopupProps = {
    location: string
    onUserClosePopup?: () => void
    onPopupClose?: () => void
    shouldClosePopup?: (popup: Window) => boolean
}
export class LoginPopup extends React.Component<LoginPopupProps> {
    popup: Window

    loginWindowTimer: NodeJS.Timer

    componentDidMount() {
        this.open();
    }

    open() {
        // TODO : solve www.domain.com with popup (cross origin error cause the popup is on domain.com)
        const popupWidth = 1000;
        const popupHeight = 800;
        const xPosition = (window.innerWidth - popupWidth) / 2;
        const yPosition = (window.innerHeight - popupHeight) / 2;

        this.popup = window.open(window.location.origin + this.props.location, 'התחברות',
            `${'location=1,scrollbars=1,'
            + 'width='}${popupWidth},height=${popupHeight},`
            + `left=${xPosition},top=${yPosition}`,
        );

        this.loginWindowTimer = setInterval(
            this.checkWhetherToClosePopup, 1000,
        );
    }

    checkWhetherToClosePopup = () => {
        if (!this.popup) {
            clearInterval(this.loginWindowTimer);
            return;
        }

        if (this.popup.closed) {
            clearInterval(this.loginWindowTimer);
            if (this.props.onUserClosePopup) {
                this.props.onUserClosePopup();
            }
        } else if (this.props.shouldClosePopup(this.popup)) {
            this.popup.close();
            clearInterval(this.loginWindowTimer);
            this.props.onPopupClose();
        }
    }

    render() {
        return null;
    }
}
