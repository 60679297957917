import {
    Container, Typography,
} from '@mui/material';
import React from 'react';
import { LoggedInUser } from '../../../types/auth.types';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type MessageProps = {
    url: string
    user?: LoggedInUser
    message?: string,
    htmlMessage?: string
}

const Message = (props: MessageProps): JSX.Element => <Page
    showBreadCrumbs={false}
    url={props.url}

    user={props.user}
    pageHeaderTitle="Mploy - הודעה" >
    <Container>
        <CustomBR />
        <BGContainer>
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - הודעה</Typography>
            <CustomBR />
            <WhiteBGContainer>
                {props.message ? <Typography whiteSpace="pre-line" variant="body1">{props.message}</Typography>
                    : <Typography whiteSpace="pre-line" variant="body1" dangerouslySetInnerHTML={{ __html: props.htmlMessage }} />}
            </WhiteBGContainer>
            <CustomBR />
        </BGContainer>
    </Container>
    <CustomBR />
</Page>;

export default Message;
