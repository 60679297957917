import {
    Container, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoggedInUser, UserSettingsViewModel } from '../../../types/auth.types';
import { TagViewModel } from '../../../types/tags.types';
import Button from '../foundation/Button';
import { ShadowBGContainer } from '../foundation/Containers';
import Select from '../foundation/Select';
import RemovableTag from '../foundation/RemovableTag';
import CustomBR from '../foundation/CustomBR';
import TextField from '../foundation/TextField';
import Page from '../layout/base.page';
import Alert from '../foundation/Alert';
import { GeoAutocomplete, LocationOption, TagsSearchAutocomplete } from '../components/AutocompleteRemote';
import Checkbox from '../foundation/Checkbox';
import { postUserSettings } from '../../api/user.api';
import UserNotification, { UserNotificationProps } from '../components/UserNotification';

export type UserSettingsPageProps = {
    url: string
    user?: LoggedInUser
    userSettings: UserSettingsViewModel
    isUserRegistered?: boolean
    currentSubscribedTags?: TagViewModel[]
}

interface IFormUserSettings {
    firstname: string
    showToEmployers: boolean
    smartAgentByActivity: boolean
    job_distance: string
    smartAgentNotificationType: string
}

const agentOptions = [
    { value: 1, text: 'ללא' },
    { value: 2, text: 'יומי - כל הבוקר' },
    { value: 3, text: 'שבועי - ראשון בבוקר' },
    { value: 4, text: 'מיידי - מספר פעמים ביום' },
];

const UserSettingsPage = (props: UserSettingsPageProps) => {
    const [notification, setNotification] = useState<UserNotificationProps>(null);
    const [subscribedTags, setSubscribedTags] = useState<TagViewModel[]>(props.currentSubscribedTags || []);
    const {
        register, handleSubmit, errors, watch,
    } = useForm<IFormUserSettings>({
        defaultValues: { smartAgentNotificationType: props.userSettings.smartAgentNotificationType?.toString() || '1' },
    });

    const [location, setLocation] = useState<LocationOption>(null);
    const locationChange = (ev, val) => { setLocation(val); };

    const onSubmit = async (data: IFormUserSettings) => {
        try {
            await postUserSettings(
                { userid: props.user.userid },
                {
                    ...data,
                    smartAgentNotificationType: parseInt(data.smartAgentNotificationType, 10),
                    ...(location ? {
                        latitude: location.latitude,
                        longitude: location.longitude,
                        formatted_address: location.formatted_address,
                    } : {}),
                    tags: subscribedTags.map(t => t.tagid),
                },
                true,
            );
            setNotification({ id: Math.random() * 10000, message: 'הפרטים נשמרו בהצלחה!', severity: 'success' });
        } catch (error) {
            setNotification({
                id: Math.random() * 10000, message: error.response?.data || error.message, severity: 'error',
            });
        }
    };

    return <Page
        showBreadCrumbs={false}
        url={props.url}
        user={props.user}
        pageHeaderTitle="Mploy - הגדרות משתמש">
        <Container>
            <CustomBR />
            {notification && <>
                <UserNotification blink {...notification} />
                <CustomBR />
            </>}
            <ShadowBGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">הגדרת מיקום</Typography>
                <Typography color="secondary" variant="body1">
                        המיקום והמרחק ישמשו אותנו לחפש לך משרות מתאימות
                </Typography>
                <CustomBR />
                <GeoAutocomplete
                    defaultValue={props.userSettings.formatted_address}
                    onChange={locationChange}
                    helperText="המיקום ממנו נחפש לך משרות"
                />
                <CustomBR />
                <TextField
                    defaultValue={props.userSettings.job_distance}
                    required
                    inputRef={register({ required: true, max: 1000, min: 0 })}
                    name="job_distance"
                    id="job_distance"
                    label="מרחק"
                    type="number"
                    fullWidth
                    error={!!errors.job_distance}
                    helperText={errors.job_distance ? 'יש להזין מרחק' : 'המרחק בק"מ מהמיקום שלך בו נחפש לך משרות'}
                />
                <CustomBR />
            </ShadowBGContainer>
            <CustomBR />
            <ShadowBGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">הגדרות סוכן חכם</Typography>
                <CustomBR />
                <Typography color="secondary" fontWeight="bold" variant="h5">סוכן חכם מבוסס פעילות</Typography>
                <CustomBR />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            defaultChecked={props.userSettings.smartAgentByActivity}
                            inputRef={register()}
                            name="smartAgentByActivity"
                        />}
                        label="הפעל סוכן חכם מבוסס פעילות"
                    />
                    <FormHelperText>קבלת התראות במייל על משרות חדשות מותאמות לפעילות שלכם ללא הגדרות נוספת</FormHelperText>
                </FormGroup>
                <CustomBR />
                <Divider />
                <CustomBR />
                <Typography color="secondary" fontWeight="bold" variant="h5">סוכן חכם מבוסס תחומים</Typography>
                <Typography color="secondary" variant="body1">
                        קבלת התראות אודות משרות מתאימות לתחומים ותפקידים שתבחרו באופן מפורש
                </Typography>
                <CustomBR />
                <FormControl fullWidth>
                    <Select
                        size="small"
                        defaultValue={props.userSettings.smartAgentNotificationType}
                        name="smartAgentNotificationType"
                        id="smartAgentNotificationType"
                        inputRef={register({ required: true })}
                        required fullWidth options={agentOptions} />
                    <FormHelperText>בחר את סוג ההתראות למייל</FormHelperText>
                </FormControl>
                <CustomBR />
                {watch('smartAgentNotificationType') !== '1' && <>
                    <Typography color="secondary" variant="h6" fontWeight="bold">
                    בחירת תחומים ותפקידים
                    </Typography>
                    <CustomBR />
                    {subscribedTags && <>
                        <Typography color="secondary" variant="body1">
                        תחומים ותפקידים אליהם נרשמת לקבלת התראות:
                        </Typography>
                        <CustomBR h={10} />
                        {subscribedTags.map(tag => <RemovableTag
                            key={tag.tagid}
                            text={tag.tag}
                            onRemoveClick={() => setSubscribedTags(subscribedTags.filter(t => t.tagid !== tag.tagid))}
                        />)}
                    </>}
                    <CustomBR />
                    <TagsSearchAutocomplete
                        filterItems={subscribedTags?.map(t => t.tag)}
                        clearOnChange
                        onChange={(ev, val: TagViewModel) => { setSubscribedTags([...subscribedTags, val]); }} />
                </>}
            </ShadowBGContainer>
            <CustomBR />
            <ShadowBGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">הגדרות נוספות</Typography>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            defaultChecked={props.userSettings.showToEmployers}
                            inputRef={register()}
                            name="showToEmployers"
                        />}
                        label="הצג אותי בפני מעסיקים פוטנציאלים"
                    />
                    <FormHelperText>האם להציג אותך בפני מעסיקים פוטנציאלים נוספים מלבד אלו שפנית אליהם</FormHelperText>
                </FormGroup>
            </ShadowBGContainer>
            <CustomBR />
            <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>שמירת פרטים אישיים</Button>
            <CustomBR />
            <CustomBR />
        </Container>
        <CustomBR />
    </Page>;
};

export default UserSettingsPage;
