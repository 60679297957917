/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg width={70.141} viewBox="0 0 70.141 64.662" {...props}>
    <path
      data-name="Path 2663"
      d="M1.367 57.993l12.875-12.875 5.3 5.3L6.667 63.291zm0 0"
      fill="#ffc400"
    />
    <path
      data-name="Path 2664"
      d="M9.457 49.907l4.789-4.788 5.3 5.3-4.789 4.789zm0 0"
      fill="#d1d1df"
    />
    <path
      data-name="Path 2665"
      d="M46.319 35.692A17.352 17.352 0 1128.967 18.34a17.353 17.353 0 0117.352 17.352zm0 0"
      fill="#f9f9fb"
    />
    <path
      data-name="Path 2666"
      d="M39.895 35.689A10.929 10.929 0 1128.966 24.76a10.929 10.929 0 0110.929 10.929zm0 0"
      fill="#fff"
    />
    <path
      data-name="Path 2667"
      d="M66.131 30.712H37.007l-8.272 5.21V4.011a2.639 2.639 0 012.639-2.639h34.757a2.639 2.639 0 012.639 2.639v24.062a2.639 2.639 0 01-2.639 2.639zm0 0"
      fill="#12b05e"
    />
    <path
      data-name="Path 2668"
      d="M42.3 17.38h7.986A7.986 7.986 0 1142.3 9.394zm0 0"
      fill="#ffc400"
    />
    <path
      data-name="Path 2669"
      d="M42.3 6.719v10.657h10.661A10.661 10.661 0 0042.3 6.719zm0 0"
      fill="#ff5d5d"
    />
    <path
      data-name="Path 2670"
      d="M40.371 46.883l-.009.009a1.391 1.391 0 10.009-.009zm0 0"
    />
    <path
      data-name="Path 2671"
      d="M56.431 2.742A1.371 1.371 0 0057.4.4a1.371 1.371 0 00-1.938 1.94 1.38 1.38 0 00.969.402zm0 0"
    />
    <path
      data-name="Path 2672"
      d="M66.132-.003h-4.217a1.371 1.371 0 000 2.742h4.216A1.27 1.27 0 0167.4 4.011v24.062a1.27 1.27 0 01-1.268 1.268H37.008a1.371 1.371 0 00-.731.211l-6.17 3.886V4.011a1.27 1.27 0 011.268-1.268h19.572a1.371 1.371 0 100-2.742H31.375a4.015 4.015 0 00-4.01 4.01v13.023a18.716 18.716 0 00-14.746 27.774L.4 57.024a1.372 1.372 0 000 1.939l5.3 5.3a1.371 1.371 0 001.939 0l12.218-12.221a18.72 18.72 0 0017.657.31 1.371 1.371 0 10-1.253-2.439 15.806 15.806 0 01-7.294 1.757 15.982 15.982 0 01-1.6-31.884v3.711a12.286 12.286 0 1013.363 8.587h3.807a16.036 16.036 0 01.41 3.6 15.828 15.828 0 01-1.592 6.962 1.372 1.372 0 102.468 1.2 18.547 18.547 0 001.867-8.159 18.834 18.834 0 00-.349-3.6h18.79a4.015 4.015 0 004.01-4.01V4.011a4.014 4.014 0 00-4.009-4.014zM6.669 61.352L3.31 57.997l6.147-6.15 3.359 3.358zm10.854-10.855l-2.769 2.769-3.354-3.358 2.768-2.768a18.888 18.888 0 003.355 3.357zm21-14.808a9.558 9.558 0 11-11.16-9.422v9.657a1.37 1.37 0 002.1 1.16l7.937-5h.416a9.477 9.477 0 01.7 3.6zm0 0"
    />
    <path
      data-name="Path 2673"
      d="M42.3 5.347a1.371 1.371 0 00-1.371 1.371v1.4A9.358 9.358 0 1051.558 18.75h1.4a1.371 1.371 0 001.371-1.371A12.045 12.045 0 0042.3 5.347zm0 18.646a6.616 6.616 0 01-1.371-13.087v6.473A1.371 1.371 0 0042.3 18.75h6.473a6.627 6.627 0 01-6.473 5.244zm1.371-7.986V8.19a9.311 9.311 0 017.817 7.822zm0 0"
    />
    <path
      data-name="Path 2674"
      d="M59.15 18.75h4.275a1.371 1.371 0 000-2.742H59.15a1.371 1.371 0 100 2.742zm0 0"
    />
    <path
      data-name="Path 2675"
      d="M63.424 21.721h-5.267a1.371 1.371 0 100 2.742h5.267a1.371 1.371 0 100-2.742zm0 0"
    />
    <path
      data-name="Path 2676"
      d="M59.981 13.038h3.444a1.371 1.371 0 100-2.742h-3.444a1.371 1.371 0 100 2.742zm0 0"
    />
  </svg>
);

SvgMarketing.defaultProps = {};
export default SvgMarketing;
/* eslint-enable */
