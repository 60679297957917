import { SellItemIdsValues } from '../../common/sellItems';
import { API_METHODS } from './api.enums';
import { apiAction } from './utils.actions';

type TransactionItem = {
    id: SellItemIdsValues,
    quantity: number
}

export type CreateTransactionPayload = {
    items: TransactionItem[],
}

export type CreateTransactionRes = {
    token: string,
    url: string,
    userid: number,
    created_at: string
    payload_start: string
    transactionid: number
}
export const postTransaction = async (
    payload: CreateTransactionPayload, backdrop?: boolean,
) => apiAction<CreateTransactionRes>({
    url: '/transactions',
    payload,
    method: API_METHODS.POST,
    backdrop,
});
