import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Divider, FormControlLabel, FormGroup, FormHelperText, Grid, Typography,
} from '@mui/material';
import { Mail, Lock } from '@mui/icons-material';

import { useForm } from 'react-hook-form';
import TextField from '../../foundation/TextField';
import Button from '../../foundation/Button/Button';
import Anchor from '../../foundation/Anchor';
import { tryNavigateToRedirectUrl } from '../../../utils/urlUtils';
import { registerUser } from '../../../api/auth.api';
import Alert from '../../foundation/Alert';
import { emailPattern } from '../../../utils/forms.utils';
import GoogleLogin from '../../components/GoogleLogin';
import LinkedinLogin from '../../components/LinkedinLogin';
import Checkbox from '../../foundation/Checkbox';
import { LoggedInUser } from '../../../../types/auth.types';
import DialogWithHeader from '../../foundation/DialogWithHeader';
import CustomBR from '../../foundation/CustomBR';

export type RegisterModalProps = {
    open: boolean
    onClose: () => void
    gotoLogin: () => void
    onRegistrationComplete?: (user: LoggedInUser) => void
    welcomeText?: string
    showSmartAgent?: boolean
};

const RegisterModalStyle = styled.div`
`;

interface IFormRegister {
    email: string;
    password: string;
    smartAgentByActivity: boolean
}

const RegisterModal = ({
    onRegistrationComplete, open, onClose, gotoLogin, welcomeText, showSmartAgent = true,
}: RegisterModalProps) => {
    const [formErr, setFormErr] = useState<string>(null);
    const {
        register, handleSubmit, errors, watch,
    } = useForm<IFormRegister>();

    const onSubmit = async (data: IFormRegister) => {
        try {
            window.showBackdrop();
            const result = await registerUser(data);
            tryNavigateToRedirectUrl();
            if (onRegistrationComplete) onRegistrationComplete(result.data);
            tryNavigateToRedirectUrl();
        } catch (error) {
            setFormErr(error.message);
        } finally {
            window.hideBackdrop();
        }
    };

    const renderFooter = () => <Typography variant="body2">
    נרשמת כבר? <Anchor text="לחץ כאן להתחברות" href="#" onClick={ev => {
            ev.preventDefault();
            gotoLogin();
        }} />
    </Typography>;

    return <DialogWithHeader renderFooter={renderFooter} open={open} onClose={onClose} title="הצטרפו ל-Mploy">
        <RegisterModalStyle>
            <span>{welcomeText}</span>
            {formErr && <><Alert severity="error">{formErr}</Alert><CustomBR /></>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {showSmartAgent && <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                defaultChecked
                                inputRef={register()}
                                name="smartAgentByActivity"
                            />}
                            label="הפעל סוכן חכם מבוסס פעילות"
                        />
                        <FormHelperText>קבלת התראות במייל על משרות חדשות מותאמות לפעילות שלכם</FormHelperText>
                    </FormGroup>}
                    <CustomBR />
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true, pattern: emailPattern })}
                            name="email"
                            id="email"
                            label="כתובת אימייל"
                            type="email"
                            autoComplete="username"
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email && 'כתובת המייל אינה תקינה'}
                            InputProps={{
                                endAdornment: <Mail />,
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true, minLength: 6 })}
                            margin="dense"
                            name="password'"
                            autoComplete="new-password"
                            id="password"
                            label="בחר סיסמא"
                            type="password"
                            fullWidth
                            error={!!errors.password}
                            helperText={errors.password && 'הסיסמא אינה תקינה, לפחות 6 תווים'}
                            InputProps={{
                                endAdornment: <Lock />,
                            }}
                        />
                    </div>
                    <CustomBR />
                    <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>הרשמה</Button>
                </div>
            </form>
            <CustomBR />
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}><Divider /></Grid>
                <Grid item><Typography variant="body1">או</Typography></Grid>
                <Grid item xs={5}><Divider /></Grid>
            </Grid>
            <CustomBR />
            <Grid container spacing={3}>
                <Grid xs={6} item>
                    <GoogleLogin smartAgentByActivity={watch('smartAgentByActivity', true)} />
                </Grid>
                <Grid xs={6} item>
                    <LinkedinLogin smartAgentByActivity={watch('smartAgentByActivity', true)} />
                </Grid>
            </Grid>
            <CustomBR />
        </RegisterModalStyle>
    </DialogWithHeader>;
};

export default RegisterModal;
