import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Component, createRef } from 'react';
import { Flexbox } from '../../foundation/Flex';
import DatePicker from '../DatePicker';

export type DateRangePickerProps = {
    onFromChange: (from: any) => void,
    onToChange: (to: any) => void,
    from: any
    to: any
};

export class DateRangePickerComp extends Component<DateRangePickerProps> {
    refFrom = createRef<HTMLInputElement>()

    refTo = createRef<HTMLInputElement>()

    // showFromMonth() {
    //     const { from, to } = this.props;
    //     if (!from) {
    //         return;
    //     }
    //     if (dayjs(to).diff(dayjs(from), 'months') < 2) {
    //         this.refTo.getDayPicker().showMonth(from);
    //     }
    // }

    handleFromChange = from => {
        this.props.onFromChange(from);
    }

    handleToChange = to => {
        this.props.onToChange(to);
        // this.showFromMonth();
    }

    onDayClick = () => {
        this.refFrom.current.focus();
    }

    render() {
        const { from, to } = this.props;
        const modifiers = { start: from, end: to };
        return (
            <Flexbox alignItems="center" width="100%">
                <DatePicker
                    value={from}
                    inputRef={this.refFrom}
                    placeholder="מתאריך"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 2,
                        onDayClick: this.onDayClick,
                    }}
                    onDayChange={this.handleFromChange}
                />
                <Typography>&nbsp;&nbsp;—&nbsp;&nbsp;</Typography>
                <DatePicker
                    value={to}
                    inputRef={this.refTo}
                    placeholder="עד תאריך"
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: from,
                        fromMonth: from,
                        numberOfMonths: 2,
                    }}
                    onDayChange={this.handleToChange}
                />
            </Flexbox>
        );
    }
}

export default DateRangePickerComp;
