import {
    Box, Divider, FormControl, FormHelperText, Grid, Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Check, MailOutlined } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import CustomBR from '../../foundation/CustomBR';
import { CenteredFlex, VStartFlex } from '../../foundation/Flex';
import { BGContainer, WhiteBGContainer } from '../../foundation/Containers';
import Button from '../../foundation/Button';
import Anchor from '../../foundation/Anchor';
import BackdropSpinner from '../../foundation/BackdropSpinner';
import { postTransaction } from '../../../api/transaction.api';
import UserContext from '../../context/user.context';
import Select from '../../foundation/Select';
import SvgFinanceEconomy from '../../svg/generated/components/FinanceEconomy';
import { sellItemIds, sellItems } from '../../../../common/sellItems';
import { summaryCount } from '../../../api/stats.api';
import AppNumbers from '../../components/AppNumbers';

export type BuyJobsContainer = {
    onTokenGenerated?: (token: string) => void
    extraDescription?: boolean
}

const BuyJobsStyled = styled.div`
`;
const StrikeStyled = styled.span`
    text-decoration: line-through;
`;
const PlanCardStyled = styled.div`
    padding: 1rem;
    box-shadow: 0 40px 50px 0 #10334b14;
    border: solid 3px #f9f9fb;
    background-color: #ffffff;
    position: relative;
`;
const PricePlan = styled(PlanCardStyled)`
    /* ${props => props.theme.breakpoints.up('md')} {
        min-height: 720px;
    }
    ${props => props.theme.breakpoints.up('xs')} {
        min-height: 770px;
    } */
`;
const PayButtonStyle = styled(CenteredFlex)`
    /* position: absolute;
    bottom: 12px;
    left: 0;
    right: 0; */
`;

const PlanCardRibbonStyled = styled.div`
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    &::before,
    &::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid ${props => props.theme.palette.primary.dark};
        border-top-color: transparent;
        border-inline-end-color: transparent;
    }
    p {
        position: absolute;
        display: block;
        width: 225px;
        padding: 10px 0;
        background-color: ${props => props.theme.palette.primary.main};
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        color: #fff;
        font: 700 18px/1 'Lato', sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        text-transform: uppercase;
        text-align: center;

        left: -20px;
        top: 30px;
        transform: rotate(-45deg);
    }

    /* top left*/
    top: -10px;
    right: -10px;
    &::before {
        top: 0;
        left: 0;
    }
    &::after {
        bottom: 0;
        right: 0;
    }
`;

// const WhiteBGContainer1Rem = styled(WhiteBGContainer)` padding: 1rem; `;
const BGContainer1Rem = styled(BGContainer)`margin: 1rem 0; padding: 1rem;`;

interface IFormRegister {
    numberOfPromotedJobs: number
    numberOfRegularJobs: number
}

const formFields = {
    numberOfPromotedJobs: 'numberOfPromotedJobs',
    numberOfRegularJobs: 'numberOfRegularJobs',
};

type Product = {
    name: string
    id: string
    price: string
    brand?: string
    category: string
    variant?: string
    quantity: number
}

type ActionField = {
    id?: string
    step: number
    option?: string
}
const onPurchase = (actionField: ActionField, products: Product[]) => {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: 'job-purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    ...actionField,
                    revenue: products.reduce((prev, curr) => curr.quantity * parseFloat(curr.price) + prev, 0).toString(),
                },
                products,
            },
        },
    });
};
const onCheckout = (actionField: ActionField, products: Product[]) => {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField,
                products,
            },
        },
    });
};

type PayloadStartParsed = { items: { description: string, id: string, quantity: number, sku: string, unitprice: number}[] }

type Counters = {
    numberOfJobs: number
    numberOfTodayJobs: number
    numberOfUsers: number
    numberOfTodayWatchedCV: number
};

const BuyJobs = (props: BuyJobsContainer) => {
    const [iframeSrc, setIFrameSrc] = useState<string>();
    const [transactionId, setTransactionId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [cartProducts, setCartProducts] = useState<Product[]>(null);
    const user = useContext(UserContext);
    const {
        register, watch,
    } = useForm<IFormRegister>({ defaultValues: { numberOfRegularJobs: 1, numberOfPromotedJobs: 1 } });

    // const numberOfRegularJobs = parseInt(watch(formFields.numberOfRegularJobs), 10);
    const numberOfPromotedJobs = parseInt(watch(formFields.numberOfPromotedJobs), 10);

    const [counters, setCounters] = useState<Counters>(null);

    const fetchCounters = async () => {
        const result = await summaryCount();
        setCounters(result.data as Counters);
    };
    useEffect(() => {
        fetchCounters();
    }, []);

    const purchasePromotedPackage = async () => {
        if (!user) {
            window.modals.toggleEmployerRegisterModal();
            return;
        }

        window.showBackdrop();
        const transactionRes = await postTransaction({
            items: [{
                quantity: numberOfPromotedJobs,
                id: sellItemIds.promotedJob,
            }],
        });
        if (props.onTokenGenerated) props.onTokenGenerated(transactionRes.data.token);
        if (window.dataLayer) {
            const payloadStart = JSON.parse(transactionRes.data.payload_start) as PayloadStartParsed;
            const products = payloadStart.items.map(item => ({
                name: item.description,
                id: item.id,
                quantity: item.quantity,
                price: item.unitprice.toString(),
                category: 'Jobs',
            }));
            setTransactionId(transactionRes.data.transactionid);
            setCartProducts(products);
            onCheckout({ step: 1 }, products);
        }
        const iframe = `${transactionRes.data.url}?m__token=${transactionRes.data.token}&is_iframe=1`;
        setIFrameSrc(iframe);
        window.hideBackdrop();
    };

    // const purchaseBundlePackage = async () => {
    //     if (!user) {
    //         window.modals.toggleEmployerRegisterModal();
    //         return;
    //     }

    //     window.showBackdrop();
    //     const transactionRes = await postTransaction({
    //         items: [{
    //             quantity: 1,
    //             id: sellItemIds.bundle3Plus1Job,
    //         }],
    //     });
    //     if (window.dataLayer) {
    //         const payloadStart = JSON.parse(transactionRes.data.payload_start) as PayloadStartParsed;
    //         const products = payloadStart.items.map(item => ({
    //             name: item.description,
    //             id: item.id,
    //             quantity: item.quantity,
    //             price: item.unitprice.toString(),
    //             category: 'Jobs',
    //         }));
    //         setTransactionId(transactionRes.data.transactionid);
    //         setCartProducts(products);
    //         onCheckout({ step: 1 }, products);
    //     }
    //     if (props.onTokenGenerated) props.onTokenGenerated(transactionRes.data.token);
    //     const iframe = `${transactionRes.data.url}?m__token=${transactionRes.data.token}&is_iframe=1`;
    //     setIFrameSrc(iframe);
    //     window.hideBackdrop();
    // };

    // const purchaseRegularPackage = async () => {
    //     if (!user) {
    //         window.modals.toggleEmployerRegisterModal();
    //         return;
    //     }

    //     window.showBackdrop();
    //     const transactionRes = await postTransaction({
    //         items: [{
    //             quantity: numberOfRegularJobs,
    //             id: sellItemIds.regularJob,
    //         }],
    //     });
    //     if (window.dataLayer) {
    //         const payloadStart = JSON.parse(transactionRes.data.payload_start) as PayloadStartParsed;
    //         const products = payloadStart.items.map(item => ({
    //             name: item.description,
    //             id: item.id,
    //             quantity: item.quantity,
    //             price: item.unitprice.toString(),
    //             category: 'Jobs',
    //         }));
    //         setTransactionId(transactionRes.data.transactionid);
    //         setCartProducts(products);
    //         onCheckout({ step: 1 }, products);
    //     }
    //     if (props.onTokenGenerated) props.onTokenGenerated(transactionRes.data.token);
    //     const iframe = `${transactionRes.data.url}?m__token=${transactionRes.data.token}&is_iframe=1`;
    //     setIFrameSrc(iframe);
    //     window.hideBackdrop();
    // };

    const onIframeLoaded = ev => {
        let locationHref;

        // we might not have access due to cors
        try {
            locationHref = ev.currentTarget?.contentWindow?.location?.href;
        } catch (error) {
            locationHref = null;
        }

        if (locationHref && locationHref.indexOf('thank-you') !== -1) {
            onPurchase({ id: transactionId.toString(), step: 2 }, cartProducts);
        } else {
            setLoading(false);
            document.getElementById('go-back-payment').scrollIntoView();
        }
    };

    const renderIframe = () => <iframe title="עמוד רכישה" onLoad={onIframeLoaded} style={{
        border: 0,
        height: loading ? '0' : '100vh',
        width: '100%',
    }} src={iframeSrc} />;

    const goBack = () => {
        setLoading(false);
        setIFrameSrc(undefined);
    };

    // const regularUnitPriceAfterDiscount = sellItems[sellItemIds.regularJob].calculateDiscount(numberOfRegularJobs);
    const promotedUnitPriceAfterDiscount = sellItems[sellItemIds.promotedJob].calculateDiscount(numberOfPromotedJobs);
    // const totalSaveRegular = numberOfRegularJobs * (sellItems[sellItemIds.regularJob].unitprice - sellItems[sellItemIds.regularJob].calculateDiscount(numberOfRegularJobs));
    const totalSavePromoted = numberOfPromotedJobs * (sellItems[sellItemIds.promotedJob].unitprice - sellItems[sellItemIds.promotedJob].calculateDiscount(numberOfPromotedJobs));

    return <BuyJobsStyled>
        <CustomBR />
        <CustomBR />
        {props.extraDescription && <>
            <Typography color="secondary" fontWeight="bold" variant="h4">יתרונות הפרסום ב - Mploy</Typography>
            <CustomBR />
            <PlanCardStyled>
                {/* <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>פשוט וקל</b> - הפכנו את תהליך הגשת המועמדות לקל ומהיר באמצעות אפליקציה ייעודית בסנגון טינדר.
                    </Typography>
                </Box>
                <CustomBR /> */}
                <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>משרות עדכניות</b> - אנחנו סורקים בכל יום אלפי משרות מהרשת ומנגישים אותם למועמדים במגוון ערוצי Mploy.
                    </Typography>
                </Box>
                <CustomBR />
                <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>טכנולוגיה</b> - אנחנו מקפידים על חווית גלישה נוחה, מהירה, ומציעים משרות באמצעות כלי AI להתאמה מקסימלית.
                    </Typography>
                </Box>
                <CustomBR />
                <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>מאגר מועמדים בלעדי וייחודי</b> - מחפשי עבודה אוהבים את Mploy.
                    </Typography>
                </Box>
                <CustomBR />
                <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>שקיפות</b> - ממשק עם נתונים וסטטיסטיקות על היקף חשיפת המשרה, הגשות קו"ח ודרכי התקשרות.
                    </Typography>
                </Box>
                <CustomBR />
                <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>שיתופי פעולה</b> - משרות שמפורסמות ב Mploy נסרקות ומוצגות במספר אתרים נוספים כמו Glassdoor, Indeed ואתרים אחרים.
                    </Typography>
                </Box>
                <CustomBR />
                <Box display="flex">
                    <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                    <Typography>
                        <b>קבוצות וואטסאפ</b> - אנו מנגישים את המשרות למועמדים בצורה נוחה דרך קבוצות וואטסאפ ייעודיות, <Anchor href="https://chat.whatsapp.com/FdTeLEGHXkX0qt942bG6GR" blank>הצטרפו אלינו</Anchor>.
                    </Typography>
                </Box>
            </PlanCardStyled>
            <CustomBR />
        </>}
        <CustomBR />
        <CustomBR />
        <CenteredFlex><Typography color="secondary" fontWeight="bold" variant="h4">פרסום משרה ב Mploy</Typography></CenteredFlex>
        <CustomBR />
        <CustomBR />
        <BackdropSpinner open={loading} />
        {!loading && iframeSrc && <><Button id="go-back-payment" onClick={goBack}>→ חזור אחורה</Button><CustomBR /></>}
        {iframeSrc ? renderIframe()
            : <Grid container spacing={4}>
                <Grid item md={6} sm={12}>
                    <PricePlan>
                        <PlanCardRibbonStyled>
                            <Typography>ללא תשלום</Typography>
                        </PlanCardRibbonStyled>
                        <Typography display="flex" justifyContent="center" fontWeight="bold" color="secondary" variant="h6">עד 2 משרות רגילות</Typography>
                        {/* <Typography display="flex" justifyContent="center" color="secondary">תקופת פרסום: 4 שבועות</Typography> */}
                        <BGContainer1Rem>
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                התחילו לפרסם <b>עכשיו</b> ללא תשלום
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                קידום בתוצאות החיפוש בתחומים והתפקידים הרלוונטים
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                שליחה למועמדים רלוונטים בדיוור ישיר על ידי הסוכן החכם
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                פרסום גם באתר וגם בקבוצות וואטסאפ
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                ניתן לצרף לוגו של החברה למשרה
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                מודל משבצות
                                </Typography>
                            </Box>
                            {/* <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="warning" />&nbsp;</VStartFlex>
                                <Typography color="#FFC400" fontWeight="bold">
                                תשלום מבוסס תוצאות*
                                </Typography>
                            </Box> */}
                        </BGContainer1Rem>
                        {/* <FormControl fullWidth>
                            <Select
                                size="small"
                                name={formFields.numberOfRegularJobs}
                                inputRef={register({ required: true })}
                                required fullWidth
                                options={[
                                    { value: 1, text: 'משרה אחת' },
                                    { value: 2, text: '2 משרות' },
                                    { value: 3, text: '3 משרות' },
                                    { value: 4, text: '4 משרות' },
                                    { value: 5, text: '5 משרות' },
                                    { value: 6, text: '6 משרות' },
                                    { value: 7, text: '7 משרות' },
                                    { value: 8, text: '8 משרות' },
                                    { value: 9, text: '9 משרות' },
                                    { value: 10, text: '10 משרות' },
                                ]}
                            />
                            <FormHelperText>בחר את כמות המשרות הרצויה</FormHelperText>
                        </FormControl> */}
                        <CustomBR />
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <SvgFinanceEconomy /><Typography>&nbsp;&nbsp;חינם אין כסף</Typography>
                        </Box>
                        {/* <CustomBR /> */}
                        {/* <Typography>מחיר למשרה: {regularUnitPriceAfterDiscount} ₪ + מע"מ</Typography>
                        <CustomBR />
                        {numberOfRegularJobs ? <Typography>סה"כ לתשלום: &nbsp;
                            {numberOfRegularJobs * regularUnitPriceAfterDiscount} ₪ + מע"מ ל - {numberOfRegularJobs} משרות
                        </Typography> : ''}
                        <CustomBR />
                        {totalSaveRegular > 0 && <Typography variant="h6" fontWeight="bold">חסכון של {totalSaveRegular} ₪</Typography>} */}
                        <CustomBR />
                        <Typography>
                            הצטרפו אלינו עכשיו והתחילו לפרסם עד 2 משרות כל חודש בחינם.
                        </Typography>
                        <CustomBR />
                        <PayButtonStyle>
                            {/* <Button onClick={purchaseRegularPackage}
                            variant="outlined" size="large">שלם {numberOfRegularJobs * regularUnitPriceAfterDiscount} ₪ + מע"מ</Button> */}
                            {user ? <Button onClick={() => { window.location.assign('/user/postedJobs'); }} variant="outlined" size="large">התחילו לפרסם</Button>
                                : <Button onClick={() => {
                                    const url = new URL(window.location.href);
                                    url.searchParams.set('redirect', '/user/postedJobs');
                                    window.history.pushState({}, '', url);
                                    window.modals.toggleRegisterModal();
                                }} variant="outlined" size="large">הצטרפו ל Mploy</Button>}
                        </PayButtonStyle>
                    </PricePlan>
                </Grid>
                {/*
                <Grid item md={4} sm={12}>
                    <PricePlan>
                        <PlanCardRibbonStyled>
                            <Typography>מומלץ</Typography>
                        </PlanCardRibbonStyled>
                        <Typography display="flex" justifyContent="center" fontWeight="bold" color="secondary" variant="h6">3 רגילות + 1 מקודמת</Typography>
                        <Typography display="flex" justifyContent="center" color="secondary">תקופת פרסום: 4 שבועות</Typography>
                        <BGContainer1Rem>
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                3 משרות רגילות
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                משרה אחת מקודמת
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                קידום בתוצאות החיפוש בתחומים והתפקידים הרלוונטים
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                שליחה למועמדים רלוונטים בדיוור ישיר על ידי הסוכן החכם
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                פרסום גם באתר וגם באפליקצייה
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                ניתן לצרף לוגו של החברה למשרה
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="warning" />&nbsp;</VStartFlex>
                                <Typography color="#FFC400" fontWeight="bold">
                                תשלום מבוסס תוצאות*
                                </Typography>
                            </Box>
                        </BGContainer1Rem>
                        <CustomBR />
                        <Typography variant="h6">סה"כ לתשלום: &nbsp; 770 ₪ + מע"מ</Typography>
                        <CustomBR />
                        <Typography variant="h6" fontWeight="bold">חסכון של 130 ₪</Typography>
                        <CustomBR />
                        <CustomBR />
                        <PayButtonStyle>
                            <Button onClick={purchaseBundlePackage} size="large">שלם 770 ₪ + מע"מ</Button>
                        </PayButtonStyle>
                    </PricePlan>
                </Grid> */}
                <Grid item md={6} sm={12}>
                    <PricePlan>
                        <Typography display="flex" justifyContent="center" fontWeight="bold" color="secondary" variant="h6">משרה מקודמת</Typography>
                        <Typography display="flex" justifyContent="center" color="secondary">תקופת פרסום: 4 שבועות</Typography>
                        <BGContainer1Rem>
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                קידום בתוצאות החיפוש בתחומים והתפקידים הרלוונטים
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                שליחה למועמדים רלוונטים בדיוור ישיר על ידי הסוכן החכם בעדיפות גבוהה
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                פרסום גם באתר וגם בקבוצות וואטסאפ
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                ניתן לצרף לוגו של החברה למשרה
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                קידום בתוצאות החיפוש בתחומים והתפקידים הרלוונטים בעדיפות גבוהה
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                מודל משבצות
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="primary" />&nbsp;</VStartFlex>
                                <Typography>
                                המשרה תופיע בצורה מודגשת ובולטת בתוצאות החיפוש
                                </Typography>
                            </Box>
                            <CustomBR />
                            <Box display="flex">
                                <VStartFlex><Check color="warning" />&nbsp;</VStartFlex>
                                <Typography color="#FFC400" fontWeight="bold">
                                תשלום מבוסס תוצאות*
                                </Typography>
                            </Box>
                        </BGContainer1Rem>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                name={formFields.numberOfPromotedJobs}
                                inputRef={register({ required: true })}
                                required fullWidth options={[
                                    { value: 1, text: 'משרה אחת' },
                                    { value: 2, text: '2 משרות' },
                                    { value: 3, text: '3 משרות' },
                                    { value: 4, text: '4 משרות' },
                                    { value: 5, text: '5 משרות' },
                                    { value: 6, text: '6 משרות' },
                                    { value: 7, text: '7 משרות' },
                                    { value: 8, text: '8 משרות' },
                                    { value: 9, text: '9 משרות' },
                                    { value: 10, text: '10 משרות' },
                                ]} />
                            <FormHelperText>בחר את כמות המשרות הרצויה</FormHelperText>
                        </FormControl>
                        <CustomBR />
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <SvgFinanceEconomy /><Typography>&nbsp;&nbsp;קנה יותר מודעות וחסוך כסף</Typography>
                        </Box>
                        <CustomBR />
                        <Typography>
                            מחיר למשרה:&nbsp;
                            {numberOfPromotedJobs > 2 ? <StrikeStyled>{sellItems[sellItemIds.promotedJob].unitprice} ₪ + מע"מ</StrikeStyled>
                                : `${sellItems[sellItemIds.promotedJob].unitprice} ₪ + מע"מ`}
                        </Typography>
                        <CustomBR />
                        {numberOfPromotedJobs ? <Typography>סה"כ לתשלום: &nbsp;
                            {numberOfPromotedJobs * promotedUnitPriceAfterDiscount} ₪ + מע"מ ל - {numberOfPromotedJobs} משרות מקודמות</Typography> : ''}
                        <CustomBR />
                        {totalSavePromoted > 0 && <>
                            <Typography variant="h6" fontWeight="bold">חסכון של {totalSavePromoted} ₪</Typography>
                            <CustomBR />
                        </>}
                        <PayButtonStyle>
                            <Button size="large" variant="outlined" onClick={purchasePromotedPackage}>שלם {numberOfPromotedJobs * promotedUnitPriceAfterDiscount} ₪ + מע"מ</Button>
                        </PayButtonStyle>
                    </PricePlan>
                </Grid>
            </Grid>}
        <CustomBR />
        <CustomBR />
        <Typography color="secondary" fontWeight="bold" variant="h4">שאלות נפוצות</Typography>
        <CustomBR />
        <PlanCardStyled>
            <Typography fontWeight="bold" color="#FFC400">
                *מה הכוונה בתשלום מבוסס תוצאות?
            </Typography>
            <CustomBR />
            <Typography>
                במידה ולא קיבלתם קורות חיים רלוונטים ב - 72 שעות הראשונות מרגע פרסום המשרה, ניתן לקבל <b>החזר מלא</b> על הרכישה של אותה משרה.
                <CustomBR />
                כדי לקבל החזר על פרסום המשרה, יש לשלוח אלינו מייל עם קישור למשרה ובקשה להחזר כספי תוך 72 שעות מרגע פרסום המשרה: <Anchor blank href="/home/contactus">צור קשר</Anchor>.
            </Typography>
        </PlanCardStyled>
        <CustomBR />
        <PlanCardStyled>
            <Typography fontWeight="bold">
                האם ניתן להעלות משרות בצורה עצמאית?
            </Typography>
            <CustomBR />
            <Typography>
                בהחלט, כל תהליך פרסום המשרה מתבצע בצורה עצמאית בממשק ידידותי ומתקדם.
                בנוסף הנציגים שלנו זמינים עבורכם לכל שאלה ועניין בצ'אט כאן בשעות הפעילות או באמצעות טופס : <Anchor blank href="/home/contactus">צור קשר</Anchor>.
                <CustomBR />
                ניתן להתחיל את פרסום המשרה בכל עת באופן עצמאי לאחר הרכישה.
            </Typography>
        </PlanCardStyled>
        <CustomBR />
        <PlanCardStyled>
            <Typography fontWeight="bold">
                האם יש מסלולי פרסום נוספים?
            </Typography>
            <CustomBR />
            <Typography>
                יש לנו מסלולי פרסום נוספים בהתאמה אישית לארגונים עם היקף משרות נרחב, <Anchor blank href="/home/contactus">צרו קשר כאן</Anchor>.
            </Typography>
        </PlanCardStyled>
        <CustomBR />
        <PlanCardStyled>
            <Typography fontWeight="bold">
                האם ניתן ליצור איתכם קשר?
            </Typography>
            <CustomBR />
            <Typography>
                בהחלט! מחלקת המכירות שלנו זמינה , <Anchor blank href="https://wa.me/0544700720">גם בוואסאפ, לחצו כאן לשליחת הודעה</Anchor>.
            </Typography>
        </PlanCardStyled>
        <CustomBR />
        <PlanCardStyled>
            <Typography fontWeight="bold">
                מה היתרונות שלכם לעומת שאר אתרי הדרושים?
            </Typography>
            <CustomBR />
            <Typography>
                אתר Mploy מבוסס על אלגוריתם חכם שפיתחנו מתוך מטרה לדייק את אחוזי ההתאמה ברמה המקסימלית, אנחנו משקיעים רבות בהתאמת פרסומי המשרות למועמדים הרלוונטים.
                אתם מוזמנים לקרוא עוד <Anchor blank href="/about/show">אודות Mploy</Anchor>
            </Typography>
        </PlanCardStyled>
        <CustomBR />
        <CustomBR />
        {props.extraDescription && <AppNumbers {...counters} />}
        <Divider />
        <CustomBR />
        <div>
            <Typography color="secondary">
                * המחירים אינם כוללים מע"מ
            </Typography>
            <Typography color="secondary">
                <>** רכישת ופרסום משרות בכפוף לתקנון! <Anchor blank href="/home/regulations" text="ותנאי השימוש" /></>
            </Typography>
        </div>
        <CustomBR />
        <CenteredFlex>
            <WhiteBGContainer>
                <Typography color="secondary">מעוניינים לפרסם יותר משרות? דברו איתנו!</Typography>
                <CenteredFlex>
                    <MailOutlined color="primary" />&nbsp;<Anchor href="mailto:info@mploy.co.il" text="info@mploy.co.il" />
                </CenteredFlex>
            </WhiteBGContainer>
        </CenteredFlex>
        <CustomBR />
    </BuyJobsStyled>;
};

export default BuyJobs;
