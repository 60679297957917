/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgManufacturingAndProduction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Manufacturing and Production"
    width={29.623}
    viewBox="0 0 29.623 29.623"
    {...props}
  >
    <path
      data-name="Path 1046"
      d="M15.053 12.233h-4.8V7.066h4.8a.37.37 0 01.37.37v4.427a.37.37 0 01-.37.37z"
      fill="#475959"
    />
    <path
      data-name="Path 1047"
      d="M12.353 7.185a2.283 2.283 0 00-.067-.119h-2.03v5.167h4.712z"
      fill="#404a4a"
    />
    <path
      data-name="Path 1048"
      d="M15.423 7.97h6.838v3.359h-6.838z"
      fill="#909ba5"
    />
    <path
      data-name="Path 1049"
      d="M21.245 7.97h1.017v3.359h-1.017z"
      fill="#808b95"
    />
    <path
      data-name="Path 1050"
      d="M22.261 7.066h2.149v5.167h-2.149z"
      fill="#475959"
    />
    <path
      data-name="Path 1051"
      d="M24.411 8.55h2.736v2.2h-2.736z"
      fill="#e7a561"
    />
    <path
      data-name="Path 1052"
      d="M26.129 8.55h1.017v2.2h-1.017z"
      fill="#da8059"
    />
    <path
      data-name="Path 1053"
      d="M20.042 29.189H3.993v-8.108a1.066 1.066 0 011.066-1.066h13.919a1.066 1.066 0 011.066 1.066z"
      fill="#12b05e"
    />
    <path
      data-name="Path 1054"
      d="M18.976 20.015H17.94a1.066 1.066 0 011.066 1.066v8.108h1.036v-8.108a1.066 1.066 0 00-1.066-1.066z"
      fill="#03984b"
    />
    <path
      data-name="Path 1055"
      d="M17.905 20.015l-1.35 1.157h-3.428l-.9-1.157-5.444-10.51a2.522 2.522 0 014.479-2.32z"
      fill="#ffcd27"
    />
    <path
      data-name="Path 1056"
      d="M12.224 20.015h5.681l-.495-.955h-5.681z"
      fill="#da8059"
    />
    <path
      data-name="Path 1057"
      d="M15.623 23.913h-7.21a1.521 1.521 0 01-1.521-1.521v-2.377h10.252v2.377a1.521 1.521 0 01-1.521 1.521z"
      fill="#ecf4ff"
    />
    <path
      data-name="Path 1058"
      d="M16.07 20.015v2.377a1.521 1.521 0 01-1.521 1.521h1.074a1.521 1.521 0 001.521-1.521v-2.377z"
      fill="#d8e2f1"
    />
    <g data-name="Group 331" transform="translate(12.066 .434)" fill="#e2dfe2">
      <circle data-name="Ellipse 76" cx={4.003} cy={4.003} r={4.003} />
      <path
        data-name="Path 1059"
        d="M4.004 0a4.021 4.021 0 00-.693.061 4 4 0 010 7.885A4 4 0 104.004 0z"
      />
    </g>
    <g data-name="Group 332">
      <path
        data-name="Path 1060"
        d="M29.189 9.215H27.58V8.55a.434.434 0 00-.434-.434h-2.3v-1.05a.434.434 0 00-.434-.434h-2.151a.434.434 0 00-.434.434v.47h-2.584a4.413 4.413 0 001.182-2.248.434.434 0 00-.852-.166 3.568 3.568 0 11-.039-1.548.434.434 0 00.842-.209 4.437 4.437 0 10-8.161 3.266h-.787a2.957 2.957 0 00-5.034 3.074l5.116 9.876H5.059a1.5 1.5 0 00-1.5 1.5v3.5a.434.434 0 00.868 0v-3.5a.633.633 0 01.632-.632h1.4v1.943a1.957 1.957 0 001.955 1.955h7.21a1.957 1.957 0 001.955-1.955v-1.943h1.4a.633.633 0 01.632.632v7.674H4.427v-2.438a.434.434 0 00-.868 0v2.438H.434a.434.434 0 100 .868H23.6a.434.434 0 000-.868h-3.124v-7.674a1.5 1.5 0 00-1.5-1.5h-.807l-1.5-2.894a.434.434 0 00-.771.4l1.292 2.494h-4.7L7.164 9.306a2.088 2.088 0 013.651-2.025c.046.077 4.248 8.191 4.268 8.229a.434.434 0 00.771-.4l-1.266-2.445h.466a.8.8 0 00.8-.8v-.1h5.97v.47a.434.434 0 00.434.434h2.149a.434.434 0 00.434-.434v-1.05h2.3a.434.434 0 00.434-.434v-.666h1.609a.434.434 0 100-.868zm-12.48 11.234v1.943a1.088 1.088 0 01-1.087 1.087h-7.21a1.088 1.088 0 01-1.087-1.087v-1.943zM14.137 11.8L11.91 7.5h.953a4.438 4.438 0 002.126 1.241V11.8zm1.72-.9V8.869c.07 0 .141.005.213.005a4.419 4.419 0 001.985-.474h3.772v2.491zm8.12.9H22.7V7.5h1.281zm2.736-1.484h-1.868V8.984h1.868z"
      />
      <path
        data-name="Path 1061"
        d="M10.278 21.964a.434.434 0 00.434.434h2.612a.434.434 0 100-.868h-2.612a.434.434 0 00-.434.434z"
      />
      <path
        data-name="Path 1062"
        d="M8.64 7.746a.434.434 0 00-.145.6l.3.487a.434.434 0 10.741-.452l-.3-.487a.434.434 0 00-.596-.148z"
      />
      <path
        data-name="Path 1063"
        d="M12.785 14.073a.434.434 0 00-.434-.434h-.775a.434.434 0 000 .868h.775a.434.434 0 00.434-.434z"
      />
      <path
        data-name="Path 1064"
        d="M12.352 16.131h.775a.434.434 0 100-.868h-.775a.434.434 0 000 .868z"
      />
      <path
        data-name="Path 1065"
        d="M13.127 17.755h.775a.434.434 0 000-.868h-.775a.434.434 0 100 .868z"
      />
      <path
        data-name="Path 1066"
        d="M18.226 4.115a.434.434 0 000-.614 3.062 3.062 0 00-4.3 0 .434.434 0 00.609.618 2.194 2.194 0 013.085 0 .434.434 0 00.606-.004z"
      />
      <path
        data-name="Path 1067"
        d="M17.545 5.121a.434.434 0 000-.614 2.093 2.093 0 00-2.941 0 .434.434 0 10.609.618 1.225 1.225 0 011.723 0 .434.434 0 00.609-.004z"
      />
      <path
        data-name="Path 1068"
        d="M16.07 5.19a1.119 1.119 0 00-.789.324.434.434 0 10.609.618.257.257 0 01.361 0 .434.434 0 10.609-.618 1.118 1.118 0 00-.79-.324z"
      />
      <path
        data-name="Path 1069"
        d="M9.779 27.436v-1.655a.434.434 0 10-.868 0v1.657a.434.434 0 00.868 0z"
      />
      <path
        data-name="Path 1070"
        d="M12.452 27.436v-1.655a.434.434 0 10-.868 0v1.657a.434.434 0 10.868 0z"
      />
      <path
        data-name="Path 1071"
        d="M15.124 27.436v-1.655a.434.434 0 00-.868 0v1.657a.434.434 0 00.868 0z"
      />
    </g>
  </svg>
);

SvgManufacturingAndProduction.defaultProps = {};
export default SvgManufacturingAndProduction;
/* eslint-enable */
