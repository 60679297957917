/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgB1 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} viewBox="0 0 60 60" {...props}>
    <g transform="translate(-85 -2597)">
      <circle
        data-name="Ellipse 13"
        cx={30}
        cy={30}
        r={30}
        transform="translate(85 2597)"
        fill="#fff"
        opacity={0.1}
      />
      <g data-name="Group 40">
        <path
          data-name="Path 174"
          d="M112.368 2627.371l6.4-6.4a.567.567 0 00-.8-.8l-6.8 6.8a.567.567 0 000 .8l6.8 6.8a.567.567 0 00.8-.8z"
          fill="#fff"
          stroke="#fff"
          strokeWidth={2.5}
        />
      </g>
    </g>
  </svg>
);

SvgB1.defaultProps = {};
export default SvgB1;
/* eslint-enable */
