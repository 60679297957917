/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgInsurance = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29.512} viewBox="0 0 29.512 33.909" {...props}>
    <g data-name="Group 65">
      <g data-name="Group 61">
        <path
          data-name="Path 212"
          d="M27.8 5.767L15.362.617a1.584 1.584 0 00-1.212 0L1.713 5.767a1.585 1.585 0 00-.961 1.232 25.239 25.239 0 0013.236 26.215 1.579 1.579 0 001.538 0A25.239 25.239 0 0028.761 7a1.585 1.585 0 00-.961-1.232z"
          fill="#f9f9fb"
        />
      </g>
      <g data-name="Group 62">
        <path
          data-name="Path 213"
          d="M15.525 33.215a1.572 1.572 0 01-.837.2A25.217 25.217 0 0027.453 7.466a1.584 1.584 0 00-.961-1.232L14.056 1.083A1.6 1.6 0 0013.3.969l.85-.352a1.583 1.583 0 011.212 0l12.437 5.151A1.583 1.583 0 0128.76 7a25.237 25.237 0 01-13.235 26.215z"
          fill="#7a8c98"
        />
      </g>
      <g data-name="Group 63">
        <path
          data-name="Path 214"
          d="M25.232 8.1l-9.989-4.077a1.29 1.29 0 00-.973 0L4.281 8.1a1.258 1.258 0 00-.772.975 19.868 19.868 0 0010.63 20.749 1.284 1.284 0 001.235 0 19.868 19.868 0 0010.63-20.749 1.258 1.258 0 00-.772-.975z"
          fill="#12b05e"
        />
      </g>
      <g data-name="Group 64">
        <path
          data-name="Path 215"
          d="M15.375 29.825a1.284 1.284 0 01-1.236 0l-.058-.033A20.706 20.706 0 0024.663 8.439a1.3 1.3 0 00-.348-.713l.918.375a1.258 1.258 0 01.772.976 19.867 19.867 0 01-10.63 20.748z"
          fill="#03984b"
        />
      </g>
    </g>
    <g data-name="Group 72">
      <g data-name="Group 66">
        <path
          data-name="Path 216"
          d="M19.531 15.193h-2.21a.785.785 0 01-.788-.782v-2.193a.785.785 0 00-.788-.782h-1.978a.785.785 0 00-.788.782v2.193a.785.785 0 01-.788.782H9.98a.785.785 0 00-.788.782v1.962a.785.785 0 00.788.782h2.208a.785.785 0 01.788.782v2.193a.785.785 0 00.788.782h1.978a.785.785 0 00.788-.782v-2.193a.785.785 0 01.788-.782h2.21a.785.785 0 00.788-.782v-1.962a.785.785 0 00-.785-.782z"
          fill="#ffcd27"
        />
      </g>
      <g data-name="Group 70">
        <g data-name="Group 67">
          <path
            data-name="Path 217"
            d="M15.878 18.719h1.442a.786.786 0 00-.789.782v2.193a.785.785 0 01-.787.782h-1.323a.559.559 0 01-.06 0 .783.783 0 00.728-.779v-2.196a.785.785 0 01.789-.782z"
            fill="#ebb400"
          />
        </g>
        <g data-name="Group 68">
          <path
            data-name="Path 218"
            d="M17.321 15.193h-1.442a.785.785 0 01-.789-.781v-2.194a.782.782 0 00-.728-.778.561.561 0 01.06 0h1.323a.784.784 0 01.787.781v2.194a.785.785 0 00.789.778z"
            fill="#ebb400"
          />
        </g>
        <g data-name="Group 69">
          <path
            data-name="Path 219"
            d="M20.319 15.975v1.963a.785.785 0 01-.787.781H18.09a.784.784 0 00.787-.781v-1.963a.785.785 0 00-.787-.782h1.442a.785.785 0 01.787.782z"
            fill="#ebb400"
          />
        </g>
      </g>
      <g data-name="Group 71">
        <path
          data-name="Path 220"
          d="M29.251 6.927a2.092 2.092 0 00-1.262-1.617L15.552.159a2.067 2.067 0 00-1.592 0L1.524 5.31A2.092 2.092 0 00.262 6.928a27.607 27.607 0 00.871 11.609 24.39 24.39 0 004.07 7.915 27.832 27.832 0 008.546 7.2 2.087 2.087 0 002.022 0 27.833 27.833 0 008.546-7.2 24.392 24.392 0 004.067-7.916 27.607 27.607 0 00.871-11.609zM27.43 18.249c-1.866 6.183-5.952 11.072-12.147 14.532a1.088 1.088 0 01-1.054 0c-6.194-3.46-10.281-8.349-12.147-14.532a26.577 26.577 0 01-.84-11.176 1.1 1.1 0 01.66-.847l12.437-5.15a1.08 1.08 0 01.832 0l12.437 5.15a1.094 1.094 0 01.66.847 26.577 26.577 0 01-.84 11.176z"
        />
        <path
          data-name="Path 221"
          d="M25.42 7.641l-4.861-1.984a.5.5 0 00-.375.919l4.861 1.984a.766.766 0 01.469.59 20.627 20.627 0 01-.671 8.8 19.387 19.387 0 01-9.708 11.443.792.792 0 01-.757 0A19.387 19.387 0 014.67 17.95a20.626 20.626 0 01-.671-8.8.766.766 0 01.469-.59l9.989-4.077a.8.8 0 01.6 0l3 1.224a.5.5 0 10.375-.919l-3-1.224a1.775 1.775 0 00-1.348 0L4.095 7.641A1.763 1.763 0 003.02 9.002a21.657 21.657 0 00.7 9.236 19.309 19.309 0 003.284 6.3 22.374 22.374 0 006.894 5.721 1.792 1.792 0 001.714 0 22.372 22.372 0 006.894-5.721 19.311 19.311 0 003.284-6.3 21.656 21.656 0 00.7-9.236 1.763 1.763 0 00-1.07-1.361z"
        />
        <path
          data-name="Path 222"
          d="M13.767 22.972h1.978a1.282 1.282 0 001.284-1.278v-2.193a.289.289 0 01.292-.286h2.21a1.282 1.282 0 001.284-1.278v-1.962a1.282 1.282 0 00-1.284-1.278h-2.21a.289.289 0 01-.292-.286v-2.193a1.282 1.282 0 00-1.284-1.278h-1.978a1.282 1.282 0 00-1.284 1.278v2.193a.289.289 0 01-.292.286H9.985a1.282 1.282 0 00-1.284 1.278v1.962a1.283 1.283 0 001.117 1.267.5.5 0 00.128-.984.288.288 0 01-.252-.283v-1.962a.289.289 0 01.292-.286h2.211a1.282 1.282 0 001.284-1.278v-2.193a.289.289 0 01.292-.286h1.978a.289.289 0 01.292.286v2.193a1.282 1.282 0 001.284 1.278h2.21a.289.289 0 01.292.286v1.962a.289.289 0 01-.292.286h-2.21a1.282 1.282 0 00-1.284 1.278v2.193a.289.289 0 01-.292.286h-1.978a.289.289 0 01-.292-.286v-2.193a1.282 1.282 0 00-1.284-1.278.5.5 0 000 .992.289.289 0 01.292.286v2.193a1.283 1.283 0 001.278 1.278z"
        />
      </g>
    </g>
  </svg>
);

SvgInsurance.defaultProps = {};
export default SvgInsurance;
/* eslint-enable */
