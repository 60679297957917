import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxMUI, CheckboxProps as CheckboxPropsMUI } from '@mui/material';

export type CheckboxProps = {
    children?: JSX.Element | string
} & CheckboxPropsMUI

const CheckboxStyled = styled(CheckboxMUI)`
`;

const Checkbox = (props: CheckboxProps): JSX.Element => <CheckboxStyled
    color="primary"
    {...props}
>
    {props.children}
</CheckboxStyled>;

export default Checkbox;
