/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg width={223.448} viewBox="0 0 223.448 12.742" {...props}>
    <path
      data-name="Path 1167"
      d="M1.729 11.013a680.392 680.392 0 01220-.9"
      fill="none"
      stroke="#12b05e"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
    />
  </svg>
);

SvgUnderline.defaultProps = {};
export default SvgUnderline;
/* eslint-enable */
