/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgEmployers = (props: SVGProps<SVGSVGElement>) => (
  <svg width={55.348} viewBox="0 0 55.348 48.416" {...props}>
    <path
      d="M49.037 9.632h-11.5V5a4 4 0 00-4-4H21.806a4 4 0 00-4 4v4.632H6.311A5.317 5.317 0 001 14.943v27.163a5.317 5.317 0 005.311 5.311h42.726a5.317 5.317 0 005.311-5.311V14.943a5.317 5.317 0 00-5.311-5.311zM21.194 5a.613.613 0 01.612-.612h11.736a.613.613 0 01.612.612v4.632h-12.96zM4.388 14.943a1.925 1.925 0 011.923-1.923h42.726a1.925 1.925 0 011.923 1.923v2.507a5.35 5.35 0 01-3.835 5.11l-17.717 5.159a6.192 6.192 0 01-3.468 0L8.223 22.564a5.35 5.35 0 01-3.835-5.11zm44.649 29.085H6.311a1.925 1.925 0 01-1.923-1.923V24.347a8.672 8.672 0 002.888 1.466c19.571 5.7 18.566 5.541 20.4 5.541 1.857 0 .8.163 20.4-5.541a8.67 8.67 0 002.888-1.466v17.759a1.925 1.925 0 01-1.923 1.923z"
      fill="#11af5d"
      stroke="#11af5d"
      strokeWidth={2}
    />
  </svg>
);

SvgEmployers.defaultProps = {};
export default SvgEmployers;
/* eslint-enable */
