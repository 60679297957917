/* eslint-disable */
// GENERATED FILE - DO NOT EDIT
import React, { SVGProps } from "react";

const SvgAlternativeMedicine = (props: SVGProps<SVGSVGElement>) => (
  <svg width={27.906} viewBox="0 0 27.906 29.832" {...props}>
    <g data-name="Alternative Medicine">
      <g data-name="Group 184" transform="translate(.439 .431)">
        <path
          data-name="Path 570"
          d="M21.014 8.302l-.035-.033-4.52 4.52.033.035a3.2 3.2 0 004.522-4.522z"
          fill="#ffcd27"
        />
        <path
          data-name="Path 571"
          d="M17.47 11.778l-1.011 1.011.033.035a3.2 3.2 0 003.1.824 3.2 3.2 0 01-2.121-1.871z"
          fill="#ffd301"
        />
        <path
          data-name="Path 572"
          d="M16.497 8.302a3.2 3.2 0 000 4.522l4.522-4.522a3.2 3.2 0 00-4.522 0z"
          fill="#12b05e"
        />
        <path
          data-name="Path 573"
          d="M18.168 8.302a3.182 3.182 0 011.423-.824 3.21 3.21 0 00-.909-.11h-.021a3.2 3.2 0 00-2.165 5.456l1-1a3.2 3.2 0 01.672-3.522z"
          fill="#009e4c"
        />
        <path
          data-name="Path 574"
          d="M24.328 4.039l-1.755-1.751-2.836 2.836 1.755 1.755a2.005 2.005 0 002.836-2.836z"
          fill="#12b05e"
        />
        <path
          data-name="Path 575"
          d="M22.915 5.657L21.16 3.902l-.1-.1-1.318 1.318 1.755 1.755a2.005 2.005 0 003.283-.681 2 2 0 01-1.865-.537z"
          fill="#009e4c"
        />
        <path
          data-name="Path 576"
          d="M22.602 2.309L20.876.587a2.005 2.005 0 10-2.836 2.836l1.726 1.726z"
          fill="#009e4c"
        />
        <path
          data-name="Path 577"
          d="M19.458 2.206a2.005 2.005 0 01-.447-2.155 2 2 0 00-.971 3.372l1.726 1.726 1.318-1.318z"
          fill="#12b05e"
        />
        <circle
          data-name="Ellipse 50"
          cx={1.125}
          cy={1.125}
          r={1.125}
          transform="translate(13.622 3.513)"
          fill="#ffcd27"
        />
        <path
          data-name="Path 578"
          d="M14.591 4.634a1.124 1.124 0 01.641-1.014 1.125 1.125 0 100 2.029 1.124 1.124 0 01-.641-1.015z"
          fill="#ffcd27"
        />
        <path
          data-name="Path 579"
          d="M20.593 20.226a.818.818 0 00.56-.15l3.653-2.628a1.394 1.394 0 01.64-.253.116.116 0 00.1-.118 1.61 1.61 0 00-.6-1.247 1.4 1.4 0 00-1.97.347l-2.578 3.717a.83.83 0 01-.33.279z"
          fill="#ffbd86"
        />
        <path
          data-name="Path 580"
          d="M24.822 16.175a1.434 1.434 0 01.14-.172.116.116 0 00-.013-.174 1.4 1.4 0 00-1.97.347l-2.578 3.717a.83.83 0 01-.33.279l.522.053a.818.818 0 00.56-.15l1.91-1.374a.116.116 0 00.028-.028z"
          fill="#f6a96c"
        />
        <path
          data-name="Path 581"
          d="M26.806 17.787a1.418 1.418 0 00-2-.377l-3.615 2.549a.946.946 0 01-.638.168l-1.9-.189a.2.2 0 01-.1-.36 1.544 1.544 0 00-.647-2.8l-6.281-.96a2.781 2.781 0 00-2.022.475l-3.334 2.349a.116.116 0 00-.028.163l3.726 5.233a.116.116 0 00.152.033 8.792 8.792 0 014.446-1.145l5.66.069a4.525 4.525 0 002.719-.824l3.468-2.443a1.424 1.424 0 00.394-1.941z"
          fill="#fed2a4"
        />
        <path
          data-name="Path 582"
          d="M9.071 17.956l2.365-1.667a2.752 2.752 0 01.389-.229.116.116 0 00-.031-.22l-.168-.026a2.781 2.781 0 00-2.022.475L6.27 18.638a.116.116 0 00-.028.163l3.726 5.233a.116.116 0 00.152.033 8.783 8.783 0 011.14-.542.116.116 0 00.053-.176l-2.551-3.583a1.3 1.3 0 01.309-1.81z"
          fill="#ffbd86"
        />
        <path
          data-name="Path 583"
          d="M18.073 19.881a.117.117 0 00.126-.083.08.08 0 01.05-.057 1.558 1.558 0 00.782-.686.461.461 0 00-.084-.016l-4.222-.376a.458.458 0 10-.082.912z"
          fill="#ffbd86"
        />
        <path
          data-name="Path 584"
          d="M3.658 18.986a.116.116 0 00-.162-.028L.282 21.235a.646.646 0 00-.168.9l4.648 6.562a.646.646 0 00.9.139l3.214-2.277a.116.116 0 00.028-.162z"
          fill="#ffcd27"
        />
        <path
          data-name="Path 585"
          d="M2.076 22.132a.646.646 0 01.168-.9l1.927-1.32a.116.116 0 00.029-.163l-.542-.766a.116.116 0 00-.162-.028L.282 21.232a.646.646 0 00-.168.9l4.648 6.562a.646.646 0 00.9.139l.676-.479a.116.116 0 00.028-.162z"
          fill="#ebb400"
        />
        <path
          data-name="Path 586"
          d="M8.848 26.436a.116.116 0 00.162.028l1.319-.934a.639.639 0 00.158-.89l-4.648-6.562a.639.639 0 00-.892-.146l-1.319.934a.116.116 0 00-.028.162z"
          fill="#d9edff"
        />
        <g data-name="Group 183">
          <path
            data-name="Path 587"
            d="M6.073 18.612a.116.116 0 00.029-.163l-.263-.372a.639.639 0 00-.892-.146l-1.319.934a.116.116 0 00-.028.162l.544.768a.116.116 0 00.161.029z"
            fill="#b4dbff"
          />
        </g>
        <path
          data-name="Path 588"
          d="M17.008 8.302l-.035-.033-4.52 4.52.033.035a3.2 3.2 0 004.522-4.522z"
          fill="#ffcd27"
        />
        <path
          data-name="Path 589"
          d="M13.464 11.778l-1.011 1.011.033.035a3.2 3.2 0 003.1.824 3.2 3.2 0 01-2.121-1.871z"
          fill="#ffd301"
        />
        <path
          data-name="Path 590"
          d="M12.486 8.302a3.2 3.2 0 000 4.522l4.522-4.522a3.2 3.2 0 00-4.522 0z"
          fill="#12b05e"
        />
        <path
          data-name="Path 591"
          d="M14.162 8.302a3.182 3.182 0 011.423-.824 3.211 3.211 0 00-.909-.11h-.025a3.2 3.2 0 00-2.169 5.456l1-1a3.2 3.2 0 01.68-3.522z"
          fill="#009e4c"
        />
      </g>
      <g data-name="Group 185">
        <path
          data-name="Path 592"
          d="M4.844 29.385a1.082 1.082 0 001.512.243l4.666-3.3a1.075 1.075 0 00.262-1.5l-.107-.151a8.364 8.364 0 013.824-.877l.989.012a.437.437 0 00.011-.874l-.989-.012a9.238 9.238 0 00-4.346 1.029l-3.368-4.756 3-2.113a2.333 2.333 0 011.7-.4l6.282.961a1.11 1.11 0 01-.105 2.206h-.033l-2.344-.187a.437.437 0 10-.07.871c3.53.282 2.413.182 5.259.466a1.281 1.281 0 00.855-.225l3.654-2.574a.972.972 0 111.1 1.6l-3.469 2.444a4.122 4.122 0 01-2.462.744l-2.633-.032h-.005a.437.437 0 00-.005.874l2.627.032a4.992 4.992 0 002.982-.9l3.469-2.444a1.871 1.871 0 00.506-2.536 1.837 1.837 0 00-1.2-.8h-.017a1.833 1.833 0 00-3.332-.816l-2.573 3.71-.828-.082a1.983 1.983 0 00-1.246-3.212l-6.282-.961a3.2 3.2 0 00-2.34.55l-3 2.114-.157-.222a1.077 1.077 0 00-1.5-.25l-4.666 3.3a1.082 1.082 0 00-.272 1.507zm18.932-12.522a.954.954 0 011.734.371 1.894 1.894 0 00-.516.255l-3.23 2.275zM5.635 18.724a.2.2 0 01.283.042l4.649 6.563a.2.2 0 01-.054.281l-1 .708-4.878-6.886zm-4.666 3.3l2.953-2.092L8.8 26.818 5.847 28.91a.21.21 0 01-.293-.035L.905 22.312a.21.21 0 01.071-.283z"
        />
        <path
          data-name="Path 593"
          d="M25.077 7.622a2.445 2.445 0 000-3.454L21.625.716a2.442 2.442 0 10-3.454 3.454l3.452 3.452a2.445 2.445 0 003.454 0zm-6.288-4.07a1.568 1.568 0 012.218-2.218l1.417 1.417-2.218 2.218zm2.035 2.035l2.216-2.218 1.417 1.417a1.568 1.568 0 11-2.217 2.218z"
        />
        <path
          data-name="Path 594"
          d="M13.625 5.076a1.562 1.562 0 101.562-1.562 1.564 1.564 0 00-1.562 1.562zm2.25 0a.688.688 0 11-.688-.688.689.689 0 01.688.688z"
        />
        <path
          data-name="Path 595"
          d="M21.903 12.754a.437.437 0 00.581-.21 3.645 3.645 0 00-.721-4.111 3.627 3.627 0 00-4.573-.463 3.634 3.634 0 100 6.065 3.628 3.628 0 003.692.186.437.437 0 00-.406-.774 2.757 2.757 0 01-2.589-.012 3.646 3.646 0 00.733-1.241l2.809-2.809a2.77 2.77 0 01.264 2.789.437.437 0 00.21.58zm-9.361-2.543a2.763 2.763 0 014.262-1.446l-3.854 3.854a2.768 2.768 0 01-.408-2.408zm5.291 1.572v.008a2.763 2.763 0 01-4.263 1.446l3.854-3.854a2.765 2.765 0 01.409 2.401zm.981-1.021a3.636 3.636 0 00-.927-2.194 2.754 2.754 0 012.925.2z"
        />
      </g>
    </g>
  </svg>
);

SvgAlternativeMedicine.defaultProps = {};
export default SvgAlternativeMedicine;
/* eslint-enable */
