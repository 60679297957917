import React from 'react';
import styled from 'styled-components';
import { CenteredFlex } from './Flex';

export type CircleDividerProps = {
}

const CircleOutlined = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 6px;
    border: solid 2px ${props => props.theme.palette.main};
    background-color: #f9f9fb;
    position: absolute;
`;
const LineStyled = styled.div`
    width: 100px;
    border: solid 1px ${props => props.theme.palette.main};
`;
const CircleDividerStyled = styled(CenteredFlex)`

`;

const CircleDivider = (props: CircleDividerProps): JSX.Element => <CircleDividerStyled>
    <LineStyled />
    <CircleOutlined />
</CircleDividerStyled>;

export default CircleDivider;
