/* eslint-disable max-len */
import {
    Container, Typography,
} from '@mui/material';
import { LoggedInUser } from '../../../types/auth.types';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';

export type PrivacyProps = {
    url: string
    user?: LoggedInUser
}

const Privacy = (props: PrivacyProps): JSX.Element => <Page
    url={props.url}
    user={props.user}
    pageHeaderTitle="Mploy - מדיניות הגנת הפרטיות" >
    <Container>
        <CustomBR />
        <BGContainer>
            <Typography color="secondary" fontWeight="bold" variant="h4">מדיניות הגנת הפרטיות</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography variant="body1" whiteSpace="pre-line">
                    {`
מאחר שהחברה Mploy (להלן: "המפעילה") מכבדת את פרטיות המשתמשים שלה בפלטפורמה (האתר www.mploy.co.il והאפליקציה Mploy) שהיא מנהלת ומפעילה (להלן: "הפלטפורמה"), החליטה המפעילה לפרסם את מדיניותה ביחס להגנת הפרטיות באתר, והיא מתחייבת כלפי המשתמש לקיים מדיניות זו. 

מטרת המדיניות הפרטיות היא להסביר מהם נוהגי המפעילה ביחס לפרטיות המשתמשים באתר Mploy, וכיצד משתמשת המפעילה במידע, הנמסר לה על-ידי המשתמשים בפלטפורמה או הנאסף על-ידה בעת השימוש בפלטפורמה. 

בזמן שימוש בשירותי האתר ובייחוד בעת הרשמה לאתר נאסף מידע עליך. חלק מהמידע מזהה אותך באופן אישי, כלומר בשמך ובכתובתך, ניסיונך המקצועי, אמצעי תשלום וכיו"ב. זהו המידע שאתה מוסר ביודעין, לדוגמה בעת הרשמה לאתר או ההתחברות בפעם הראשונה דרך הפייסבוק או ליניקדאין. לא כל המידע מזהה אותך אישית, חלק מהמידע אינו נשמר יחד עם פרטיך ונקרא מידע סטטיסטי ומצטבר. לדוגמה, העמודים שבהם צפית, פרסומות, ההצעות והשירותים שעניינו אותך, חיפושים שביצעת, כתובת האינטרנט (IP) שממנה פנית ועוד.
הנתונים שנאספו על ידי המפעילה המזהה אותך באופן אישי יישמרו במאגר המידע של המפעילה ובאחריותה. 

שימוש במידע

השימוש במידע שנאסף על ידי המפעילה יהיה אך ורק על פי מדיניות הפרטיות זו או על פי הוראות כל דין
מידע שלא מסרת באופן אישי כדי להשתמש בפלטפורמה וישמש את המפעילה, יהיה מידע שאינו מזהה אישית וסטטיסטי בעיקרו.

מטרת השימוש במידע

- לאפשר למשתמשים להשתמש בשירותי האתר בצורה קלה ופשוטה.
- לאפשר למשתמשים, מעסיקים, המפרסמים באתר גישה לגיליון קו"ח של מחפשי עבודה אשר שלחו קורות חיים למשרות אשר פרסמו אותם מעסיקים.
- לצורך יצירת קשר עם המשתמש באמצעות דואר אלקטרוני ו/או הודעת SMS לטלפון הנייד ו/או בדואר ו/או בטלפון, וזאת כדי למסור למשתמש הצעות עבודה והצעות הנוגעות לשירותים אותם מציעים המפעילה או צדדים שלישיים , וכן מידע והצעות בעלי תוכן שיווקי או פירסומי של המפעילה או של ממפרסמים שונים המבקשים לפנות למחפשי העבודה באמצעות המפעילה. הצעות אלה יישלחו למשתמש רק בהסכמתו המתבטאת בהסכמתו לתנאי השימוש באתר. המשתמש רשאי בכל עת לחזור בו מהסכמתו זו.
- על מנת להתאים את הצעות העבודה ו/או הצעות פירסומיות, הנשלחות למשתמש בדוא"ל ו/או בהודעת SMS, לתחום המקצועי הרלוונטי עבורו.
- לאפשר למעסיקים פוטנציאליים, גישה לקורות החיים של כל מחפשי עבודה אשר שלחו קורות חיים, למשרות שונות באתר, וזאת על מנת לתת למשתמשים חשיפה מירבית של הזדמנויות תעסוקה.
- לצורך פיתוח האתר ותכניו ותפעולם התקין, לרבות שינוי, שיפור או העשרה של השירותים והתכנים המוצעים באתר. המידע לצורך זה, יהיה בעיקרו סטטיסטי, שאינו מזהה את המשתמש באופן אישי.
- לצורך ניתוח ומסירת מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים, מבלי לזהות את המשתמש באופן אישי.
- לכל מטרה אחרת המפורטת במדיניות הפרטיות או בתקנון האתר.

מסירת מידע לגוף שלישי 

המפעילה לא תעביר לצדדים שלישיים את פרטיך האישיים והמידע שנאסף על פעילותך באתר מלבד המקרים המפורטים להלן :

• אם המפעילה תקבלו צו מבית משפט המורה על מסירת פרטיך או המידע אודותיך לגוף שלישי
• אם תבצע בפלטפורמה (האתר או האפליקציה), פעולות שבניגוד לדין.
• אם תרכוש מוצרים ושירותים מצדדים שלישיים המציעים אותם למכירה באמצעות הפלטפורמה, יועבר לצדדים שלישיים אלה המידע הדרוש להם לשם השלמת תהליך הרכישה שאותו ביצעת.
• במקרה של מחלוקת משפטית בינך לבין המפעילה שתחייב חשיפת פרטיך.
• אם המפעילה תמכור או תעביר בכל צורה שהיא את פעילות הפלטפורמה לתאגיד כלשהו - וכן במקרה שתתמזג עם גוף אחר או תמזג את פעילות הפלטפורמה עם פעילותו של צד שלישי, ובלבד שתאגיד זה יקבל על עצמו כלפיך את הוראות מדיניות פרטיות זו. 

Cookies

המפעילה משתמש ב"עוגיות" ( Cookies ) על מנת לאפשר את תפעול השוטף של הפלטפורמה, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפותיך האישיות ולצורכי אבטחת מידע. 
דפדפנים מודרניים כוללים אפשרות להימנע מקבלת Cookies, אם אינך יודע כיצד לעשות זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש. 

פרסומות של צדדים שלישיים 

המפעילה מתירה לחברות אחרות לנהל את מערך הפרסומות בפלטפורמה. המודעות שבהן אתה צופה בעת הביקור באתרי מגיעות ממחשביהן של אותן חברות. כדי לנהל את הפרסומות שלהן, חברות אלה מציבות Cookies במחשבך. ה-Cookies מאפשרים להן לאסוף מידע על הפלטפורמה שבהם צפית בפרסומות שהציבו ועל אילו פרסומות הקשת. השימוש שחברות אלה עושות ב-Cookies כפוף למדיניות הפרטיות שלהן ולא למדיניות הפרטיות של המפעילה. 

דיוור ישיר אלקטרוני 

המפעילה מעוניינת לשלוח אליך מדי פעם בדואר אלקטרוני מידע בדבר שירותיה וכן מידע שיווקי ופרסומי 
מידע כזה ישוגר אליך רק אם נתת הסכמה מפורשת לכך, ובכל עת תוכל לבטל את הסכמתך ולחדול מקבלתו. 
המפעילה לא תמסור את פרטיך האישיים למפרסמים. עם זאת, היא רשאית להעביר מידע סטטיסטי על פעילות המשתמשים בפלטפורמה. 
מידע סטטיסטי שיועבר לא יזהה אותך אישית. 

זכות לעיין במידע 

על-פי חוק הגנת הפרטיות, התשמ"א - 1981, כל אדם זכאי לעיין במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למוחקו. פנייה כזאת יש להפנות אל  info@mploy.co.il.
בנוסף, אם המידע שבמאגרי המפעילה משמש לצורך פניה אישית אליך, אתה זכאי על-פי חוק הגנת הפרטיות, התשמ"א- 1981 לדרוש בכתב שהמידע המתייחס אליך יימחק ממאגר המידע.

אבטחת מידע 

המפעילה מיישמת באתריה מערכות ונהלים עדכניים לאבטחת מידע. בעוד שמערכות ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי-מורשית, אין הם מעניקים בטחון מוחלט. לכן, המפעילה לא מתחייבת ששירותיה יהיו חסינים באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בהם. 

מחיקת מידע

משתמש יכול לבקש בכל עת למחוק את פרטיו האישיים על ידי בקשה כתובה, באמצעות שליחת מייל לכתובת : info@mploy.co.il

שינויים במדיניות הפרטיות 

בכל מקרה בו יבוצעו במדיניות זו שינויים מהותיים בהוראות שעניינן השימוש במידע אישי שמסרת, תפורסם על-כך הודעה בעמוד הבית של הפלטפורמה.
                    `}

                </Typography>
            </WhiteBGContainer>
            <CustomBR />
            <Typography color="secondary" fontWeight="bold" variant="h4">Mploy והרשות להגנת הפרטיות</Typography>
            <CustomBR />
            <WhiteBGContainer>
                <Typography variant="body1" whiteSpace="pre-line">
                    {`

Mploy רשומה כמאגר מידע בפנקס מאגרי המידע של הרשות להגנת הפרטיות.

שם המאגר: Mploy
מספר המאגר: 700064245
מספר זיהוי: 515721058
                `}
                </Typography>
            </WhiteBGContainer>
        </BGContainer>
        <CustomBR />
    </Container>
</Page>;

export default Privacy;
