import { TextFieldProps } from '@mui/material';
import { useState, memo } from 'react';
import TextField from '../TextField';

export type TextAreaProps = {
    maxLength?: number
    defaultValue?: string
} & TextFieldProps

const TextArea = (props: TextAreaProps): JSX.Element => {
    const [letters, setLetters] = useState<number>(props.defaultValue?.length || 0);

    const countLetters = ev => {
        setLetters(ev.target.value?.length);
        if (props.onChange) props.onChange(ev);
    };

    return <TextField
        multiline
        {...props}
        onChange={countLetters}
        inputProps={{ style: { resize: 'vertical' } }}
        helperText={(props.helperText || '') + (props.maxLength ? ` ${props.maxLength} / ${letters}` : '')}
    />;
};

export default memo(TextArea);
