import { Container, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { TagViewModel } from '../../../types/tags.types';
import Button from '../foundation/Button/Button';
import { colCenteredFlex } from '../foundation/Flex';
import mapCatsToSvg from './AllCategories/mapCatsToSvg';
import CustomBR from '../foundation/CustomBR';
import Anchor from '../foundation/Anchor';

const cats = [4, 945, 49, 1, 36, 30, 50, 22];

export type PopularDomainsProps = {
    categories: (TagViewModel & {
        totalJobs: number
    })[]
    numberOfTodayJobs?: number
}

const PopularDomainsStyled = styled.div`
    background-color:#fafafb;
    .MuiContainer-root {
        ${colCenteredFlex}
        > h3 {
            font-weight: bold;
        }
        > p {
            font-weight: 600;
            display: inline-block;
            border-bottom: 0.15rem solid;
        }
    }
`;
const PopularDomainItemStyled = styled.div`
    padding: 2rem;
    margin: 1rem;
    background-color: white;
    display: flex;
    border-radius: 10px;
    svg { 
        margin-inline-end: 1.5rem;
    }
    h5 {
        font-weight: 600;
    }
    cursor: pointer;
    &:hover {
        box-shadow: 0 23px 26px 0 #b5b5c929;
    }
    > div > div {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        button {
            margin-inline-end: 0.5rem;
        }
    }
`;

const PopularDomains = (props: PopularDomainsProps): JSX.Element => <PopularDomainsStyled>
    <Container>
        <CustomBR h={44} />
        <Typography color="secondary" variant="h3">תחומים פופולרים</Typography>
        <CustomBR />
        <Typography color="secondary" variant="h6">{props.numberOfTodayJobs} משרות עדכניות מהיום</Typography>
        <CustomBR />
        <Grid container>
            {props.categories?.map(cat => {
                if (cats.indexOf(cat.tagid) === -1) return null;
                const onClick = () => window.location.assign(`/job/tagged/${cat.display}`);
                const Icon = mapCatsToSvg[cat.tagid];
                return <Grid item xs={12} sm={6} md={4} key={cat.tagid}>
                    <PopularDomainItemStyled role="button"
                        onKeyUp={onClick}
                        onClick={onClick}
                        tabIndex={0}>
                        <div><Icon width={40} /></div>
                        <div>
                            <Typography color="secondary" variant="h5">{cat.tag}</Typography>
                            <div>
                                <Button onClick={onClick} color="secondary" size="small">מצא עבודה</Button>
                                <Typography color="secondary" variant="body2">{cat.totalJobs} משרות</Typography>
                            </div>
                        </div>
                    </PopularDomainItemStyled>
                </Grid>;
            })}
        </Grid>
        <CustomBR h={44} />
        <Typography variant="h5" color="primary" fontWeight="bold">
            <Anchor href="#all-categories">עבור לכל {props.categories.length} התחומים {'>'}</Anchor>
        </Typography>
        <CustomBR h={44} />
    </Container>
</PopularDomainsStyled>;

export default React.memo(PopularDomains);
